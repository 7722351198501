<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block" id="exampleModalLabel">
      Login
      <a *ngIf="isEnableCls" (click)="close('Close')" class="fs-12 text-underline float-right pt-1 text-white" data-dismiss="modal">
        <i class="fas fa-times"></i>
      </a>
    </h5>
  </div>
  <div class="modal-body">
    <form [formGroup]="SubmitLoginForm">
      <p class="text-right text-secondary fs-12 mandatory d-inline-block float-right">Required Fields.</p>
      <div class="form-group">
        <label class="fieldLabel mandatory">User ID/ Email ID</label>
        <input type="text" class="form-control" formControlName="USER_ID" autocomplete="off"
               placeholder="Please enter User ID or Registered Email ID" maxlength="100"
               (blur)="handleInput($event.target,'USER_ID');"
               [ngClass]="{ 'is-invalid': (form.USER_ID.dirty || form.USER_ID.touched || submitted) && form.USER_ID.errors }">
        <div *ngIf="(form.USER_ID.dirty || form.USER_ID.touched || submitted) && form.USER_ID.invalid" class="invalid-form-control text-danger fs-12">
          <div *ngIf="form.USER_ID.errors?.['required']">
            Email or User ID is required.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="fieldLabel mandatory">Password</label>
        <input type="password" class="form-control" formControlName="PASSWORD" autocomplete="off"
               placeholder="Please Enter" maxlength="100"
               [ngClass]="{ 'is-invalid': (form.PASSWORD.dirty || form.PASSWORD.touched || submitted) && form.PASSWORD.errors }">
        <div *ngIf="(form.PASSWORD.dirty || form.PASSWORD.touched || submitted) && form.PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
          <div *ngIf="form.PASSWORD.errors?.['required']">
            Password is required.
          </div>
        </div>
      </div>
      <div *ngIf="invalidCredentials && (form.USER_ID.errors||form.PASSWORD.errors)" class="invalid-form-control text-danger fs-12">
        Invalid User ID/ Email ID or Password.
      </div>
      <!-- <div *ngIf="submitted && noAccountFound" class="invalid-form-control text-danger fs-12">
        No account is found with the credentials you entered, create a new account.
      </div> -->
      <div *ngIf="submitted && passwordExpired" class="invalid-form-control text-danger fs-12">
        Your password has expired. Please click Forgot Password to Reset Password.
      </div>
      <div *ngIf="submitted && userDeactivated">
        You have requested for deactivation of your account on {{this.deactivatedDate | date:'dd-MMM-YYYY'}} please click on <a href="javascript:void(0)"
                                                                                                                                (click)="openVerificationPopup(userLoginResult)">Verify</a> to reactivate your account for Login
      </div>
      <div class="text-center pt-2">
        <!-- <span tooltip="{{this.enableLogin ? '': 'Please provide UserID/ EmailID and Password to Login'}}"> -->
        <button type="button" class="btn btn-primary btn-block text-center px-3 fs-14"
                [disabled]="!this.enableLogin" (click)="login()">
          LOGIN
        </button>
        <!-- </span> -->
      </div>

      <div class="">
        <a class="fs-12 text-underline float-right pt-1" href="javascript:void(0)" (click)="this.openForgotPassword()">Forgot your Password?</a>
      </div>
      <div class="fs-13 pt-3 text-center clearfix pb-2">
        Don't have an account?
        <a (click)="this.openRegisterForm()" href="javascript:void(0)">Click here to Register</a>
      </div>

      <div class="col-md-12 text-center pb-3" *ngIf="!isEnableCls">
        <a href="javascript:void(0)" class="d-inline-block fs-13 text-underline pb-0"
           (click)="OnNotProfileClick()"><i class="fas fa-sign-out-alt fs-16"></i> &nbsp; Not a {{profilename}}?</a>
      </div>

    </form>
  </div>
</div>
