import { Component, OnInit, Input, forwardRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadDirective, TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProductControlService } from '../../_services/product-control.service';
import { UtilityService } from '../../_services/utility.service';
import { escape } from 'lodash';
import { ProductsAutoComplete } from 'src/app/_models/products';

@Component({
  selector: 'app-product-control',
  templateUrl: './product-control.component.html',
  styleUrls: ['./product-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductControlComponent),
      multi: true,
    },
  ],
})
export class ProductControlComponent implements OnInit, ControlValueAccessor {
  @Input("inputAllProducts") inputAllProducts: ProductsAutoComplete[] = [];
  @Input("inputSelectedProduct") inputSelectedProduct: string | '' = '';
  @ViewChild('typeahead') typeahead: TypeaheadDirective;
  @Output() newItemEvent = new EventEmitter<boolean>();

  private _value: any;
  public disabled: boolean;
  onChange: any = () => { }
  onTouch: any = () => { }

  isProductInvalid: boolean = false;
  typeaheadNoResults: boolean = false;
  productTypeaheadLoading: boolean;
  typeaheadHideResultsOnBlur: boolean = true;
  productDataSource$: Observable<ProductsAutoComplete[]>;
  typeaheadMinLength = 0;

  constructor(private productControl: ProductControlService,
    public utilityService: UtilityService,) { }

  get value(): any { return this._value; };

  set value(val: any) {
    this._value = val;
    this.onChange(val);
  }

  writeValue(value: string) {
    if (value == "") {
      this.inputSelectedProduct = '';
    }
    else {
      this.value = value;
    }
  }


  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.filterProducts();
  }

  onProductChange($event: any) {
    if ($event == "") {
      this.onChange(undefined);
    }
  }

  filterProducts() {
    this.productDataSource$ = Observable.create((observer: any) => {  // Runs on every search
      observer.next(this.inputSelectedProduct);
    }).pipe(
      switchMap((searchKey: string) => this.productControl.searchProducts(this.inputAllProducts, searchKey)));
  }

  fixedHighlight(match: TypeaheadMatch, query: string[]): any {
    // Avoid calling
    query = query.filter((value) => value.trim().length > 0);
    match = new TypeaheadMatch(match.item, escape(match.value), match.isHeader());
    let res: any;
    if (this.typeahead && this.typeahead._container) {
      res = this.typeahead._container?.highlight(match, query);
    }
    return res;
  }

  onProductSelect($event: TypeaheadMatch): void {
    this.newItemEvent.emit(false);
    if ($event.item && typeof $event.item === 'object' && $event.item !== null && $event.item?.PK_PRODUCT_MAPPING_ID != '') {
      this.typeaheadNoResults = false;
      if ($event.item != null && $event.item != undefined)
        this.inputSelectedProduct = $event.item.PRODUCT_DISPLAY_NAME;
    }
    else {
      this.inputSelectedProduct = '';
    }
    if ($event.item != null && $event.item != undefined) {
      this.onChange($event.item.PK_PRODUCT_MAPPING_ID);
      this.newItemEvent.emit(true);
    }
  }

  productTypeaheadOnBlur($event: any): void {
    //this.isProductInvalid = false;
    let filteredProducts = this.inputAllProducts.filter(p => p.PRODUCT_DISPLAY_NAME.toLowerCase().indexOf(($event.srcElement.value.toLowerCase())) > -1);
    if ($event.item && typeof $event.item === 'object' && $event.item !== null && $event.item?.PK_PRODUCT_MAPPING_ID != '' || filteredProducts && filteredProducts.length > 0) {
      this.typeaheadNoResults = false;
      if ($event.item != null && $event.item != undefined)
        this.inputSelectedProduct = $event.item.PRODUCT_DISPLAY_NAME;
    }
    else {
      this.typeaheadNoResults = true;
      if ($event.srcElement) $event.srcElement.value = '';
      $event.srcElement.onpointerrawupdate = '';
      this.inputSelectedProduct = $event.srcElement.value;
      this.onChange(undefined);
    }
  }

  productTypeaheadNoResults($event: any) {
    if ($event && typeof $event.item === 'object' && $event.item !== null && $event.item?.PK_PRODUCT_MAPPING_ID != '') {
      this.typeaheadNoResults = true;
    }
    else {
      if (this.inputSelectedProduct == '') {
        this.typeaheadNoResults = false;
      }
    }
  }

  changeProductTypeaheadLoading(e: any): void {
    this.productTypeaheadLoading = e;
  }


  handleInput($event: any): boolean {
    const control = document.getElementById('homeproducta');
    $event.value = this.utilityService.replaceEmojis($event.value);
    control?.setAttribute("value", $event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control?.setAttribute("value", $event.value);
      return false;
    }
    return true;
  }

}


