export enum FileStoreType {
  TEMP = 'TEMP',
  DOC = 'DOC',
  FILE = 'FILE'
}

export enum ComponentType {
  SUBMIT_REQUEST = 'SUBMIT_REQUEST',
  MSL_REQUEST = 'MSL_REQUEST',
  CONTENT_INFORMATION = 'CONTENT_INFORMATION',
  OUR_PRODUCTS = 'OUR_PRODUCTS',
  OUR_PRODUCTS_PRODUCTS = 'OUR_PRODUCTS_PRODUCTS'
}

export enum ContentInformationResponseType {
  Document = 0,
  FAQ
}

export enum FileIconType {
  IMAGE = "IMAGE",
  PDF = "PDF",
  WORD = "WORD",
  HTML = "HTML",
  PPT = "PPT",
  EXEL = "EXEL",
  VIDEO = "VIDEO",
  OTHER = "OTHER"
}

export enum MAPPING_TYPE {
  HYBRID_MIQ = "HYBRID_MIQ",
  HYBRID_MIQ_VAULT = "HYBRID_MIQ_VAULT",
  MIQ_ONLY = "MIQ_ONLY"
}


export class RegistrationErrors {
  public static EmailIdExists = "Email Id already exists";
  public static UserIdExists = "User Id already exists";
  public static LicenseNumberExists = "License number already exists";
  public static RegisteredAlreadyVerified = "Registered User Already Verified";
  public static RegisteredNotVerified = "Registered User Not Verified";
  public static RegisteredDeactivated = "Registered User Deactivated";
  public static InvalidCredentials = "Invalid User Name or Password";
  public static PasswordExpired = "Password Expired";
  public static NoAccountFound = "No Account Found";
  public static ExistsInLast3Passwords = "Exists In Last 3 Passwords";
  public static InvalidCurrentPassword = "Invalid Password";
  public static PWD_POLICIES_NOT_CONFIGURED = "Password policies not configured. Please contact administrator.";
  public static IsForcedPWDChange = "Forced to change password";
  public static MultiBrowserLogin = "MultiBrowserLogIn";
}

export class AccountLoginErrors {
  public static ProfileNotFound = "Portal User Environment Profile Not Found.";
  public static UserNotAllowedToProfile = "User not allowed to this profile.";
}

export class CodeVerificationErrors {
  public static InValidCode = "InValid Code";
  public static InValidLink = "InValid Link";
  public static CodeExpired = "Code Expired";
  public static AlreadyVerified = "User Already Verified";
  public static EmailExists = "Registered User Not Verified";
}

export class Constants {
  public static ForgotPassword = "ForgotPassword";
}

export class RequestTypes {
  public static Call = "Call";
  public static Meeting = "Meeting";
  public static AdverseEvent = "Adverse Event";
  public static ProductComplaint = "Product Complaint";
  public static MedicalInformation = "Medical Information";
}

export class PortalConfigErrors {
  public static PORTAL_NOT_FOUND = "Portal not found or inactive.";
  public static PORTAL_NOT_CONFIGURED = "Portal not configured.";
  public static AUTH_NOT_CONFIG = "Authentication not configured.";
}



export class PROFILETYPES {
  public static SYSTEM: number = 0;
  public static APP_ADMIN: number = 1;
  public static ENV_ADFMIN: number = 2;
  public static ENV_PORTAL: number = 3;
}

export class ACCESSTYPES {
  public static NO_ACCESS: number = 0;
  public static GUEST: number = 1;
  public static AUTHENTICATED: number = 2;
}

export class USERTYPES {
  public static INTERNAL: number = 0;
  public static EXTERNAL: number = 1;
}

export class FILE_ICON_TYPE {
  public static IMAGE: string = "IMAGE";
  public static PDF: string = "PDF";
  public static WORD: string = "WORD";
  public static HTML: string = "HTML";
  public static PPT: string = "PPT";
  public static XLS: string = "XLS";
  public static VIDEO: string = "VIDEO";
  public static OTHER: string = "OTHER";

}


export class REQUEST_SOURCE {
  public static PORTALS = "P";
  public static ADMIN = "AC";
}


export class LOGOUT_REASON {
  public static MANUAL = "Manual";
  public static PWD_CHANGE = "Password Change";
  public static SESSION_EXPIRE = "Session Expire";
  public static AUTH_FAILURE = "Authorization Failure";
  public static SESSION_OVERRIDE = "Session Override";
}
