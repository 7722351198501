<div class="tradeName position-relative">
  <input class="form-control" id="countryautocomplete" name="countryautocomplete" [(ngModel)]="inputSelectedCountry"
         [disabled]="disabled"
         #typeahead=bs-typeahead
         placeholder="-Select-"
         [typeaheadAsync]="true"
         [typeahead]="countryDataSource$"
         typeaheadOptionField="CountryDisplayName"
         [typeaheadMinLength]="typeaheadMinLength"
         [typeaheadSingleWords]="true"
         [typeaheadScrollable]="true"
         [typeaheadSelectFirstItem]="false"
         [typeaheadIsFirstItemActive]="false"
         [typeaheadOptionsInScrollableView]="5"
         [typeaheadOptionsLimit]="1000"
         [typeaheadWaitMs]="500"
         [typeaheadHideResultsOnBlur]="typeaheadHideResultsOnBlur"
         (countryTypeaheadLoading)="changeCountryTypeaheadLoading($event)"
         (focusout)="countryTypeaheadOnBlur($event)"
         (typeaheadOnSelect)="onCountrySelect($event)"
         (typeaheadNoResults)="countryTypeaheadNoResults($event)"
         autocomplete="off"
         maxlength="150"
         (ngModelChange)="onCountryChange($event)"
         [typeaheadItemTemplate]="template" />

  <ng-template #template let-match="match" let-query="query">
    <div [innerHtml]="fixedHighlight(match, query)"></div>
  </ng-template>

  <span class="pushaheadfield"><i class="fas fa-long-arrow-alt-down"></i></span>
  <div *ngIf="countryTypeaheadLoading===true">Loading</div>
</div>
