import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[noSpecailChars]'

})
export class NoSpecailCharsDirective {

  constructor(private _el: ElementRef, private ngControl: NgControl) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    // const initalValue = this._el.nativeElement.value;
    // this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9 ]/, '');
    // if (initalValue !== this._el.nativeElement.value) {
    //   event.stopPropagation();
    // }

    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
        return true;
    }

    event.preventDefault();
    return false;
  }

  @HostListener('keypress', ['$event']) onKeypress(event: any) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
        return true;
    }

    event.preventDefault();
    return false;
  }

  @HostListener('paste', ['$event']) onPaste(event: any) {
    let clipboardData = event.clipboardData;
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    let pastedText = clipboardData.getData('text');
    if (regex.test(pastedText)) {
        return true;
    }

    event.preventDefault();
    return false;
  }

}
