<div class="captcha-container {{ config.cssClass}}">
    <canvas id="captcahCanvas" width="316" height="80"></canvas>
    

    <div class="captcha-actions">
  <input type="text" [(ngModel)]="captch_input" />
  <input type="button" value="Check" (click)="checkCaptcha()" />
  <!-- <a href="javascript:void(0)" class="cpt-btn" (click)="playCaptcha()">🔈</a> -->
    <a href="javascript:void(0)" class="cpt-btn reload" (click)="createCaptcha()">&#x21bb;</a>
</div>
<div class="powered-by">
  powered by BinsSoft
</div>
</div>