import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Portal, PortalComponents } from 'src/app/_models/portals';
import { AppNavigation } from 'src/app/_models/user';
import { ComponentType } from '../../_enums/constant-types.enum';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {
  @Input("componentType") componentType: string | '' = '';
  @Input("productName") productName: string | '' = '';
  @Output() navigateTo = new EventEmitter<AppNavigation>();


  pageComponent: PortalComponents = {};
  portal: Portal = {
    PortalComponents: []
  };

  subMenuRoute: string = '';
  menuToggle: boolean = false;
  pageTitle: string = '';

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    if (this.componentType && this.authService.portal && this.authService.portal.PortalComponents) {
      if (this.componentType != ComponentType.OUR_PRODUCTS_PRODUCTS) {
        let index = this.authService.portal?.PortalComponents?.findIndex(x => x.Code == this.componentType);
        this.pageComponent = this.authService.portal?.PortalComponents[index];
        this.portal = this.authService.portal;
        this.pageTitle = this.pageComponent.Name ?? '';
      }
      else {
        this.pageTitle = this.productName;
      }
    }
  }

  onMenu() {
    this.menuToggle = true;
    this.portal = this.authService.portal;
  }

  onMenuClose() {
    this.menuToggle = false;
  }

  onNavigate(url: string) {
    let navigate: AppNavigation = {
      NavigateURL: url,
      IsNavigateToPreviousRoute : false
    };   
    this.navigateTo.emit(navigate);
  }

  navigateToPreviousRoute() {
    let navigate: AppNavigation = {
      NavigateURL: '',
      IsNavigateToPreviousRoute: true
    };
    this.navigateTo.emit(navigate);
  }

}
