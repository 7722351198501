<block-ui [delayStart]="100" [delayStop]="200">
  <section class="main-body">
    <p [hidden]="true"> Version: {{version}}</p>
    <!--<ng-container *ngIf="router.url != '/error-page'">-->
    <app-header *ngIf="showHeader"></app-header>
    <!--</ng-container>-->
    <!--<div *ngIf="(LoginStatus$ | async) && isIdleTimeout" class="fixed-top d-block rounded bg-danger text-center text-light" style="width: 30%; margin-left: 35%; z-index :2147483647">-->
    <!--<div  class="fixed-top d-block rounded bg-danger text-center text-light" style="width: 30%; margin-left: 35%; z-index :2147483647">-->
    <!--<div [ngClass]="{'hide':(!isIdleTimeout),'show':(isIdleTimeout)}">
    <span *ngIf="!(timedOut)" style="width:100%">
      Session Time-out in {{ displayCountdown }}
      <a class="pointer" [ngClass]="{'hide':isIdleTimeout,'show':(!isIdleTimeout)}" (click)="reset()">
        <i class="fas fa-redo-alt" style="float: right; text-align: center;"></i>
      </a>
    </span>-->
    <!--<span *ngIf="(timedOut && utilityService.IsSessionExpired)">Session Timed-out</span>-->
    <!--</div>
    </div>-->
    <!--<div *ngIf="IsSessionExpired">
      <div class="col-md-12">
        <span class="fs-14 text-center pb-3">
          Your Session is Expired due to inactivity. Please click here to <a href="javascript:void(0)"
                                                                             (click)="openLoginForm()">Login</a>
        </span>
      </div>
    </div>-->


    <div class="modal fade" id="sessiontimeout" [ngClass]="{'show' : IsSessionExpired, 'hide':!(IsSessionExpired)}"
         [ngStyle]="{'display': IsSessionExpired ? 'block' : 'none'}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title w-100 d-block">
              Session Timeout !
              <a (click)="closeTimeoutPopup();" class="fs-12 text-underline float-right pt-1 text-white" data-dismiss="modal"><i class="fas fa-times"></i></a>
            </h5>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <p class="fs-14 text-center">
                    Your session has expired due to inactivity. Please click here to <a href="javascript:void(0)"
                                                                                        (click)="openLoginForm()">Login</a>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>



    <!--<div *ngIf="IsSessionExpired" class="fixed-top d-block rounded bg-danger text-center text-light" style="width: 30%; margin-left: 35%; z-index :2147483647">
      <div>
        <span> Your Session is Expired due to inactivity. Please <a href="javascript:void(0)" (click)="openLoginForm()"> Login</a> to continue.</span>
      </div>
    </div>-->

    <section class="pageContentMain">

      <router-outlet></router-outlet>
      <app-footer *ngIf="showFooter"></app-footer>

      <!-- Auto Modal Popover -->
      <div *ngIf="showHeader && !regLogin" class="modal self-hcp-backdrop fade" [ngClass]="{'show' : !authService.isUserLoggedIn() && (!utilityService.isProfileSelected() || utilityService.isProfileSelection) }" id="autoModal" data-backdrop="true" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
           [ngStyle]="{'display': !authService.isUserLoggedIn() && (!utilityService.isProfileSelected() || utilityService.isProfileSelection) ? 'block' : 'none'}">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Welcome to {{utilityService.getPortalName()}}</h5>
            </div>
            <div class="modal-body popup-scroll">
              <p class="fs-13 text-center">A resource dedicated to provide scientifically balanced medical information on {{utilityService.getPortalName()}} to support informed clinical decisions for patient care.</p>
              <ng-container *ngFor="let userprofile of portalProfiles">
                <button type="button" class="btn btn-block btn-primary mt-3 fs-14" data-dismiss="modal" (click)="NavigatePortalUser(userprofile, true);">{{userprofile.NAME}}</button>
              </ng-container>
              <p class="fs-13 pt-3">Please call <a href="javascript:void(0)">+1 (855) 254-0815</a> to speak to a Medical Information Healthcare Professional directly.</p>
              <p class="fs-13 pt-3">
                Please review our <a href="https://www.techsolcorp.com/application-privacy-notice/" target="_blank">Terms of Use</a> and <a href="https://www.scimaxglobal.com/privacy-policy/" target="_blank">Privacy</a> Statement before continuing. <a href="https://www.scimaxglobal.com/products/scimax-platform/" target="_blank">Click here</a> to learn about {{utilityService.getPortalName()}} products and view the current prescribing information.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Non HCP redirection popup -->
      <div *ngIf="showHeader" class="modal self-hcp-backdrop fade" [ngClass]="{'show': utilityService.isNonHCP}" id="nonhcpredirection" data-backdrop="false" tabindex="-1" role="dialog"
           [ngStyle]="{'display': utilityService.isNonHCP ? 'block' : 'none'}" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title w-100 d-block">
                Welcome to Patient/Caregiver Page
              </h5>
            </div>
            <div class="modal-body pt-0">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12 text-center mt-3 px-0">
                    <h3 class="fs-14">
                      You are about to leave {{utilityService.getPortalName()}} Medical
                      <P>Information Portal</P>
                    </h3>
                    <p class="fs-14 pt-3">Please Click <a href="https://www.scimaxglobal.com/products/scimax-platform/" class="text-underline">here</a> to Continue to SCIMAX Platform</p>
                    <p class="fs-14 pt-3">Please Click <a href="https://www.scimaxglobal.com/products/scimax-cp/" class="text-underline">here</a> to Continue to Collaboration Portal</p>
                  </div>
                  <button type="button" class="btn btn-primary btn-block text-center mt-3 fs-14" data-dismiss="modal" (click)="onBack();">Back</button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div *ngIf="utilityService.IsNotHcpAction" class="modal self-hcp-backdrop fade show z-index-3" id="nonhcpredirection2"
           [ngStyle]="{'display': utilityService.IsNotHcpAction ? 'block' : 'none'}"
           data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title w-100 d-block">
                Welcome to Patient/Caregiver Page
              </h5>
            </div>
            <div class="modal-body pt-0">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12 text-center mt-3 px-0">
                    <h3 class="fs-14">
                      You are about to leave {{utilityService.getPortalName()}} Medical
                      <P>Information Portal</P>
                    </h3>
                    <p class="fs-14 pt-3">Please Click <a href="https://www.scimaxglobal.com/products/scimax-platform/" class="text-underline">here</a> to Continue to SCIMAX Platform</p>
                    <p class="fs-14 pt-3">Please Click <a href="https://www.scimaxglobal.com/products/scimax-cp/" class="text-underline">here</a> to Continue to Collaboration Portal</p>
                  </div>
                  <button type="button" class="btn btn-primary btn-block text-center mt-3 fs-14" data-dismiss="modal" (click)="onNonHCPClose();">Close</button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div *ngIf="showHeader" class="privacyPrompt pt-4" [ngStyle]="{'display':cookieToogle ? 'block' : 'none'}">
        <div class="row">
          <div class="col-md-7 col-sm-7">
            <p>
              This site uses cookies. By using our site without changing your cookie settings you agree to our use of cookies as described in our <a href="https://www.scimaxglobal.com/privacy-policy/" target="_blank">Privacy Policy.</a>
            </p>
          </div>
          <div class="col-md-5 col-sm-5">
            <div class="text-right mbl-100 cookieSettings">
              <a href="javascript:void(0);" (click)="allowCookies()">Accept</a>
              <a href="javascript:void(0)" (click)="closeCookies()">Reject</a>
            </div>
          </div>
        </div>

      </div>

    </section>

    <!--<div class="fixed-top d-block rounded bg-danger text-center text-light" style="width: 30%; margin-left: 35%;">
      <div [ngClass]="{'hide':(!idle.isTimerStarted),'show':(idle.isTimerStarted)}">
        <span *ngIf="(!idle.isTimedOut)">
          Session Time-out in {{ displayTime }}
          <a class="pointer" [ngClass]="{'hide':idle.isTimedOut,'show':(!idle.isTimedOut)}" (click)="reset()">
            <i class="fas fa-redo-alt"></i>
          </a>
        </span>
        <span *ngIf="(idle.isTimedOut)">Session Timed-out</span>
      </div>
    </div>-->





    <app-toast-message></app-toast-message>
  </section>
</block-ui>
