<div class="modal-content">

    <!-- Modal Header -->
    <div class="modal-header p-2 border-bottom">
        <h4 class="pl-3 mb-0 text-white">Subscribe</h4>
        <button (click)="this.close()" type="button" class="close pr-4 text-white" data-dismiss="modal">×</button>
    </div>
    <!-- Modal body -->
    <div class="modal-body pb-2">
        <div class="container-fluid ">
            <form [formGroup]="SubscribeForm">
                <div class="row">
                    <div class="col-md-12">
                        <p class="text-right text-secondary fs-12 float-right mandatory">Required Fields</p>
                      </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="fieldLabel mandatory">Last Name</label>
                            <input type="text" class="form-control" required formControlName="LastName" 
                            (blur)="handleInput($event.target,'LastName');" autocomplete="off"
                            maxlength="50" [ngClass]="{ 'is-invalid': (form.LastName.dirty || form.LastName.touched || submitted) && form.LastName.errors }">
                            <div *ngIf="(form.LastName.dirty || form.LastName.touched || submitted) && form.LastName.invalid" class="invalid-form-control text-danger fs-12">
                                <div *ngIf="form.LastName.errors?.['required']">
                                  Last Name is required.
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="fieldLabel mandatory">Email ID</label>
                            <input type="text" class="form-control" required formControlName="EmailId" 
                            (blur)="handleInput($event.target,'EmailId');" autocomplete="off"
                            maxlength="100" [ngClass]="{ 'is-invalid': (form.EmailId.dirty || form.EmailId.touched || submitted) && form.EmailId.errors }">
                            <div *ngIf="(form.EmailId.dirty || form.EmailId.touched || submitted) && form.EmailId.invalid" class="invalid-form-control text-danger fs-12">
                                <div *ngIf="form.EmailId.errors?.['required']">
                                  Email ID is required.
                                </div>
                              </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="text-center py-2">
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                            [disabled]="!this.enableSubscribe"
                            (click)="subscribe()">Subscribe</button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>