import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, Meta, Title, TransferState } from '@angular/platform-browser';
import { Observable, of, tap } from 'rxjs';
import { AppConstants } from '../../shared/AppConstants';
import { APIResponse } from '../_models/apiResponse';
import { UtilityService } from '../_services/utility.service';
import { TransferStateService } from './seo.statetransfer.service';
@Injectable({
  providedIn: 'root'
})


export class SeoService {

  TagsData$: any;
  CurrentTags$: any;
  constructor(private _httpclient: HttpClient,
    private transferState: TransferState,
    private ts: TransferStateService,
    private titleService: Title,
    private meta: Meta,
    private utilityService: UtilityService) {
  }

  getSeoTagsData(): Observable<any> {
    //const key = makeStateKey<any>('SeoTagsData');

    // Check if data is available in transfer state (server-side)
    const serverData = this.ts.get('SeoTagsData');

    if (serverData) {
      // Use data from transfer state on the server side
      this.ts.remove('SeoTagsData');
      return of(serverData);
    }
    else {
      let input = { Feature: "", TagType: "", Tags: "", Status: null, EnvironmentId: AppConstants.EnvironmentId, IsFromPortal: true };
      //return this._httpclient.post(AppConstants.PortalApiBaseUrl + '/api/GoogleSearchConsole/GetFeaturesSummary', input);

      return this._httpclient.post(AppConstants.PortalApiBaseUrl + '/api/GoogleSearchConsole/GetFeaturesSummary', input).pipe(tap((data) => {
        this.ts.set('SeoTagsData', data);
      }));
    }
  }


  async getTranslation() {

    let input = { Feature: "", TagType: "", Tags: "", Status: null, EnvironmentId: AppConstants.EnvironmentId, IsFromPortal: true };
    return await this._httpclient
      .post(AppConstants.PortalApiBaseUrl + '/api/GoogleSearchConsole/GetFeaturesSummary', input)
      .toPromise()
      .then(resp => {
        return resp;
      });
  }




  //async updateTitle() {
  //  this.TagsData$ = await this.getTranslation();
  //  this.CurrentTags$ = this.getCurrentPageTags("RQ_AE");
  //  let title = "";
  //  (this.CurrentTags$ == undefined || (this.CurrentTags$?.Title == "")) ? title = this.utilityService.getPortalName() : title = this.CurrentTags$.Title;
  //  this.titleService.setTitle(title);
  //}

  async updateDescription() {
    this.TagsData$ = await this.getTranslation();
    this.CurrentTags$ = this.getCurrentPageTags("RQ_AE");
    let title = "";
    (this.CurrentTags$ == undefined || (this.CurrentTags$?.Title == "")) ? title = this.utilityService.getPortalName() : title = this.CurrentTags$.Title;
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'description', content: this.CurrentTags$.MetaDescription });
    this.meta.updateTag({ name: 'title', content: this.CurrentTags$.MetaTitle });
  }

  //async updateKeywords(keywords: string) {
  //  const translation = await this.getTranslation(keywords);
  //  this.meta.updateTag({ name: 'keywords', content: translation });
  //}

  //async updateOgUrl(url: string) {
  //  const translation = await this.getTranslation(url);
  //  this.meta.updateTag({
  //    name: 'og:url',
  //    property: 'og:url',
  //    content: translation
  //  });
  //}

  async updateMetaTitle(ogTitle: string) {
    const translation = await this.getSeoTagsData();
    this.CurrentTags$ = this.getCurrentPageTags("RQ_AE");
    this.meta.updateTag({ name: 'title', content: this.CurrentTags$.MetaTitle });
  }

  //async updateOgDescription(ogDesc: string) {
  //  const translation = await this.getTranslation(ogDesc);
  //  this.meta.updateTag({
  //    name: 'og:description',
  //    property: 'og:description',
  //    content: translation
  //  });
  //}

  //async updateOgImage(ogImg: string) {
  //  const translation = await this.getTranslation(ogImg);
  //  this.meta.updateTag({
  //    name: 'og:image',
  //    property: 'og:image',
  //    content: translation
  //  });
  //}

  //async disableFollow() {
  //  this.meta.addTag({
  //    name: 'robots',
  //    property: 'robots',
  //    content: 'noindex, nofollow'
  //  });
  //}

  //async enableFollow() {
  //  this.meta.removeTag('robots');
  //}

  getCurrentPageTags(Code: string) {
    let features = this.TagsData$;
    let tagData = features.filter((x: any) => { return x.FeatureCode == Code })[0];
    return tagData;
  }


}
