import { NgModule, isDevMode, APP_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AccountActivationComponent } from './components/account-activation/account-activation.component';
import { PortalsComponent } from './components/portals/portals.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorHandlerInterceptor } from './_helpers/error-handler.interceptor';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { BlockUIModule } from 'ng-block-ui';
import { DatePipe } from '@angular/common';
//import { NgxCaptchaModule } from '@binssoft/ngx-captcha';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { AppPreSetupModule, AppPreSetupService } from './_services/app-pre-setup.service';
import { EncryptDecryptService } from './_services/encrypt-decrypt.service';
import { SupportLoginComponent } from './components/support-login/support-login.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ValidationsBanertemplate } from './components/Common/validations-banertemplate.component';
import { SuccessBanertemplate } from './components/Common/success-banertemplate.component';
import { ErrorBanertemplate } from './components/Common/error-banertemplate.component';
import { ConfirmationBanertemplate } from './components/Common/confirmation-bannertemplate.component';
import { CaptchaComponent } from './components/Common/captcha/captcha.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { UserProfileComponent } from './user-profile/user-profile/user-profile.component';
import { BookmarkedContentComponent } from './components/bookmarked-content/bookmarked-content.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SharedModule } from './shared/shared.module';
import { SubmitReviewComponent } from './components/register/submit-review/submit-review.component';
import { AccountVerificationComponent } from './components/account-verification/account-verification.component';
import { PasswordPolicyComponent } from './components/password-policy/password-policy.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccountVerifyComponent } from './externalpage-components/account-verify/account-verify.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AccountResetpasswordComponent } from './externalpage-components/account-resetpassword/account-resetpassword.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ExternalRedirectionConfirmationBannertemplate } from './components/Common/external-redirection-confirmation-bannertemplate.component';
import { UserRequestsComponent } from './user-request/user-requests/user-requests.component';
import { EditProfileComponent } from './user-profile/user-profile/edit-profile/edit-profile.component';
import { UpdateEmailComponent } from './user-profile/user-profile/update-email/update-email.component';
import { UpdateEmailVerificationComponent } from './user-profile/user-profile/update-email-verification/update-email-verification.component';
import { ViewEditAeRequestComponent } from './user-request/user-requests/view-edit-ae-request/view-edit-ae-request.component';
import { ViewEditPcRequestComponent } from './user-request/user-requests/view-edit-pc-request/view-edit-pc-request.component';
import { ViewEditMiRequestComponent } from './user-request/user-requests/view-edit-mi-request/view-edit-mi-request.component';
import { ViewEditCallRequestComponent } from './user-request/user-requests/view-edit-call-request/view-edit-call-request.component';
import { ViewEditMeetingRequestComponent } from './user-request/user-requests/view-edit-meeting-request/view-edit-meeting-request.component';
import { EventUnsubscribeComponent } from './externalpage-components/event-unsubscribe/event-unsubscribe.component';
import { NewsUnsubscribeComponent } from './externalpage-components/news-unsubscribe/news-unsubscribe.component';
import { ChangePasswordSuccessBanertemplate } from './components/Common/changepassword-success-banertemplate.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ToastrModule } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TransferState } from '@angular/platform-browser';
import { provideClientHydration } from '@angular/platform-browser';
import { ServerModule } from '@angular/platform-server';
import { SeoService } from './_resolver/seo.servece';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    AppComponent,
    HomeComponent,
    AccountActivationComponent,
    PortalsComponent,
    SupportLoginComponent,
    ToastMessageComponent,
    ValidationsBanertemplate,
    SuccessBanertemplate,
    ChangePasswordSuccessBanertemplate,
    ErrorBanertemplate,
    ConfirmationBanertemplate,
    ExternalRedirectionConfirmationBannertemplate,
    CaptchaComponent,
    BookmarkedContentComponent,
    UnauthorizedComponent,
    LoginComponent,
    ResourcesComponent,
    SitemapComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    SubmitReviewComponent,
    AccountVerificationComponent,
    PasswordPolicyComponent,
    AccountVerifyComponent,
    ResetPasswordComponent,
    AccountResetpasswordComponent,
    //UserRequestsComponent,
    EditProfileComponent,
    UpdateEmailComponent,
    UpdateEmailVerificationComponent,
    ViewEditAeRequestComponent,
    ViewEditPcRequestComponent,
    ViewEditMiRequestComponent,
    ViewEditCallRequestComponent,
    ViewEditMeetingRequestComponent,
    EventUnsubscribeComponent,
    NewsUnsubscribeComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule.withServerTransition({ appId: 'SeoTagsData' }),
    //ModuleMapLoaderModule,
    //BrowserModule,
    //ServerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BlockUIModule.forRoot({ message: 'Loading...' }),
    BlockUIHttpModule.forRoot({ blockAllRequestsInProgress: true }),
    //NgxCaptchaModule,
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),
    CollapseModule,
    SharedModule,
    TooltipModule.forRoot(),
    CarouselModule,
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),

  ],
  providers: [
    SeoService,
    AppPreSetupService,
    AppPreSetupModule.init(),
    EncryptDecryptService,
    BsModalRef,
    DatePipe,
    TransferState,
    provideClientHydration(),
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: DeviceDetectorService },
    /*{ provide: APP_ID, useValue: 'serverApp' },*/
    //CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

