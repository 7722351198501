<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block">
      Review and Submit
      <a (click)="this.close()" class="fs-12 text-underline float-right pt-1 pl-2 text-white fs-14" data-dismiss="modal">
        <i class="fas fa-times"></i>
      </a>
    </h5>
  </div>

  <div class="modal-body">
    <div class="tabsMain modalbody-ht">
      <div class="container-fluid">
        <form [formGroup]="SubmitRegisterForm">
          <div class="row pb-4">
            <div class="col-md-12 pb-2">
              <div class="title-lg">Tell Us Who You Are <a class="fs-16 float-right" (click)="enableTellUsWhoYouAre()"><i class="fas fa-pen"></i></a></div>
              <p class="fs-14 py-2">
                Please tell us a little bit about yourself. We value your privacy. You can
                <a href="https://www.scimaxglobal.com/privacy-policy/" target="_blank">click here</a> to review our privacy policy
              </p>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">First Name</label>
                <input type="text" class="form-control" formControlName="FIRST_NAME" autocomplete="off"
                       (blur)="handleInput($event.target,'FIRST_NAME');" required="" maxlength="35" noNumbers
                       [ngClass]="{ 'is-invalid': form.FIRST_NAME.errors }">
                <div *ngIf="form.FIRST_NAME.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FIRST_NAME.errors?.['required']">
                    First Name is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Last Name</label>
                <input type="text" class="form-control" formControlName="LAST_NAME" autocomplete="off"
                       (blur)="handleInput($event.target,'LAST_NAME');" required="" maxlength="50" noNumbers
                       [ngClass]="{ 'is-invalid': form.LAST_NAME.errors }">
                <div *ngIf="form.LAST_NAME.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.LAST_NAME.errors?.['required']">
                    Last Name is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Date of Birth</label>
                <div>
                  <!--<input type="text" id="dob" class="form-control" #datepickerDOB="bsDatepicker"
                  placeholder="DD-MMM-YYYY" bsDatepicker autocomplete="off" maxlength="50"
                  [bsConfig]="bsConfig" formControlName="DATE_OF_BIRTH" autocomplete="off"
                  (keyup)="keyPress($event,'DATE_OF_BIRTH','dob','',undefined)"
                  (bsValueChange)="Ondatechange($event,'DATE_OF_BIRTH')"
                  [ngClass]="{ 'is-invalid': form.DATE_OF_BIRTH.errors }">
                  <span (click)="datepickerDOB.toggle()"><i class="far fa-calendar-alt"></i></span>-->

                  <p-calendar useUtc inputId="dob"
                              [maxDate]="minDate"
                              placeholder="DD-MMM-YYYY"
                              formControlName="DATE_OF_BIRTH"
                              dateFormat="dd-M-yy"
                              [showIcon]="true"
                              icon="far fa-calendar-alt"
                              appendTo="body"
                              [defaultDate]="minDate"
                              [selectOtherMonths]="true"
                              (onInput)="MinMaxDateValidation($event,'DATE_OF_BIRTH','G',minDate)"
                              styleClass="{ 'is-invalid': form.DATE_OF_BIRTH.errors }"></p-calendar>

                </div>
                <div *ngIf="form.DATE_OF_BIRTH.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.DATE_OF_BIRTH.errors?.['required']">
                    Date of Birth is required.
                  </div>
                </div>
                <div *ngIf="form.DATE_OF_BIRTH.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.DATE_OF_BIRTH.errors?.age">
                    Age should not be less than 16 years
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory form-control-placeholder">Primary Degree</label>
                <select class="form-control" formControlName="PrimaryDegree" [ngClass]="{ 'is-invalid': form.PrimaryDegree.errors }">
                  <option value="">Select</option>
                  <option *ngFor="let pd of primaryDegree" value="{{pd.PK_LOOKUP_ID}}">{{pd.NAME}}</option>
                </select>
                <div *ngIf="form.PrimaryDegree.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.PrimaryDegree.errors?.['required']">
                    Primary Degree is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Country</label>
                <app-country-control formControlName="FK_COUNTRY_ID" [inputAllCountries]="allCountries" [inputSelectedCountry]="selectedCountry"></app-country-control>
                <!-- <select class="form-control"  formControlName="FK_COUNTRY_ID" [(ngModel)]="userData.FK_COUNTRY_ID">
                 <option *ngFor="let country of allCountries" value="{{country.PK_COUNTRY_ID}}">{{country.NAME}}
                </select> -->
                <span class="fltlabel-highlight"></span>
                <div *ngIf="form.FK_COUNTRY_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FK_COUNTRY_ID.errors?.['required']">
                    Country is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">Zip Code</label>
                <input type="text" class="form-control" noSpaces (blur)="handleInput($event.target,'ZIP_CODE');"
                       formControlName="ZIP_CODE" autocomplete="off" maxlength="15" noSpecailChars />
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">State</label>
                <input type="text" class="form-control" formControlName="STATE_PROVINCE" (blur)="handleInput($event.target,'STATE_PROVINCE');" autocomplete="off"
                       maxlength="50" />
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">City</label>
                <input type="text" class="form-control" formControlName="CITY" autocomplete="off"
                       (blur)="handleInput($event.target,'CITY');" maxlength="50" />
              </div>
            </div>
            <div class="col-md-8 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">Address</label>
                <input type="text" class="form-control" formControlName="ADDRESS" autocomplete="off"
                       (blur)="handleInput($event.target,'ADDRESS');" maxlength="200" />
              </div>
            </div>
          </div>

          <div class="row pb-4">
            <div class="col-md-12 pb-2">
              <div class="title-lg">Communication Preference <a class="fs-16 float-right" (click)="enableCommunicationPreference()"><i class="fas fa-pen"></i></a></div>
              <p class="fs-14 py-2">Please provide details for your preferred way of communication to all requests raised through the portal.</p>
            </div>
            <div class="col-md-12">
              <div class="title-sm mandatory">Preferred Contact Method</div>
            </div>
            <div *ngFor="let preferredContact of preferedContacts" class="col-md-6 col-sm-6">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" value="{{preferredContact.Id}}"
                       formControlName="FK_PREFERRED_CONTACT_ID" id="{{preferredContact.Id}}">
                <label class="custom-control-label" for="{{preferredContact.Id}}">{{preferredContact.ContactName}}</label>
              </div>
            </div>
            <div *ngIf="form.FK_PREFERRED_CONTACT_ID.invalid" class="invalid-form-control text-danger fs-12">
              <div *ngIf="form.FK_PREFERRED_CONTACT_ID.errors?.['required']">
                Preferred Contact Method is required.
              </div>
            </div>

            <!-- <div class="col-md-6 col-sm-6">
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" value="PHONE"
                formControlName="CONTACT_PREFERENCE" id="pn">
                <label class="custom-control-label" for="pn">Phone Number</label>
              </div>
            </div> -->

            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel" [ngClass]="{ 'mandatory' : this.isEmailMandatory }">Email ID</label>
                <input type="text" id="eml" class="form-control"
                       formControlName="PREFERED_EMAIL" autocomplete="off"
                       (blur)="handleInput($event.target,'PREFERED_EMAIL');" maxlength="100" />
                <div *ngIf="form.PREFERED_EMAIL.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.PREFERED_EMAIL.errors?.['required']">
                    Email Id is required.
                  </div>
                </div>
                <div *ngIf="!form.PREFERED_EMAIL.errors?.['required'] && (form.PREFERED_EMAIL.dirty || form.PREFERED_EMAIL.touched || invalidPreferredEmail)" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="invalidPreferredEmail || form.PREFERED_EMAIL.errors?.['email']">
                    Please enter valid Email.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="row">
                <div class="col-md-9 col-sm-9">
                  <div class="form-group">
                    <label class="fieldLabel" [ngClass]="{ 'mandatory' : this.isPhoneMandatory }">Primary Phone Number</label>
                    <input phoneNumber type="text" id="pnr1" class="form-control"
                           formControlName="PREFERED_PHONE" autocomplete="off" maxlength="25" (blur)="handleInput($event.target,'PREFERED_PHONE');" />
                    <div *ngIf="form.PREFERED_PHONE.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.PREFERED_PHONE.errors?.['required']">
                        Primary Phone Number is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-3">
                  <div class="form-group">
                    <label class="fieldLabel">Ext</label>
                    <input type="text" id="ext1" class="form-control"
                           formControlName="PREFERED_PHONE_EXT" phoneNumber
                           (blur)="handleInput($event.target,'PREFERED_PHONE_EXT');" autocomplete="off" maxlength="10" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="row">
                <div class="col-md-9 col-sm-9">
                  <div class="form-group">
                    <label class="fieldLabel">Other Phone Number</label>
                    <input phoneNumber type="text" id="pnr2" class="form-control" formControlName="PHONE" (blur)="handleInput($event.target,'PHONE');" autocomplete="off"
                           maxlength="25" />
                  </div>
                </div>
                <div class="col-md-3 col-sm-3">
                  <div class="form-group">
                    <label class="fieldLabel">Ext</label>
                    <input type="text" id="ext2" class="form-control" autocomplete="off" phoneNumber
                           (blur)="handleInput($event.target,'PHONE_EXT');" formControlName="PHONE_EXT" maxlength="10" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <p class="fs-13 py-2">{{utilityService.getPortalName()}} sends you emails on important Program updates that you are enrolled or when new information is available in this account. A limited number of communications may be sent to you via postal mail when necessary. You can also choose to receive notifications via text message. If available, you will be asked to provide your mobile phone number if you have not yet provided one.</p>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="custom-control custom-checkbox mb-1">
                <input type="checkbox" class="custom-control-input" id="submit-review-cb1" name="example1"
                       formControlName="HAS_RECEIVE_EMAILS">
                <label class="custom-control-label" for="submit-review-cb1">I want to receive Emails from {{utilityService.getPortalName()}} Medical Information team on any updates</label>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="custom-control custom-checkbox mb-1">
                <input type="checkbox" class="custom-control-input" id="submit-review-cb2" name="example1"
                       formControlName="HAS_ALLOW_CALLS">
                <label class="custom-control-label" for="submit-review-cb2">I allow {{utilityService.getPortalName()}} to call me when there are important program updates</label>
              </div>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-md-12 pb-2">
              <div class="title-lg">Tell Us About Your Practice <a class="fs-16 float-right" (click)="enableTellUsAboutYourPractice()"><i class="fas fa-pen"></i></a></div>
              <p class="fs-14 py-2">Please enter valid license details as these cannot be changed after account creation</p>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Profession</label>
                <select [ngClass]="{ 'is-invalid': form.FK_PROFESSION_ID.errors }"
                        class="floating-select form-control" required="" formControlName="FK_PROFESSION_ID">
                  <option value="">-Select-</option>
                  <option *ngFor="let occ of occupationList" value="{{occ.PK_LOOKUP_ID}}">{{occ.NAME}}</option>
                </select>
                <span class="fltlabel-highlight"></span>
                <div *ngIf="form.FK_PROFESSION_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FK_PROFESSION_ID.errors?.['required']">
                    Profession is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Specialty</label>
                <select [ngClass]="{ 'is-invalid': form.FK_SPECAILITY_ID.errors }"
                        class="floating-select form-control" required="" formControlName="FK_SPECAILITY_ID">
                  <option value="">-Select-</option>
                  <option *ngFor="let spec of specialityList" value="{{spec.PK_LOOKUP_ID}}">{{spec.NAME}}</option>
                </select>
                <span class="fltlabel-highlight"></span>
                <div *ngIf="form.FK_SPECAILITY_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FK_SPECAILITY_ID.errors?.['required']">
                    Speciality is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">License Number</label>
                <input type="text" id="ln" class="form-control" required="" formControlName="LICENCE_NUMBER" autocomplete="off"
                       (blur)="handleInput($event.target,'LICENCE_NUMBER');" (keyup)="updatedValue($event.target, 'LICENCE_NUMBER');"
                       maxlength="10" [ngClass]="{ 'is-invalid': form.LICENCE_NUMBER.errors }" />
                <div *ngIf="form.LICENCE_NUMBER.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.LICENCE_NUMBER.errors?.['required']">
                    License Number is required.
                  </div>
                </div>
                <div *ngIf="form.LICENCE_NUMBER.value && this.duplicateLicense" class="invalid-form-control text-danger fs-12">
                  License Number already exists
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <label class="fieldLabel">Primary Practice</label>
              <div class="form-group">
                <input type="text" id="pp" class="form-control" formControlName="PRIMARY_PRACTICE" autocomplete="off"
                       (blur)="handleInput($event.target,'PRIMARY_PRACTICE');" maxlength="200" />
              </div>
            </div>

          </div>
          <div class="row pb-4">
            <div class="col-md-12 pb-2">
              <div class="title-lg">Account Creation <a class="fs-16 float-right" (click)="enableAccountCreation()"><i class="fas fa-pen"></i></a></div>
              <p class="fs-14 py-2">Please enter Email ID, User ID and password to access the portal. Please note User ID cannot be changed once account is created. We will initiate verification process to the registered Email ID specified below.</p>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Registered Email ID</label>
                <input type="text" id="eu" class="form-control" required=""
                       formControlName="REGISTERED_EMAIL_ID" autocomplete="off" maxlength="100"
                       (blur)="handleInput($event.target,'REGISTERED_EMAIL_ID');"
                       [ngClass]="{ 'is-invalid': form.REGISTERED_EMAIL_ID.errors }" />
                <div *ngIf="form.REGISTERED_EMAIL_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.REGISTERED_EMAIL_ID.errors?.['required']">
                    Email ID is required.
                  </div>
                </div>
                <div *ngIf="!form.REGISTERED_EMAIL_ID.errors?.['required'] && (form.REGISTERED_EMAIL_ID.dirty || form.REGISTERED_EMAIL_ID.touched || invalidRegisteredEmail)" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="invalidRegisteredEmail || form.REGISTERED_EMAIL_ID.errors?.['email']">
                    Please enter valid Email.
                  </div>
                </div>
                <div *ngIf="this.emailIdAlreadyExists" class="invalid-form-control text-danger fs-12">
                  Email Id already exists
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">User ID</label>
                <input type="text" id="userId" class="form-control" required=""
                       formControlName="USER_ID" autocomplete="off" maxlength="100"
                       (blur)="handleInput($event.target,'USER_ID');" (keyup)="updatedValue($event.target, 'USER_ID');"
                       [ngClass]="{ 'is-invalid': form.USER_ID.errors }" />
                <div *ngIf="form.USER_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.USER_ID.errors?.['required']">
                    User ID is required.
                  </div>
                </div>
                <div *ngIf="form.USER_ID.value && this.duplicateUserId" class="invalid-form-control text-danger fs-12">
                  User id already exists
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Password</label>
                <input type="password" id="pwd1" class="form-control" required="" formControlName="PASSWORD" (blur)="handleInput($event.target,'PASSWORD');" autocomplete="off"
                       maxlength="100" noSpaces PWDSpecailChars [ngClass]="{ 'is-invalid': form.PASSWORD.errors }" />
                <div *ngIf="form.PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.PASSWORD.errors?.['required']">
                    Password is required.
                  </div>
                  <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['number']">
                    Password should contain at least {{passwordPolicy.MIN_NUMERIC_CHARS}} number
                  </div>
                  <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['upper']">
                    Password should contain at least {{passwordPolicy.MIN_UPPERCASE_CHARS}} uppercase letter
                  </div>
                  <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['special']">
                    Password should contain at least {{passwordPolicy.MIN_SPECAIL_CHARS}} special letter
                  </div>
                  <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['minlength']">
                    Password should contain minimum of {{passwordPolicy.MIN_PASSWORD_LENGTH}} characters
                  </div>
                  <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['pattern']">
                    Password doesn't meet the policy requirement. Please check password policy.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Confirm Password</label>
                <input type="password" id="cpwd" class="form-control" required="" noSpaces PWDSpecailChars
                       formControlName="CONFIRM_PASSWORD" autocomplete="off" maxlength="100" (blur)="handleInput($event.target,'CONFIRM_PASSWORD');"
                       [ngClass]="{ 'is-invalid': form.CONFIRM_PASSWORD.errors }" />
                <div *ngIf="form.CONFIRM_PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.CONFIRM_PASSWORD.errors?.['required']">
                    Confirm Password is required.
                  </div>
                  <div *ngIf="!form.CONFIRM_PASSWORD.errors?.['required'] && form.CONFIRM_PASSWORD.errors?.mustMatch">
                    Password and Confirm password should match
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <p class="fs-13 py-2">
                Please note that your password should meet our
                <a href="javascript:void(0)" (click)="this.openPasswordPolicy()">Password Policy. &nbsp;<i class="fas fs-16 fa-info-circle"></i></a>
              </p>
            </div>
            <div class="col-md-12 text-right pb-3">
              <button type="button" class="btn btn-primary" (click)="Submit()">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
