<div class="pageContent bodyBg py-3">
    <div class="row">
      <div class="col-md-9">
        <div class="card px-3 py-2 mb-3">
         <div class="defaultTabs">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                 <div class="title-lg text-primary" style="display: flex;">
                  <i class="fas fa-search fs-20 opacity-2"></i>&nbsp;<h1 style="font-size: 24px;">   {{CurrentTags == undefined || CurrentTags?.Header == "" ? "Site Map " : CurrentTags?.Header }}</h1>               </div>
                <p class="fs-12 pt-2 pb-3">List of all pages for {{utilityService.getPortalName().toLowerCase()}} med-info portal are displayed below clicking on any of the links will redirect you to the selected page</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 pb-3" *ngIf="checkHomeExists('SR_MI','RQ_AE')">
                <a routerLink="/" class="fs-16">&gt; Home</a>
              </div>
              <div class="col-md-12 pb-3" *ngIf="checkFeatureExists('SR_MI')">
                <a routerLink="/find-medical-info" class="fs-16">&gt; Search Medical Information</a>
              </div>
              <div class="col-md-12 pb-3" *ngIf="checkFeatureExists('RQ_MI')">
                <a routerLink="/request-mi" class="fs-16">&gt; Request for Medical Information</a>
              </div>
              <div class="col-md-12 pb-3" *ngIf="checkFeatureExists('F_MSL')">
                <a routerLink="/find-msl" class="fs-16">&gt; Find My MSL</a>
              </div>
              <div class="col-md-12 pb-3" *ngIf="checkFeatureExists('RQ_AE')">
                <a routerLink="/report-ae" class="fs-16">&gt; Report an Adverse Event</a>
              </div>
              <div class="col-md-12 pb-3" *ngIf="checkFeatureExists('RQ_PC')">
                <a routerLink="/report-pc" class="fs-16">&gt; Report a Product Complaint</a>
              </div>
              <div class="col-md-12 pb-3" *ngIf="checkFeatureExists('NS_AN')">
                <a routerLink="/news-announcements" class="fs-16">&gt; News and Announcements</a>
              </div>
              <div class="col-md-12 pb-3" *ngIf="checkFeatureExists('EV_CF')">
                <a routerLink="/events-conferences" class="fs-16">&gt; Events and Conferences</a>
              </div>
              <div class="col-md-12 pb-3" *ngIf="checkFeatureExists('OR_PD')">
                <a routerLink="/our-products" class="fs-16">&gt; Our Products</a>
              </div>
              <div class="col-md-12 pb-3" *ngIf="checkFeatureExists('RESO')">
                <a routerLink="/resources" class="fs-16">&gt; Resources</a>
              </div>
            </div>
           </div>
         </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <app-promotional-cards></app-promotional-cards>
        </div>
      </div>
    </div>
    
  </div>
