<div class="pageContent bodyBg py-3">
  <form [formGroup]="SearchMIForm" class="col-md-12">
    <div class="row">
      <div class="col-md-9">
        <div class="card px-3 py-2 mb-3 home-img" *ngIf="enableSMI">
            <div class="row">
              <div class="col-md-12">
                <p class="fs-12 pt-2 font-weight-bold"> About Daiichi Sankyo </p>
                <p class="fs-12 pt-2">
                  Daiichi Sankyo is a global pharmaceutical company with corporate origins in Japan. We provide innovative products and services in 29 countries/regions around the world. With more than 120 years of scientific expertise, our company draws upon a rich legacy of innovation and a robust pipeline of promising new medicines to help patients. We share a passion for innovation, as well as compassion for the patients around the world who are in need of our medicines.
                </p>
              </div>
              <!-- <div class="col-md-6 align-items-center d-flex">
                <img src="../../../assets/images/daiichi-sankyo-home-page.jpg"  class="img-fluid" />
              </div> -->
              <div class="col-md-12">
                <p class="fs-12 pt-2 font-weight-bold"> Teamwork </p>
                <p class="fs-12 pt-2 ">
                  We strive to foster a professional, collaborative, and open work environment to ensure we are meeting our client's needs and expectations while working efficiently and effectively together.
                </p>

                <p class="fs-12 pt-2 font-weight-bold"> Client Focused </p>
                <p class="fs-12 pt-2">
                  Quality begins and ends with meeting our client's needs. We, as a company, always strive to ensure client expectations are met and exceeded.
                </p>

                <p class="fs-12 pt-2 font-weight-bold"> Relationship Focused </p>
                <p class="fs-12 pt-2">
                  We work to develop mutually beneficial and respectful relationships with our clients, partners, contractors, and anyone else with whom we interact.
                </p>
              </div>
            </div>
        </div>
        
        <div class="card px-3 py-2 mb-3" *ngIf="enableSMI">
          <div class="row">
            <div class="col-md-12">
              <div class="title-lg text-primary" style="display: flex;">
                <i class="fas fa-search fs-20 opacity-2"></i>&nbsp;
                <h1 style="font-size: 24px;">
                  {{CurrentTags_SM == undefined || CurrentTags_SM?.Header == "" ? "Search Medical Information" : CurrentTags_SM?.Header }}
                </h1>
              </div>
              <p class="fs-12 pt-2 pb-3">
                Documents obtained from search may contain information that is not included
                in the approved product labeling. {{utilityService.getPortalName()}} recommends the use of its products only in accordance
                with the FDA-approved prescribing information. Please refer to the full prescribing information for
                approved product labeling and important safety information.
              </p>
            </div>

            <div class="col-md-12 text-right">
              <label class="fieldLabel mandatory fs-12 text-secondary">Required Fields</label>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Product</label>

                <app-product-control [inputAllProducts]="allProducts" [inputSelectedProduct]="selectedProduct" (newItemEvent)="FetchFrequentlyUsedProductKeywords($event)"
                                     formControlName="ProductApprovalId" (SearchParams)="setProduct($event)"></app-product-control>
                <div *ngIf="(form.ProductApprovalId.dirty || form.ProductApprovalId.touched || prodcutvalidationmsg) && form.ProductApprovalId.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.ProductApprovalId.errors?.['required']">
                    Product is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="form-group">
                <label for="search-tags" class="fieldLabel requiredfild">Keyword(s)</label>
                <input id="search-tags" type="text" class="form-control" autocomplete="off"
                       (blur)="handleInput($event.target,'SearchTags');" maxlength="100"
                       placeholder="Please enter keyword(s)" formControlName="SearchTags" />
                <div *ngIf="keywordvalidationmsg" class="invalid-form-control text-danger fs-12">
                  {{keywordvalidationmsg}}
                </div>
                <div class="w-100 overflow-hidden" *ngIf="freq_keywords && freq_keywords.Keywords">
                  <p class="fs-12 font-weight-bold">Most used keyword(s):</p>
                  <div *ngIf="freq_keywords!=null" class="invalid-form-control text-danger fs-12 p-1" style="max-height: 70px; overflow: auto; margin-right:-5px;">
                    <a href="javascript:void(0);" (click)="KeySelected(key)" class="fs-12 d-inline-block border p-1 rounded ml-1 mt-1" *ngFor="let key of freq_keywords.Keywords;let i=index;">{{key}}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <label class="fieldLabel">&nbsp;</label>
              <span class="d-block tooltip-wrapper" tooltip="{{this.enableSearch ? '': 'Select a product to search medical information'}}">
                <button type="button" class="btn btn-block btn-primary"
                        [disabled]="!this.enableSearch" (click)="OnSearchClick();">
                  Search
                </button>
              </span>
            </div>

            <div class="col-md-12 pt-3">
              <p class="fs-12">
                The medical information search will retrieve any scientific resource from our medical information database where the search terms match the product and keywords for the document. Please enter unique keyword(s) related to your topic.
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="card px-3 py-2 mb-3" *ngIf="enableReportAE">
          <div class="row">
            <div class="col-md-12">
              <div class="title-lg text-primary" style="display: flex;">
                <i class="fas fa-exclamation-triangle fs-20 opacity-2"></i>&nbsp;
                <h1 style="font-size: 24px;">
                  {{CurrentTags_RA == undefined || CurrentTags_RA?.Header == "" ? "Report an Adverse Event" : CurrentTags_RA?.Header }}
                </h1>
              </div>
              <p class="fs-12 pt-2 pb-3">
                Adverse events are sometimes known as side effects and are unwanted or unexpected events or reactions to a medicine or drug.
              </p>
            </div>
            <div class="col-md-12">
              <div class="title-sm mandatory title-color-2 pb-2">Which best describes you?</div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 pt-2" *ngFor="let prof of occupationList;let index i">
              <div class="custom-control custom-radio ml-1 mt-2">
                <input type="radio" class="custom-control-input" id="{{prof.PK_LOOKUP_ID}}" value="{{prof.PK_LOOKUP_ID}}" formControlName="profession">
                <label class="custom-control-label" title="{{prof.NAME}}" for="{{prof.PK_LOOKUP_ID}}">{{prof.NAME}}</label>
              </div>
            </div>
            <div *ngIf="profesionvalidationmsg" class="invalid-form-control text-danger fs-12">
              {{profesionvalidationmsg}}
            </div>
            <div class="col-md-12 py-3">
              <button type="button" class="btn btn-primary px-3" [disabled]="!this.enableProceed" (click)="ProceedToAE();">Proceed</button>
            </div>
            <div class="col-md-12">
              <h4 class="font-weight-bold fs-14">Disclaimer</h4>
              <p class="fs-12 pb-2">The information we are requesting is the minimum necessary for {{utilityService.getPortalName()}} to process the reported adverse event.</p>
            </div>
          </div>
        </div> -->
      </div>
      <div class="col-md-3">
        <div class="row">
          <app-promotional-cards></app-promotional-cards>
        </div>
      </div>
    </div>
  </form>
</div>
