import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-portals',
  templateUrl: './portals.component.html',
  styleUrls: ['./portals.component.css']
})
export class PortalsComponent implements OnInit {

  loginToggle:boolean = false;
  showSelectionItems = false;
  showCookiesPopUp = true;

  constructor(private cookieService: CookieService) { }

  ngOnInit(): void {
  }

  cookieSettings(){
    this.showSelectionItems = !this.showSelectionItems;
  }
  allowCookies(){
    this.showSelectionItems = false;
    this.showCookiesPopUp = false;

    this.cookieService.set("functional_cookie", "Functional Cookies");
    this.cookieService.set("strict_necessary_cookie", "Strcitly Necessary Cookies");
    this.cookieService.set("performance_cookie", "Performance Cookies");
    this.cookieService.set("social_media_cookie", "Social Media Cookies");
  }
}
