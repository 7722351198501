import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AppConstants } from '../../shared/AppConstants';
import { FileStoreType } from '../_enums/constant-types.enum';
import { DocumentRecord, OpDocumentsRequest, OpDocumentsResponse, OurProductsResponse, ProductDocument, ProductDocumentsRequest } from '../_models/ContentInformation';
import { ContentSearchCriteria } from '../_models/contentSearchCriteria';
import { PortalProfiles } from '../_models/portals';
import { Products, ProductsAutoComplete } from '../_models/products';
import { TherapeticAreas } from '../_models/therapauticAreas';
import { UtilityService } from './utility.service';

//https://stackblitz.com/edit/angular-breadcrumb-demo?file=app%2Fmenu.service.ts

@Injectable({
  providedIn: 'root'
})
export class OurProductsService {

  constructor(private http: HttpClient, private utility: UtilityService) { }

  getOurProducts(): Observable<TherapeticAreas[]> {
    return this.http.get<TherapeticAreas[]>(AppConstants.PortalApiBaseUrl + '/api/Products/GetOurProducts/' + AppConstants.EnvironmentId);
  }

  getDisplayProducts(ProductId: string = ''): Observable<Products[]> {
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utility.getSelectedProfile();
    let getProducts = {
      EnvironmentId: AppConstants.EnvironmentId,
      ProductId: ProductId,
      PkProfileId: profileinfo.PK_ENV_PROFILE_ID,
    }
    return this.http.post<Products[]>(AppConstants.PortalApiBaseUrl + '/api/Products/GetDisplayProducts', getProducts);
  }

  getProductDetails(id: string): Observable<Products> {
    return this.http.get<Products>(AppConstants.PortalApiBaseUrl + '/api/Products/GetProductDetails/' + AppConstants.EnvironmentId + '/' + id);
  }

  getProductDocuments(criteria: ContentSearchCriteria): Observable<any> {
    criteria.PortalCode = AppConstants.EnvironmentId;
    criteria.FK_PortalId = this.utility.getPortalID();
    criteria.PortalId = this.utility.getPortalID();
    criteria.ReporterTypeId = AppConstants.ReporterTypeId;
    criteria.FK_EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<TherapeticAreas[]>(AppConstants.PortalApiBaseUrl + '/api/FindMedical', criteria);
  }

  downloadFileURL(id: string, storeType: FileStoreType): string {
    return AppConstants.PortalApiBaseUrl + '/api/File/Download?type=' + storeType + '&id=' + id;
  }

  getProductsTypeHeadData(ProductId: string = ''): Observable<ProductsAutoComplete[]> {
    let productsData: ProductsAutoComplete[] = [];
    return this.getDisplayProducts(ProductId).pipe(
      map(x => {
        x = x.sort((a, b) => {
          return a.ProductDisplayName?.toLowerCase() && b.ProductDisplayName?.toLowerCase() && a.ProductDisplayName.toLowerCase() < b.ProductDisplayName.toLowerCase() ? -1 : 1;
        });
        x.forEach(p => {
          productsData.push({
            PK_PRODUCT_MAPPING_ID: p.PK_ProductMappingId ?? '',
            MIQ_PRODUCT_APPROVAL_ID: p.MiqProductApprovalId ?? '',
            PRODUCT_DISPLAY_NAME: p.ProductDisplayName ?? '',
            IS_INVALID: false
          });
        });
        return productsData;
      }),
      shareReplay(1)
    );
  }

  searchProducts(products: ProductsAutoComplete[], searchKey: string): Observable<ProductsAutoComplete[]> {
    if (!searchKey || searchKey.length == 0) {
      return of(products);
    }
    else if (searchKey.length < 3) {
      let prods: ProductsAutoComplete[] = [];
      prods.push(
        {
          PK_PRODUCT_MAPPING_ID: '',
          PRODUCT_DISPLAY_NAME: 'Please enter at least 3 characters to perform search',
          MIQ_PRODUCT_APPROVAL_ID: '',
          IS_INVALID: true
        });

      return of(prods);
    }
    else {
      let filteredProducts = products != null ? products.filter(p => p.PRODUCT_DISPLAY_NAME.toLowerCase().indexOf((searchKey.toLowerCase())) > -1) : null;
      if (filteredProducts && filteredProducts.length > 0) {
        return of(filteredProducts);
      }
      else {
        let prods: ProductsAutoComplete[] = [];
        prods.push(
          {
            PK_PRODUCT_MAPPING_ID: '',
            PRODUCT_DISPLAY_NAME: "No Results for '" + searchKey + "'",
            MIQ_PRODUCT_APPROVAL_ID: '',
            IS_INVALID: true
          });

        return of(prods);
      }
    }
  }


  GetTherapeticAreasWithProducts(): Observable<OurProductsResponse> {
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utility.getSelectedProfile();
    let profileId = profileinfo.PK_ENV_PROFILE_ID;
    return this.http.get<OurProductsResponse>(AppConstants.PortalApiBaseUrl + '/api/Products/GetTherapeticAreasWithProducts/' + AppConstants.EnvironmentId + "/" + profileId);
  }


  GetProductDocuments(searchData: OpDocumentsRequest): Observable<OpDocumentsResponse> {
    searchData.EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<OpDocumentsResponse>(AppConstants.PortalApiBaseUrl + '/api/Products', searchData);
  }

}
