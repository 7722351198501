import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ACCESSTYPES, RegistrationErrors } from 'src/app/_enums/constant-types.enum';
import { PasswordValidator } from 'src/app/_helpers/custom-password-validators';
import { ComparePassword } from 'src/app/_helpers/customvalidators';
import { PASSWORD_POLICY } from 'src/app/_models/password-policy';
import { ResetPasswordRequest, UserLoginResult, UserParams } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppConstants } from '../../../shared/AppConstants';
import { PasswordPolicyComponent } from '../password-policy/password-policy.component';
import { LoginComponent } from '../login/login.component';
import { PortalProfiles } from 'src/app/_models/portals';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  emailID: string | undefined = undefined;
  userParams: UserParams = {};
  ResetPasswordForm: FormGroup;
  passwordPolicy: PASSWORD_POLICY;
  resetPasswordRequest: ResetPasswordRequest = {};
  submitted = false;
  invalidRegisteredEmail = false;
  pattern: string;
  popupTitle: string | undefined = "Reset Password";


  //idle properties
  timed: boolean = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing: Date | null = null;
  countdown: any;
  displayCountdown: any;

  isIdleTimeout: boolean = false;
  IsSessionExpired: boolean = false;
  isLoggedInUser: boolean = false;
  sessionID: string = '';
  //

  constructor(private authService: AuthenticationService,
    private alertifyService:AlertifyService,
    private utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private router: Router,
    public modalRef: BsModalRef,
    public passwordPolicyModal: BsModalRef,
    private modalService: BsModalService,
    public options: ModalOptions,
    private idle: Idle) {

    idle.setIdle(AppConstants.ResetPasswordIdleTimeout * 60);
    idle.setTimeout(1);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
    });

    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => {
      //this.displayCountdown = this.secondsToTime(countdown);
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      this.isIdleTimeout = true;
      console.log(countdown);

      if (countdown == 1) {
        this.timed = true;        
      }
    });

    this.resetTimeout();
  }

  resetTimeout() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.sessionID = '';
    this.timedOut = false;
    this.isIdleTimeout = false;
    this.IsSessionExpired = false;
  }


  ngOnInit(): void {
    this.userParams = this.options.initialState as any as UserParams;    
    this.emailID = this.userParams.EMAIL_ID;
    if (this.userParams.Referrer != undefined && this.userParams.Referrer != "") {
      this.popupTitle = this.userParams.Referrer
    }
    this.setFormGroup();
    this.getPasswordPolicy();
  }

  get form() {
    return this.ResetPasswordForm.controls;
  }

  formControlValid(controlName: string): boolean
  {
    let isValid = true;
    if((this.ResetPasswordForm.controls[controlName].value != null
    && this.ResetPasswordForm.controls[controlName].value.trim() == "")
    || (!this.ResetPasswordForm.controls[controlName].value))
    {
      isValid = false;
    }
    return isValid;
  }

  get enableResetPassword()
  {
    let isValid = true;
    if(!this.formControlValid("REGISTERED_EMAIL_ID"))
    {
      isValid = false;
    }
    if(!this.formControlValid("PASSWORD"))
    {
      isValid = false;
    }
    if(!this.formControlValid("CONFIRM_PASSWORD"))
    {
      isValid = false;
    }
    return isValid;
  }

  validForm()
  {
    let isValid = true;
    if(!this.formControlValid("REGISTERED_EMAIL_ID"))
    {
      isValid = false;
    }
    else if ((!this.utilityService.validateEmail(this.ResetPasswordForm.controls["REGISTERED_EMAIL_ID"].value)) || (this.ResetPasswordForm.controls["REGISTERED_EMAIL_ID"].value && this.ResetPasswordForm.controls["REGISTERED_EMAIL_ID"].errors?.email)) {
      isValid = false;
      this.invalidRegisteredEmail = true;
    }
    if(!this.formControlValid("PASSWORD"))
    {
      isValid = false;
    }
    if (this.ResetPasswordForm.get('PASSWORD')?.errors) {
      isValid = false;
    }
    if (this.ResetPasswordForm.controls["CONFIRM_PASSWORD"].valid) {
      if (this.ResetPasswordForm.controls["PASSWORD"].valid && (this.ResetPasswordForm.controls["PASSWORD"].value != this.ResetPasswordForm.controls["CONFIRM_PASSWORD"].value)) {
        isValid = false;
      }
    }
    else {
      isValid = false;
    }
    return isValid;
  }

  setFormGroup() {
    this.ResetPasswordForm = this.formBuilder.group({
      REGISTERED_EMAIL_ID: new FormControl({value:this.emailID, disabled: true}, [Validators.required, Validators.email]),
      PASSWORD: new FormControl(""),
      CONFIRM_PASSWORD: new FormControl("", [Validators.required]),
    },
    {
      // Used custom form validator name
      validator: ComparePassword("PASSWORD", "CONFIRM_PASSWORD")
    });
  }

  getPasswordPolicy() {
    this.authService.getPasswordPolicy().subscribe({
      next:(result) => {
      this.passwordPolicy = result;
      this.pattern = this.utilityService.getPasswordPolicyPattern(this.passwordPolicy)
      this.setPasswordValidations();
    },
    error:(err) => {
      console.error(err);
    }});
  }

  setPasswordValidations() {
    PasswordValidator.minUpperCase = this.passwordPolicy.MIN_UPPERCASE_CHARS ?? 0;
    PasswordValidator.minLowerCase = this.passwordPolicy.MIN_LOWER_CHARS ?? 0;
    PasswordValidator.minSpecialChars = this.passwordPolicy.MIN_SPECAIL_CHARS ?? 0;
    PasswordValidator.minNumbers = this.passwordPolicy.MIN_NUMERIC_CHARS ?? 0;

    const passwordValidations = [
      Validators.required,
      Validators.pattern(this.pattern),
      Validators.minLength(this.passwordPolicy.MIN_PASSWORD_LENGTH ?? 0),
      PasswordValidator.number,
      PasswordValidator.special,
      PasswordValidator.lower,
      PasswordValidator.upper,];

      this.ResetPasswordForm.controls['PASSWORD'].setValidators(passwordValidations);
  }

  resetPassword()
  {
    this.cleanForm();
    this.submitted = true;
    if(this.validForm())
    {
      this.resetPasswordRequest.EmailId = this.ResetPasswordForm.controls["REGISTERED_EMAIL_ID"].value;
      this.resetPasswordRequest.Password = this.ResetPasswordForm.controls["PASSWORD"].value;
      this.resetPasswordRequest.ConfirmPassword = this.ResetPasswordForm.controls["CONFIRM_PASSWORD"].value;
      this.resetPasswordRequest.PkUserId = this.userParams.PK_USER_ID;
      this.authService.resetPassword(this.resetPasswordRequest)
        .subscribe({
          next:(result) => {
          if (result.Errors?.length != undefined && result.Errors?.length  > 0) {
            var errors:any=[];
            result.Errors.forEach(error => {
              switch (error) {
                case RegistrationErrors.NoAccountFound:
                  errors.push("No account exists with this email ID. Please enter valid registered email id or click on Register to continue Login");
                  break;
                case RegistrationErrors.PWD_POLICIES_NOT_CONFIGURED:
                  errors.push("Password policies are not configured. Please contact your administrator.");
                  break;
                case RegistrationErrors.ExistsInLast3Passwords:
                  errors.push("New password cannot be from last " + this.passwordPolicy.PREV_PWD_MATCHES + " Passwords. Please use a different password.");
                  break;
              }              
            });
            this.alertifyService.errorBaner(errors);
          }
          else
          {
            this.close();
            if (this.userParams.Referrer == "Change Password")
            {
              this.authService.logout();
              this.authService.loginStatus.next(false);
              this.authService.UserFullName.next("");
              this.alertifyService.successBaner('Password changed successfully. Please Login with the updated password');
              this.router.navigate(['/']);
            }
            else
            {
              this.loginPopup();
              this.alertifyService.successBaner('Password changed successfully. You can login now with the new credentials.');
            }
            
          }
        },
        error:(err) => {
          console.error(err);
        }});
      }
  }

  loginPopup() {
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo && profileinfo.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED && !profileinfo.IS_LOGIN_OPTIONAL) {
      this._loginPopup();
    }
  }

  _loginPopup() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(LoginComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.onLogin.subscribe((result: UserLoginResult) => {
      if (result != null) {
        this.authService.loginStatus.next(true);
        this.authService.UserFullName.next(result.UserFullName ?? "");
        this.authService.hcpDetails.next(this.utilityService.getAppUserInfoObject(result));
      }
    });
  }

  openPasswordPolicy()
  {
    const config: ModalOptions = {
      initialState : <any>this.passwordPolicy,
      backdrop : 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.passwordPolicyModal = this.modalService.show(PasswordPolicyComponent, config);
    this.passwordPolicyModal.content.closeBtnName = 'Close';
  }

  public cleanForm() {
    let count = 0;
    if (this.ResetPasswordForm != null && this.ResetPasswordForm != undefined) {
      Object.keys(this.ResetPasswordForm.controls).forEach(key => {

        let value: any = this.ResetPasswordForm.controls[key].value;
        if (this.ResetPasswordForm.controls[key].value != null && typeof (this.ResetPasswordForm.controls[key].value) === "string"
          && this.ResetPasswordForm.controls[key].value !== "") {
          value = this.ResetPasswordForm.controls[key].value.trim();
        }
        else if (this.ResetPasswordForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.ResetPasswordForm.controls[key].setValue(value);
        }

      });
    }
  }

  close()
  {
    this.modalRef.hide();
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.ResetPasswordForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }
    return true;
  }

}
