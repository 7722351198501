<div class="error-bg">
  <h1>
    <div class="row d-inline-block align-items-center">
      <div class="col-md-12 d-flex align-items-center">
        <strong class="pe-3 pt-3"><i class="fa-solid fa-triangle-exclamation"></i></strong>
        <small>404</small>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <span>Oops... Sorry, an error has occurred, Requested page not found!</span>
      </div>
    </div>
  </h1>
</div>
