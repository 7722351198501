import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/_services/utility.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { HomeService } from '../../_services/home.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {

  featuresList: any;
  selectedList: any;
  platformId: any;
  CurrentTags: any;
  constructor(
    public authService: AuthenticationService,
    public utilityService: UtilityService,
    private route: Router,
    private titleService: Title, private metaTagService: Meta,
    public homeService: HomeService, @Inject(PLATFORM_ID) platformId: any) {
    this.platformId = platformId;
    this.setSEOTags();
  }

  ngOnInit(): void {
    this.homeService.setRouterUrl(this.route.url);
    this.authService.setCurrentFeatureCode("ST_MP");
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }

    if (this.authService.isCookie()) {
      this.authService.cookieTracking("ST_MP").subscribe();
    }

    this.homeService.featureProfileObserver.subscribe((response) => {
      if (response) {
        this.featuresList = response;
      }
    });
  }

  checkFeatureExists(code: string): boolean {
    let result: boolean = false;
    let isExist = this.featuresList.findIndex((profile: any) => profile.Code == code);
    if (isExist != -1) {
      result = true
    }
    return result;
  }

  checkHomeExists(Smi_code: string, Ae_code: string): boolean {
    let result: boolean = false;
    let isExist = this.featuresList.findIndex((profile: any) => (profile.Code == Smi_code) || (profile.Code == Ae_code));
    if (isExist != -1) {
      result = true
    }
    return result;
  }


  setSEOTags() {
    this.CurrentTags = this.utilityService.getCurrentPageTags("ST_MP");
    let title = "";
    (this.CurrentTags == undefined || (this.CurrentTags?.Title == "")) ? title = "Site Map" : title = this.CurrentTags.Title;
    this.titleService.setTitle(title);
    if (this.CurrentTags != null && this.CurrentTags != undefined) {
      this.metaTagService.updateTag({ name: 'description', content: this.CurrentTags.MetaDescription });
      this.metaTagService.updateTag({ name: 'title', content: this.CurrentTags.MetaTitle });
    }
    else {
      this.metaTagService.updateTag({ name: 'title', content: "" });
      this.metaTagService.updateTag({ name: 'description', content: "" });
    }
  }

}
