
      <div *ngIf="isMSLPage" class="col-md-12">
        <div class="promoCards text-center">
          <div class="title-md text-center title-color-2">Find My MSL</div>
          <span class="pt-2"><i class="far fa-user-circle"></i></span>
          <a href="javascript:void(0)" routerLink="/find-msl"
          class="d-inline-block">Click Here</a>
          <p>To schedule a call/ meeting <br>with MSL</p>
        </div>
      </div>
      <div class="col-md-12">
        <div class="promoCards">
          <div class="title-md text-center title-color-2">Contact Us</div>
          <span class="pt-3"><i class="fas fa-headset"></i></span>
          <ul class="contact-dtls">
            <li>
              <span><i class="fas fa-phone-alt"></i></span>
              <small>+1 (855) 254-0815 available on Monday – Friday from 9am - 7pm.</small>
            </li>
            <li>
              <span><i class="fas fa-envelope"></i></span>
              <a href="mailto:info@daiichisankyo.com?subject=Medical%20Information%20Request"
                  class="d-inline-block">info@daiichisankyo.com</a>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="isEventsPage" class="col-md-12">
        <div class="promoCards text-center">
          <span><i class="fas fa-envelope-open-text"></i></span>
          <a href="javascript:void(0)" (click)="openSubscribeModal()" class="d-inline-block"
          *ngIf="subscribed != null && !subscribed">Click to Subscribe</a>
          <span class="subscribed" *ngIf="subscribed != null && subscribed">Subscribed</span>
          <p>And get updates on all upcoming Events and Conferences</p>
        </div>
      </div>
