import { PortalComponents, PortalProfiles } from "src/app/_models/portals";

export class AppConstants {
  static PortalApiBaseUrl: string;
  static EncryptSecretKey: string;
  static PortalId?: string;
  static EnvironmentId?: string;
  static PkUserId?: string;
  static ReporterTypeId?: string;
  static ReporterTypeName?: string;
  static LandingPage: string;
  static ProductsMappingType: string;
  static ReportAttachmentMaxFileSize: number;
  static LocalStorageTimeOutInSeconds: number;
  static PortalSuffix: string;
  static SessionIdleTimeout: number;
  static AutoLogoutTimeout: number;
  static SessionInactiveTime: number;
  static ResetPasswordIdleTimeout: number;
  static PortalProfiles?: PortalProfiles[];

  static readonly Grid = {
    DefaultPageSize: 10
  };

  static enabledMenuItems = {
    isSMI: false,
    isRMI: false,
    isMSL: false,
    isAE: false,
    isPC: false,
    isNews: false,
    isConferences: false,
    isProducts: false,
    isResources: false,
    isSitemap:false
  };


  //static portalApiBaseUrlFormat: string;
  //static appBaseUrl: string;
  //static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
  //static appBaseUrlFormat: string;
  //static readonly userManagement = {
  //    defaultAdminUserName: 'admin'
  //};
  //static sessionIdleTimeOut: number;

  //https://stackblitz.com/edit/bins-captcha?file=src%2Fapp%2Fcaptcha%2Fcaptcha.component.ts
}
