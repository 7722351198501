<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block">
      {{this.popupTitle}}
      <span (click)="this.close()" class="fs-12 text-underline float-right pt-1"><i class="fas fa-times"></i></span>
    </h5>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div *ngIf="!timedOut">
        <form [formGroup]="ResetPasswordForm">
          <div class="row">
            <div class="col-md-12 text-right">
              <label class="fieldLabel mandatory fs-12 text-secondary">Required Fields</label>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="fieldLabel mandatory">Registered Email ID</label>
                <input type="email" class="form-control" formControlName="REGISTERED_EMAIL_ID"
                       (blur)="handleInput($event.target,'REGISTERED_EMAIL_ID');" autocomplete="off"
                       maxlength="100" [ngClass]="{ 'is-invalid': form.REGISTERED_EMAIL_ID.errors }" />
                <div *ngIf="(form.REGISTERED_EMAIL_ID.dirty || form.REGISTERED_EMAIL_ID.touched || submitted) && form.REGISTERED_EMAIL_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.REGISTERED_EMAIL_ID.errors?.['required']">
                    Email ID is required.
                  </div>
                  <div *ngIf="invalidRegisteredEmail || form.REGISTERED_EMAIL_ID.errors?.['email']">
                    Please enter valid Email.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="fieldLabel mandatory">New Password</label>
                <input type="password" class="form-control" required="" noSpaces PWDSpecailChars
                       formControlName="PASSWORD" autocomplete="off" maxlength="100" (blur)="handleInput($event.target,'PASSWORD');"
                       [ngClass]="{ 'is-invalid': (form.PASSWORD.dirty || form.PASSWORD.touched || submitted) && form.PASSWORD.errors }" />
                <div *ngIf="(form.PASSWORD.dirty || form.PASSWORD.touched || submitted) && form.PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.PASSWORD.errors?.['required']">
                    Password is required.
                  </div>
                  <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['number']">
                    Password should contain at least {{passwordPolicy.MIN_NUMERIC_CHARS}} number
                  </div>
                  <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['upper']">
                    Password should contain at least {{passwordPolicy.MIN_UPPERCASE_CHARS}} uppercase letter
                  </div>
                  <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['lower']">
                    Password should contain at least {{passwordPolicy.MIN_LOWER_CHARS}} lowercase letter
                  </div>
                  <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['special']">
                    Password should contain at least {{passwordPolicy.MIN_SPECAIL_CHARS}} special letter
                  </div>
                  <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['minlength']">
                    Password should contain minimum of {{passwordPolicy.MIN_PASSWORD_LENGTH}} characters
                  </div>
                  <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['pattern']">
                    Password doesn't meet the policy requirement. Please check password policy.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="fieldLabel mandatory">Confirm Password</label>
                <input type="password" class="form-control" required="" noSpaces PWDSpecailChars
                       formControlName="CONFIRM_PASSWORD" autocomplete="off" maxlength="100" (blur)="handleInput($event.target,'CONFIRM_PASSWORD');"
                       [ngClass]="{ 'is-invalid': (form.CONFIRM_PASSWORD.dirty || form.CONFIRM_PASSWORD.touched || submitted) && form.CONFIRM_PASSWORD.errors }" />
                <div *ngIf="(form.CONFIRM_PASSWORD.dirty || form.CONFIRM_PASSWORD.touched || submitted) && form.CONFIRM_PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.CONFIRM_PASSWORD.errors?.['required']">
                    Confirm Password is required.
                  </div>
                  <div *ngIf="!form.CONFIRM_PASSWORD.errors?.['required'] && form.CONFIRM_PASSWORD.errors?.mustMatch">
                    Password and Confirm password should match
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 pt-2">
              <button type="button" [disabled]="!this.enableResetPassword"
                      class="btn btn-primary btn-block fs-14" (click)="resetPassword()">
                Confirm
              </button>
              <p class="fs-13 pt-1">
                Please note that your password should meet our
                <a href="javascript:void(0)" (click)="this.openPasswordPolicy()">Password Policy.&nbsp;<i class="fas fa-info-circle fs-14"></i></a>
              </p>
            </div>
          </div>
        </form>

      </div>


      <div class="row" *ngIf="timedOut">
        <div class="col-md-12">
          <p class="fs-14 text-center">
            Session timeout ! please try again
        </div>
      </div>

      <div>

      </div>
    </div>

  </div>

</div>
