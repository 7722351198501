import { HttpClient } from '@angular/common/http';
//import { Byte } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/shared/AppConstants';
import { ContentInformationResponseType } from '../_enums/constant-types.enum';
import { ContentSearch, FrequentlyUsedKewordsRequest, FrequentlyUsedKewordsResponse, SearchMICriteria, SearchMISearchResult, SearchMISetupResponse } from '../_models/ContentInformation';
import { PortalProfiles } from '../_models/portals';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class MedicalInfoService {

  constructor(private http: HttpClient, private utility: UtilityService, private sanitizer: DomSanitizer) { }

  getContentInformation(criteria: ContentSearchCriteria): Observable<DocumentResponse> {
    criteria.PortalCode = AppConstants.EnvironmentId ?? '';
    criteria.FK_EnvironmentId = AppConstants.EnvironmentId;
    criteria.ReporterTypeId = AppConstants.ReporterTypeId;
    criteria.PK_UserID = this.utility.getAppUserInfo() != null ? this.utility.getAppUserInfo().PK_UserId : "";
    criteria.PortalId = this.utility.getPortalID();
    criteria.FK_PortalId = this.utility.getPortalID();
    return this.http.post<DocumentResponse>(AppConstants.PortalApiBaseUrl + '/api/FindMedical', criteria);
  }

  getVaultDocument(parms: VaultDocParams): Observable<CP_TEMP_FILE_STORE> {
    parms.FK_EnvironmentId = AppConstants.EnvironmentId;
    parms.PortalCode = AppConstants.EnvironmentId;
    parms.PortalId = this.utility.getPortalID();
    parms.FK_PortalId = this.utility.getPortalID();
    parms.PK_UserID = this.utility.getAppUserInfo().PK_UserId;
    parms.ProductsMappingType = this.utility.getProductsMappingType();
    return this.http.post<CP_TEMP_FILE_STORE>(AppConstants.PortalApiBaseUrl + '/api/VeevaVault/GetVaultDocument', parms);
  }

  getDocument(parms: VaultDocParams): Observable<FileResponse> {
    parms.FK_EnvironmentId = AppConstants.EnvironmentId;
    parms.FK_PortalId = this.utility.getPortalID();
    parms.PortalCode = AppConstants.EnvironmentId;
    parms.PK_UserID = this.utility.getAppUserInfo().PK_UserId;
    parms.ProductsMappingType = this.utility.getProductsMappingType();
    return this.http.post<FileResponse>(AppConstants.PortalApiBaseUrl + '/api/FindMedical/GetDocument', parms);
  }

  getReporterTypes(): Observable<ReporterTypeMappings[]> {
    return this.http.get<ReporterTypeMappings[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetReporterTypes/' + AppConstants.EnvironmentId);
  }

  getIndications(): Observable<VVIndicationsDataResponse> {
    return this.http.get<VVIndicationsDataResponse>(AppConstants.PortalApiBaseUrl + '/api/VeevaVault/GetIndications/' + AppConstants.EnvironmentId);
  }


  // #region NEW SMI API SERVICES

  getSMIContent(): Observable<SearchMISetupResponse> {
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utility.getSelectedProfile();
    let pkprofileID = profileinfo.PK_ENV_PROFILE_ID;
    return this.http.get<SearchMISetupResponse>(AppConstants.PortalApiBaseUrl + '/api/FindMedical/getSMIContent/' + AppConstants.EnvironmentId + "/" + pkprofileID);
  }

  searchMI(searchData: SearchMICriteria): Observable<SearchMISearchResult> {
    searchData.EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<SearchMISearchResult>(AppConstants.PortalApiBaseUrl + '/api/FindMedical', searchData);
  }

  getURL(parms: ContentSearch): string {
    parms.EnvironmentId = AppConstants.EnvironmentId;
    let props = parms.EnvironmentId + "/" + parms.DocId + "/" + parms.VersionId + "/" + parms.Version + "/" + parms.DocFileKey + "/" + parms.FileNameWithoutExt + "/" + parms.FileType + "/" + parms.ViewType;
    return AppConstants.PortalApiBaseUrl + '/api/File/GetContentFile/' + props;
  }

  downloadURL(parms: ContentSearch): SafeUrl {
    parms.EnvironmentId = AppConstants.EnvironmentId;
    let props = parms.EnvironmentId + "/" + parms.DocId + "/" + parms.VersionId + "/" + parms.Version + "/" + parms.DocFileKey + "/" + parms.FileNameWithoutExt + "/" + parms.FileType + "/" + parms.ViewType;
    return this.sanitizer.bypassSecurityTrustResourceUrl(AppConstants.PortalApiBaseUrl + '/api/File/GetContentFile/' + props);
  }

  FetchFrequentlyUsedProductKeywords(searchData: FrequentlyUsedKewordsRequest): Observable<FrequentlyUsedKewordsResponse> {
    searchData.EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<FrequentlyUsedKewordsResponse>(AppConstants.PortalApiBaseUrl + '/api/FindMedical/FetchFrequentlyUsedProductKeywords', searchData);
  }

  // #endregion NEW SMI API SERVICES

}

export interface DocumentResponse {
  Status?: boolean;
  ErrorMessage?: string;
  Documents: DDocument[];
}

export interface DDocument {
  ID?: string;
  Code?: string;
  Type?: ContentInformationResponseType;
  Title: string;
  Name?: string;
  Version?: string;
  FileName?: string;
  FileType?: string;
  MajorVersion?: number;
  MinorVersion?: number;
  VaultMD5CheckSum?: string;
  Question?: string;
  Answer?: string;
  IsApprovedLabel: boolean;
  IsFAQ: boolean;
  IsBookmarked?: boolean;
  BookmarkedVersion?: string;
  BookmarkId?: string;
  VersionId?: string;
}

export interface ContentSearchCriteria {
  ProductApprovalId?: string;
  ReporterTypeId?: string;
  SearchTags?: string;
  FK_EnvironmentId?: string;
  FK_PortalId?: string;
  PortalCode?: string;
  PortalId?: string;
  PK_UserID?: string;
  Indication?: string;
}

export interface VaultDocParams {
  DocumentId?: string;
  DocumentVersionId?: string;
  MajorVersion?: number;
  MinorVersion?: number;
  VaultMD5CheckSum?: string;
  FileNameWithExt?: string;
  FileName?: string;
  FileExt?: string;
  FK_EnvironmentId?: string;
  FK_PortalId?: string;
  PortalCode?: string;
  PortalId?: string;
  PK_UserID?: string;
  ProductsMappingType?: string;
}

export interface ReporterTypeMappings {
  PK_ReporterTypeMappingId?: string;
  FK_EnvironmentId?: string;
  FK_PortalId?: string;
  FK_MiqReporterTypeId?: string;
  MiqOrganizationId?: string;
  MiqReporterTypeId?: string;
  MiqReporterTypeName?: string;
  SerialNum?: number;
}


export interface FileResponse {
  Status?: boolean;
  ErrorMessage?: string;
  //TODO - Test this sceinario - version change effect
  // FileBytes: Byte[];
  FileBytes: any[];
  FileFetchedFromCache: boolean;
  FileStore: CP_TEMP_FILE_STORE;
}

export interface CP_TEMP_FILE_STORE {
  PK_TEMP_FILE_STORE_ID: string;
  IS_ACTIVE: boolean;
  CREATED_DATE_ONLY: Date;
  CREATED_DATE: Date;
  FILE_NAME: string;
  FILE_EXT: string;
  //TODO - Test this sceinario - version change effect
  //FILE_BYTES: Byte[];
  FILE_BYTES: any[];
}

export interface FAQlist {
  Question?: string;
  Answer?: string;
  visible: boolean;
}

export interface VVIndicationsDataResponse {
  Status: boolean;
  Error: string;
  Indications: NameValuePair[]
}

export interface NameValuePair {
  Name: string;
  ID: string;
}
