import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { environment } from '../../environments/environment';
import { UtilityService } from '../_services/utility.service';
import { Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  platformId: any;
  constructor(private authService: AuthenticationService,
    private utilityService: UtilityService,
    private router: Router,
    private alertifyService: AlertifyService, @Inject(PLATFORM_ID) platformId: any) { this.platformId = platformId; }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      if (err.status === 401) {
        this.callLogout();
        location.reload();
      }
      if (err.status === 803) {
        this.callLogout();
        this.alertifyService.errorBaner("Session timed out, please login.");
        location.reload();
      }
      if (err.status === 804) {
        this.callLogout();
        this.alertifyService.errorBaner("Session timed out, please login.");
        location.reload();
      }

      if (!environment.production) {
        console.error(err);
      }

      const error = (err && err.error && err.error.message) || err.statusText;
      return throwError(error);

    }));
  }

  callLogout() {
    let IsSessionTimeOut = false;
    this.authService.logoutResponse(IsSessionTimeOut).subscribe((response) => {
      console.log('logout-res: ', response);
      if (isPlatformBrowser(this.platformId)) {
        this.authService.clearLocalStorage();
      }
      this.authService.loginStatus.next(false);
      this.utilityService.isProfileSelection = true;
      this.utilityService.actionOnSelfQualification(false);
      this.authService.stopRefreshTokenTimer();
      this.router.navigate(['/']);
    });
  }

}
