<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block">
      {{this.IsView ? 'View' : 'Edit'}} MSL Meeting Request - {{this.Data.Request.REQUEST_ID}}
      <a href="javascript:void(0);" (click)="this.close()" class="fs-12 float-right pt-1 text-white">
        <i class="fas fa-times"></i>
      </a>
    </h5>
  </div>
  <div class="modal-body">
    <div class="container-fluid">

      <form [formGroup]="MSLRequestForm">
        <div class="row">

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="fieldLabel">Primary Degree</label>
                  <select [ngClass]="{'is-invalid': (form.FK_PRIMARY_DEGREE_ID.dirty || form.FK_PRIMARY_DEGREE_ID.touched) && form.FK_PRIMARY_DEGREE_ID.errors }"
                          class="form-control"
                          formControlName="FK_PRIMARY_DEGREE_ID">
                    <option value="">-Select-</option>
                    <option *ngFor="let pd of primaryDegreeList" value="{{pd.PK_LOOKUP_ID}}">{{pd.NAME}}</option>
                  </select>
                  <span class="fltlabel-highlight"></span>
                  <div *ngIf="(form.FK_PRIMARY_DEGREE_ID.dirty || form.FK_PRIMARY_DEGREE_ID.touched) && form.FK_PRIMARY_DEGREE_ID.invalid" class="invalid-form-control text-danger fs-12">
                    <div *ngIf="form.FK_PRIMARY_DEGREE_ID.errors?.['required']">
                      Primary Degree is required.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label class="fieldLabel mandatory">Profession</label>
              <select [ngClass]="{'is-invalid': (form.FK_PROFESSION_ID.dirty || form.FK_PROFESSION_ID.touched) && form.FK_PROFESSION_ID.errors }"
                      class="form-control"
                      formControlName="FK_PROFESSION_ID">
                <option value="">-Select-</option>
                <option *ngFor="let prof of professionList" value="{{prof.PK_LOOKUP_ID}}">{{prof.NAME}}</option>
              </select>
              <span class="fltlabel-highlight"></span>
              <div *ngIf="(form.FK_PROFESSION_ID.dirty || form.FK_PROFESSION_ID.touched) && form.FK_PROFESSION_ID.invalid" class="invalid-form-control text-danger fs-12">
                <div *ngIf="form.FK_PROFESSION_ID.errors?.['required']">
                  Profession is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label class="fieldLabel">Specialty</label>
              <select [ngClass]="{'is-invalid': (form.FK_SPECIALTY_ID.dirty || form.FK_SPECIALTY_ID.touched) && form.FK_SPECIALTY_ID.errors }"
                      class="form-control"
                      formControlName="FK_SPECIALTY_ID">
                <option value="">-Select-</option>
                <option *ngFor="let spec of specialityList" value="{{spec.PK_LOOKUP_ID}}">{{spec.NAME}}</option>
              </select>
              <span class="fltlabel-highlight"></span>
              <div *ngIf="(form.FK_SPECIALTY_ID.dirty || form.FK_SPECIALTY_ID.touched) && form.FK_SPECIALTY_ID.invalid" class="invalid-form-control text-danger fs-12">
                <div *ngIf="form.FK_SPECIALTY_ID.errors?.['required']">
                  Specialty is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label for="firstname" class="fieldLabel mandatory">First Name</label>
              <input type="text" maxlength="35" class="form-control" id="firstname"
                     (blur)="handleInput($event.target,'FIRST_NAME');" formControlName="FIRST_NAME" autocomplete="off"
                     [ngClass]="{ 'is-invalid': ((form.FIRST_NAME.dirty || form.FIRST_NAME.touched)&&submitted) && form.FIRST_NAME.errors }" />
              <div *ngIf="((form.FIRST_NAME.dirty || form.FIRST_NAME.touched)&&submitted) && form.FIRST_NAME.invalid"
                   class="invalid-form-control text-danger fs-12">
                <div *ngIf="form.FIRST_NAME.errors?.['required']">
                  First Name is required.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label for="lastname" class="fieldLabel mandatory">Last Name</label>
              <input type="text" maxlength="50" class="form-control" id="lastname"
                     (blur)="handleInput($event.target,'LAST_NAME');" formControlName="LAST_NAME" autocomplete="off"
                     [ngClass]="{ 'is-invalid': ((form.LAST_NAME.dirty || form.LAST_NAME.touched)&&submitted) && form.LAST_NAME.errors }" />
              <div *ngIf="((form.LAST_NAME.dirty || form.LAST_NAME.touched)&&submitted) && form.LAST_NAME.invalid"
                   class="invalid-form-control text-danger fs-12">
                <div *ngIf="form.LAST_NAME.errors?.['required']">
                  Last Name is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label class="fieldLabel mandatory">Country</label>
              <app-country-control formControlName="FK_COUNTRY_ID" [inputAllCountries]="allCountries" [inputSelectedCountry]="selectedCountry"></app-country-control>
              <span class="fltlabel-highlight"></span>
              <div *ngIf="(form.FK_COUNTRY_ID.dirty || form.FK_COUNTRY_ID.touched && submitted) && form.FK_COUNTRY_ID.invalid" class="invalid-form-control text-danger fs-12">
                <div *ngIf="form.FK_COUNTRY_ID.errors?.['required']">
                  Country is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-sm-12">
            <div class="form-group">
              <label class="fieldLabel mandatory">Preferred Contact Method</label>
              <select class="form-control" formControlName="FK_PREFERRED_CONTACT_ID">
                <option value="">-Select-</option>
                <option *ngFor="let preferredContact of preferedContacts"
                        value="{{preferredContact.Id}}">
                  {{preferredContact.ContactName}}
                </option>
              </select>
              <div *ngIf="((form.FK_PREFERRED_CONTACT_ID.dirty || form.FK_PREFERRED_CONTACT_ID.touched) && submitted) &&form.FK_PREFERRED_CONTACT_ID.invalid"
                   class="invalid-form-control text-danger fs-12">
                <div *ngIf="form.FK_PREFERRED_CONTACT_ID.errors?.['required']">
                  Preferred Contact Method is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label for="email" class="fieldLabel" [ngClass]="{ 'mandatory' : this.isEmailMandatory }">Email</label>
              <input type="text" maxlength="100" class="form-control" id="email" formControlName="EMAIL_ID"
                     (blur)="handleInput($event.target,'EMAIL_ID');" autocomplete="off">
              <div *ngIf="((form.EMAIL_ID.dirty) && submitted) && form.EMAIL_ID.invalid" class="invalid-form-control text-danger fs-12">
                <div *ngIf="form.EMAIL_ID.errors?.['required']">
                  Email Id is required.
                </div>
              </div>
              <div *ngIf="(form.EMAIL_ID.dirty )" class="invalid-form-control text-danger fs-12">
                <div *ngIf="invalidPreferredEmail || form.EMAIL_ID.errors?.['email']">
                  Please enter valid Email.
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-6 col-sm-6">
            <div class="row">
              <div class="col-lg-9 col-md-8 col-sm-8">
                <div class="form-group">
                  <label class="fieldLabel" [ngClass]="{ 'mandatory' : this.isPhoneMandatory }">Phone Number</label>
                  <input type="text" maxlength="25" class="form-control" id="mobile-no"
                         formControlName="PHONE" autocomplete="off" phoneNumber (blur)="handleInput($event.target,'PHONE');"
                         [ngClass]="{ 'is-invalid': ((form.PHONE.dirty || form.PHONE.touched)&&submitted) && form.PHONE.errors }" />
                  <div *ngIf="((form.PHONE.dirty || form.PHONE.touched)&&submitted) && form.PHONE.invalid"
                       class="invalid-form-control text-danger fs-12">
                    <div *ngIf="form.PHONE.errors?.['required']">
                      Phone Number is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-4">
                <div class="form-group">
                  <label class="fieldLabel">Ext</label>
                  <input type="text" class="form-control" maxlength="10"
                         (blur)="handleInput($event.target,'PHONE_EXT');" phoneNumber
                         id="smi-ext" formControlName="PHONE_EXT" autocomplete="off">
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12" *ngIf="!this.IsView">
            <div class="title-md text-center title-color-2">Meeting Schedule</div>
          </div>

          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label for="date-select" class="fieldLabel mandatory">Date</label>
              <div>
                <!--<input type="text" placeholder="DD-MMM-YYYY" id="vem-dt" class="form-control" autocomplete="off"
                       formControlName="MSL_SCHEDULED_DATE"
                       (keyup)="keyPress($event,'MSL_SCHEDULED_DATE','vem-dt','',undefined)"
                       (bsValueChange)="Ondatechange($event,'MSL_SCHEDULED_DATE')"
                       [bsConfig]="bsConfig" bsDatepicker>
                <span><i class="far fa-calendar-alt"></i></span>-->

                <p-calendar useUtc inputId="vem-dt"
                            [minDate]="minDate"
                            placeholder="DD-MMM-YYYY"
                            formControlName="MSL_SCHEDULED_DATE"
                            dateFormat="dd-M-yy"
                            [showIcon]="true"
                            appendTo="body"
                            [selectOtherMonths]="true"
                            (onInput)="MinMaxDateValidation($event,'MSL_SCHEDULED_DATE','L',minDate)"
                            icon="far fa-calendar-alt"></p-calendar>
              </div>
              <div *ngIf="(form.MSL_SCHEDULED_DATE.dirty&&submitted) && form.MSL_SCHEDULED_DATE.invalid"
                   class="invalid-form-control text-danger fs-12">
                <div *ngIf="form.MSL_SCHEDULED_DATE.errors?.['required']">
                  Date is required.
                </div>
                <div *ngIf="form.MSL_SCHEDULED_DATE.errors?.bsDate?.invalid">
                  Invalid Date.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label class="fieldLabel mandatory">Time</label>
              <select class="form-control" formControlName="MSL_SCHEDULED_TIME">
                <option value="">-Select-</option>
                <option value="06AM-08AM">06AM - 08AM</option>
                <option value="08AM-10AM">08AM - 10AM</option>
                <option value="10AM-12PM">10AM - 12PM</option>
                <option value="12PM-02PM">12PM - 02PM</option>
                <option value="02PM-04PM">02PM - 04PM</option>
                <option value="04PM-06PM">04PM - 06PM</option>
                <option value="06PM-08PM">06PM - 08PM</option>
                <option value="08PM-10PM">08PM - 10PM</option>
              </select>
              <div *ngIf="((form.MSL_SCHEDULED_TIME.dirty || form.MSL_SCHEDULED_TIME.touched)&&submitted) && form.MSL_SCHEDULED_TIME.invalid"
                   class="invalid-form-control text-danger fs-12">
                <div *ngIf="form.MSL_SCHEDULED_TIME.errors?.['required']">
                  Time is required.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="form-group">
              <label class="fieldLabel mandatory">Time Zone</label>
              <select class="form-control" id="select-timezone" formControlName="MSL_SCHEDULED_TIME_ZONE">
                <option value="">-Select-</option>
                <option value="(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi">(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                <option value="(UTC+08:00) Kuala Lumpur, Singapore">(UTC+08:00) Kuala Lumpur, Singapore</option>
                <option value="(UTC+08:00) Taipei">(UTC+08:00) Taipei</option>
                <option value="(UTC+08:00) Perth">(UTC+08:00) Perth</option>
                <option value="(UTC+08:00) Ulaanbaatar">(UTC+08:00) Ulaanbaatar</option>
                <option value="(UTC+08:00) Irkutsk">(UTC+08:00) Irkutsk</option>
                <option value="(UTC+09:00) Seoul">(UTC+09:00) Seoul</option>
                <option value="(UTC+09:00) Osaka, Sapporo, Tokyo">(UTC+09:00) Osaka, Sapporo, Tokyo</option>
                <option value="(UTC+09:30) Darwin">(UTC+09:30) Darwin</option>
                <option value="(UTC+09:30) Adelaide">(UTC+09:30) Adelaide</option>
                <option value="(UTC+09:00) Yakutsk">(UTC+09:00) Yakutsk</option>
                <option value="(UTC+10:00) Canberra, Melbourne, Sydney">(UTC+10:00) Canberra, Melbourne, Sydney</option>
                <option value="(UTC+10:00) Brisbane">(UTC+10:00) Brisbane</option>
                <option value="(UTC+10:00) Hobart">(UTC+10:00) Hobart</option>
                <option value="(UTC+10:00) Guam, Port Moresby">(UTC+10:00) Guam, Port Moresby</option>
                <option value="(UTC+10:00) Vladivostok">(UTC+10:00) Vladivostok</option>
                <option value="(UTC+11:00) Solomon Is., New Caledonia">(UTC+11:00) Solomon Is., New Caledonia</option>
                <option value="(UTC+11:00) Magadan">(UTC+11:00) Magadan</option>
                <option value="(UTC+12:00) Fiji">(UTC+12:00) Fiji</option>
                <option value="(UTC+12:00) Auckland, Wellington">(UTC+12:00) Auckland, Wellington</option>
                <option value="(UTC+12:00) Coordinated Universal Time+12">(UTC+12:00) Coordinated Universal Time+12</option>
                <option value="(UTC+13:00) Nuku alofa">(UTC+13:00) Nuku alofa</option>
                <option value="(UTC+13:00) Samoa">(UTC+13:00) Samoa</option>
                <option value="(UTC-12:00) International Date Line West">(UTC-12:00) International Date Line West</option>
                <option value="(UTC-11:00) Coordinated Universal Time-11">(UTC-11:00) Coordinated Universal Time-11</option>
                <option value="(UTC-10:00) Hawaii">(UTC-10:00) Hawaii</option>
                <option value="(UTC-09:00) Alaska">(UTC-09:00) Alaska</option>
                <option value="(UTC-08:00) Pacific Time(US & Canada)">(UTC-08:00) Pacific Time(US & Canada)</option>
                <option value="(UTC-08:00) Baja California">(UTC-08:00) Baja California</option>
                <option value="(UTC-07:00) Mountain Time (US & Canada)">(UTC-07:00) Mountain Time (US & Canada)</option>
                <option value="(UTC-07:00) Chihuahua, La Paz, Mazatlan">(UTC-07:00) Chihuahua, La Paz, Mazatlan</option>
                <option value="(UTC-07:00) Arizona">(UTC-07:00) Arizona</option>
                <option value="(UTC-06:00) Saskatchewan">(UTC-06:00) Saskatchewan</option>
                <option value="(UTC-06:00) Central America">(UTC-06:00) Central America</option>
                <option value="(UTC-06:00) Central Time (US & Canada)">(UTC-06:00) Central Time (US & Canada)</option>
                <option value="(UTC-06:00) Guadalajara, Mexico City, Monterrey">(UTC-06:00) Guadalajara, Mexico City, Monterrey</option>
                <option value="(UTC-05:00) Eastern Time (US & Canada)">(UTC-05:00) Eastern Time (US & Canada)</option>
                <option value="(UTC-05:00) Bogota, Lima, Quito, Rio Branco">(UTC-05:00) Bogota, Lima, Quito, Rio Branco</option>
                <option value="(UTC-05:00) Indiana (East)">(UTC-05:00) Indiana (East)</option>
                <option value="(UTC-04:00) Caracas">(UTC-04:00) Caracas</option>
                <option value="(UTC-04:00) Atlantic Time (Canada)">(UTC-04:00) Atlantic Time (Canada)</option>
                <option value="(UTC-04:00) Cuiaba">(UTC-04:00) Cuiaba</option>
                <option value="(UTC-04:00) Santiago">(UTC-04:00) Santiago</option>
                <option value="(UTC-04:00) Georgetown, La Paz, Manaus, San Juan">(UTC-04:00) Georgetown, La Paz, Manaus, San Juan</option>
                <option value="(UTC-04:00) Asuncion">(UTC-04:00) Asuncion</option>
                <option value="(UTC-03:30) Newfoundland">(UTC-03:30) Newfoundland</option>
                <option value="(UTC-03:00) Brasilia">(UTC-03:00) Brasilia</option>
                <option value="(UTC-03:00) Greenland">(UTC-03:00) Greenland</option>
                <option value="(UTC-03:00) Montevideo">(UTC-03:00) Montevideo</option>
                <option value="(UTC-03:00) Cayenne, Fortaleza">(UTC-03:00) Cayenne, Fortaleza</option>
                <option value="(UTC-03:00) City of Buenos Aires">(UTC-03:00) City of Buenos Aires</option>
                <option value="(UTC-02:00) Mid-Atlantic - Old">(UTC-02:00) Mid-Atlantic - Old</option>
                <option value="(UTC-01:00) Azores">(UTC-01:00) Azores</option>
                <option value="(UTC+00:00) Dublin, Edinburgh, Lisbon, London">(UTC+00:00) Dublin, Edinburgh, Lisbon, London</option>
                <option value="(UTC+00:00) Monrovia, Reykjavik">(UTC+00:00) Monrovia, Reykjavik</option>
                <option value="(UTC+00:00) Casablanca">(UTC+00:00) Casablanca</option>
                <option value="UTC">UTC</option>
                <option value="(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague">(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                <option value="(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb">(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                <option value="(UTC+01:00) Brussels, Copenhagen, Madrid, Paris">(UTC+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                <option value="(UTC+01:00) West Central Africa">(UTC+01:00) West Central Africa</option>
                <option value="(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna">(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                <option value="(UTC+02:00) Windhoek">(UTC+02:00) Windhoek</option>
                <option value="(UTC+02:00) Chisinau">(UTC+02:00) Chisinau</option>
                <option value="(UTC+02:00) Cairo">(UTC+02:00) Cairo</option>
                <option value="(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius">(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                <option value="(UTC+02:00) Athens, Bucharest">(UTC+02:00) Athens, Bucharest</option>
                <option value="(UTC+02:00) Jerusalem">(UTC+02:00) Jerusalem</option>
                <option value="(UTC+02:00) Amman">(UTC+02:00) Amman</option>
                <option value="(UTC+02:00) Beirut">(UTC+02:00) Beirut</option>
                <option value="(UTC+02:00) Harare, Pretoria">(UTC+02:00) Harare, Pretoria</option>
                <option value="(UTC+02:00) Damascus">(UTC+02:00) Damascus</option>
                <option value="(UTC+03:00) Istanbul">(UTC+03:00) Istanbul</option>
                <option value="(UTC+03:00) Kuwait, Riyadh">(UTC+03:00) Kuwait, Riyadh</option>
                <option value="(UTC+03:00) Baghdad">(UTC+03:00) Baghdad</option>
                <option value="(UTC+03:00) Nairobi">(UTC+03:00) Nairobi</option>
                <option value="(UTC+02:00) Kaliningrad">(UTC+02:00) Kaliningrad</option>
                <option value="(UTC+03:30) Tehran">(UTC+03:30) Tehran</option>
                <option value="(UTC+03:00) Moscow, St. Petersburg, Volgograd">(UTC+03:00) Moscow, St. Petersburg, Volgograd</option>
                <option value="(UTC+04:00) Abu Dhabi, Muscat">(UTC+04:00) Abu Dhabi, Muscat</option>
                <option value="(UTC+04:00) Baku">(UTC+04:00) Baku</option>
                <option value="(UTC+04:00) Yerevan">(UTC+04:00) Yerevan</option>
                <option value="(UTC+04:00) Tbilisi">(UTC+04:00) Tbilisi</option>
                <option value="(UTC+04:00) Port Louis">(UTC+04:00) Port Louis</option>
                <option value="(UTC+04:30) Kabul">(UTC+04:30) Kabul</option>
                <option value="(UTC+05:00) Ashgabat, Tashkent">(UTC+05:00) Ashgabat, Tashkent</option>
                <option value="(UTC+05:00) Islamabad, Karachi">(UTC+05:00) Islamabad, Karachi</option>
                <option value="(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi">(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                <option value="(UTC+05:30) Sri Jayawardenepura">(UTC+05:30) Sri Jayawardenepura</option>
                <option value="(UTC+05:45) Kathmandu">(UTC+05:45) Kathmandu</option>
                <option value="(UTC+05:00) Ekaterinburg">(UTC+05:00) Ekaterinburg</option>
                <option value="(UTC+06:00) Astana">(UTC+06:00) Astana</option>
                <option value="(UTC+06:00) Dhaka">(UTC+06:00) Dhaka</option>
                <option value="(UTC+06:30) Yangon (Rangoon)">(UTC+06:30) Yangon (Rangoon)</option>
                <option value="(UTC+07:00) Novosibirsk">(UTC+07:00) Novosibirsk</option>
                <option value="(UTC+07:00) Bangkok, Hanoi, Jakarta">(UTC+07:00) Bangkok, Hanoi, Jakarta</option>
                <option value="(UTC+07:00) Krasnoyarsk">(UTC+07:00) Krasnoyarsk</option>
              </select>
              <div *ngIf="((form.MSL_SCHEDULED_TIME_ZONE.dirty || form.MSL_SCHEDULED_TIME_ZONE.touched)&&submitted) && form.MSL_SCHEDULED_TIME_ZONE.invalid"
                   class="invalid-form-control text-danger fs-12">
                <div *ngIf="form.MSL_SCHEDULED_TIME_ZONE.errors?.['required']">
                  Time Zone is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12" *ngIf="Has_RET_Rule">
            <div class="row">
              <div class="col-md-6 pl-0">
                <h4 class="fs-12 pt-2" [ngClass]="{ 'mandatory' : this.isConsentMandatory }">I consent {{utilityService.getPortalName()}} to store information provided for Request processing : </h4>
              </div>
              <div class="col-md-1 pt-2 px-0 custom-radio">
                <input type="radio" class="custom-control-input" value="Accept"
                       formControlName="CONSENT_TYPE" id="accept">
                <label class="custom-control-label"
                       for="accept">Accept</label>
              </div>
              <div class="col-md-2 pt-2 px-0 custom-radio">
                <input type="radio" class="custom-control-input" value="Reject"
                       formControlName="CONSENT_TYPE" id="reject">
                <label class="custom-control-label"
                       for="reject">Reject</label>
              </div>
            </div>
            <div *ngIf="isConsentMandatory && submitted" class="invalid-form-control text-danger fs-12">
              <div *ngIf="form.CONSENT_TYPE.errors?.['required']">
                Select Consent Type.
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="Has_RTF_Rule">
            <div class="custom-control custom-checkbox mt-3">
              <input type="checkbox" class="custom-control-input" id="cp-rtf" name="rtf" formControlName="RTF_CONSENT_TYPE">
              <label class="custom-control-label" for="cp-rtf">Consent for Right to Forget: Should you choose to exercise your Right to Forget and have your personal data removed from our records, kindly check the checkbox</label>
            </div>
          </div>

          <div class="col-md-12 buttonsRow py-2">
            <div class="text-center" ngPreserveWhitespaces *ngIf="!this.IsView">
              <button type="button" class="btn btn-primary" [disabled]="this.enableSaveDraft"
                      (click)="SendRequest('DRAFT')">
                <i class="fas fa-save"></i>&nbsp;Save as draft
              </button>
              <button type="button" class="btn btn-primary" (click)="SendRequest('SUBMITTED')"
                      [disabled]="!this.enableSubmit" id="req-a-call-submit">
                Submit
              </button>
              <button type="button" class="btn btn-primary" (click)="cancel()">Cancel</button>
            </div>
            <button *ngIf="this.IsView" (click)="this.close()" type="button"
                    class="btn btn-primary px-3">
              Close
            </button>
          </div>
        </div>
      </form>


    </div>
  </div>
</div>
