import { FormControl } from "@angular/forms";

export interface ValidationResult {
  [key: string]: boolean;
}

export class PasswordValidator {

  public static minUpperCase: number;
  public static minLowerCase: number;
  public static minSpecialChars: number;
  public static minNumbers: number;

  constructor() {

  }

  public static number(control: FormControl): ValidationResult | null {
    if (control.value) {
      let hasNumber = /\d/.test(control.value);
      let numbersLength = (control.value).replace(/[^0-9]/g, "").length;
      if (hasNumber && (numbersLength >= PasswordValidator.minNumbers)) {
        return null;
      }
    }
    return { number: true };
  }

  public static upper(control: FormControl): ValidationResult | null {
    if (control.value) {
      let hasUpper = /[A-Z]/.test(control.value);
      let uppercaseCharsLength = (control.value).replace(/[^A-Z]/g, "").length;
      if (hasUpper && (uppercaseCharsLength >= PasswordValidator.minUpperCase)) {
        return null;
      }
    }
    return { upper: true };
  }

  public static lower(control: FormControl): ValidationResult | null {
    if (control.value) {
      let hasLower = /[a-z]/.test(control.value);
      let lowercaseCharsLength = (control.value).replace(/[^a-z]/g, "").length;
      if (hasLower && (lowercaseCharsLength >= PasswordValidator.minLowerCase)) {
        return null;
      }
    }
    return { lower: true };
  }

  public static special(control: FormControl): ValidationResult | null {
    // .replace(/[!#$%&()*s+-/@[\]^_{}~]/g, "")
    if (control.value) {
      let hasSpecial = /[!#$%&()*+-/@~[\]^_{}]/.test(control.value);
      let specialCharsLength = (control.value).replace(/[^#%!$&()+-/@_{}~*\^]*/g, "");
      if (hasSpecial && (specialCharsLength.length >= PasswordValidator.minSpecialChars)) {
        return null;
      }
    }
    return { special: true };
  }
}
