import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppUserInfo } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { EventsConferencesService } from 'src/app/_services/events-conferences.service';
import { NewsAnnouncementsService } from 'src/app/_services/news-announcements.service';
import { UtilityService } from 'src/app/_services/utility.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent implements OnInit {
  public onClose: Subject<boolean>;
  SubscribeForm: FormGroup;
  submitted = false;
  Data: any;
  Referrer: string;
  isLoggedInUser: boolean
  hcpDetails: AppUserInfo;

  public onSubscription: EventEmitter<any> = new EventEmitter();

  constructor(public modalRef: BsModalRef,
    private alertify: AlertifyService,
    private utilityService: UtilityService,
    public authService: AuthenticationService,
    private readonly eventConfService: EventsConferencesService,
    private newsAnnouncementsService: NewsAnnouncementsService,
    private modalService: BsModalService,
    public options: ModalOptions) {
    this.onClose = new Subject();
  }

  ngOnInit(): void {
    this.Data = this.options.initialState as any;
    this.Referrer = this.Data.Referrer;
    let uri = this.authService.getUnsubscribeConfsURI();
    if (this.Referrer == "NEWS") {
      uri = this.authService.getUnsubscribeNewsURI();
    }


    this.isLoggedInUser = this.authService.isUserLoggedIn();
    if (this.isLoggedInUser) {
      this.hcpDetails = this.authService.getLoginHCPDetails();
      let emailid = this.hcpDetails.PREFERRED_CONTACT_NAME?.toUpperCase() == "EMAIL" ? this.hcpDetails.PREFERED_EMAIL ?? "" : (this.hcpDetails.EmailID ? this.hcpDetails.EmailID : "");
      this.SubscribeForm = new FormGroup({
        PK_UserID: new FormControl(this.Data.PK_UserID),
        UnsubscribeURI: new FormControl(uri),
        LastName: new FormControl(this.hcpDetails.LAST_NAME ?? "", Validators.required),
        EmailId: new FormControl(emailid, [Validators.required, Validators.email])
      });
    }
    else {
      this.SubscribeForm = new FormGroup({
        PK_UserID: new FormControl(this.Data.PK_UserID),
        UnsubscribeURI: new FormControl(uri),
        LastName: new FormControl("", Validators.required),
        EmailId: new FormControl("", [Validators.required, Validators.email])
      });
    }
  }

  get form() {
    return this.SubscribeForm.controls;
  }

  get enableSubscribe() {
    let isValid = true;
    if ((this.SubscribeForm.controls["LastName"].value != null
      && this.SubscribeForm.controls["LastName"].value.trim() == "")
      || (!this.SubscribeForm.controls["LastName"].value)) {
      isValid = false;
    }
    if ((this.SubscribeForm.controls["EmailId"].value != null
      && this.SubscribeForm.controls["EmailId"].value.trim() == "")
      || (!this.SubscribeForm.controls["EmailId"].value)) {
      isValid = false;
    }
    return isValid;
  }

  close() {
    this.onClose.next(false);
    this.modalRef.hide();
  }

  subscribe() {
    this.cleanForm();
    let isValidEmail: Boolean = true;
    let isValid: Boolean = true;
    let errrs: any = [];
    // if (this.SubscribeForm.controls['EmailId'].value === "") {
    //   errrs.push("Please enter Email.");
    //   isValid = false;
    // }  // No need as submit button is disabled till the mandatory fields filled
    if (!this.SubscribeForm.controls["LastName"].value.trim()) {
      errrs.push("Please enter Last Name.");
      isValid = false;
    }
    if ((!this.utilityService.validateEmail(this.SubscribeForm.controls['EmailId'].value)) || (this.SubscribeForm.controls['EmailId'].value && this.SubscribeForm.controls['EmailId'].errors?.email)) {
      errrs.push("Please enter valid Email.");
      isValidEmail = false;
    }
    if (isValidEmail && isValid) {
      this.submitted = true;
      if (this.Referrer == "EVENTS") {
        this.eventConfService.postEventConfSubs(this.SubscribeForm.value).subscribe({
          next:(res) => {
          if (res) {
            if (res.Status) {
              this.alertify.successBaner("Thank you for subscribing. You will receive a confirmation message shortly on your provided email id.");
              this.onSubscription.emit(res);
              this.modalRef.hide();
            }
            else {
              this.alertify.errorBaner(res.ErrorMessage ?? "");
            }
          }
          else {
            this.alertify.errorBaner('Unknown Error..!');
          }
        },
          error:(err) => {
            this.alertify.error(err);
          }});
      }
      else if (this.Referrer == "NEWS") {
        this.newsAnnouncementsService.subscribeNewsAnnnouncements(this.SubscribeForm.value).subscribe({
          next:(res) => {
          if (res) {
            if (res.Status) {
              this.alertify.successBaner("Thank you for subscribing. You will receive a confirmation message shortly on your provided email id.");
              this.onSubscription.emit(res);
              this.onClose.next(true);
              this.modalRef.hide();
            }
            else {
              if (res.Errors != null && res.Errors.length > 0) {
                res.Errors.forEach(error => {
                  switch (error) {
                    case "ALREADY_SUBSCRIBED":
                      this.alertify.errorBaner(res.ErrorMessage ?? "");
                      this.onSubscription.emit(res);
                      this.modalRef.hide();
                      break;
                  }
                });
              }
              else {
                this.alertify.errorBaner(res.ErrorMessage ?? "");
              }
            }
          }
          else {
            this.alertify.errorBaner('Unknown Error..!');
          }
        },
         error: (err) => {
            this.alertify.error(err);
          }});
      }
    }
    else {
      this.alertify.validationBaner(errrs);
    }
  }


  public cleanForm() {
    let count = 0;
    if (this.SubscribeForm != null && this.SubscribeForm != undefined) {
      Object.keys(this.SubscribeForm.controls).forEach(key => {

        let value: any = this.SubscribeForm.controls[key].value;
        if (this.SubscribeForm.controls[key].value != null && typeof (this.SubscribeForm.controls[key].value) === "string"
          && this.SubscribeForm.controls[key].value !== "") {
          value = this.SubscribeForm.controls[key].value.trim();
        }
        else if (this.SubscribeForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.SubscribeForm.controls[key].setValue(value);
        }

      });
    }
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.SubscribeForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }

    return true;
  }

}
