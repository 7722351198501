<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title w-100 d-block">Update Registered Email
        </h5>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <form [formGroup]="UpdateEmailForm">
                <div class="row">
                    <div class="col-md-12 text-right">
                        <label class="fieldLabel mandatory fs-12 text-secondary">Required Fields</label>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="fieldLabel mandatory">Registered Email ID</label>
                            <input type="email" class="form-control" formControlName="REGISTERED_EMAIL_ID"
                            (blur)="handleInput($event.target,'REGISTERED_EMAIL_ID');" 
                            autocomplete="off" maxlength="100" [ngClass]="{ 'is-invalid': form.REGISTERED_EMAIL_ID.errors }" />
                            <div *ngIf="(form.REGISTERED_EMAIL_ID.dirty || form.REGISTERED_EMAIL_ID.touched || submitted) && form.REGISTERED_EMAIL_ID.invalid"
                                class="invalid-form-control text-danger fs-12">
                                <div *ngIf="form.REGISTERED_EMAIL_ID.errors?.['required']">
                                    Email ID is required.
                                </div>
                                <div *ngIf="form.REGISTERED_EMAIL_ID.errors?.['email']">
                                    Please enter valid Email.
                                </div>
                            </div>
                        </div>
                        <p class="fs-12 py-1">If you change registered email id you will have to go through the
                            verification process again</p>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="fieldLabel mandatory">Enter New Email ID</label>
                            <input type="email" class="form-control" formControlName="NEW_EMAIL_ID"
                            (blur)="handleInput($event.target,'NEW_EMAIL_ID');" (keyup)="newMailValidation($event.target);" autocomplete="off" maxlength="100"
                                [ngClass]="{ 'is-invalid': (form.NEW_EMAIL_ID.dirty || form.NEW_EMAIL_ID.touched || submitted) && form.NEW_EMAIL_ID.errors }" />
                            <div *ngIf="(form.NEW_EMAIL_ID.dirty || form.NEW_EMAIL_ID.touched || submitted) && form.NEW_EMAIL_ID.invalid"
                                class="invalid-form-control text-danger fs-12">
                                <div *ngIf="form.NEW_EMAIL_ID.errors?.['required']">
                                    Email ID is required.
                                </div>                                
                            </div>
                            <div *ngIf="form.NEW_EMAIL_ID.value && (invalidNewEmail || form.NEW_EMAIL_ID.errors?.['email'])" class="invalid-form-control text-danger fs-12">
                                    Please enter valid Email.
                            </div>
                            <div *ngIf="form.NEW_EMAIL_ID.value && sameNewEmail" class="invalid-form-control text-danger fs-12">
                                Old email id and new email id cannot be same.
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 pt-2 text-center" ngPreserveWhitespaces>
                        <button type="button" class="btn btn-primary fs-14" (click)="this.close()">Cancel</button>
                        <button type="button" class="btn btn-primary fs-14" 
                        [disabled]="!this.enableSubmit" (click)="submit()">Submit</button>
                    </div>
                </div>
            </form>
        </div>

    </div>

</div>
