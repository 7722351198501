<div class="pageContent bodyBg py-3">
     <div class="row">
          <div class="col-md-9">
               <div class="card px-3 py-2 mb-3">
                    <div class="defaultTabs">
                         <div class="container-fluid">
                              <div class="row">
                                   <div class="col-md-12">
                                        <div class="title-lg text-primary" style="display: flex;"><i
                                                  class="fas fa-exclamation-triangle fs-20 opacity-2"></i>&nbsp;
                                                                           <h1 style="font-size: 24px;">
                                                                             {{CurrentTags == undefined || CurrentTags?.Header == "" ? "Resources" : CurrentTags?.Header }}
                                                                           </h1>
                                        </div>
                                        <p class="fs-12 pt-2 pb-3">{{utilityService.getPortalName()}} Resources for Healthcare Professional will provide various programs details and access from here.</p>
                                   </div>
                              </div>
                              <div class="row row-flex">
                                   <div class="col-md-4 col-sm-6 mb-2">
                                        <div class="card resourceCard mb-2">
                                             <div class="title-md zindex-1 py-1 font-weight-bold">Professional Resources</div>
                                             <p class="fs-13 zindex-1 pb-3">Learn more about the required Professional Resources at {{utilityService.getPortalName()}}.</p>
                                             <a (click)="openConfirmationPopup('https://www.cdc.gov/')" href="javascript:void(0);"
                                                  class="float-right text-right zindex-1 pt-1"><i
                                                       class="fas fa-chevron-right"></i></a>
                                        </div>
                                   </div>
                                   <div class="col-md-4 col-sm-6 mb-2">
                                        <div class="card resourceCard mb-2">
                                             <div class="title-md zindex-1 py-1 font-weight-bold">Investigator Sponsored Research</div>
                                             <p class="fs-13 zindex-1 pb-3">We are dedicated to improving the quality of human life by enabling people and feel better. We recognize the valuable role that Investigator-Sponsored Studies (ISS) play in fulfilling this mission.</p>
                                             <a (click)="openConfirmationPopup('https://www.scimaxglobal.com/')" href="javascript:void(0);"
                                                  class="float-right text-right zindex-1 pt-1"><i
                                                       class="fas fa-chevron-right"></i></a>
                                        </div>
                                   </div>
                                   <div class="col-md-4 col-sm-6 mb-2">
                                        <div class="card resourceCard mb-2">
                                             <div class="title-md zindex-1 py-1 font-weight-bold">Grants & Contributions</div>
                                             <p class="fs-13 zindex-1 pb-3">Providing funds to organizations who share our special purpose to help people do more and feel better.</p>
                                             <a (click)="openConfirmationPopup('https://www.phactmi.org/')" href="javascript:void(0);"
                                                  class="float-right text-right zindex-1 pt-1"><i
                                                       class="fas fa-chevron-right"></i></a>
                                        </div>
                                   </div>

                                   <div class="col-md-4 col-sm-6 mb-2">
                                        <div class="card resourceCard mb-2">
                                             <div class="title-md zindex-1 py-1 font-weight-bold">Product Pipeline</div>
                                             <p class="fs-13 zindex-1 pb-3">We invest in scientific and technical excellence to develop and launch a pipeline of new products that meet the needs of patients and payers.</p>
                                             <a (click)="openConfirmationPopup('https://www.scimaxglobal.com/')" href="javascript:void(0);"
                                                  class="float-right text-right zindex-1 pt-1"><i
                                                       class="fas fa-chevron-right"></i></a>
                                        </div>
                                   </div>
                                   <div class="col-md-4 col-sm-6 mb-2">
                                        <div class="card resourceCard mb-2">
                                             <div class="title-md zindex-1 py-1 font-weight-bold">Compassionate Access Request</div>
                                             <p class="fs-13 zindex-1 pb-3">We recognize that there may be circumstances when it is appropriate for Healthcare Professionals to give their patients Investigational medicines to treat life threatening or seriously debilitating diseases/ conditions where no satisfactory alternatives exist.</p>
                                             <a (click)="openConfirmationPopup('https://www.scimaxglobal.com/')" href="javascript:void(0);"
                                                  class="float-right text-right zindex-1 pt-1"><i
                                                       class="fas fa-chevron-right"></i></a>
                                        </div>
                                   </div>
                                   <div class="col-md-4 col-sm-6 mb-2">
                                        <div class="card resourceCard mb-2">
                                             <div class="title-md zindex-1 py-1 font-weight-bold">Clinical Trials & Results</div>
                                             <p class="fs-13 zindex-1 pb-3">Find our clinical trial studies from the ClinicalTrials.gov database.</p>
                                             <a (click)="openConfirmationPopup('https://clinicaltrials.gov/')" href="javascript:void(0);"
                                                  class="float-right text-right zindex-1 pt-1"><i
                                                       class="fas fa-chevron-right"></i></a>
                                        </div>
                                   </div>
                                   <div class="col-md-4 col-sm-6 mb-2">
                                        <div class="card resourceCard mb-2">
                                             <div class="title-md zindex-1 py-1 font-weight-bold">Patient Assistance Program</div>
                                             <p class="fs-13 zindex-1 pb-3">We recognize the burden patients bear on access the costlier medicines. Our PAP program supports copay and subsidy channels for eligible patients.</p>
                                             <a (click)="openConfirmationPopup('https://www.scimaxglobal.com/')" href="javascript:void(0);"
                                                  class="float-right text-right zindex-1 pt-1"><i
                                                       class="fas fa-chevron-right"></i></a>
                                        </div>
                                   </div>
                                   <div class="col-md-4 col-sm-6 mb-2">
                                        <div class="card resourceCard mb-2">
                                             <div class="title-md zindex-1 py-1 font-weight-bold">Patient Reported Outcomes</div>
                                             <p class="fs-13 zindex-1 pb-3">We care for the people long healthy life and here is the place where you can find all the Patient reported Outcomes for our recently marketed approval drugs.</p>
                                             <a (click)="openConfirmationPopup('https://www.scimaxglobal.com/')" href="javascript:void(0);"
                                                  class="float-right text-right zindex-1 pt-1"><i
                                                       class="fas fa-chevron-right"></i></a>
                                        </div>
                                   </div>
                                   <div class="col-md-4 col-sm-6 mb-2">
                                        <div class="card resourceCard mb-2">
                                             <div class="title-md zindex-1 py-1 font-weight-bold">Drug Shortages</div>
                                             <p class="fs-13 zindex-1 pb-3">You can reach out to us for any research and clinical study drug requests on any need hour.</p>
                                             <a (click)="openConfirmationPopup('https://www.scimaxglobal.com/')" href="javascript:void(0);"
                                                  class="float-right text-right zindex-1 pt-1"><i
                                                       class="fas fa-chevron-right"></i></a>
                                        </div>
                                   </div>                                   
                              </div>
                         </div>

                    </div>
               </div>
          </div>
          <div class="col-md-3">
               <div class="row">
                    <app-promotional-cards></app-promotional-cards>
               </div>
          </div>
     </div>

</div>
