import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { UserLoginResult } from "src/app/_models/user";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { UtilityService } from "src/app/_services/utility.service";
import { PortalProfiles } from "../../_models/portals";
import { HomeService } from "../../_services/home.service";
import { LoginComponent } from "../login/login.component";

@Component({
  selector: 'changepassword-success-banertemplate',
  template: `
   
      <div class="modelHeader">Success Message</div>
      <div class="modal-body">
            <p class="text-success fs-13 pb-2">Password changed successfully. Please 
            <a href="javascript:void(0)" (click)="this.openLoginForm()">Login</a> with the updated password</p>
            <div class="text-center">
              <button type="button" class="btn btn-default" (click)="messageClose()">Ok</button>
            </div>
      </div>
    
        `,
  styles: [
  ]
})
export class ChangePasswordSuccessBanertemplate implements OnInit {

  message: any;
  result: Subject<boolean> = new Subject<boolean>();
  public onClose: Subject<boolean>;

  constructor(private authService: AuthenticationService,
    private utilityService: UtilityService,
    public modalRef: BsModalRef,
    public options: ModalOptions,
    private modalService: BsModalService,
    private _bsModalRef: BsModalRef,
    private homeService: HomeService,
    private route: Router) {
  }
  ngOnInit(): void {
    this.onClose = new Subject();
  }
  messageClose() {
    this.onClose.next(false);
    this._bsModalRef.hide();
    this.route.navigate(['/']);
  }

  openLoginForm() {
    this.messageClose();
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(LoginComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.onLogin.subscribe((result: UserLoginResult) => {
      if (result != null) {
        //this.utilityService.isProfileSelection = false;
        //this.utilityService.actionOnSelfQualification(true);
        let userProfile: PortalProfiles = {
          ACCESS_TYPE_CODE: result.ACCESS_TYPE_CODE,
          PK_ENV_PROFILE_ID: result.FK_ENV_PROF_ID,
          PROF_TYPE_CODE: result.PROF_TYPE_CODE,
          NAME: result.ENV_PROFILE,
          IS_LOGIN_OPTIONAL: result.IS_LOGIN_OPTIONAL,
        };
        this.homeService.setselectedProfile(userProfile);
        localStorage.setItem('profInfo', JSON.stringify(userProfile));
        this.authService.loginStatus.next(true);
        this.authService.UserFullName.next(result.UserFullName ?? "");
        this.authService.hcpDetails.next(this.utilityService.getAppUserInfoObject(result));
      }
    });
  }

}

