import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from '../_services/utility.service';
import { Subscription, tap, map, startWith } from 'rxjs';
import { AppPreSetupService } from '../_services/app-pre-setup.service';
const Tags_Data_Key = makeStateKey<any>('tags_data');
@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.css']
})
export class AboutPageComponent {


  TAGS_KEY = makeStateKey('tags');
  tagsData: any
  platformId: any;
  CurrentTags$: any;
  allTags$:any;
  
  

  TagsData: any;
  constructor(
    private utilityService: UtilityService,
    private transferState: TransferState, public activaterouter: ActivatedRoute,private ap: AppPreSetupService,
    @Inject(PLATFORM_ID) platformId: any, private titleService: Title, private metaTagService: Meta) {
    this.platformId = platformId;
  }

  ngOnInit() {

    //let seoTagsData = this.activaterouter.snapshot.data["seoTags"];
    this.setSEOTags();
     }


  setSEOTags() {
        
    this.ap.seoTagsData$.subscribe((response: any) => {
      if (response) {
        this.allTags$ = response;
      }
    });

    let test: any = this.allTags$;

   
    this.allTags$ = this.ap.seoTagsData$.pipe(
      startWith(this.transferState.get(Tags_Data_Key, [])),
    );



    this.allTags$
      .pipe(
        tap((item: any) => {
          this.transferState.set(Tags_Data_Key, item);
        }),
        map((item: any) => {
          return item.filter((x: any) => { return x.FeatureCode == 'F_MSL' })[0];
        }
        ),
      )
      .subscribe((tags: any) => {
        this.CurrentTags$ = tags;
        let title = "";
        (tags == undefined || (tags?.Title == "")) ? title = "Find My MSL" : title = tags.Title;
        this.titleService.setTitle(title);
        if (tags != null && tags != undefined) {
          this.metaTagService.updateTag({ name: 'description', content: tags.MetaDescription });
          this.metaTagService.updateTag({ name: 'title', content: tags.MetaTitle });
        }
        else {
          this.metaTagService.updateTag({ name: 'title', content: "" });
          this.metaTagService.updateTag({ name: 'description', content: "" });
        }
      });

  }

}


