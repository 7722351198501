import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { AppConstants } from '../../shared/AppConstants';
import { FileStoreType } from '../_enums/constant-types.enum';
import { PASSWORD_POLICY } from '../_models/password-policy';
import { Portal, PortalProfiles } from '../_models/portals';
import { AppNavigation, AppUserInfo, UserLoginResult } from '../_models/user';
import { BehaviorSubject, Observable } from 'rxjs';
//import { HomeService } from './home.service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  portalTitle?: string = '';
  reporterTypeName: string = 'I am a Healthcare Professional';
  nonHcpReporterTypeName: string = '';
  pk_reporterTypeId: string = 'I am a Patient / Caregiver';
  isProfileSelection: boolean = true;
  isNonHCP: boolean = false;
  IsNotHcpAction: boolean = false;
  previousUrl: string = '';
  currentUrl: string = '';
  sspUN: string = 'sspadmin';
  sspPD: string = '123456';
  platformId: any;

  PageFeatures: any;

  private _featureslistSubject: BehaviorSubject<any>;
  public _featureslistObserver: Observable<any>;
  constructor(private http: HttpClient, private router: Router, private sanitizer: DomSanitizer,

    @Inject(PLATFORM_ID) platformId: any
  ) {
    this.platformId = platformId;
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        //console.log(event);
        //console.log("currentUrl:" + this.currentUrl + ",  PreviousUrl:" + this.previousUrl);
      };
    });


    this._featureslistSubject = new BehaviorSubject<any>([]);
    this._featureslistObserver = this._featureslistSubject.asObservable();
  }

  setEnvironmentStorage(next: Portal) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('ENV_ID', next.FK_EnvironmentId ?? '');
      //localStorage.setItem('RT_ID', next.ReporterTypeId ?? '');
      localStorage.setItem('RT_NAME', next.ReporterTypeName ?? '');
      localStorage.setItem('NON_RT_ID', next.NonHcpReporterTypeId ?? '');
      localStorage.setItem('NON_RT_NAME', next.NonHcpReporterTypeName ?? '');
      localStorage.setItem('PORTAL_NAME', next.Name ?? '');
      localStorage.setItem('PRODUCTS_MAPPING_TYPE', next.ProductsMappingType ?? '');
      localStorage.setItem('cp', JSON.stringify(next));
    }
  }

  getEnvironmentID(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('ENV_ID') ?? '';
    }
    return '';
  }

  getPortalID(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('PORTAL_ID') ?? '';
    }
    return '';
  }

  getReporterTypeID(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('RT_ID') ?? '';
    }
    return '';
  }

  isPortalDataAvailable(): boolean {
    return !!localStorage.getItem('cp');
  }

  isProfileSelected(): boolean {
    //if (isPlatformBrowser(this.platformId)) {
    return !!localStorage.getItem('IS_HCP');
    //}
  }

  getSelectedProfile(): PortalProfiles {
    let selectedProfile: PortalProfiles = {};
    if (isPlatformBrowser(this.platformId)) {
      let objProfInfo = localStorage.getItem('profInfo');
      if (objProfInfo) selectedProfile = JSON.parse(objProfInfo);
    }
    return selectedProfile;
  }

  resetProfileSelection() {
    this.isProfileSelection = true;
  }

  getReporterTypeName(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('RT_NAME') ?? 'Healthcare Professional';
    }
    return "Healthcare Professional";
  }

  getNonHCPReporterTypeName(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('NON_RT_NAME') ?? 'Patient / Caregiver';
    }
    return 'Patient / Caregiver';
  }

  getPortalName(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('PORTAL_NAME') ?? '';
    }
    return "";
  }

  getProductsMappingType(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('PRODUCTS_MAPPING_TYPE') ?? '';
    }
    return '';
  }

  IsSessionExpired(): boolean {
    let xx = localStorage.getItem('idle-timeout');
    console.log(xx);
    return !!localStorage.getItem('idle-timeout');
  }

  setSessionExpired() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("idle-timeout", "true");
    }
  }

  resetSessionExpired() {
    if (isPlatformBrowser(this.platformId)) { return localStorage.removeItem('idle-timeout'); }

  }


  IsSuperAdmin(): boolean {
    if (this.router.routerState.snapshot.url == '' || this.router.routerState.snapshot.url == 'home') {
      return false;
    }
    let appUserInfo: AppUserInfo = this.getAppUserInfo();
    return appUserInfo?.IsSuperAdmin ?? false;
  }

  getAppUserInfo(): AppUserInfo {
    let useinfo: AppUserInfo = {};
    if (isPlatformBrowser(this.platformId)) {
      let objUserInfo = localStorage.getItem('cpappuserinfo');
      if (objUserInfo) useinfo = JSON.parse(objUserInfo);
    }
    return useinfo;
  }

  setEnv() {
    AppConstants.ReporterTypeId = this.getReporterTypeID();
    AppConstants.PkUserId = this.getAppUserInfo().PK_UserId ?? '';
    // this.isProfileSelection = this.isProfileSelected();
    this.reporterTypeName = AppConstants.ReporterTypeName = this.getReporterTypeName();
    this.nonHcpReporterTypeName = this.getNonHCPReporterTypeName();
  }

  editProfile() {
    this.isProfileSelection = true;
    this.isNonHCP = false;
  }

  actionOnSelfQualification(isHCP: boolean) {
    this.isProfileSelection = !isHCP;
    let hcp: string = isHCP ? 'HCP' : 'NON_HCP';
    localStorage.setItem('IS_HCP', hcp);
  }

  navigateTo($navigation: AppNavigation) {
    if ($navigation && $navigation.IsNavigateToPreviousRoute) {
      this.router.navigate([this.previousUrl]);
    }
    else {
      this.router.navigate(["/" + $navigation.NavigateURL]);
    }
  }

  downloadBlob(data: HttpEvent<Blob>, fileName: string) {
    switch (data.type) {
      case HttpEventType.Response:
        const downloadedFile = new Blob([data.body as BlobPart], { type: data.body?.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = fileName;
        a.href = URL.createObjectURL(downloadedFile);
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
        break;
    }
  }

  previewURL(id: string, storeType: FileStoreType): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(AppConstants.PortalApiBaseUrl + '/api/File/Preview?type=' + storeType + '&id=' + id);
  }

  getURL(id: string, storeType: FileStoreType): string {
    return AppConstants.PortalApiBaseUrl + '/api/File/Preview?type=' + storeType + '&id=' + id;
  }

  downloadURL(id: string, storeType: FileStoreType): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(AppConstants.PortalApiBaseUrl + '/api/File/Download?type=' + storeType + '&id=' + id);
  }





  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }

  validateEmail(email: string): boolean {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }

  replaceEmojis(value: string) {
    if (value != undefined && value != null && value != "")
      //return value.replace(/(?:[\u20E3\u00A9\u00AE\u203C\u2049\u2122\u2139\u2194-\u2199\u21A9-\u21AA\u231A-\u231B\u2328\u23CF\u23E9-\u23F3\u23F8-\u23FA\u24C2\u25AA-\u25AB\u25B6\u25C0\u25FB-\u25FE\u2600-\u2604\u260E\u2611\u2614-\u2615\u2618\u261D\u2620\u2622-\u2623\u2626\u262A\u262E-\u262F\u2638-\u263A\u2640\u2642\u2648-\u2653\u2660\u2663\u2665-\u2666\u2668\u267B\u267F\u2692-\u2697\u2699\u269B-\u269C\u26A0-\u26A1\u26AA-\u26AB\u26B0-\u26B1\u26BD-\u26BE\u26C4-\u26C5\u26C8\u26CE-\u26CF\u26D1\u26D3-\u26D4\u26E9-\u26EA\u26F0-\u26F5\u26F7-\u26FA\u26FD\u2702\u2705\u2708-\u270D\u270F\u2712\u2714\u2716\u271D\u2721\u2728\u2733-\u2734\u2744\u2747\u274C\u274E\u2753-\u2755\u2757\u2763-\u2764\u2795-\u2797\u27A1\u27B0\u27BF\u2934-\u2935\u2B05-\u2B07\u2B1B-\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299]|(?:\uD83C[\uDC04\uDCCF\uDD70-\uDD71\uDD7E-\uDD7F\uDD8E\uDD91-\uDD9A\uDDE6-\uDDFF\uDE01-\uDE02\uDE1A\uDE2F\uDE32-\uDE3A\uDE50-\uDE51\uDF00-\uDF21\uDF24-\uDF93\uDF96-\uDF97\uDF99-\uDF9B\uDF9E-\uDFF0\uDFF3-\uDFF5\uDFF7-\uDFFF]|\uD83D[\uDC00-\uDCFD\uDCFF-\uDD3D\uDD49-\uDD4E\uDD50-\uDD67\uDD6F-\uDD70\uDD73-\uDD7A\uDD87\uDD8A-\uDD8D\uDD90\uDD95-\uDD96\uDDA4-\uDDA5\uDDA8\uDDB1-\uDDB2\uDDBC\uDDC2-\uDDC4\uDDD1-\uDDD3\uDDDC-\uDDDE\uDDE1\uDDE3\uDDE8\uDDEF\uDDF3\uDDFA-\uDE4F\uDE80-\uDEC5\uDECB-\uDED2\uDEE0-\uDEE5\uDEE9\uDEEB-\uDEEC\uDEF0\uDEF3-\uDEF6]|\uD83E[\uDD10-\uDD1E\uDD20-\uDD27\uDD30\uDD33-\uDD3A\uDD3C-\uDD3E\uDD40-\uDD45\uDD47-\uDD4B\uDD50-\uDD5E\uDD80-\uDD91\uDDC0]))/g, '');
      //return value.replace(/(?:[\u2700\-\u27bf]|(?:\ud83c[\udde6\-\uddff]){2}|[\ud800\-\udbff][\udc00\-\udfff]|[\u0023\-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70\-\udd71]|\ud83c[\udd7e\-\udd7f]|\ud83c\udd8e|\ud83c[\udd91\-\udd9a]|\ud83c[\udde6\-\uddff]|\ud83c[\ude01\-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32\-\ude3a]|\ud83c[\ude50\-\ude51]|\u203c|\u2049|[\u25aa\-\u25ab]|\u25b6|\u25c0|[\u25fb\-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600\-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9\-\u23f3]|[\u23f8\-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190\-\u21ff]|[\uE000\-\uF8FF]|\uD83C[\uDF00\-\uDFFF]|\uD83D[\uDC00\-\uDDFF])/g, '').replace(/[^A-Za-z0-9\w\.,\?""!@#\$%\^&\*\(\)\-_=\+;:<>\/\\\|\}\{\[\]`~\s\']*/g, '');
      return value.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '');
    else
      return value;
  }

  getPasswordPolicyPattern(passwordPolicy: PASSWORD_POLICY): string {
    // `(?=([^a-z]*[a-z])\{${passwordPolicy.MIN_UPPERCASE_CHARS},\})`,

    let pattern = [
      `(?=([^A-Z]*[A-Z])\{${passwordPolicy.MIN_UPPERCASE_CHARS},\})`,
      `(?=([^a-z]*[a-z])\{${passwordPolicy.MIN_LOWER_CHARS},\})`,
      `(?=([^0-9]*[0-9])\{${passwordPolicy.MIN_NUMERIC_CHARS},\})`,
      // `(?=(\.\*[\$\@\$\!\%\*\?\&\#])\{${passwordPolicy.MIN_SPECAIL_CHARS},\})`,
      // `[A-Za-z\\d\$\@\$\!\%\*\?\&\.\#]{${passwordPolicy.MIN_PASSWORD_LENGTH},}`,
      `(?=(\.\*[\$\@\#\!\%\*\%\^\)\(\~\/\_\\-\{\}\+\\]\[\&])\{${passwordPolicy.MIN_SPECAIL_CHARS},\})`,
      `[A-Za-z\\d\$\@\!\%\*\?\%\^\)\(\#\&\~\/\_\\-\{\}\+\\]\[\.]{${passwordPolicy.MIN_PASSWORD_LENGTH},}`,
    ]
      .map((item) => item.toString())
      .join('');
    return pattern;
  }

  resetCalendar(date: Date, fcn: string, fg: FormGroup) {
    if (date && (date.toString() == "Invalid Date" || date.toString() == "Invalid date")) {
      fg.controls[fcn].reset();
    }
  }

  OnkeyupCalendar(event: KeyboardEvent, fcn: string, fg: FormGroup, controlid: string, mmtype: string, dateval?: Date | undefined) {
    var value = $("#" + controlid).val();
    var m = moment(value, 'DD-MMM-YYYY', true).isValid();
    var isvalid = true;
    var isfuture = false;
    var ispast = false;
    if (value) {
      var valArr = value.split("-");
      if (valArr.length == 2) {
        var date = moment(valArr[0], 'DD', true).isValid();
        if (!date) {
          isvalid = false;
        }
      }
      if (valArr.length == 3) {
        var month = moment(valArr[1], 'MMM', true).isValid();
        if (!month) {
          isvalid = false;
        }
      }
      else {
        if (value.length == 3 && value.charAt(2) != "-") {
          isvalid = false;
        }
        if (value.length == 7 && value.charAt(6) != "-") {
          isvalid = false;
        }
      }
      if (value.length >= 11 && !m) {
        isvalid = false;
      }
      else if (value.length >= 11 && m) {
        if (mmtype) {
          const val = moment(value).format('DD-MMM-YYYY');
          const dt = moment(dateval).format('DD-MMM-YYYY');
          var _val = new Date(val);
          var _dt = new Date(dt);
          if (mmtype == "L") {
            if (_val < _dt) {
              fg.controls[fcn]?.reset(null);
              fg.controls[fcn]?.setValue(dt);
              ispast = true;
            }
          }
          else if (mmtype == "G") {
            if (_val > _dt) {
              fg.controls[fcn]?.reset(null);
              fg.controls[fcn]?.setValue(dt);
              isfuture = true;
            }
          }
        }
      }
    }
    else {
      fg.controls[fcn]?.reset(null);
    }
    if (!isvalid) {
      fg.controls[fcn]?.reset("Invalid date");
    }
  }


  getAppUserInfoObject(result: UserLoginResult) {
    let appUserInfo: AppUserInfo = {};
    appUserInfo.EmailID = result.EmailID;
    appUserInfo.PK_UserId = result.PK_UserId;
    appUserInfo.UserID = result.UserID;
    appUserInfo.UserFullName = result.UserFullName;
    appUserInfo.IsSSPAdmin = result.IsSSPAdmin;
    appUserInfo.IsSuperAdmin = result.IsSuperAdmin;
    appUserInfo.IsSubscribedToNews = result.IsSubscribedToNews;
    appUserInfo.IsSubscribedToEvents = result.IsSubscribedToEvents;
    appUserInfo.FIRST_NAME = result.FIRST_NAME;
    appUserInfo.LAST_NAME = result.LAST_NAME;
    appUserInfo.DATE_OF_BIRTH = result.DATE_OF_BIRTH;
    appUserInfo.FK_COUNTRY_ID = result.FK_COUNTRY_ID;
    appUserInfo.ZIP_CODE = result.ZIP_CODE;
    appUserInfo.STATE_PROVINCE = result.STATE_PROVINCE;
    appUserInfo.CITY = result.CITY;
    appUserInfo.ADDRESS = result.ADDRESS;
    appUserInfo.FK_PREFERRED_CONTACT_ID = result.FK_PREFERRED_CONTACT_ID;
    appUserInfo.PREFERED_EMAIL = result.PREFERED_EMAIL;
    appUserInfo.PREFERED_PHONE = result.PREFERED_PHONE;
    appUserInfo.PREFERED_PHONE_EXT = result.PREFERED_PHONE_EXT;
    appUserInfo.PHONE = result.PHONE;
    appUserInfo.PHONE_EXT = result.PHONE_EXT;
    appUserInfo.HAS_RECEIVE_EMAILS = result.HAS_RECEIVE_EMAILS;
    appUserInfo.HAS_ALLOW_CALLS = result.HAS_ALLOW_CALLS;
    appUserInfo.FK_PROFESSION_ID = result.FK_PROFESSION_ID;
    appUserInfo.FK_SPECAILITY_ID = result.FK_SPECAILITY_ID;

    appUserInfo.COUNTRY_NAME = result.COUNTRY_NAME;
    appUserInfo.PREFERRED_CONTACT_NAME = result.PREFERRED_CONTACT_NAME;
    appUserInfo.PROFESSION_NAME = result.PROFESSION_NAME;
    appUserInfo.SPECAILITY_NAME = result.SPECAILITY_NAME;

    appUserInfo.LICENCE_NUMBER = result.LICENCE_NUMBER;
    appUserInfo.PRIMARY_PRACTICE = result.PRIMARY_PRACTICE;

    appUserInfo.ACCESS_TYPE_CODE = result.ACCESS_TYPE_CODE;
    appUserInfo.FK_ENV_PROF_ID = result.FK_ENV_PROF_ID;
    appUserInfo.ENV_PROFILE = result.ENV_PROFILE;
    appUserInfo.PROF_TYPE_CODE = result.PROF_TYPE_CODE;
    appUserInfo.IS_LOGIN_OPTIONAL = result.IS_LOGIN_OPTIONAL;

    appUserInfo.PRIMARY_DEGREE_NAME = result.PRIMARY_DEGREE_NAME;
    appUserInfo.FK_PRIMARY_DEGREE_ID = result.FK_PRIMARY_DEGREE_ID;

    return appUserInfo;
  }


  ValidateDate(ControlValue: any, Fcn: string, MinMaxFlag?: string, MinMaxDateVal?: Date, formGroup?: FormGroup) {
    var value = ControlValue;
    var m = moment(value, 'DD-MMM-YYYY', true).isValid();
    var isvalid = true;
    if (value.length >= 11 && m) {
      const val = moment(value).format('DD-MMM-YYYY');
      const dt = moment(MinMaxDateVal).format('DD-MMM-YYYY');
      var _value = new Date(val);
      var _mindate = new Date(dt);
      if (MinMaxFlag == "G") {
        if (_value > _mindate) {
          formGroup?.get(Fcn)?.reset(null);
        }
      }
      else if (MinMaxFlag == "L") {
        if (_value < _mindate) {
          formGroup?.get(Fcn)?.reset(null);
        }
      }

    }
  }

  getCurrentPageTags(Code: string) {
    let features = this.pageFeaturesList;
    this.PageFeatures = features.filter((x: any) => { return x.FeatureCode == Code })[0];
    return this.PageFeatures;
  }


  setFeaturesList(list: any) {
    this._featureslistSubject.next(list);
  }

  public get pageFeaturesList(): any {
    return this._featureslistSubject.value;
  }


  GetFeaturesSummary(SearchFeature: any): Observable<any> {
    SearchFeature.EnvironmentId = AppConstants.EnvironmentId ?? '';
    SearchFeature.IsFromPortal = true;
    return this.http.post(AppConstants.PortalApiBaseUrl + '/api/GoogleSearchConsole/GetFeaturesSummary', SearchFeature);
  }

}


