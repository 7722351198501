<div class="container-fluid">
    <div class="verification-content">
        <div class="row">
            <div class="col-md-12" *ngIf="unsubscribeSuccess != null && unsubscribeSuccess">
                <a routerLink="/" class="text-center d-block pt-2"><img src="./assets/images/logo.png" alt="">
                </a>
                <p class="fs-14 text-center pt-3"> <i class="fas fa-check-circle"></i>Your request for un-subscription is initiated, Thank you</p>
                <div class="text-center pt-4"></div>
            </div>
            <div class="col-md-12" *ngIf="inValidLink">
                <a routerLink="/" class="text-center d-block pt-2"><img src="./assets/images/logo.png" alt="">
                </a>
                <p class="fs-14 text-center pt-3"><i class="far fa-times-circle"></i> Invalid unsubscribe link. Please
                    use a valid unsubscribe link</p>
            </div>
        </div>
    </div>
</div>