<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block" id="exampleModalLabel">Zipcode Selection
      <a (click)="this.close()" class="fs-12 text-underline float-right pt-1 text-white" data-dismiss="modal"><i
          class="fas fa-times"></i></a>
    </h5>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <form [formGroup]="zipCodeForm">
        <table class="table fs-14">
          <thead>
            <tr>
              <td class="p-2" style="border:0px"></td>
              <td class="p-2" style="border:0px">Zip Code</td>
              <td class="p-2" style="border:0px">City</td>
              <td class="p-2" style="border:0px">State</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor=" let zipcode of zipCodes;">
              <td class="p-2" style="border:0px">
                <input class="form-check-input" type="radio" name="ZIP_CODE" [value]="zipcode.ID"
                  formControlName="ZIP_CODE" autocomplete="off">
              </td>
              <td class="p-2" style="border:0px">{{zipcode.ZIPCODE}}</td>
              <td class="p-2" style="border:0px">{{zipcode.CITY}}</td>
              <td class="p-2" style="border:0px">{{zipcode.CITYSTATE}}</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="(form.ZIP_CODE.dirty || form.ZIP_CODE.touched || submitted) && form.ZIP_CODE.invalid" class="invalid-form-control text-danger fs-12">
          <div *ngIf="form.ZIP_CODE.errors?.['required']">
            Please select atleast one.
          </div>
        </div>
        <button type="submit" class="btn btn-default px-3" (click)="submitZipcode()">Ok</button>&nbsp;
        <button type="submit" class="btn btn-default px-3" (click)="close()">Cancel</button>
      </form>
    </div>
  </div>
</div>
