import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppConstants } from '../../shared/AppConstants';
import { SeoService } from '../_resolver/seo.servece';
import { AuthenticationService } from './authentication.service';
import { HomeService } from './home.service';
import { UtilityService } from './utility.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

//https://stackoverflow.com/questions/42110817/load-config-json-file-in-angular-2
//
//

@Injectable({
  providedIn: 'root'
})
export class AppPreSetupService {

  private _config: any;
  private _appSettings: string = '';
  platformId: any;

  appData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  seoTagsData$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.getTagsInfoFromCookie()
  );
  constructor(private _http: HttpClient, private authService: AuthenticationService, private seoService: SeoService, private ssrCookieService: SsrCookieService,
    public utilityService: UtilityService, private homeService: HomeService, @Inject(PLATFORM_ID) platformId: any) { this.platformId = platformId; }

  load() {
    if (isPlatformBrowser(this.platformId)) {
      return new Promise((resolve, reject) => {
        this._appSettings = 'appSettings';
        if (environment.production)
          this._appSettings = 'appSettings';
        this._http.get('./assets/' + this._appSettings + '.json').subscribe(
          (data: any) => {
            this._config = data;
            AppConstants.PortalApiBaseUrl = data.PortalApiBaseUrl;
            AppConstants.EnvironmentId = data.EnvironmentId;
            AppConstants.ReportAttachmentMaxFileSize = data.ReportAttachmentMaxFileSize;
            AppConstants.LocalStorageTimeOutInSeconds = data.LocalStorageTimeOutInSeconds;
            AppConstants.PortalSuffix = data.PortalSuffix;
            //AppConstants.SessionIdleTimeout = data.SessionIdleTimeout;
            //AppConstants.AutoLogoutTimeout = data.AutoLogoutTimeout;
            //AppConstants.SessionInactiveTime = data.SessionInactiveTime;
            AppConstants.ResetPasswordIdleTimeout = data.ResetPasswordIdleTimeout;

            //AppConstants.EncryptSecretKey = data.EncryptSecretKey;

            this.authService.checkIdleTime(true);
            this.homeService.getPortal().subscribe((data) => {
              this.authService.portal = data;

              this.appData$.next(data);

              if (data && data.features) {
                this.ssrCookieService.set('tagsInfo', JSON.stringify(data.features));
              }
              else {
                this.ssrCookieService.delete('tagsInfo');
              }
              this.seoTagsData$.next(data.features);




              AppConstants.SessionIdleTimeout = data.SAUserSessionTime ?? 60;
              AppConstants.AutoLogoutTimeout = data.LoginUserSessionTime ?? 60;

              AppConstants.PortalProfiles = data.PortalProfiles;

              this.utilityService.portalTitle = data.PortalTitle;
              this.utilityService.setEnvironmentStorage(data);

              if (data.features) {
                this.utilityService.setFeaturesList(data.features);
              }

              //this.PortalUserSessionTimeOut();
              //this.GetFeaturesSummary();


              if (this.authService.portal.PortalAccessType != "RL") {
                //this.homeService.getUserProfiles().subscribe((next) => {
                //  if (next) {
                //    this.homeService.userProfiles = next;
                //    resolve(true);
                //  }
                //  },
                //  err => {
                //    console.log(err);
                //    resolve(false);
                //})
                resolve(true);
              } else {
                resolve(true);
              }
            },
              (error) => {
                console.error(error);
                resolve(false);
              });

            //this.GetFeaturesSummary();
            //this.PortalUserSessionTimeOut();
          },
          (error: any) => {
            console.error(error);
            resolve(false);
            //return Observable.throw(error.json().error || 'Server error');
            return throwError(() => new Error(error.json().error || 'Server error'));
          });


      });
    }
    else {
      return;
    }
  }


  getTagsInfoFromCookie() {
    const tagdata = this.ssrCookieService.get('tagsInfo');
    if (tagdata) {
      return JSON.parse(tagdata);
    }
    return null;
  }


  PortalUserSessionTimeOut() {
    this.homeService.GetPortalIdleSessionTimes().subscribe({
      next: (data) => {
        if (data) {
          AppConstants.SessionIdleTimeout = data.SAUserSessionTime
          AppConstants.AutoLogoutTimeout = data.LoginUserSessionTime
        }

      },

    });
  }

  GetFeaturesSummary() {
    let input = { Feature: "", TagType: "", Tags: "", Status: null };
    this.utilityService.GetFeaturesSummary(input).subscribe((list: any) => {
      this.utilityService.setFeaturesList(list);
      //this.homeService.pageFeaturesList;
    });
  }

  // Is app in the development mode?
  isDevmode() {
    return this._appSettings === 'development';
  }

  // Gets API route based on the provided key
  getApi(key: string): string {
    return this._config["PortalApiBaseUrl"][key];
  }

  // Gets a value of specified property in the configuration file
  get(key: any) {
    return this._config[key];
  }

}

export function AppPreSetupFactory(config: AppPreSetupService) {
  return () => config.load();
}

export function init() {
  return {
    provide: APP_INITIALIZER,
    useFactory: AppPreSetupFactory,
    deps: [AppPreSetupService],
    multi: true
  }
}

const AppPreSetupModule = {
  init: init
}

export { AppPreSetupModule };
