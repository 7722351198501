import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppHeaderComponent } from './app-header/app-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PreviewComponent } from './preview/preview.component';
import { PromotionalCardsComponent } from '../components/Common/promotional-cards/promotional-cards.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ProductControlComponent } from '../components/product-control/product-control.component';
import { CountryControlComponent } from '../components/country-control/country-control.component';
import { ZipcodeSelectionComponent } from '../components/Common/zipcode-selection/zipcode-selection.component';
import { NumbersOnlyDirective } from '../directives/numbers-only.directive';
import { PhoneNumberDirective } from '../directives/phone-number.directive';
import { NoSpecailCharsDirective } from '../directives/no-special-chars.directive';
import { NoSpacesDirective } from '../directives/no-spaces.directive';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoNumbersDirective } from '../directives/no-numbers.directive';
import { PWDSpecailCharsDirective } from '../directives/pwd-special-chars.directive';
import { CalendarModule } from 'primeng/calendar';
import { UseUtcDirective } from '../directives/useUtc.directive';

@NgModule({
  declarations: [
    AppHeaderComponent,
    PreviewComponent,
    NumbersOnlyDirective,
    NoNumbersDirective,
    PhoneNumberDirective,
    NoSpecailCharsDirective,
    NoSpacesDirective,
    ProductControlComponent,
    CountryControlComponent,
    ZipcodeSelectionComponent,
    PreviewComponent,
    PromotionalCardsComponent,
    UseUtcDirective,
    PWDSpecailCharsDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CalendarModule,
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
  ],
  exports: [
    AppHeaderComponent,
    PreviewComponent,
    NumbersOnlyDirective,
    NoNumbersDirective,
    PhoneNumberDirective,
    NoSpecailCharsDirective,
    NoSpacesDirective,
    PromotionalCardsComponent,
    ProductControlComponent,
    CountryControlComponent,
    ZipcodeSelectionComponent,
    AccordionModule,
    CalendarModule,
    UseUtcDirective,
    PWDSpecailCharsDirective
    ]
})
export class SharedModule { }
