import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SubscribeComponent } from 'src/app/events-conferences/subscribe/subscribe.component';
import { EventsConfsResponse } from 'src/app/_models/eventsConference';
import { AppUserInfo } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { EventsConferencesService } from '../../../_services/events-conferences.service';
import { HomeService } from '../../../_services/home.service';

@Component({
  selector: 'app-promotional-cards',
  templateUrl: './promotional-cards.component.html',
  styleUrls: ['./promotional-cards.component.css']
})
export class PromotionalCardsComponent implements OnInit {
  @Input() isMSLPage = false;
  @Input() isEventsPage = false;

  isLoggedInUser: boolean
  user_id: string | undefined;
  subscribed: boolean | null = null;
  featuresList: any;
  ecInterval: any;
  platformId: any;
  constructor(public modalRef: BsModalRef,
    private modalService: BsModalService,
    private authService: AuthenticationService,
    private readonly eventConfService: EventsConferencesService,
    private homeService: HomeService, @Inject(PLATFORM_ID) platformId: any) {
    this.platformId = platformId;
    this.authService.hcpDetails.subscribe(next => {
      if (this.authService.isUserLoggedIn()) {
        this.isLoggedInUser = true;
        this.user_id = next.PK_UserId?.toString();
        this.subscribed = next.IsSubscribedToEvents ?? false;
      }
    });
  }

  ngOnInit(): void {
    this.isLoggedInUser = this.authService.isUserLoggedIn();
    if (this.isLoggedInUser) {
      this.user_id = this.authService.getLoginHCPDetails().PK_UserId?.toString();
      this.subscribed = this.authService.getLoginHCPDetails().IsSubscribedToEvents ?? false;
    }
    else {
      this.subscribed = false;
    }

    this.homeService.featureProfileObserver.subscribe((response) => {
      this.featuresList = response;
      this.isMSLPage = false;
      this.isEventsPage = false;
      if (this.featuresList && this.featuresList.length > 0) {
        for (let index = 0; index < this.featuresList.length; index++) {
          const component = this.featuresList[index];
          if (component) {
            if (component.Code == "F_MSL") {
              this.isMSLPage = true;
            }
            if (component.Code == "EV_CF") {
              this.isEventsPage = true;
            }
          }
        }
      }
    });

    this.ecInterval = setInterval(() => {
      if (isPlatformBrowser(this.platformId)) {
        let token = localStorage.getItem('access_token');
        if (token && token != '') {
          if (this.isEventsPage) {
            this.GetEventsAndConferencesCurrentStatus();
          }
        }
      }
    }, 100);
  }

  GetEventsAndConferencesCurrentStatus() {
    clearInterval(this.ecInterval);
    this.eventConfService.getEventsAndConfSubscriptionStatus(this.user_id).subscribe({
      next:(res) => {
      if (res !== null && res !== undefined) {
        if (res.HasSubscribed) {
          this.subscribed = res.HasSubscribed;
        }
        else {
          this.subscribed = false;
        }
        if (isPlatformBrowser(this.platformId)) {
        let objUserInfo = localStorage.getItem('cpappuserinfo');
        if (objUserInfo) {
          let appUserInfo: AppUserInfo = JSON.parse(objUserInfo);
          appUserInfo.IsSubscribedToEvents = this.subscribed;
          localStorage.removeItem('cpappuserinfo');
          localStorage.setItem('cpappuserinfo', JSON.stringify(appUserInfo));
}
        }
      }
    },
      error:(err) => {
        console.log(err);
      }});
  }

  openSubscribeModal() {
    let options: any = {
      PK_UserID: this.user_id,
      Referrer: "EVENTS"
    };
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false,
      initialState: <any>options,
    };
    this.modalRef = this.modalService.show(SubscribeComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.onClose.subscribe((result: any) => {
      if (result) {
        console.log(result);
      }
    });
    this.modalRef.content.onSubscription.subscribe((response: EventsConfsResponse) => {
      if (response != null && response.Status) {
        this.subscribed = true;
        if (this.isLoggedInUser) {
        if (isPlatformBrowser(this.platformId)) {
          let objUserInfo = localStorage.getItem('cpappuserinfo');
          if (objUserInfo) {
            let appUserInfo: AppUserInfo = JSON.parse(objUserInfo);
            appUserInfo.IsSubscribedToEvents = true;
            localStorage.removeItem('cpappuserinfo');
            localStorage.setItem('cpappuserinfo', JSON.stringify(appUserInfo));
}
          }
        }
      }
      else {
        if (response.Errors != null && response.Errors.length > 0) {
          response.Errors.forEach(error => {
            switch (error) {
              case "ALREADY_SUBSCRIBED":
                this.subscribed = true;
                break;
            }
          });
        }
        else {
          this.subscribed = false;
        }
      }
    });
  }

}
