import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HomeService } from '../_services/home.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileFeatureGuard implements CanActivate {
  platformId: any;
  constructor(private homeService: HomeService,
    private router: Router, @Inject(PLATFORM_ID) platformId: any) { this.platformId = platformId; }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = route.data.featurecode;
    let result: boolean = false;
    let ecInterval: any;
    let featuresList = this.homeService.selectedProfileFeaturesList();

    if (featuresList.length != 0) {
      if (isPlatformBrowser(this.platformId)) {
        clearInterval(ecInterval);
      }
    }

    if (featuresList.length == 0 && role != 'Error') {
      if (isPlatformBrowser(this.platformId)) {
        ecInterval = setInterval(() => {
          featuresList = this.homeService.selectedProfileFeaturesList();
          if (featuresList.length != 0) {
            clearInterval(ecInterval);
            this.navigateToPath(role, featuresList);
          }
        }, 200);
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      let userProfile = localStorage.getItem('profInfo');
      if (role == 'Error' && userProfile) return true;
      if (featuresList.length == 0) return false;

      if (role == 'HOME') {
        let isHomeExist = featuresList.findIndex((profile: any) => profile.Code == 'SR_MI' || profile.Code == 'RQ_AE');
        if (isHomeExist != -1) {
          return true;
        }
        else {
          return false;
        }
      }

      if (featuresList && featuresList.length > 0) {
        for (const element of featuresList) {
          const component = element;
          if (component.Code == role) {
            result = true;
            break;
          }
        }
      }
      if (result) {
        return true;
      } else {
        this.homeService.setFeatures(true);
        //let navigateURL: string = this.homeService._setFeatures();
        //this.router.navigate([navigateURL]);
      }
    }

    return false;
  }

  navigateToPath(role: string, featuresList: Array<any>) {
    if (role == 'HOME') {
      let isHomeExist = featuresList.findIndex((profile: any) => profile.Code == 'SR_MI' || profile.Code == 'RQ_AE');
      if (isHomeExist != -1) {
        this.router.navigate(['/home']);
        return;
      }
    }

    let isRoleExists = featuresList.findIndex((profile: any) => profile.Code == role);
    if (isRoleExists != -1) {
      let url = this.homeService.navigateTo(role);
      this.router.navigate([url]);
    } else {
      this.homeService.setFeatures(true);
    }
    //else {
    //  for (const element of featuresList) {
    //    const component = element;
    //    if (component.Code == role) {
    //      let url = this.homeService.navigateTo(component.Code);
    //      this.router.navigate([url]);
    //      break;
    //    }
    //  }
    //}
  }

}

