<div #modalcontent class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block">
      {{this.IsView ? 'View' : 'Edit'}} Adverse Event Request - {{this.Data.Request.REQUEST_ID}}
      <a href="javascript:void(0);" (click)="this.close()" class="fs-12 float-right pt-1 text-white">
        <i class="fas fa-times"></i>
      </a>
    </h5>
  </div>
  <div class="modal-body">
    <div class="modalbody-ht">
      <div class="container-fluid">
        <form [formGroup]="SubmitRequestForm">
          <div class="row">
            <div class="col-md-12 text-right">
              <label class="fieldLabel mandatory fs-12 text-secondary">Required Fields</label>
            </div>
            <div class="col-md-12">
              <div class="title-md">HCP Details</div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel">Primary Degree</label>
                    <select [ngClass]="{'is-invalid': (form.FK_PRIMARY_DEGREE_ID.dirty || form.FK_PRIMARY_DEGREE_ID.touched || HCP_Next) && form.FK_PRIMARY_DEGREE_ID.errors }"
                            class="form-control"
                            formControlName="FK_PRIMARY_DEGREE_ID">
                      <option value="">-Select-</option>
                      <option *ngFor="let pd of primaryDegreeList" value="{{pd.PK_LOOKUP_ID}}">{{pd.NAME}}</option>
                    </select>
                    <span class="fltlabel-highlight"></span>
                    <div *ngIf="(form.FK_PRIMARY_DEGREE_ID.dirty || form.FK_PRIMARY_DEGREE_ID.touched || HCP_Next) && form.FK_PRIMARY_DEGREE_ID.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.FK_PRIMARY_DEGREE_ID.errors?.['required']">
                        Primary Degree is required.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel mandatory">Profession</label>
                    <select [ngClass]="{'is-invalid': (form.FK_PROFESSION_ID.dirty || form.FK_PROFESSION_ID.touched || submitted) && form.FK_PROFESSION_ID.errors }" class="form-control"
                            formControlName="FK_PROFESSION_ID">
                      <option value="">-Select-</option>
                      <option *ngFor="let occ of occupationList" value="{{occ.PK_LOOKUP_ID}}">{{occ.NAME}}</option>
                    </select>
                    <span class="fltlabel-highlight"></span>
                    <div *ngIf="(form.FK_PROFESSION_ID.dirty || form.FK_PROFESSION_ID.touched || submitted) && form.FK_PROFESSION_ID.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.FK_PROFESSION_ID.errors?.['required']">
                        Profession is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel">Specialty</label>
                    <select [ngClass]="{'is-invalid': (form.FK_SPECIALTY_ID.dirty || form.FK_SPECIALTY_ID.touched || HCP_Next) && form.FK_SPECIALTY_ID.errors }"
                            class="form-control"
                            formControlName="FK_SPECIALTY_ID">
                      <option value="">-Select-</option>
                      <option *ngFor="let spec of specialityList" value="{{spec.PK_LOOKUP_ID}}">{{spec.NAME}}</option>
                    </select>
                    <span class="fltlabel-highlight"></span>
                    <div *ngIf="(form.FK_SPECIALTY_ID.dirty || form.FK_SPECIALTY_ID.touched || HCP_Next) && form.FK_SPECIALTY_ID.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.FK_SPECIALTY_ID.errors?.['required']">
                        Specialty is required.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">First Name</label>
                <input type="text" id="Fname" class="form-control" formControlName="FIRST_NAME"
                       (blur)="handleInput($event.target,'FIRST_NAME');" autocomplete="off" noSpecailChars maxlength="35" />
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Last Name</label>
                <input type="text" id="Lname" class="form-control" formControlName="LAST_NAME"
                       (blur)="handleInput($event.target,'LAST_NAME');" autocomplete="off" noSpecailChars maxlength="50"
                       [ngClass]="{ 'is-invalid': (form.LAST_NAME.dirty || form.LAST_NAME.touched || submitted) && form.LAST_NAME.errors }" />
                <div *ngIf="(form.LAST_NAME.dirty || form.LAST_NAME.touched || submitted) && form.LAST_NAME.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.LAST_NAME.errors?.['required']">
                    Last Name is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Country</label>
                <app-country-control formControlName="FK_COUNTRY_ID" [inputAllCountries]="allCountries"
                                     [inputSelectedCountry]="selectedCountry"></app-country-control>
                <span class="fltlabel-highlight"></span>
                <div *ngIf="(form.FK_COUNTRY_ID.dirty || form.FK_COUNTRY_ID.touched || submitted) && form.FK_COUNTRY_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FK_COUNTRY_ID.errors?.['required']">
                    Country is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">Zip Code</label>
                <input type="text" id="z-code" class="form-control" autocomplete="off" formControlName="POSTAL_CODE" (blur)="handleInput($event.target,'POSTAL_CODE');"
                       autocomplete="off" maxlength="15" noSpecailChars noSpaces />
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">State</label>
                <input type="text" class="form-control" formControlName="STATE"
                       (blur)="handleInput($event.target,'STATE');" autocomplete="off" maxlength="50" />
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">City</label>
                <input type="text" class="form-control" formControlName="CITY"
                       (blur)="handleInput($event.target,'CITY');" autocomplete="off" maxlength="50" />
              </div>
            </div>

            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Contact Preference</label>
                <select class="form-control" (ngModelChange)='CP_Change($event)' id="smi-contact-pref" formControlName="FK_CONTACT_PREFERENCE_ID">
                  <option value="">-Select-</option>
                  <option *ngFor="let prd of ContactsList" value="{{prd.Id}}">{{prd.ContactName}}</option>
                </select>
                <div *ngIf="(form.FK_CONTACT_PREFERENCE_ID.dirty || form.FK_CONTACT_PREFERENCE_ID.touched || submitted) && form.FK_CONTACT_PREFERENCE_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FK_CONTACT_PREFERENCE_ID.errors?.['required']">
                    Contact Preference is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="row">
                <div class="col-lg-9 col-md-8 col-sm-8">
                  <div class="form-group">
                    <label class="fieldLabel" [ngClass]="{ 'mandatory' : this.isPhoneMandatory }">Phone Number</label>
                    <input type="text" class="form-control" formControlName="PHONE" (blur)="handleInput($event.target,'PHONE');"
                           autocomplete="off" maxlength="25" phoneNumber />
                    <div *ngIf="(form.PHONE.dirty || form.PHONE.touched || submitted) && form.PHONE.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.PHONE.errors?.['required']">
                        Phone Number is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-4">
                  <div class="form-group">
                    <label class="fieldLabel">Ext</label>
                    <input type="text" class="form-control" formControlName="PHONE_EXT" phoneNumber
                           (blur)="handleInput($event.target,'PHONE_EXT');" autocomplete="off" maxlength="10" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel" [ngClass]="{ 'mandatory' : this.isEmailMandatory }">Email ID</label>
                <input type="email" class="form-control" formControlName="EMAIL_ID" maxlength="100"
                       (blur)="handleInput($event.target,'EMAIL_ID');" autocomplete="off"
                       [ngClass]="{ 'is-invalid': (form.EMAIL_ID.dirty || form.EMAIL_ID.touched || submitted) && (form.EMAIL_ID.errors || invalidPreferredEmail) }" />
                <div *ngIf="(form.EMAIL_ID.dirty || form.EMAIL_ID.touched || submitted) && (form.EMAIL_ID.invalid || invalidPreferredEmail)" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.EMAIL_ID.errors?.['required']">
                    Email ID is required.
                  </div>
                  <div *ngIf="invalidPreferredEmail || form.EMAIL_ID.errors?.['email']">
                    Please enter valid Email.
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="title-md">Adverse Event</div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Product</label>
                <app-product-control [inputAllProducts]="allProducts" [inputSelectedProduct]="selectedProduct"
                                     formControlName="PRODUCT_APPROVAL_ID"></app-product-control>
                <div *ngIf="(form.PRODUCT_APPROVAL_ID.dirty || form.PRODUCT_APPROVAL_ID.touched) && form.PRODUCT_APPROVAL_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.PRODUCT_APPROVAL_ID.errors?.['required']">
                    Product is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="fieldLabel mandatory">Description</label>
                <textarea cols="5" rows="5" class="form-control" formControlName="DESCRIPTION"
                          (blur)="handleInput($event.target,'DESCRIPTION');" autocomplete="off" maxlength="50000"
                          [ngClass]="{ 'is-invalid': (form.DESCRIPTION.dirty || form.DESCRIPTION.touched || submitted) && form.DESCRIPTION.errors }"> </textarea>
                <div *ngIf="(form.DESCRIPTION.dirty || form.DESCRIPTION.touched || submitted) && form.DESCRIPTION.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.DESCRIPTION.errors?.['required']">
                    Description is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">Event Occur Date</label>
                <div>
                  <!--<input type="text" id="view-ae-eod" class="form-control" #datepickerEOD="bsDatepicker"
                         formControlName="AE_EVENT_OCCUR_DATE" autocomplete="off" maxlength="50" [bsConfig]="bsConfig"
                         placeholder="DD-MMM-YYYY" (bsValueChange)="Ondatechange($event,'AE_EVENT_OCCUR_DATE')" (keyup)="keyPress($event,'AE_EVENT_OCCUR_DATE','view-ae-eod','',undefined)"
                         [maxDate]="maxDate"
                         bsDatepicker [ngClass]="{ 'is-invalid': (form.AE_EVENT_OCCUR_DATE.dirty || form.AE_EVENT_OCCUR_DATE.touched || submitted) && form.AE_EVENT_OCCUR_DATE.errors }" />
                  <span (click)="datepickerEOD.toggle()"><i class="far fa-calendar-alt"></i></span>-->

                  <p-calendar useUtc inputId="view-ae-eod"
                              [maxDate]="maxDate"
                              placeholder="DD-MMM-YYYY"
                              formControlName="AE_EVENT_OCCUR_DATE"
                              dateFormat="dd-M-yy"
                              [showIcon]="true"
                              icon="far fa-calendar-alt"
                              appendTo="body"
                              [selectOtherMonths]="true"
                              (onInput)="MinMaxDateValidation($event,'AE_EVENT_OCCUR_DATE','G',maxDate)"
                              styleClass="{ 'is-invalid': (form.AE_EVENT_OCCUR_DATE.dirty || form.AE_EVENT_OCCUR_DATE.touched || submitted) && form.AE_EVENT_OCCUR_DATE.errors }"></p-calendar>

                </div>
                <div *ngIf="(form.AE_EVENT_OCCUR_DATE.dirty || form.AE_EVENT_OCCUR_DATE.touched || submitted) && form.AE_EVENT_OCCUR_DATE.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.AE_EVENT_OCCUR_DATE.errors?.bsDate?.invalid">
                    Invalid Event Occur Date.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="this.IsView && this.Data.Request.FK_FILE_STORE_ID">
              <div class="title-md">Attachment</div>
            </div>
            <div class="col-md-12 pt-2" *ngIf="!this.IsView">
              <div class="row">
                <div class="col-md-4 text-center">
                  <div class="upload-icon upload-btn">
                    <div class="custom-file pl-1 bg-transparent">
                      <input type="file" class="custom-file-input" id="customFile" (change)="handleFileInput($event)"
                             accept=".doc,.docx,.ppt,.pptx,.xlsx,.xls,.pdf,.msg,.eml,.png,.jpeg,.jpg,.txt,.htm,.html,.wav,.mp3" name="customFile" />

                      <label class="custom-file-label fs-12 text-center bg-transparent" for="customFile" title="Upload"><i class="fas fa-upload"></i>&nbsp;{{uploadbtnName}} Attachment</label>
                    </div>
                  </div>
                  <span class="fs-11 text-secondary">File size should not exceed {{this.attachmentMaxFileSize}} MB</span>
                </div>
                <div class="col-md-8">
                  <a href="javascript:void(0)" class="fs-14 pl-2" (click)="downloadDocument(fileStoreParams.PK_FileStoreId);">{{fileDisplayName}}</a>

                  <span *ngIf="this.fileDisplayName" class="fs-16 pl-1 text-secondary"><i (click)="deleteAttachment();" class="fas fa-trash-alt"></i></span>
                </div>
              </div>
            </div>
            <div class="col-md-12 pt-2" *ngIf="this.IsView && this.Data.Request.FK_FILE_STORE_ID">
              <div class="row">
                <div class="col-md-4 text-center">
                  <a href="javascript:void(0)" class="fs-14 pl-2" (click)="downloadDocument(this.Data.Request.FK_FILE_STORE_ID);">{{this.Data.Request.FILE_NAME}}{{this.Data.Request.FILE_EXT}}</a>
                  <!-- <label>{{this.Data.Request.FILE_NAME}}</label> -->
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="title-md py-2">Patient Information</div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">First Name</label>
                <input type="text" class="form-control" formControlName="AE_PATIENT_FIRST_NAME"
                       (blur)="handleInput($event.target,'AE_PATIENT_FIRST_NAME');" autocomplete="off" maxlength="35" noSpecailChars />
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">Last Name</label>
                <input type="text" class="form-control" formControlName="AE_PATIENT_LAST_NAME"
                       (blur)="handleInput($event.target,'AE_PATIENT_LAST_NAME');" autocomplete="off" noSpecailChars maxlength="50" />
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel">Date of Birth</label>
                <div>
                  <p-calendar useUtc inputId="view-ae-pdbt" *ngIf="!form.AE_PATIENT_DATE_OF_BIRTH.value?true:isDate(form.AE_PATIENT_DATE_OF_BIRTH.value)"
                              [maxDate]="maxDate"
                              placeholder="DD-MMM-YYYY"
                              formControlName="AE_PATIENT_DATE_OF_BIRTH"
                              dateFormat="dd-M-yy"
                              [showIcon]="true"
                              appendTo="body"
                              icon="far fa-calendar-alt"
                              [selectOtherMonths]="true"
                              (onInput)="MinMaxDateValidation($event,'AE_PATIENT_DATE_OF_BIRTH','G',maxDate)"
                              styleClass="{ 'is-invalid': (form.AE_PATIENT_DATE_OF_BIRTH.dirty || form.AE_PATIENT_DATE_OF_BIRTH.touched || submitted) && form.AE_PATIENT_DATE_OF_BIRTH.errors }"></p-calendar>


                  <div *ngIf="!form.AE_PATIENT_DATE_OF_BIRTH.value?false:!isDate(form.AE_PATIENT_DATE_OF_BIRTH.value)">
                    <div class="form-group d-flex">
                      <input type="text" class="form-control dppr-cal-brder-0" formControlName="AE_PATIENT_DATE_OF_BIRTH" [disabled]="true" autocomplete="off" />
                      <span class="dppr-calendar">
                        <i class="far fa-calendar-alt"></i>
                      </span>
                    </div>
                  </div>


                </div>
                <div *ngIf="(form.AE_PATIENT_DATE_OF_BIRTH.dirty || form.AE_PATIENT_DATE_OF_BIRTH.touched || submitted) && form.AE_PATIENT_DATE_OF_BIRTH.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.AE_PATIENT_DATE_OF_BIRTH.errors?.bsDate?.invalid">
                    Invalid Date of Birth.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group">
                <label class="fieldLabel mandatory">Gender</label>
                <select [ngClass]="{ 'is-invalid': (form.FK_AE_PATIENT_GENDER_ID.dirty || form.FK_AE_PATIENT_GENDER_ID.touched) && form.FK_AE_PATIENT_GENDER_ID.errors }"
                        class="form-control" formControlName="FK_AE_PATIENT_GENDER_ID">
                  <option value="">-Select-</option>
                  <option *ngFor="let prd of GenderList" value="{{prd.PK_LOOKUP_ID}}">{{prd.NAME}}</option>
                </select>
                <div *ngIf="(form.FK_AE_PATIENT_GENDER_ID.dirty || form.FK_AE_PATIENT_GENDER_ID.touched) && form.FK_AE_PATIENT_GENDER_ID.invalid" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.FK_AE_PATIENT_GENDER_ID.errors?.['required']">
                    Gender is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <h4 class="font-weight-bold fs-14 pt-2">Disclaimer</h4>
              <p class="fs-12 pb-2"> The information we are requesting is the minimum necessary for {{utilityService.getPortalName()}} to process your reported adverse event.</p>

              <div class="col-md-12" *ngIf="Has_RET_Rule">
                <div class="row">
                  <div class="col-md-6 pl-0">
                    <h4 class="fs-12 pt-2" [ngClass]="{ 'mandatory' : this.isConsentMandatory }">I consent {{utilityService.getPortalName()}} to store information provided for Request processing : </h4>
                  </div>
                  <div class="col-md-1 pt-2 px-0 custom-radio">
                    <input type="radio" class="custom-control-input" value="Accept"
                           formControlName="CONSENT_TYPE" id="accept">
                    <label class="custom-control-label"
                           for="accept">Accept</label>
                  </div>
                  <div class="col-md-2 pt-2 px-0 custom-radio">
                    <input type="radio" class="custom-control-input" value="Reject"
                           formControlName="CONSENT_TYPE" id="reject">
                    <label class="custom-control-label"
                           for="reject">Reject</label>
                  </div>
                </div>
                <div *ngIf="isConsentMandatory && submitted" class="invalid-form-control text-danger fs-12">
                  <div *ngIf="form.CONSENT_TYPE.errors?.['required']">
                    Select Consent Type.
                  </div>
                </div>
              </div>

            </div>

            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="Has_RTF_Rule">
              <div class="custom-control custom-checkbox mt-3">
                <input type="checkbox" class="custom-control-input" id="cp-rtf" name="rtf" formControlName="RTF_CONSENT_TYPE">
                <label class="custom-control-label" for="cp-rtf">Consent for Right to Forget: Should you choose to exercise your Right to Forget and have your personal data removed from our records, kindly check the checkbox</label>
              </div>
            </div>

            <div class="col-md-6 col-sm-6">
              <div class="custom-control custom-checkbox mt-3">
                <input type="checkbox" class="custom-control-input" id="view-ae-cb2" name="example1" formControlName="IS_CALLBACK_ON_PRIORITY">
                <label class="custom-control-label" for="view-ae-cb2">Choose to contact back on Priority for processing the report</label>
              </div>
            </div>
            <div class="col-md-6 buttonsRow pt-2" ngPreserveWhitespaces *ngIf="!this.IsView">
              <button type="button" class="btn btn-primary px-3" [disabled]="!isDraftValid" (click)="saveAsDraftRequest();"><i class="fas fa-save"></i> &nbsp;Save as Draft</button>
              <button type="button" class="btn btn-primary px-3" [disabled]="!isSubmitValid" (click)="submitRequest();"><i class="fas fa-share"></i> &nbsp; Submit</button>
              <button type="button" class="btn btn-primary px-3" (click)="cancel();"><i class="fas fa fa-ban"></i> &nbsp; Cancel</button>
            </div>
            <div class="col-md-12 buttonsRow py-2" *ngIf="this.IsView">
              <button (click)="this.close()" type="button" class="btn btn-primary px-3">Close</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="pdf-img text-center" *ngIf="pdfDownloadURL" [style.display]="'none'">
  <iframe width="0" height="0" [src]="pdfDownloadURL" name="DownloadDocument" title="Download Document"></iframe>
</div>
