import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { AlertifyService } from '../../_services/alertify.service';
//import { MessageType } from '../../enums/message-type.enum';
//import { AlertifyService } from '../../services/alertify.service';
//import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {
  @Input("previewURL") previewURL: SafeUrl | null = null;
  @Output() onClose = new EventEmitter<boolean>();

  constructor(private alertify: AlertifyService) { }
  //private common: CommonService) { }

  ngOnInit() {
    if (this.previewURL) {
      //this.common.checkSessionTimeout().subscribe(resp => {
      //    if (!resp) {
      //        window.location.href = '/Home/Index';
      //    }
      //},
      //    error => { console.error(error); });
    }
    else {
      this.previewURL = null;
    }
  }

  close() {
    this.onClose.emit(true);
  }

}
