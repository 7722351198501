// src/app/transfer-state.service.ts

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class TransferStateService {
  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  // Set data in TransferState
  set(key: string, value: any): void {
    if (isPlatformServer(this.platformId)) {
      console.log("Server Side Rendered and Setting seotags key");
      const stateKey = makeStateKey<any>(key);
      this.transferState.set(stateKey, value);
    }
  }

  // Get data from TransferState
  get(key: string): any {
    if (isPlatformBrowser(this.platformId)) {
      console.log("Client Side Rendered and getting seotags key");
      const stateKey = makeStateKey<any>(key);
      const value = this.transferState.get(stateKey, null);
      this.transferState.remove(stateKey); // Clear the value to avoid leaking data
      return value;
    }
    return null;
  }

  // Remove data in TransferState
  remove(key: string): void {
    const stateKey = makeStateKey<any>(key);
    if (stateKey) {
      this.transferState.remove(stateKey);
    }
  }

}
