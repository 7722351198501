import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUserInfo } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { EventsConferencesService } from 'src/app/_services/events-conferences.service';

@Component({
  selector: 'app-event-unsubscribe',
  templateUrl: './event-unsubscribe.component.html',
  styleUrls: ['./event-unsubscribe.component.css']
})
export class EventUnsubscribeComponent implements OnInit {

  inValidLink: boolean = false;
  unsubscribeSuccess: boolean | null = null;
  isLoggedInUser: boolean;
  platformId: any;
  constructor(private readonly eventConfService: EventsConferencesService,
    public authServices: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router, @Inject(PLATFORM_ID) platformId: any) { this.platformId = platformId; }

  ngOnInit(): void {
    const email_id = this.route.snapshot.queryParams['eid'];
    const pk_event_subscriber_id = this.route.snapshot.queryParams['sid'];
    if(email_id == undefined || pk_event_subscriber_id == undefined)
    {
      this.inValidLink = true;
    }
    else
    {
     // remove eid and sid from url to prevent http referer leakage
     this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

     this.isLoggedInUser = this.authServices.isUserLoggedIn();

     this.eventConfService.unsubscribe(pk_event_subscriber_id, email_id).subscribe(res => {
      if (res) {
        if (res.Status) {
          this.unsubscribeSuccess = true;
          this.inValidLink = false;
          if (this.isLoggedInUser) {
           if (isPlatformBrowser(this.platformId)) {
            let objUserInfo = localStorage.getItem('cpappuserinfo');
            if (objUserInfo)
            {
              let appUserInfo: AppUserInfo = JSON.parse(objUserInfo);
              appUserInfo.IsSubscribedToEvents = false;
              localStorage.removeItem('cpappuserinfo');
              localStorage.setItem('cpappuserinfo', JSON.stringify(appUserInfo));
}
            }
          }
        }
        else
        {
          this.unsubscribeSuccess = false;
          this.inValidLink = true;
        }
      }
     });
    }
  }

}
