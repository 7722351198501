<div class="container-fluid" *ngIf = "showVerify || codeVerified">
    <div class="reset-password-form-content">
     <div class="row">
    <div class="col-md-12" *ngIf = "showVerify">
        <form [formGroup]="SubmitVerificationForm">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                      <p class="fs-14 text-center pb-3">Verification code has been shared to your registered email address please enter the code to verify.</p>
                    </div>
                    <div class="col-md-12">
                        <label class="fieldLabel mandatory">Code</label>
                        <input numbersOnly type="text" class="form-control" formControlName="Code"  autocomplete="off"
                        placeholder="Enter Code" maxlength="4" (blur)="handleInput($event.target,'Code');"
                        [ngClass]="{ 'is-invalid': verifySubmitted && verifyForm.Code.errors }">
                        <div *ngIf="verifySubmitted && verifyForm.Code.invalid" class="invalid-form-control text-danger fs-12">
                          <div *ngIf="verifyForm.Code.errors?.['required']">
                            Code is required.
                          </div>
                        </div>
                        <div *ngIf="inValidCode" class="invalid-form-control text-danger fs-12">
                          Invalid verification code, please re-enter valid verification code or click on Resend Code
                        </div>
                        <div *ngIf="codeExpired" class="invalid-form-control text-danger fs-12">
                          Your verification code has expired. Please click on Resend Code
                        </div>
                    </div>
                    <div class="col-md-12 pt-3">
                        <div class="row">
                          <div class="col-md-6 col-sm-6">
                            <button type="button" class="btn btn-primary"  [disabled]="!this.enableVerify"
                            (click)="verify()">Verify</button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          </form>
    </div>
     <div class="col-md-12" *ngIf = "codeVerified">
        <div class="container-fluid">
            <p class="fs-14 text-center text-success pt-3"><i class="fas fa-check-circle"></i> You have successfully verified code. Please reset your password.</p>
            <form [formGroup]="ResetPasswordForm">
            <div class="row">
              <div class="col-md-12 text-right">
                <label class="fieldLabel mandatory fs-12 text-secondary" >Required Fields</label>
              </div>
                <div class="col-md-12">
                  <div class="form-group">
                        <label class="fieldLabel mandatory" >Registered Email ID</label>                       
                        <input type="email" class="form-control" formControlName="REGISTERED_EMAIL_ID" autocomplete="off" (blur)="handleInput($event.target,'REGISTERED_EMAIL_ID');"
                        maxlength="100" [ngClass]="{ 'is-invalid': form.REGISTERED_EMAIL_ID.errors }" />
                        <div *ngIf="resetSubmitted && form.REGISTERED_EMAIL_ID.invalid" class="invalid-form-control text-danger fs-12">
                            <div *ngIf="form.REGISTERED_EMAIL_ID.errors?.['required']">
                                Email ID is required.
                            </div>
                            <div *ngIf="form.REGISTERED_EMAIL_ID.errors?.['email']">
                                Please enter valid Email.
                            </div>
                        </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                        <label class="fieldLabel mandatory">New Password</label>                       
                        <input type="password" class="form-control" required="" noSpaces PWDSpecailChars
                        formControlName="PASSWORD" autocomplete="off" maxlength="100" (blur)="handleInput($event.target,'PASSWORD');"
                        [ngClass]="{ 'is-invalid': resetSubmitted && form.PASSWORD.errors }" />
                        <div *ngIf="resetSubmitted && form.PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
                            <div *ngIf="form.PASSWORD.errors?.['required']">
                                Password is required.
                            </div>
                            <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['number']">
                                Password should contain at least {{passwordPolicy.MIN_NUMERIC_CHARS}} number
                            </div>
                            <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['upper']">
                                Password should contain at least {{passwordPolicy.MIN_UPPERCASE_CHARS}} uppercase letter 
                            </div>
                            <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['special']">
                                Password should contain at least {{passwordPolicy.MIN_SPECAIL_CHARS}} special letter 
                            </div>
                            <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['minlength']">
                                Password should contain minimum of {{passwordPolicy.MIN_PASSWORD_LENGTH}} characters
                            </div>
                            <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['pattern']">
                                Password doesn't meet the policy requirement. Please check password policy.
                            </div>
                        </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                        <label class="fieldLabel mandatory">Confirm Password</label>                       
                        <input type="password" class="form-control" required="" noSpaces PWDSpecailChars
                        formControlName="CONFIRM_PASSWORD" autocomplete="off" maxlength="100" (blur)="handleInput($event.target,'CONFIRM_PASSWORD');"
                        [ngClass]="{ 'is-invalid': resetSubmitted && form.CONFIRM_PASSWORD.errors }" />
                        <div *ngIf="resetSubmitted && form.CONFIRM_PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
                            <div *ngIf="form.CONFIRM_PASSWORD.errors?.['required']">
                                Confirm Password is required.
                            </div>
                            <div *ngIf="!form.CONFIRM_PASSWORD.errors?.['required'] && form.CONFIRM_PASSWORD.errors?.mustMatch">
                                Password and Confirm password should match
                            </div>
                        </div>
                  </div>
                </div>
                <div class="col-md-12 pt-2">
                  <button type="button" [disabled]="!this.enableResetPassword" 
                  class="btn btn-primary btn-block fs-14" (click)="resetPassword()">Confirm</button>
                  <p class="fs-13 pt-1">Please note that your password should meet our  
                    <a href="javascript:void(0)" (click)="this.openPasswordPolicy()">Password Policy.&nbsp;<i class="fas fa-info-circle fs-14"></i></a></p>
                </div>
            </div>
            </form>
        </div>          
     </div>
</div>
</div>
</div>

<div class="container-fluid" *ngIf = "isResetSuccess || codeExpired || inValidLink || codeAlreadyVerified">
    <div class="reset-password-content">
     <div class="row">
         <div class="col-md-12" *ngIf = "isResetSuccess">
            <a routerLink="/" class="text-center d-block pt-2"><img src="./assets/images/logo.png" alt=""> </a>
            <span class="d-block fs-13 text-center text-success">Success Message</span>
            <p class="fs-14 text-center pt-3"> You have successfully reset your password. Please login to continue</p>
            <div class="text-center pt-4"><a routerLink="/" class="btn btn-primary px-3">Login</a></div>
        </div>
        <div class="col-md-12" *ngIf = "codeAlreadyVerified">
            <a routerLink="/" class="text-center d-block pt-2"><img src="./assets/images/logo.png" alt=""> </a>
            <span class="d-block fs-13 text-center text-danger">Error Message</span>
            <p class="fs-14 text-center pt-3"> Invalid reset password link or you have already reset your password.</p>
        </div>
         <div class="col-md-12" *ngIf = "codeExpired">
            <a routerLink="/" class="text-center d-block pt-2"><img src="./assets/images/logo.png" alt=""> </a>
            <span class="d-block fs-13 text-center text-danger">Error Message</span>
            <p class="fs-14 text-center pt-3"> Please click on resend link as the reset password link has expired</p>
            <div class="text-center pt-4">
              <button type="button" class="btn btn-primary m-auto" [disabled]="!enableLink" (click)="resendLink()">Resend link</button>                
            </div>
            <div class="text-center pt-4">
                <span class="float-right clearfix fs-12" *ngIf="this.showTimer">Resend link button will enable in {{("0"+minutesToDday).slice(-2)}} : {{("0"+secondsToDday).slice(-2)}} </span> 
            </div>
        </div>
        <div class="col-md-12" *ngIf = "inValidLink">
            <a routerLink="/" class="text-center d-block pt-2"><img src="./assets/images/logo.png" alt=""> </a>
            <span class="d-block fs-13 text-center text-danger">Error Message</span>
            <p class="fs-14 text-center pt-3"> Invalid reset password link. Please use a valid reset password link</p>
        </div>
     </div>
   </div>
</div>
