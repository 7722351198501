<div #modalconent class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title w-100 d-block">
      REGISTER
      <a (click)="close(true)" class="fs-12 text-underline float-right pt-1 pl-2 text-white fs-14"><i class="fas fa-times"></i></a>
    </h5>
  </div>

  <div class="modal-body">
    <div class="tabs-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-sm-12 tabsMain">
            <nav>
              <div class="nav default-tabs nav-tabs" id="nav-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link ff" id="first-tab"
                     [ngClass]="{'active':(type=='WHO_YOU_ARE'),'completed':(type=='COMMUNICATION' || type=='YOUR_PRACTICE' || type=='ACCOUNT_CREATION')}"
                     role="tab" aria-controls="nav-home"></a>
                </li>
                <li class="nav-item">
                  <a class="nav-item nav-link" id="second-tab" [ngClass]="{'active':(type=='COMMUNICATION'),'completed':(type=='YOUR_PRACTICE' || type=='ACCOUNT_CREATION')}"
                     role="tab" aria-controls="nav-profile"></a>
                </li>
                <li class="nav-item">
                  <a class="nav-item nav-link" id="three-tab" [ngClass]="{'active':(type=='YOUR_PRACTICE'),'completed':(type=='ACCOUNT_CREATION')}"
                     role="tab" aria-controls="nav-profile"></a>
                </li>
                <li class="nav-item">
                  <a class="nav-item nav-link" id="four-tab" [ngClass]="{'active':(type=='ACCOUNT_CREATION')}"
                     role="tab" aria-controls="nav-profile"></a>
                </li>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="SubmitRegisterForm">
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade" id="tab1" [ngClass]="{'show active':(type=='WHO_YOU_ARE')}"
             *ngIf="type=='WHO_YOU_ARE'">
          <div class="registaerForm-fields">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 text-center pb-2">
                  <div class="title-lg">Tell Us Who You Are</div>
                  <p class="fs-14 py-2">
                    Please tell us a little bit about yourself. We value your privacy. You can
                    <a href="https://www.scimaxglobal.com/privacy-policy/" target="_blank">click here</a> to review our privacy policy
                  </p>
                  <p class="text-right text-secondary fs-12 float-right mandatory">Required Fields.</p>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel mandatory form-control-placeholder" for="Fname">First Name</label>
                    <input type="text" id="Fname" name="Fname" class="form-control" required="" noNumbers
                           (blur)="handleInput($event.target,'FIRST_NAME');" formControlName="FIRST_NAME" autocomplete="off" maxlength="35"
                           [ngClass]="{ 'is-invalid': (form.FIRST_NAME.dirty || form.FIRST_NAME.touched || WHO_YOU_ARE_Next) && form.FIRST_NAME.errors }" />
                    <div *ngIf="(form.FIRST_NAME.dirty || form.FIRST_NAME.touched || WHO_YOU_ARE_Next) && form.FIRST_NAME.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.FIRST_NAME.errors?.['required']">
                        First Name is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel mandatory form-control-placeholder" for="Lname">Last Name</label>
                    <input type="text" id="Lname" class="form-control" required="" formControlName="LAST_NAME"
                           (blur)="handleInput($event.target,'LAST_NAME');" autocomplete="off" maxlength="50" noNumbers
                           [ngClass]="{ 'is-invalid': (form.LAST_NAME.dirty || form.LAST_NAME.touched || WHO_YOU_ARE_Next) && form.LAST_NAME.errors }" />
                    <div *ngIf="(form.LAST_NAME.dirty || form.LAST_NAME.touched || WHO_YOU_ARE_Next) && form.LAST_NAME.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.LAST_NAME.errors?.['required']">
                        Last Name is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel mandatory form-control-placeholder" for="register-dbt">Date of Birth</label>
                    <!-- <small><img src="./assets/images/calender.png" (click)="datepickerDOB.toggle()"
                    [attr.aria-expanded]="datepickerDOB.isOpen" alt=""> </small> -->
                    <div>
                      <!--<input type="text" id="register-dbt" class="form-control" required="" #datepickerDOB="bsDatepicker" (keyup)="keyPress($event,'DATE_OF_BIRTH','register-dbt','',undefined)"
                      formControlName="DATE_OF_BIRTH" autocomplete="off" maxlength="50" [bsConfig]="bsConfig"
                      placeholder="DD-MMM-YYYY" (bsValueChange)="Ondatechange($event,'DATE_OF_BIRTH')"
                      bsDatepicker [ngClass]="{ 'is-invalid': (form.DATE_OF_BIRTH.dirty || WHO_YOU_ARE_Next) && form.DATE_OF_BIRTH.errors }" />
                      <span (click)="datepickerDOB.toggle()"><i class="far fa-calendar-alt"></i></span>-->

                      <p-calendar useUtc inputId="register-dbt"
                                  [maxDate]="minDate"
                                  placeholder="DD-MMM-YYYY"
                                  formControlName="DATE_OF_BIRTH"
                                  dateFormat="dd-M-yy"
                                  [showIcon]="true"
                                  icon="far fa-calendar-alt"
                                  appendTo="body"
                                  [defaultDate]="minDate"
                                  [selectOtherMonths]="true"
                                  (onInput)="MinMaxDateValidation($event,'DATE_OF_BIRTH','G',minDate)"
                                  styleClass="{ 'is-invalid': (form.DATE_OF_BIRTH.dirty || WHO_YOU_ARE_Next) && form.DATE_OF_BIRTH.errors }"></p-calendar>

                    </div>
                    <div *ngIf="(form.DATE_OF_BIRTH.dirty || WHO_YOU_ARE_Next) && form.DATE_OF_BIRTH.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.DATE_OF_BIRTH.errors?.['required']">
                        Date of Birth is required.
                      </div>
                      <div *ngIf="form.DATE_OF_BIRTH.errors?.bsDate?.invalid">
                        Invalid Date of Birth.
                      </div>

                    </div>
                    <div *ngIf="form.DATE_OF_BIRTH.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.DATE_OF_BIRTH.errors?.age">
                        Age should not be less than 16 years
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel mandatory form-control-placeholder">Primary Degree</label>
                    <select class="form-control" formControlName="FK_PRIMARY_DEGREE_ID" [ngClass]="{ 'is-invalid': (form.FK_PRIMARY_DEGREE_ID.dirty || form.FK_PRIMARY_DEGREE_ID.touched || WHO_YOU_ARE_Next) && (form.FK_PRIMARY_DEGREE_ID.errors) }">
                      <option value="">Select</option>
                      <option *ngFor="let pd of primaryDegree" value="{{pd.PK_LOOKUP_ID}}">{{pd.NAME}}</option>
                    </select>
                    <div *ngIf="(form.FK_PRIMARY_DEGREE_ID.dirty || form.FK_PRIMARY_DEGREE_ID.touched|| WHO_YOU_ARE_Next) && form.FK_PRIMARY_DEGREE_ID.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.FK_PRIMARY_DEGREE_ID.errors?.['required']">
                        Primary Degree is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="select-control-placeholder fieldLabel mandatory">Country</label>
                    <app-country-control formControlName="FK_COUNTRY_ID" [inputAllCountries]="allCountries" [inputSelectedCountry]="selectedCountry"></app-country-control>
                    <span class="fltlabel-highlight"></span>
                    <div *ngIf="(form.FK_COUNTRY_ID.dirty || form.FK_COUNTRY_ID.touched || WHO_YOU_ARE_Next) && form.FK_COUNTRY_ID.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.FK_COUNTRY_ID.errors?.['required']">
                        Country is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel form-control-placeholder" for="z-code">Zip Code</label>
                    <input type="text" id="z-code" class="form-control" formControlName="ZIP_CODE" autocomplete="off" (blur)="handleInput($event.target,'ZIP_CODE');"
                           maxlength="15" noSpecailChars noSpaces />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel form-control-placeholder" for="sp">State</label>
                    <input type="text" id="sp" class="form-control" formControlName="STATE_PROVINCE"
                           (blur)="handleInput($event.target,'STATE_PROVINCE');" autocomplete="off"
                           maxlength="50" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel form-control-placeholder" for="city">City</label>
                    <input type="text" id="city" class="form-control" formControlName="CITY"
                           (blur)="handleInput($event.target,'CITY');" autocomplete="off" maxlength="50" />
                  </div>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12">
                  <label class="fieldLabel form-control-placeholder" for="adrs">Address</label>
                  <input type="text" id="adrs" class="form-control" formControlName="ADDRESS"
                         (blur)="handleInput($event.target,'ADDRESS');" autocomplete="off" maxlength="200" />
                </div>
                <div class="col-md-12 py-2">
                  <div class="row">
                    <div class="col-md-8 col-sm-6 fs-13 pt-2 mb-2">
                      Already have an account? <a href="javascript:void(0)"
                                                  (click)="this.openLoginForm()">Click here to Login</a>
                    </div>
                    <div class="col-md-4 col-sm-6 text-right">
                      <!-- <button type="button" class="btn btn-default px-3 disabled"><i class="fas fa-arrow-left"></i>
                      &nbsp;Back</button>&nbsp; -->
                      <button type="button" class="btn btn-default px-3" [disabled]="!this.isStep1Valid" (click)="validations('COMMUNICATION');">
                        Next
                        &nbsp;<i class="fas fa-arrow-right float-right mt-1"></i>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tab2" [ngClass]="{'show active':(type=='COMMUNICATION')}"
             *ngIf="type=='COMMUNICATION'" role="tabpanel" aria-labelledby="tabcontent-2">
          <div class="registaerForm-fields">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 text-center pb-2">
                  <div class="title-lg">Communication Preference</div>
                  <p class="fs-14 py-2">
                    Please provide details for your preferred way of communication to all requests raised through the portal.
                  </p>
                  <p class="text-right text-secondary fs-12 float-right mandatory">Required Fields.</p>
                </div>
                <div class="col-md-12 pb-2">
                  <div class="fieldLabel pb-2 mandatory">Preferred Contact Method</div>
                  <div class="row">
                    <div *ngFor="let preferredContact of preferedContacts" class="col-md-3 col-sm-6">
                      <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" value="{{preferredContact.Id}}"
                               formControlName="FK_PREFERRED_CONTACT_ID" id="{{preferredContact.Id}}">
                        <label class="custom-control-label" for="{{preferredContact.Id}}">{{preferredContact.ContactName}}</label>
                      </div>
                    </div>
                    <!-- <div class="col-md-3 col-sm-6">
                      <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" value="PHONE"
                        formControlName="CONTACT_PREFERENCE" id="pn">
                        <label class="custom-control-label" for="pn">Phone Number</label>
                      </div>
                    </div> -->
                  </div>
                  <div *ngIf="(form.FK_PREFERRED_CONTACT_ID.dirty || form.FK_PREFERRED_CONTACT_ID.touched || COMMUNICATION_Next) && form.FK_PREFERRED_CONTACT_ID.invalid" class="invalid-form-control text-danger fs-12">
                    <div *ngIf="form.FK_PREFERRED_CONTACT_ID.errors?.['required']">
                      Preferred Contact Method is required.
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-10 col-sm-10">
                  <div class="form-group">
                    <label class="fieldLabel form-control-placeholder" [ngClass]="{ 'mandatory' : this.isEmailMandatory }" for="eml">Email ID</label>
                    <input type="text" id="eml" class="form-control" formControlName="PREFERED_EMAIL" autocomplete="off"
                           (blur)="handleInput($event.target,'PREFERED_EMAIL');" maxlength="100" />
                    <div *ngIf="(form.PREFERED_EMAIL.dirty || form.PREFERED_EMAIL.touched || COMMUNICATION_Next) && form.PREFERED_EMAIL.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.PREFERED_EMAIL.errors?.['required']">
                        Email Id is required.
                      </div>
                    </div>
                    <div *ngIf="!form.PREFERED_EMAIL.errors?.['required'] && (form.PREFERED_EMAIL.dirty || form.PREFERED_EMAIL.touched || COMMUNICATION_Next)" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="invalidPreferredEmail || form.PREFERED_EMAIL.errors?.['email']">
                        Please enter valid Email.
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-lg-6 col-md-10 col-sm-10">
                  <div class="row">
                    <div class="col-9 col-md-9 col-sm-9">
                      <div class="form-group">
                        <label class="fieldLabel form-control-placeholder" for="pnr1" [ngClass]="{ 'mandatory' : this.isPhoneMandatory }">Primary Phone Number</label>
                        <input phoneNumber type="text" id="pnr1" class="form-control" formControlName="PREFERED_PHONE" autocomplete="off"
                               maxlength="25" (blur)="handleInput($event.target,'PREFERED_PHONE');" />
                        <div *ngIf="(form.PREFERED_PHONE.dirty || form.PREFERED_PHONE.touched || COMMUNICATION_Next) && form.PREFERED_PHONE.invalid" class="invalid-form-control text-danger fs-12">
                          <div *ngIf="form.PREFERED_PHONE.errors?.['required']">
                            Primary Phone Number is required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 col-md-3 col-sm-3">
                      <div class="form-group">
                        <label class="fieldLabel form-control-placeholder" for="ext1">
                          Ext
                        </label>
                        <input type="text" id="ext1" class="form-control" autocomplete="off" phoneNumber
                               (blur)="handleInput($event.target,'PREFERED_PHONE_EXT');" formControlName="PREFERED_PHONE_EXT" maxlength="10" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-10 col-sm-10">
                  <div class="row">
                    <div class="col-9 col-md-9 col-sm-9">
                      <div class="form-group">
                        <label class="fieldLabel form-control-placeholder" for="pnr2">Other Phone Number</label>
                        <input phoneNumber type="text" id="pnr2" class="form-control" formControlName="PHONE" autocomplete="off"
                               maxlength="25" (blur)="handleInput($event.target,'PHONE');" />
                      </div>
                    </div>
                    <div class="col-3 col-md-3 col-sm-3">
                      <div class="form-group">
                        <label class="fieldLabel form-control-placeholder" for="ext2">
                          Ext
                        </label>
                        <input type="text" id="ext2" class="form-control" autocomplete="off" phoneNumber
                               (blur)="handleInput($event.target,'PHONE_EXT');" formControlName="PHONE_EXT" maxlength="10" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <p class="fs-13 py-2">{{utilityService.getPortalName()}} sends you emails on important Program updates that you are enrolled or when new information is available in this account. A limited number of communications may be sent to you via postal mail when necessary. You can also choose to receive notifications via text message. If available, you will be asked to provide your mobile phone number if you have not yet provided one.</p>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="custom-control custom-checkbox mb-1">
                    <input type="checkbox" class="custom-control-input" id="register-cb1" name="example1"
                           formControlName="HAS_RECEIVE_EMAILS">
                    <label class="custom-control-label" for="register-cb1">I want to receive Emails from {{utilityService.getPortalName()}} Medical Information team on any updates</label>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="custom-control custom-checkbox mb-1">
                    <input type="checkbox" class="custom-control-input" id="register-cb2" name="example1"
                           formControlName="HAS_ALLOW_CALLS">
                    <label class="custom-control-label" for="register-cb2">I allow {{utilityService.getPortalName()}} to call me when there are important program updates</label>
                  </div>
                </div>
                <div class="col-md-12 py-2">
                  <div class="row">
                    <div class="col-md-6 col-sm-6 fs-13 pt-2 mb-2">
                      Already have an account? <a href="javascript:void(0)"
                                                  (click)="this.openLoginForm()">Click here to Login</a>
                    </div>
                    <div class="col-md-6 col-sm-6 text-right">
                      <button type="button" class="btn btn-default px-3" (click)="type='WHO_YOU_ARE'">
                        <i class="fas fa-arrow-left"></i> &nbsp;Back
                      </button>&nbsp;
                      <button type="button" class="btn btn-default px-3" (click)="validations('YOUR_PRACTICE');"
                              [disabled]="!this.isStep2Valid">
                        Next
                        &nbsp;<i class="fas fa-arrow-right float-right mt-1"></i>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tab3" [ngClass]="{'show active':(type=='YOUR_PRACTICE')}"
             *ngIf="type=='YOUR_PRACTICE'" role="tabpanel" aria-labelledby="tabcontent-3">
          <div class="registaerForm-fields">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 text-center pb-2">
                  <div class="title-lg">Tell Us About Your Practice</div>
                  <p class="fs-14 py-2">Please enter valid license details as these cannot be changed after account creation</p>
                  <p class="text-right text-secondary fs-12 float-right mandatory">Required Fields.</p>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="isLoginOnly">
                  <div class="form-group">
                    <label class="select-control-placeholder fieldLabel mandatory">User Profile</label>
                    <select [ngClass]="{ 'is-invalid': (form.FK_REPORTINGTYPE_ID.dirty || form.FK_REPORTINGTYPE_ID.touched || YOUR_PRACTICE_Next) && form.FK_REPORTINGTYPE_ID.errors }"
                            class="floating-select form-control" required="" formControlName="FK_REPORTINGTYPE_ID">
                      <option value="">-Select-</option>
                      <option *ngFor="let user of userProfiles" value="{{user.ReporterTypeID}}">{{user.ReporterTypeName}}</option>
                    </select>
                    <span class="fltlabel-highlight"></span>
                    <div *ngIf="(form.FK_REPORTINGTYPE_ID.dirty || form.FK_REPORTINGTYPE_ID.touched || YOUR_PRACTICE_Next) && form.FK_REPORTINGTYPE_ID.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.FK_REPORTINGTYPE_ID.errors?.['required']">
                        User Profile is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="select-control-placeholder fieldLabel mandatory">Profession</label>
                    <select [ngClass]="{ 'is-invalid': (form.FK_PROFESSION_ID.dirty || form.FK_PROFESSION_ID.touched || YOUR_PRACTICE_Next) && form.FK_PROFESSION_ID.errors }"
                            class="floating-select form-control" required="" formControlName="FK_PROFESSION_ID">
                      <option value="">-Select-</option>
                      <option *ngFor="let occ of occupationList" value="{{occ.PK_LOOKUP_ID}}">{{occ.NAME}}</option>
                    </select>
                    <span class="fltlabel-highlight"></span>
                    <div *ngIf="(form.FK_PROFESSION_ID.dirty || form.FK_PROFESSION_ID.touched || YOUR_PRACTICE_Next) && form.FK_PROFESSION_ID.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.FK_PROFESSION_ID.errors?.['required']">
                        Profession is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="select-control-placeholder fieldLabel mandatory">Specialty</label>
                    <select [ngClass]="{ 'is-invalid': (form.FK_SPECAILITY_ID.dirty || form.FK_SPECAILITY_ID.touched || YOUR_PRACTICE_Next) && form.FK_SPECAILITY_ID.errors }"
                            class="floating-select form-control" required="" formControlName="FK_SPECAILITY_ID">
                      <option value="">-Select-</option>
                      <option *ngFor="let spec of specialityList" value="{{spec.PK_LOOKUP_ID}}">{{spec.NAME}}</option>
                    </select>
                    <span class="fltlabel-highlight"></span>
                    <div *ngIf="(form.FK_SPECAILITY_ID.dirty || form.FK_SPECAILITY_ID.touched || YOUR_PRACTICE_Next) && form.FK_SPECAILITY_ID.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.FK_SPECAILITY_ID.errors?.['required']">
                        Speciality is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel mandatory form-control-placeholder" for="ln">License Number</label>
                    <input type="text" id="ln" class="form-control" required="" formControlName="LICENCE_NUMBER" autocomplete="off"
                           maxlength="10" (blur)="handleInput($event.target,'LICENCE_NUMBER');"
                           [ngClass]="{ 'is-invalid': (form.LICENCE_NUMBER.dirty || form.LICENCE_NUMBER.touched || YOUR_PRACTICE_Next) && form.LICENCE_NUMBER.errors }" />
                    <div *ngIf="(form.LICENCE_NUMBER.dirty || form.LICENCE_NUMBER.touched || YOUR_PRACTICE_Next) && form.LICENCE_NUMBER.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.LICENCE_NUMBER.errors?.['required']">
                        License Number is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel form-control-placeholder" for="pp">Primary Practice</label>
                    <input type="text" id="pp" class="form-control" formControlName="PRIMARY_PRACTICE" autocomplete="off"
                           (blur)="handleInput($event.target,'PRIMARY_PRACTICE');" maxlength="200" />
                  </div>
                </div>
                <div class="col-md-12 py-2">
                  <div class="row">
                    <div class="col-md-6 col-sm-6 fs-13 pt-2 mb-2">
                      Already have an account? <a href="javascript:void(0)"
                                                  (click)="this.openLoginForm()">Click here to Login</a>
                    </div>
                    <div class="col-md-6 col-sm-6 text-right">
                      <button type="button" class="btn btn-default px-3" (click)="type='COMMUNICATION'">
                        <i class="fas fa-arrow-left"></i> &nbsp;Back
                      </button>&nbsp;
                      <button type="button" class="btn btn-default px-3" (click)="validations('ACCOUNT_CREATION');"
                              [disabled]="!this.isStep3Valid">
                        Next
                        &nbsp;<i class="fas fa-arrow-right float-right mt-1"></i>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tab4" [ngClass]="{'show active':(type=='ACCOUNT_CREATION')}" role="tabpanel"
             aria-labelledby="tabcontent-4">
          <div class="registaerForm-fields">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 text-center pb-2">
                  <div class="title-lg">Account Creation</div>
                  <p class="fs-14 py-2">Please enter Email ID, User ID and password to access the portal. Please note User ID cannot be changed once account is created. We will initiate verification process to the registered Email ID specified below.</p>
                  <p class="text-right text-secondary fs-12 float-right mandatory">Required Fields.</p>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel mandatory form-control-placeholder" for="eu">Registered Email ID</label>
                    <input type="text" id="eu" class="form-control" required="" (blur)="handleInput($event.target,'REGISTERED_EMAIL_ID');"
                           formControlName="REGISTERED_EMAIL_ID" maxlength="100" autocomplete="off"
                           [ngClass]="{ 'is-invalid': (form.REGISTERED_EMAIL_ID.dirty || form.REGISTERED_EMAIL_ID.touched || ACCOUNT_CREATION_Next) && form.REGISTERED_EMAIL_ID.errors }" />
                    <div *ngIf="(form.REGISTERED_EMAIL_ID.dirty || form.REGISTERED_EMAIL_ID.touched || ACCOUNT_CREATION_Next) && form.REGISTERED_EMAIL_ID.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.REGISTERED_EMAIL_ID.errors?.['required']">
                        Email ID is required.
                      </div>
                    </div>
                    <div *ngIf="!form.REGISTERED_EMAIL_ID.errors?.['required'] && (form.REGISTERED_EMAIL_ID.dirty || form.REGISTERED_EMAIL_ID.touched || ACCOUNT_CREATION_Next)" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="invalidRegisteredEmail || form.REGISTERED_EMAIL_ID.errors?.['email']">
                        Please enter valid Email.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel mandatory form-control-placeholder" for="userId">User ID</label>
                    <input type="text" id="userId" class="form-control" required=""
                           (blur)="handleInput($event.target,'USER_ID');" formControlName="USER_ID" maxlength="100" autocomplete="off"
                           [ngClass]="{ 'is-invalid': (form.USER_ID.dirty || form.USER_ID.touched || ACCOUNT_CREATION_Next) && form.USER_ID.errors }" />
                    <div *ngIf="(form.USER_ID.dirty || form.USER_ID.touched || ACCOUNT_CREATION_Next) && form.USER_ID.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.USER_ID.errors?.['required']">
                        User ID is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel mandatory form-control-placeholder" for="pwd1">Password</label>
                    <input type="password" id="pwd1" class="form-control" required="" formControlName="PASSWORD" autocomplete="off"
                           maxlength="100" noSpaces PWDSpecailChars
                           [ngClass]="{ 'is-invalid': (form.PASSWORD.dirty || form.PASSWORD.touched || ACCOUNT_CREATION_Next) && form.PASSWORD.errors }" />
                    <div *ngIf="(form.PASSWORD.dirty || form.PASSWORD.touched || ACCOUNT_CREATION_Next) && form.PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.PASSWORD.errors?.['required']">
                        Password is required.
                      </div>
                      <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['number']">
                        Password should contain at least {{passwordPolicy.MIN_NUMERIC_CHARS}} number
                      </div>
                      <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['upper']">
                        Password should contain at least {{passwordPolicy.MIN_UPPERCASE_CHARS}} uppercase letter
                      </div>
                      <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['lower']">
                        Password should contain at least {{passwordPolicy.MIN_LOWER_CHARS}} lowercase letter
                      </div>
                      <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['special']">
                        Password should contain at least {{passwordPolicy.MIN_SPECAIL_CHARS}} special letter
                      </div>
                      <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['minlength']">
                        Password should contain minimum of {{passwordPolicy.MIN_PASSWORD_LENGTH}} characters
                      </div>
                      <div *ngIf="!form.PASSWORD.errors?.['required'] && form.PASSWORD.errors?.['pattern']">
                        Password doesn't meet the policy requirement. Please check password policy.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="fieldLabel mandatory form-control-placeholder" for="cpwd">Confirm Password</label>
                    <input type="password" id="cpwd" class="form-control" required="" formControlName="CONFIRM_PASSWORD" autocomplete="off"
                           maxlength="100" noSpaces PWDSpecailChars
                           [ngClass]="{ 'is-invalid': (form.CONFIRM_PASSWORD.dirty || form.CONFIRM_PASSWORD.touched || ACCOUNT_CREATION_Next) && form.CONFIRM_PASSWORD.errors }" />
                    <div *ngIf="(form.CONFIRM_PASSWORD.dirty || form.CONFIRM_PASSWORD.touched || ACCOUNT_CREATION_Next) && form.CONFIRM_PASSWORD.invalid" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.CONFIRM_PASSWORD.errors?.['required']">
                        Confirm Password is required.
                      </div>
                      <div *ngIf="!form.CONFIRM_PASSWORD.errors?.['required'] && form.CONFIRM_PASSWORD.errors?.mustMatch">
                        Password and Confirm password should match
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <p class="fs-13 py-2">
                    Please note that your password should meet our
                    <a href="javascript:void(0)" (click)="this.openPasswordPolicy()">Password Policy. &nbsp;<i class="fas fs-16 fa-info-circle"></i></a>
                  </p>

                </div>
                <!-- <div class="col-md-4 col-sm-6">
                  <div class="form-group">
                    <label for="" class="fieldLabel requiredfild mandatory">&nbsp;Enter Captcha</label>
                    <div class="captcha-container {{ config.cssClass}}">
                      <canvas id="captcahCanvas" width="300" height="80"></canvas>
                      <div class="captcha-actions">
                        <input type="text" formControlName="captch_input" style="width: 291px;" />
                        <a href="javascript:void(0)" class="cpt-btn reload" (click)="createCaptcha()">&#x21bb;</a>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="col-md-12 py-2">
                  <div class="row">
                    <div class="col-md-12 col-lg-6 fs-13 py-2 mb-2">
                      Already have an account? <a href="javascript:void(0)"
                                                  (click)="this.openLoginForm()">Click here to Login</a>
                    </div>
                    <div class="col-md-12 col-lg-6 text-right">
                      <button type="button" class="btn btn-default px-3" (click)="type='YOUR_PRACTICE'">
                        <i class="fas fa-arrow-left"></i> &nbsp;Back
                      </button>&nbsp;
                      <button type="button" class="btn btn-default px-3"
                              (click)="validations('');" [disabled]="!this.isStep4Valid">
                        Review and Submit
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
</div>
