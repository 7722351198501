import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { HomeService } from 'src/app/_services/home.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {
  platformId: any;
  CurrentTags: any;
  constructor(private alertifyService: AlertifyService,
    public utilityService: UtilityService,
    public authService: AuthenticationService,
    private route: Router,
    private homeService: HomeService,
    private titleService: Title, private metaTagService: Meta,
    @Inject(PLATFORM_ID) platformId: any) {
    this.platformId = platformId;
    this.setSEOTags();
  }

  ngOnInit(): void {
    this.homeService.setRouterUrl(this.route.url);
    this.authService.setCurrentFeatureCode("RESO");
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }

    if (this.authService.isCookie()) {
      this.authService.cookieTracking("RESO").subscribe();
    }
  }

  openConfirmationPopup(redirectURL: string) {
    let title = "You are about to leave " + this.utilityService.getPortalName() + " Med-info Portal";
    let message = "By clicking Continue, you will be taken to a website that is independent from " + this.utilityService.getPortalName() + ". The site you are linking to is not controlled or endorsed by " + this.utilityService.getPortalName() + " and is not responsible for its content.";
    this.alertifyService.ExternalLinkConfirmationBanner(title, message, redirectURL, this.ConfimationCallBack, "Confirm");
  }

  ConfimationCallBack = (result: any, url: any) => {
    if (result) {
      if (isPlatformBrowser(this.platformId)) {
        window.open(url, "_blank");
      }
    }
  }


  setSEOTags() {
    this.CurrentTags = this.utilityService.getCurrentPageTags("RESO");
    let title = "";
    (this.CurrentTags == undefined || (this.CurrentTags?.Title == "")) ? title = "Resources" : title = this.CurrentTags.Title;
    this.titleService.setTitle(title);
    if (this.CurrentTags != null && this.CurrentTags != undefined) {
      this.metaTagService.updateTag({ name: 'description', content: this.CurrentTags.MetaDescription });
      this.metaTagService.updateTag({ name: 'title', content: this.CurrentTags.MetaTitle });
    }
    else {
      this.metaTagService.updateTag({ name: 'title', content: "" });
      this.metaTagService.updateTag({ name: 'description', content: "" });
    }
  }

}
