<div class="tradeName position-relative">
  <input class="form-control" id="homeproducta" name="homeproducta" [(ngModel)]="inputSelectedProduct"
         [disabled]="disabled"
         #typeahead=bs-typeahead
         placeholder="-Select-"
         [typeaheadAsync]="true"
         [typeahead]="productDataSource$"
         typeaheadOptionField="PRODUCT_DISPLAY_NAME"
         [typeaheadMinLength]="typeaheadMinLength"
         [typeaheadSingleWords]="true"
         [typeaheadScrollable]="true"
         [typeaheadSelectFirstItem]="false"
         [typeaheadIsFirstItemActive]="false"
         [typeaheadOptionsInScrollableView]="5"
         [typeaheadOptionsLimit]="1000"
         [typeaheadWaitMs]="500"
         [typeaheadHideResultsOnBlur]="typeaheadHideResultsOnBlur"
         (productTypeaheadLoading)="changeProductTypeaheadLoading($event)"
         (focusout)="productTypeaheadOnBlur($event)"
         (typeaheadOnSelect)="onProductSelect($event)"
         (typeaheadNoResults)="productTypeaheadNoResults($event)"
         autocomplete="off"
         maxlength="150"
         (ngModelChange)="onProductChange($event)"
         [typeaheadItemTemplate]="template" />

  <ng-template #template let-match="match" let-query="query">
    <div [innerHtml]="fixedHighlight(match, query)"></div>
  </ng-template>

  <span class="pushaheadfield"><i class="fas fa-long-arrow-alt-down"></i></span>
  <div *ngIf="productTypeaheadLoading===true">Loading</div>
</div>
