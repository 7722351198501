import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { ACCESSTYPES, PortalConfigErrors, RegistrationErrors } from 'src/app/_enums/constant-types.enum';
import { PasswordValidator } from 'src/app/_helpers/custom-password-validators';
import { ComparePassword } from 'src/app/_helpers/customvalidators';
import { CountryAutoComplete } from 'src/app/_models/countries';
import { CP_VW_LOOKUPS } from 'src/app/_models/cpvw-lookups';
import { Occupation, PreferredContact } from 'src/app/_models/msl';
import { PASSWORD_POLICY } from 'src/app/_models/password-policy';
import { RegisterResponse, VerificationPopupInput } from 'src/app/_models/register-response';
import { User, UserLoginResult } from 'src/app/_models/user';
import { ZipCode } from 'src/app/_models/zipcode';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SystemConfigService } from 'src/app/_services/system-config.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { PortalProfiles } from '../../../_models/portals';
import { HomeService } from '../../../_services/home.service';
import { AccountVerificationComponent } from '../../account-verification/account-verification.component';
import { ZipcodeSelectionComponent } from '../../Common/zipcode-selection/zipcode-selection.component';
import { LoginComponent } from '../../login/login.component';
import { PasswordPolicyComponent } from '../../password-policy/password-policy.component';

@Component({
  selector: 'app-submit-review',
  templateUrl: './submit-review.component.html',
  styleUrls: ['./submit-review.component.css']
})
export class SubmitReviewComponent implements OnInit {
  public userData: User;
  public onClose: Subject<boolean>;
  config: any = '';
  bsConfig: any;
  SubmitRegisterForm: FormGroup;
  //allCountries: COUNTRIES[] = [];
  allCountries: CountryAutoComplete[] = [];
  preferedContacts: PreferredContact[] = [];
  //occupationList: Occupation[] = [];
  occupationList: CP_VW_LOOKUPS[] = [];
  specialityList: CP_VW_LOOKUPS[] = [];
  primaryDegree: CP_VW_LOOKUPS[] = [];
  passwordPolicy: PASSWORD_POLICY = {};
  isTellUsWhoYouAreDisabled: boolean = true;
  isCommunicationPreferenceDisabled: boolean = true;
  isTellUsAboutYourPracticeDisabled: boolean = true;
  isAccountCreationDisabled: boolean = true;
  private subscribeDisplayCountries: Subscription | null = null;
  selectedCountry: string | '' = '';
  invalidPreferredEmail = false;
  invalidRegisteredEmail = false;
  emailIdAlreadyExists = false;
  duplicateUserId = false;
  duplicateLicense = false;
  registeredAlreadyVerified = false;
  registeredNotVerified = false;
  registeredDeactivated = false;
  pattern: string;
  minDate: Date = new Date();

  isPhoneMandatory: boolean = false;
  isEmailMandatory: boolean = false;

  constructor(private configService: SystemConfigService,
    private authService: AuthenticationService,
    public utilityService: UtilityService,
    private alertfyService: AlertifyService,
    private formBuilder: FormBuilder,
    public modalRef: BsModalRef,
    public passwordPolicyModal: BsModalRef,
    private modalService: BsModalService,
    public zipCodeModal: BsModalRef,
    public options: ModalOptions,
    private homeService: HomeService) {
    this.minDate.setFullYear(this.minDate.getFullYear() - 16);
  }

  ngOnInit(): void {
    this.userData = this.options.initialState as any as User;

    this.setFormGroup();
    this.getAllCountries();
    this.loadLookUps();
    this.getPasswordPolicy();
  }

  get form() {
    return this.SubmitRegisterForm.controls;
  }

  //get minDate()
  //{
  //  let date: Date = new Date();
  //  date.setFullYear( date.getFullYear() - 16 );
  //  return date;
  //}

  setFormGroup() {
    this.bsConfig = Object.assign({}, {
      containerClass: "theme-blue",
      dateInputFormat: 'DD-MMM-YYYY',
      showWeekNumbers: false,
      maxDate: this.minDate
    });
    this.config = {
      type: 1,
      length: 6,
      cssClass: 'custom',
      back: {
        stroke: "#2F9688",
        solid: "#f2efd2"
      },
      font: {
        color: "#000000",
        size: "35px"
      }
    };
    this.SubmitRegisterForm = this.formBuilder.group({
      FIRST_NAME: new FormControl({ value: this.userData.FIRST_NAME, disabled: this.isTellUsWhoYouAreDisabled }, Validators.required),
      LAST_NAME: new FormControl({ value: this.userData.LAST_NAME, disabled: this.isTellUsWhoYouAreDisabled }, Validators.required),
      DATE_OF_BIRTH: new FormControl({ value: this.userData.DATE_OF_BIRTH, disabled: this.isTellUsWhoYouAreDisabled }, [Validators.required, this.AgeValidator]),
      ZIP_CODE: new FormControl({ value: this.userData.ZIP_CODE, disabled: this.isTellUsWhoYouAreDisabled }, Validators.required),
      FK_COUNTRY_ID: new FormControl({ value: this.userData.FK_COUNTRY_ID, disabled: this.isTellUsWhoYouAreDisabled }, Validators.required),
      CITY: new FormControl({ value: this.userData.CITY, disabled: this.isTellUsWhoYouAreDisabled }),
      STATE_PROVINCE: new FormControl({ value: this.userData.STATE_PROVINCE, disabled: this.isTellUsWhoYouAreDisabled }),
      ADDRESS: new FormControl({ value: this.userData.ADDRESS, disabled: this.isTellUsWhoYouAreDisabled }),
      PHONE: new FormControl({ value: this.userData.PHONE, disabled: this.isCommunicationPreferenceDisabled }, [Validators.required, Validators.pattern('[+()-_0-9]*')]),
      PHONE_EXT: new FormControl({ value: this.userData.PHONE_EXT, disabled: this.isCommunicationPreferenceDisabled }, [Validators.pattern('[0-9]*')]),
      FK_PREFERRED_CONTACT_ID: new FormControl({ value: this.userData.FK_PREFERRED_CONTACT_ID, disabled: this.isCommunicationPreferenceDisabled }, Validators.required),
      PREFERED_EMAIL: new FormControl({ value: this.userData.PREFERED_EMAIL, disabled: this.isCommunicationPreferenceDisabled }),
      PREFERED_PHONE: new FormControl({ value: this.userData.PREFERED_PHONE, disabled: this.isCommunicationPreferenceDisabled }),
      PREFERED_PHONE_EXT: new FormControl({ value: this.userData.PREFERED_PHONE_EXT, disabled: this.isCommunicationPreferenceDisabled }),
      HAS_RECEIVE_EMAILS: new FormControl({ value: this.userData.HAS_RECEIVE_EMAILS, disabled: true }),
      HAS_ALLOW_CALLS: new FormControl({ value: this.userData.HAS_ALLOW_CALLS, disabled: true }),
      FK_PROFESSION_ID: new FormControl({ value: this.userData.FK_PROFESSION_ID, disabled: this.isTellUsAboutYourPracticeDisabled }, Validators.required),
      FK_SPECAILITY_ID: new FormControl({ value: this.userData.FK_SPECAILITY_ID, disabled: this.isTellUsAboutYourPracticeDisabled }, Validators.required),
      LICENCE_NUMBER: new FormControl({ value: this.userData.LICENCE_NUMBER, disabled: this.isTellUsAboutYourPracticeDisabled }, Validators.required),
      PRIMARY_PRACTICE: new FormControl({ value: this.userData.PRIMARY_PRACTICE, disabled: this.isTellUsAboutYourPracticeDisabled }),
      REGISTERED_EMAIL_ID: new FormControl({ value: this.userData.REGISTERED_EMAIL_ID, disabled: this.isAccountCreationDisabled }, [Validators.required, Validators.email]),
      USER_ID: new FormControl({ value: this.userData.USER_ID, disabled: this.isAccountCreationDisabled }, Validators.required),
      PASSWORD: new FormControl({ value: this.userData.PASSWORD, disabled: this.isAccountCreationDisabled }, Validators.required),
      CONFIRM_PASSWORD: new FormControl({ value: this.userData.CONFIRM_PASSWORD, disabled: this.isAccountCreationDisabled }, [Validators.required]),
      PrimaryDegree: new FormControl({ value: this.userData.FK_PRIMARY_DEGREE_ID, disabled: this.isTellUsWhoYouAreDisabled }, Validators.required),
    },
      {
        // Used custom form validator name
        validator: ComparePassword("PASSWORD", "CONFIRM_PASSWORD")
      });

    this.onChanges();
  }

  onChanges() {
    const contactPreferenceControl = <FormControl>this.SubmitRegisterForm.get('FK_PREFERRED_CONTACT_ID');
    const preferredEmailControl = <FormControl>this.SubmitRegisterForm.get('PREFERED_EMAIL');
    const preferredPhoneControl = <FormControl>this.SubmitRegisterForm.get('PREFERED_PHONE');
    const otherPhoneControl = <FormControl>this.SubmitRegisterForm.get('PHONE');
    const zipCodeControl = <FormControl>this.SubmitRegisterForm.get('ZIP_CODE');


    setTimeout(() => {
      if (contactPreferenceControl.value) {
        this.invalidPreferredEmail = false;
        let selectedValue = this.preferedContacts.find(x => x.Id == this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"].value);
        if (selectedValue && selectedValue.ContactCode == "EMAIL") {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = true;
          this.SubmitRegisterForm.get('PREFERED_EMAIL')?.setValidators([Validators.required, Validators.email]);
          this.SubmitRegisterForm.get('PREFERED_PHONE')?.setValidators([Validators.pattern(/[^0-9()-]*/g)]);
        }
        else if (selectedValue && selectedValue.ContactCode == "PHONE") {
          this.isPhoneMandatory = true;
          this.isEmailMandatory = false;
          this.SubmitRegisterForm.get('PREFERED_PHONE')?.setValidators([Validators.required, Validators.pattern(/[^0-9()-]*/g)]);
          this.SubmitRegisterForm.get('PREFERED_EMAIL')?.setValidators([Validators.email]);
        }
        else {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = false;
        }
      }
    }, 100);

    preferredEmailControl.valueChanges
      .subscribe(value => {
        if (value == undefined || value == null || value == "") {
          this.SubmitRegisterForm.controls["HAS_RECEIVE_EMAILS"]?.disable();
          this.SubmitRegisterForm.controls["HAS_RECEIVE_EMAILS"].setValue(false);
        }
        else {
          this.SubmitRegisterForm.controls["HAS_RECEIVE_EMAILS"]?.enable();
        }
      });

    preferredPhoneControl.valueChanges
      .subscribe(value => {
        if ((value == undefined || value == null || value == "")
          && (otherPhoneControl.value == undefined || otherPhoneControl.value == null || otherPhoneControl.value == "")) {
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"]?.disable();
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"].setValue(false);
        }
        else {
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"]?.enable();
        }
      });

    otherPhoneControl.valueChanges
      .subscribe(value => {
        if ((value == undefined || value == null || value == "")
          && (preferredPhoneControl.value == undefined || preferredPhoneControl.value == null || preferredPhoneControl.value == "")) {
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"]?.disable();
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"].setValue(false);
        }
        else {
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"]?.enable();
        }
      });


    contactPreferenceControl.valueChanges
      .subscribe(value => {
        this.preferredContactValidations();
      });

    zipCodeControl.valueChanges
      .subscribe(value => {
        let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].value);
        if (country?.CountryCode == 'US') {
          if (value.length > 4) {
            this.subscribeDisplayCountries = this.configService.getZipCodes(value).subscribe({
              next:(zipCodes) => {
              if (zipCodes.length == 1) {
                this.setCountryWithZipCode(zipCodes[0]);
              }
              else if (zipCodes.length > 1) {
                this.SubmitRegisterForm.controls["CITY"].setValue("");
                this.SubmitRegisterForm.controls["STATE_PROVINCE"].setValue("");
                this.openMultipleZipCodePopup(zipCodes);
              }
            },
              error:(err) => {
                console.error(err);
              }});
          }
        }
      });
  }

  preferredContactValidations() {
    const preferredEmailControl = <FormControl>this.SubmitRegisterForm.get('PREFERED_EMAIL');
    const preferredPhoneControl = <FormControl>this.SubmitRegisterForm.get('PREFERED_PHONE');
    this.invalidPreferredEmail = false;
    let selectedValue = this.preferedContacts.find(x => x.Id == this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"].value);
    if (selectedValue != undefined && selectedValue.ContactCode == "EMAIL") {
      this.isPhoneMandatory = false;
      this.isEmailMandatory = true;
      this.SubmitRegisterForm.get('PREFERED_EMAIL')?.setValidators([Validators.required, Validators.email]);
      this.SubmitRegisterForm.get('PREFERED_PHONE')?.setValidators([Validators.pattern(/[^0-9()-]*/g)]);
    }
    else if (selectedValue != undefined && selectedValue.ContactCode == "PHONE") {
      this.isPhoneMandatory = true;
      this.isEmailMandatory = false;
      this.SubmitRegisterForm.get('PREFERED_PHONE')?.setValidators([Validators.required, Validators.pattern(/[^0-9()-]*/g)]);
      this.SubmitRegisterForm.get('PREFERED_EMAIL')?.setValidators([Validators.email]);
    }
    else {
      this.isPhoneMandatory = false;
      this.isEmailMandatory = false;
      this.SubmitRegisterForm.get('PREFERED_PHONE')?.clearValidators();
      this.SubmitRegisterForm.get('PREFERED_EMAIL')?.clearValidators();
    }
    preferredEmailControl.updateValueAndValidity();
    preferredPhoneControl.updateValueAndValidity();
  }


  openMultipleZipCodePopup(zipCodes: ZipCode[]) {
    const config: ModalOptions = {
      initialState: <any>zipCodes,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false,
    };

    this.zipCodeModal = this.modalService.show(ZipcodeSelectionComponent, config);
    this.zipCodeModal.content.closeBtnName = 'Close';
    this.zipCodeModal.content.event.subscribe((res: ZipCode) => {
      this.setCountryWithZipCode(res);
    });
  }

  setCountryWithZipCode(zipcode: ZipCode) {
    this.SubmitRegisterForm.controls["CITY"].setValue(zipcode.CITY);
    this.SubmitRegisterForm.controls["STATE_PROVINCE"].setValue(zipcode.CITYSTATE);
    let country = this.allCountries.find(c => c.CountryCode == zipcode.COUNTRYCODE);
    this.selectedCountry = country?.CountryDisplayName ?? '';
    this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].setValue(country?.PKCountryId);
  }

  loadLookUps() {
    this.getPreferedContacts();
    this.getOccupations();
    this.getSpeciality();
    this.getPrimaryDegree();
  }

  getAllCountries() {
    this.subscribeDisplayCountries = this.configService.getCountriesTypeHeadData().subscribe({
      next:(data) => {
      this.allCountries = data;
      let user = this.options.initialState as any as User;
      let country = this.allCountries.find(c => c.PKCountryId == user.FK_COUNTRY_ID);
      if (country != undefined) {
        this.selectedCountry = country.CountryDisplayName;
      }
    },
      error:(err) => {
        console.error(err);
      }});
  }

  getPasswordPolicy() {
    this.authService.getPasswordPolicy().subscribe({
      next:(data) => {
      this.passwordPolicy = data;
      this.pattern = this.utilityService.getPasswordPolicyPattern(this.passwordPolicy);

      this.setPasswordValidations();
    },
      error:(err)=> {
        console.error(err);
      }});
  }

  setPasswordValidations() {
    PasswordValidator.minUpperCase = this.passwordPolicy.MIN_UPPERCASE_CHARS ?? 0;
    PasswordValidator.minSpecialChars = this.passwordPolicy.MIN_SPECAIL_CHARS ?? 0;
    PasswordValidator.minNumbers = this.passwordPolicy.MIN_NUMERIC_CHARS ?? 0;

    const passwordValidations = [
      Validators.required,
      Validators.pattern(this.pattern),
      Validators.minLength(this.passwordPolicy.MIN_PASSWORD_LENGTH ?? 0),
      PasswordValidator.number,
      PasswordValidator.special,
      PasswordValidator.upper,];

    this.SubmitRegisterForm.controls['PASSWORD'].setValidators(passwordValidations);
  }

  getPreferedContacts() {
    this.configService.getPreferredContact().subscribe({
      next:(result) => {
      //this.ContactsList = next;
      this.preferedContacts = result;
    },
      error:(err) => {
        console.error(err);
      }});
  }

  getOccupations() {
    let profiletype_id = "";
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo) {
      profiletype_id = profileinfo.PK_ENV_PROFILE_ID ?? '';
    }
    if (profiletype_id) {
      this.configService.getProfessionByProfile(profiletype_id).subscribe({
        next:(result) => {
        this.occupationList = result;
      },
        error:(err) => {
          console.error(err);
        }});
    }
  }

  getSpeciality() {
    this.configService.getLookUps('SPECIALITY').subscribe({
      next:(data) => {
      this.specialityList = data;
    },
      error:(err) => {
        console.error(err);
      }});
  }

  enableTellUsWhoYouAre() {
    this.isTellUsWhoYouAreDisabled = false;
    this.SubmitRegisterForm.controls["FIRST_NAME"]?.enable();
    this.SubmitRegisterForm.controls["LAST_NAME"]?.enable();
    this.SubmitRegisterForm.controls["DATE_OF_BIRTH"].enable();
    this.SubmitRegisterForm.controls["ZIP_CODE"].enable({ emitEvent: false });
    this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].enable();
    this.SubmitRegisterForm.controls["CITY"]?.enable();
    this.SubmitRegisterForm.controls["STATE_PROVINCE"]?.enable();
    this.SubmitRegisterForm.controls["ADDRESS"]?.enable();
    this.SubmitRegisterForm.controls["PrimaryDegree"]?.enable();
  }

  enableCommunicationPreference() {
    this.isCommunicationPreferenceDisabled = false;
    this.SubmitRegisterForm.controls["PHONE"]?.enable();
    this.SubmitRegisterForm.controls["PHONE_EXT"]?.enable();
    this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"]?.enable();
    this.SubmitRegisterForm.controls["PREFERED_EMAIL"]?.enable();
    this.SubmitRegisterForm.controls["PREFERED_PHONE"]?.enable();
    this.SubmitRegisterForm.controls["PREFERED_PHONE_EXT"]?.enable();
    this.SubmitRegisterForm.controls["HAS_RECEIVE_EMAILS"]?.enable();
    this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"]?.enable();
  }

  enableTellUsAboutYourPractice() {
    this.isTellUsAboutYourPracticeDisabled = false;
    this.SubmitRegisterForm.controls["FK_PROFESSION_ID"]?.enable();
    this.SubmitRegisterForm.controls["FK_SPECAILITY_ID"]?.enable();
    this.SubmitRegisterForm.controls["LICENCE_NUMBER"]?.enable();
    this.SubmitRegisterForm.controls["PRIMARY_PRACTICE"]?.enable();
  }

  enableAccountCreation() {
    this.isAccountCreationDisabled = false;
    this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"]?.enable();
    this.SubmitRegisterForm.controls["USER_ID"]?.enable();
    this.SubmitRegisterForm.controls["PASSWORD"]?.enable();
    this.SubmitRegisterForm.controls["CONFIRM_PASSWORD"]?.enable();
  }

  AgeValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value != "Invalid Date") {
      let timeDiff = Date.now() - control.value.getTime();
      let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
      if (age < 16) {
        return { 'age': true };
      }
    }
    return null;
  }

  Submit() {
    this.emailIdAlreadyExists = false;
    this.duplicateUserId = false;
    this.duplicateLicense = false;
    this.registeredNotVerified = false;
    this.registeredDeactivated = false;
    if (this.validateForm()) {
      let user: User = <User>{
        FIRST_NAME: this.SubmitRegisterForm.controls["FIRST_NAME"].value,
        LAST_NAME: this.SubmitRegisterForm.controls["LAST_NAME"].value,
        DATE_OF_BIRTH: this.SubmitRegisterForm.controls["DATE_OF_BIRTH"].value,
        FK_COUNTRY_ID: this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].value,
        ZIP_CODE: this.SubmitRegisterForm.controls["ZIP_CODE"].value,
        STATE_PROVINCE: this.SubmitRegisterForm.controls["STATE_PROVINCE"].value,
        CITY: this.SubmitRegisterForm.controls["CITY"].value,
        ADDRESS: this.SubmitRegisterForm.controls["ADDRESS"].value,

        FK_PREFERRED_CONTACT_ID: this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"].value,
        PREFERED_EMAIL: this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value,
        PREFERED_PHONE: this.SubmitRegisterForm.controls["PREFERED_PHONE"].value,
        PREFERED_PHONE_EXT: this.SubmitRegisterForm.controls["PREFERED_PHONE_EXT"].value,
        PHONE: this.SubmitRegisterForm.controls["PHONE"].value,
        PHONE_EXT: this.SubmitRegisterForm.controls["PHONE_EXT"].value,
        HAS_RECEIVE_EMAILS: this.SubmitRegisterForm.controls["HAS_RECEIVE_EMAILS"].value,
        HAS_ALLOW_CALLS: this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"].value,

        FK_PROFESSION_ID: this.SubmitRegisterForm.controls["FK_PROFESSION_ID"].value,
        FK_SPECAILITY_ID: this.SubmitRegisterForm.controls["FK_SPECAILITY_ID"].value,
        LICENCE_NUMBER: this.SubmitRegisterForm.controls["LICENCE_NUMBER"].value,
        PRIMARY_PRACTICE: this.SubmitRegisterForm.controls["PRIMARY_PRACTICE"].value,

        REGISTERED_EMAIL_ID: this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value,
        USER_ID: this.SubmitRegisterForm.controls["USER_ID"].value,
        PASSWORD: this.SubmitRegisterForm.controls["PASSWORD"].value,
        CONFIRM_PASSWORD: this.SubmitRegisterForm.controls["CONFIRM_PASSWORD"].value,
        USER_TYPE: 'HCP',
        FK_PRIMARY_DEGREE_ID: this.SubmitRegisterForm.controls["PrimaryDegree"].value
      };
      let profileinfo: PortalProfiles = {};
      profileinfo = this.utilityService.getSelectedProfile();
      if (profileinfo) {
        user.FK_ENV_PROFILE_ID = profileinfo.PK_ENV_PROFILE_ID ?? '';
        user.ACCESS_TYPE_CODE = profileinfo.ACCESS_TYPE_CODE;
      }
      this.authService.userRegister(user).subscribe({
        next: (result)=> {
        if (result.Success) {
          this.openVerificationPopup(result);
        }
        else {
          if (result.Errors.length > 0) {
            result.Errors.forEach(error => {
              switch (error) {
                case RegistrationErrors.UserIdExists:
                  this.enableAccountCreation();
                  this.duplicateUserId = true;
                  break;
                case RegistrationErrors.LicenseNumberExists:
                  this.enableTellUsAboutYourPractice();
                  this.duplicateLicense = true;
                  break;
                case RegistrationErrors.RegisteredAlreadyVerified:
                  this.registeredAlreadyVerified = true;
                  break;
                case RegistrationErrors.RegisteredNotVerified:
                  this.registeredNotVerified = true;
                  break;
                case RegistrationErrors.RegisteredDeactivated:
                  this.registeredDeactivated = true;
                  break;
                case PortalConfigErrors.PORTAL_NOT_FOUND, PortalConfigErrors.AUTH_NOT_CONFIG:
                  this.alertfyService.errorBaner("Oops! Please try after sometime.")
                  break;
              }
            });
          }

          if (this.registeredAlreadyVerified || this.registeredNotVerified || this.registeredDeactivated) {
            this.openVerificationPopup(result);
          }
        }
      },
        error:(err) => {
          console.error(err);
        }});
    }
  }

  formControlValid(controlName: string): boolean {
    let isValid = true;
    if ((this.SubmitRegisterForm.controls[controlName].value != null
      && this.SubmitRegisterForm.controls[controlName].value.trim() == "")
      || (!this.SubmitRegisterForm.controls[controlName].value)) {
      isValid = false;
    }
    return isValid;
  }

  validateForm() {
    let IsValid = true;
    this.invalidRegisteredEmail = false;

    if (!this.formControlValid("FIRST_NAME")) {
      IsValid = false;
    }
    if (!this.formControlValid("LAST_NAME")) {
      IsValid = false;
    }

    if (this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value && !this.utilityService.validateEmail(this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value)) {
      this.invalidPreferredEmail = true;
      IsValid = false;
    }

    if (this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value && this.SubmitRegisterForm.controls["PREFERED_EMAIL"].errors?.email) {
      this.invalidPreferredEmail = true;
      IsValid = false;
    }

    if (this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value && !this.utilityService.validateEmail(this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value)) {
      this.invalidRegisteredEmail = true;
      IsValid = false;
    }
    if (this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value && this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].errors?.email) {
      this.invalidRegisteredEmail = true;
      IsValid = false;
    }

    if ((this.SubmitRegisterForm.controls["DATE_OF_BIRTH"].value == null ||
      this.SubmitRegisterForm.controls["DATE_OF_BIRTH"].value == "") ||
      (!this.isTellUsWhoYouAreDisabled && !this.SubmitRegisterForm.controls["DATE_OF_BIRTH"].valid)) {
      IsValid = false;
    }
    if ((this.SubmitRegisterForm.controls["FK_COUNTRY_ID"] == null ||
      this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].value == "") ||
      (!this.isTellUsWhoYouAreDisabled && !this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].valid)) {
      IsValid = false;
    }

    if ((this.SubmitRegisterForm.controls["PrimaryDegree"] == null ||
      this.SubmitRegisterForm.controls["PrimaryDegree"].value == "") ||
      (!this.isTellUsWhoYouAreDisabled && !this.SubmitRegisterForm.controls["PrimaryDegree"].valid)) {
      IsValid = false;
    }

    if ((this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"] == null ||
      this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"].value == "") ||
      (!this.isCommunicationPreferenceDisabled && !this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"].valid)) {
      IsValid = false;
    }
    else {
      let selectedValue = this.preferedContacts.find(x => x.Id == this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"].value);
      if (selectedValue != undefined &&
        selectedValue.ContactCode == "EMAIL") {
        if (!this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value) {
          IsValid = false;
        }
        else if (!this.utilityService.validateEmail(this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value)) {
          this.invalidPreferredEmail = true;
          IsValid = false;
        }
      }
      else if (selectedValue != undefined &&
        selectedValue.ContactCode == "PHONE") {
        if (!this.SubmitRegisterForm.controls["PREFERED_PHONE"].value) {
          IsValid = false;
        }
        if (this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value &&
          !this.utilityService.validateEmail(this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value)) {
          this.invalidPreferredEmail = true;
          IsValid = false;
        }
      }
    }

    if ((this.SubmitRegisterForm.controls["FK_PROFESSION_ID"] == null ||
      this.SubmitRegisterForm.controls["FK_PROFESSION_ID"].value == "") ||
      (!this.isTellUsAboutYourPracticeDisabled && !this.SubmitRegisterForm.controls["FK_PROFESSION_ID"].valid)) {
      IsValid = false;
    }
    if ((this.SubmitRegisterForm.controls["FK_SPECAILITY_ID"] == null ||
      this.SubmitRegisterForm.controls["FK_SPECAILITY_ID"].value == "") ||
      (!this.isTellUsAboutYourPracticeDisabled && !this.SubmitRegisterForm.controls["FK_SPECAILITY_ID"].valid)) {
      IsValid = false;
    }
    if ((this.SubmitRegisterForm.controls["LICENCE_NUMBER"] == null ||
      this.SubmitRegisterForm.controls["LICENCE_NUMBER"].value == "") ||
      (!this.isTellUsAboutYourPracticeDisabled && !this.SubmitRegisterForm.controls["LICENCE_NUMBER"].valid)) {
      IsValid = false;
    }

    if (this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"] == null ||
      this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value == "") {
      IsValid = false;
    }
    else if (!this.utilityService.validateEmail(this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value)) {
      this.invalidRegisteredEmail = true;
      IsValid = false;
    }

    if ((this.SubmitRegisterForm.controls["USER_ID"] == null ||
      this.SubmitRegisterForm.controls["USER_ID"].value == "") ||
      (!this.isAccountCreationDisabled && !this.SubmitRegisterForm.controls["USER_ID"].valid)) {
      IsValid = false;
    }
    if ((this.SubmitRegisterForm.controls["PASSWORD"] == null ||
      this.SubmitRegisterForm.controls["PASSWORD"].value == "") ||
      (!this.isAccountCreationDisabled && !this.SubmitRegisterForm.controls["PASSWORD"].valid)) {
      IsValid = false;
    }
    if (this.SubmitRegisterForm.controls["CONFIRM_PASSWORD"] != null &&
      this.SubmitRegisterForm.controls["CONFIRM_PASSWORD"].value != "" &&
      (this.isAccountCreationDisabled || this.SubmitRegisterForm.controls["CONFIRM_PASSWORD"].valid)) {
      if (this.SubmitRegisterForm.controls["PASSWORD"].value != this.SubmitRegisterForm.controls["CONFIRM_PASSWORD"].value) {
        IsValid = false;
      }
    }
    else {
      IsValid = false;
    }

    return IsValid;
  }

  openVerificationPopup(registerResponse: RegisterResponse) {
    this.close();
    let verificationInput = <VerificationPopupInput>{
      EmailID: registerResponse.EmailID,
      RegisteredAlreadyVerified: registerResponse.RegisteredAlreadyVerified,
      RegisteredNotVerified: registerResponse.RegisteredNotVerified,
      RegisteredDeactivated: registerResponse.RegisteredDeactivated,
      DeactivatedDate: registerResponse.DeactivatedDate,
    }
    const config: ModalOptions = {
      initialState: <any>verificationInput,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(AccountVerificationComponent, config);
    this.modalRef.content.closeBtnName = 'Close';
  }

  openPasswordPolicy() {
    const config: ModalOptions = {
      initialState: <any>this.passwordPolicy,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.passwordPolicyModal = this.modalService.show(PasswordPolicyComponent, config);
    this.passwordPolicyModal.content.closeBtnName = 'Close';
  }

  close() {
    this.modalRef?.hide();
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo && profileinfo.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED && !profileinfo.IS_LOGIN_OPTIONAL) {
      this.loginPopup();
    }
  }

  loginPopup() {
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo && profileinfo.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED && !this.authService.isUserLoggedIn()) {
      return;
    }

    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(LoginComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.onLogin.subscribe((result: UserLoginResult) => {
      if (result != null) {
        this.authService.loginStatus.next(true);
        this.authService.UserFullName.next(result.UserFullName ?? "");
        this.authService.hcpDetails.next(this.utilityService.getAppUserInfoObject(result));
      }
    });
  }

  updatedValue($event: any, controlName: string) {
    if (controlName == 'USER_ID') {
      this.duplicateUserId = ($event.value != "" && $event.value == this.userData.USER_ID) ? true : false;
    } else if (controlName == 'LICENCE_NUMBER') {
      this.duplicateLicense = ($event.value != "" && $event.value == this.userData.LICENCE_NUMBER) ? true : false;
    }
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.SubmitRegisterForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    if (controlName != "ZIP_CODE") {
      control.setValue($event.value);
    }
    else {
      control.setValue($event.value, { emitEvent: false });
    }
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      if (controlName != "ZIP_CODE") {
        control.setValue($event.value);
      }
      else {
        control.setValue($event.value, { emitEvent: false });
      }
      return false;
    }

    return true;
  }

  MinMaxDateValidation(event: any, fcn: string, MinMaxFlag?: string, MinMaxDateVal?: Date) {
    this.utilityService.ValidateDate(event.target.value, fcn, MinMaxFlag, MinMaxDateVal, this.SubmitRegisterForm);
  }

  getPrimaryDegree() {
    this.configService.getLookUps('PRIMARY_DEGREE').subscribe({
      next:(data) => {
      this.primaryDegree = data;
    },
      error:(err) => {
        console.error(err);
      }});
  }

}
