import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
  selector: 'success-confirmation-bannertemplate',
  template: `
    <div class="modelHeader">Confirmation Message</div>
      <div class="modal-body" ngPreserveWhitespaces>
      <p class="text-success fs-13 pb-2">Password changed successfully. Please 
        <a href="javascript:void(0)" (click)="Confirm()">Login</a> with the updated password</p>
        <div class="text-center" ngPreserveWhitespaces>
            <button type="button" class="btn btn-primary" (click)="Confirm()" id="smi-confirm-btn">{{ButtonText}}</button>
        </div>
    </div>
        `,
  styles: [

  ]
})
export class SuccessConfirmationBannertemplate implements OnInit {

  message: any;
  ButtonText: string = "Ok";
  result: Subject<boolean> = new Subject<boolean>();
  onClose: Subject<boolean>;

  constructor(private _bsModalRef: BsModalRef) {
  }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  messageClose() {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  Confirm() {
    this.onClose.next(true);
    this._bsModalRef.hide();
  }
}

