<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title w-100 d-block">Password Policy 
        <a (click)="this.close()" class="fs-12 text-underline float-right pt-1 text-white" data-dismiss="modal"><i class="fas fa-times"></i></a>
      </h5>
    </div>
    <div class="modal-body">
      <!-- <p class="fs-13">Please note, your password will expire after 1 year. Password you are entering should have below</p> -->
      <ul class="changepasswordContent fs-13">
        <li>Contain a minimum of {{passwordPolicy.MIN_PASSWORD_LENGTH}} characters in length</li>
        <li>Contain at least {{passwordPolicy.MIN_UPPERCASE_CHARS}} uppercase letter (A-Z)</li>
        <li>Contain at least {{passwordPolicy.MIN_NUMERIC_CHARS}} number (0-9)</li>
        <li>Contain at least {{passwordPolicy.MIN_SPECAIL_CHARS}} special character {{special_chars}}</li>
        <li>New password must not have been used in the last {{passwordPolicy.PREV_PWD_MATCHES}} passwords.</li>
        <!--<li>{{passwordPolicy.NUM_OF_FAILED_ATTMPTS}} consecutive failed login attempts to disable the user account.</li>
        <li>Password expiry days are {{passwordPolicy.PASSWORD_EXPIRY_DAYS}}.</li>
        <li>Password expiry warning messages from {{passwordPolicy.PWD_EXPIRY_WARN_DAYS}} days.</li>-->
      </ul>
    </div>
  </div>
