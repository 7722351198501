import { Component, EventEmitter, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { CountryAutoComplete } from 'src/app/_models/countries';
import { CP_VW_LOOKUPS } from 'src/app/_models/cpvw-lookups';
import { Occupation, PreferredContact } from 'src/app/_models/msl';
import { PASSWORD_POLICY } from 'src/app/_models/password-policy';
import { AppUserInfo, User } from 'src/app/_models/user';
import { ZipCode } from 'src/app/_models/zipcode';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SystemConfigService } from 'src/app/_services/system-config.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ZipcodeSelectionComponent } from '../../../components/Common/zipcode-selection/zipcode-selection.component';
import { CalendarModule } from 'primeng/calendar';
import { PortalProfiles } from '../../../_models/portals';
import { HomeService } from '../../../_services/home.service';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  public userData: AppUserInfo;
  public onClose: Subject<boolean>;
  config: any = '';
  bsConfig: any;
  EditProfileForm: FormGroup;
  //allCountries: COUNTRIES[] = [];
  allCountries: CountryAutoComplete[] = [];
  preferedContacts: PreferredContact[] = [];
  //occupationList: Occupation[] = [];
  occupationList: CP_VW_LOOKUPS[] = [];
  specialityList: CP_VW_LOOKUPS[] = [];
  primaryDegree: CP_VW_LOOKUPS[] = [];
  passwordPolicy: PASSWORD_POLICY = {};
  private subscribeDisplayCountries: Subscription | null = null;
  selectedCountry: string | '' = '';
  invalidPreferredEmail = false;
  emailIdAlreadyExists = false;
  duplicateUserId = false;
  duplicateLicense = false;
  registeredAlreadyVerified = false;
  registeredNotVerified = false;
  registeredDeactivated = false;
  minDate: Date = new Date();

  isDisabled: boolean = true;
  // pattern = this.utilityService.getPasswordPolicyPattern();

  public onSuccess: EventEmitter<boolean> = new EventEmitter();


  isPhoneMandatory: boolean = false;
  isEmailMandatory: boolean = false;
  platformId: any;
  constructor(private configService: SystemConfigService,
    private authService: AuthenticationService,
    public utilityService: UtilityService,
    private alertfyService: AlertifyService,
    private homeService: HomeService,
    private formBuilder: FormBuilder,
    public modalRef: BsModalRef,
    public passwordPolicyModal: BsModalRef,
    private modalService: BsModalService,
    public zipCodeModal: BsModalRef,
    public options: ModalOptions,
    private titleService: Title, @Inject(PLATFORM_ID) platformId: any
  ) {
    this.titleService.setTitle('My Profile');
    this.platformId = platformId;
    this.minDate.setFullYear(this.minDate.getFullYear() - 16);
  }

  ngOnInit(): void {
    this.authService.setCurrentFeatureCode("PROF_EDIT");
    this.userData = this.options.initialState as any as AppUserInfo;

    this.loadLookUps();
    this.setFormGroup();
    this.getAllCountries();


    if (this.authService.isCookie()) {
      this.authService.cookieTracking("PROF_EDIT").subscribe();
    }
  }

  get form() {
    return this.EditProfileForm.controls;
  }

  //get minDate() {
  //  let date: Date = new Date();
  //  date.setFullYear(date.getFullYear() - 16);
  //  return date;
  //}

  Ondatechange(value: Date, fcn: string) {
    this.utilityService.resetCalendar(value, fcn, this.EditProfileForm)
  }

  setFormGroup() {
    this.bsConfig = Object.assign({}, {
      containerClass: "theme-blue",
      dateInputFormat: 'DD-MMM-YYYY',
      showWeekNumbers: false,
      maxDate: this.minDate
    });
    this.config = {
      type: 1,
      length: 6,
      cssClass: 'custom',
      back: {
        stroke: "#2F9688",
        solid: "#f2efd2"
      },
      font: {
        color: "#000000",
        size: "35px"
      }
    };
    this.EditProfileForm = this.formBuilder.group({
      FIRST_NAME: new FormControl(this.userData.FIRST_NAME, Validators.required),
      LAST_NAME: new FormControl(this.userData.LAST_NAME, Validators.required),
      DATE_OF_BIRTH: new FormControl(new Date(this.userData.DATE_OF_BIRTH ?? ""), [Validators.required, this.AgeValidator]),
      ZIP_CODE: new FormControl(this.userData.ZIP_CODE, Validators.required),
      FK_COUNTRY_ID: new FormControl(this.userData.FK_COUNTRY_ID, Validators.required),
      CITY: new FormControl(this.userData.CITY),
      STATE_PROVINCE: new FormControl(this.userData.STATE_PROVINCE),
      ADDRESS: new FormControl(this.userData.ADDRESS),
      PHONE: new FormControl(this.userData.PHONE, Validators.pattern('[+()-_0-9]*')),
      PHONE_EXT: new FormControl(this.userData.PHONE_EXT, [Validators.pattern('[0-9]*')]),
      FK_PREFERRED_CONTACT_ID: new FormControl(this.userData.FK_PREFERRED_CONTACT_ID?.toString(), Validators.required),
      PREFERED_EMAIL: new FormControl(this.userData.PREFERED_EMAIL),
      PREFERED_PHONE: new FormControl(this.userData.PREFERED_PHONE),
      PREFERED_PHONE_EXT: new FormControl(this.userData.PREFERED_PHONE_EXT),
      HAS_RECEIVE_EMAILS: new FormControl({ value: this.userData.HAS_RECEIVE_EMAILS != null ? this.userData.HAS_RECEIVE_EMAILS : false, disabled: this.userData.PREFERED_EMAIL ? false : true }),
      HAS_ALLOW_CALLS: new FormControl({ value: this.userData.HAS_ALLOW_CALLS != null ? this.userData.HAS_ALLOW_CALLS : false, disabled: this.userData.PREFERED_PHONE ? false : true }),
      FK_PROFESSION_ID: new FormControl(this.userData.FK_PROFESSION_ID, Validators.required),
      FK_SPECAILITY_ID: new FormControl(this.userData.FK_SPECAILITY_ID, Validators.required),
      LICENCE_NUMBER: new FormControl({ value: this.userData.LICENCE_NUMBER, disabled: true }, Validators.required),
      PRIMARY_PRACTICE: new FormControl(this.userData.PRIMARY_PRACTICE),
      FK_PRIMARY_DEGREE_ID: new FormControl(!this.userData.FK_PRIMARY_DEGREE_ID ? "" : this.userData.FK_PRIMARY_DEGREE_ID, Validators.required),
    });
    this.onChanges();
  }


  get EnableSubmit() {
    let isValid = true;

    if (!this.EditProfileForm.controls['FIRST_NAME'].value
      || !this.EditProfileForm.controls['LAST_NAME'].value
      || !this.EditProfileForm.controls['DATE_OF_BIRTH'].value
      || !this.EditProfileForm.controls['FK_PRIMARY_DEGREE_ID'].value
      || !this.EditProfileForm.controls['FK_PROFESSION_ID'].value
      || !this.EditProfileForm.controls['FK_SPECAILITY_ID'].value
      || !this.EditProfileForm.controls['LICENCE_NUMBER'].value
      || (this.isEmailMandatory && !this.EditProfileForm.controls['PREFERED_EMAIL'].value)
      || (this.isPhoneMandatory && !this.EditProfileForm.controls['PREFERED_PHONE'].value)
      || !this.isCountryExists(this.EditProfileForm.controls['FK_COUNTRY_ID'].value)) {
      isValid = false;
    }

    return isValid;
  }

  isCountryExists(countryid: number): boolean {
    let hasCountry = true;
    if (!countryid) {
      return hasCountry = false;
    }
    else {
      if (this.allCountries) {
        let index = this.allCountries.findIndex(x => x.PKCountryId == countryid);
        if (index == -1) {
          hasCountry = false;
        }
      }
    }
    return hasCountry;
  }


  onChanges() {
    const contactPreferenceControl = <FormControl>this.EditProfileForm.get('FK_PREFERRED_CONTACT_ID');
    const preferredEmailControl = <FormControl>this.EditProfileForm.get('PREFERED_EMAIL');
    const preferredPhoneControl = <FormControl>this.EditProfileForm.get('PREFERED_PHONE');
    const otherPhoneControl = <FormControl>this.EditProfileForm.get('PHONE');
    const zipCodeControl = <FormControl>this.EditProfileForm.get('ZIP_CODE');


    setTimeout(() => {
      if (contactPreferenceControl.value) {
        this.invalidPreferredEmail = false;
        let selectedValue = this.preferedContacts.find(x => x.Id == this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].value);
        if (selectedValue && selectedValue.ContactCode == "EMAIL") {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = true;
          this.EditProfileForm.get('PREFERED_EMAIL')?.setValidators([Validators.required, Validators.email]);
          this.EditProfileForm.get('PREFERED_PHONE')?.setValidators([Validators.pattern(/[^0-9()-]*/g)]);
        }
        else if (selectedValue && selectedValue.ContactCode == "PHONE") {
          this.isPhoneMandatory = true;
          this.isEmailMandatory = false;
          this.EditProfileForm.get('PREFERED_PHONE')?.setValidators([Validators.required, Validators.pattern(/[^0-9()-]*/g)]);
          this.EditProfileForm.get('PREFERED_EMAIL')?.setValidators([Validators.email]);
        }
        else {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = false;
        }
      }
    }, 100);


    preferredEmailControl.valueChanges
      .subscribe(value => {
        if (value == undefined || value == null || value == "") {
          this.EditProfileForm.controls["HAS_RECEIVE_EMAILS"]?.disable();
          this.EditProfileForm.controls["HAS_RECEIVE_EMAILS"].setValue(false);
        }
        else {
          this.EditProfileForm.controls["HAS_RECEIVE_EMAILS"]?.enable();
        }
      });

    preferredPhoneControl.valueChanges
      .subscribe(value => {
        if ((value == undefined || value == null || value == "")
          && (otherPhoneControl.value == undefined || otherPhoneControl.value == null || otherPhoneControl.value == "")) {
          this.EditProfileForm.controls["HAS_ALLOW_CALLS"]?.disable();
          this.EditProfileForm.controls["HAS_ALLOW_CALLS"].setValue(false);
        }
        else {
          this.EditProfileForm.controls["HAS_ALLOW_CALLS"]?.enable();
        }
      });

    otherPhoneControl.valueChanges
      .subscribe(value => {
        if ((value == undefined || value == null || value == "")
          && (preferredPhoneControl.value == undefined || preferredPhoneControl.value == null || preferredPhoneControl.value == "")) {
          this.EditProfileForm.controls["HAS_ALLOW_CALLS"]?.disable();
          this.EditProfileForm.controls["HAS_ALLOW_CALLS"].setValue(false);
        }
        else {
          this.EditProfileForm.controls["HAS_ALLOW_CALLS"]?.enable();
        }
      });

    contactPreferenceControl.valueChanges
      .subscribe(value => {
        this.invalidPreferredEmail = false;
        let selectedValue = this.preferedContacts.find(x => x.Id == this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].value);
        if (selectedValue && selectedValue.ContactCode == "EMAIL") {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = true;
          this.EditProfileForm.get('PREFERED_EMAIL')?.setValidators([Validators.required, Validators.email]);
          this.EditProfileForm.get('PREFERED_PHONE')?.setValidators([Validators.pattern(/[^0-9()-]*/g)]);
        }
        else if (selectedValue && selectedValue.ContactCode == "PHONE") {
          this.isPhoneMandatory = true;
          this.isEmailMandatory = false;
          this.EditProfileForm.get('PREFERED_PHONE')?.setValidators([Validators.required, Validators.pattern(/[^0-9()-]*/g)]);
          this.EditProfileForm.get('PREFERED_EMAIL')?.setValidators([Validators.email]);
        }
        else {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = false;
        }
        preferredEmailControl.updateValueAndValidity();
        preferredPhoneControl.updateValueAndValidity();
      });

    zipCodeControl.valueChanges
      .subscribe(value => {
        let country = this.allCountries.find(c => c.PKCountryId == this.EditProfileForm.controls["FK_COUNTRY_ID"].value);
        if (country?.CountryCode == 'US') {
          if (value.length > 4) {
            this.subscribeDisplayCountries = this.configService.getZipCodes(value).subscribe({
              next: (zipCodes) => {
                if (zipCodes.length == 1) {
                  this.setCountryWithZipCode(zipCodes[0]);
                }
                else if (zipCodes.length > 1) {
                  this.EditProfileForm.controls["CITY"].setValue("");
                  this.EditProfileForm.controls["STATE_PROVINCE"].setValue("");
                  this.openMultipleZipCodePopup(zipCodes);
                }
              },
              error: (err) => {
                console.error(err);
              }
            });
          }
        }
      });
  }

  openMultipleZipCodePopup(zipCodes: ZipCode[]) {
    const config: ModalOptions = {
      initialState: <any>zipCodes,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false,
    };

    this.zipCodeModal = this.modalService.show(ZipcodeSelectionComponent, config);
    this.zipCodeModal.content.closeBtnName = 'Close';
    this.zipCodeModal.content.event.subscribe((res: ZipCode) => {
      this.setCountryWithZipCode(res);
    });
  }

  setCountryWithZipCode(zipcode: ZipCode) {
    this.EditProfileForm.controls["CITY"].setValue(zipcode.CITY);
    this.EditProfileForm.controls["STATE_PROVINCE"].setValue(zipcode.CITYSTATE);
    let country = this.allCountries.find(c => c.CountryCode == zipcode.COUNTRYCODE);
    this.selectedCountry = country?.CountryDisplayName ?? '';
    this.EditProfileForm.controls["FK_COUNTRY_ID"].setValue(country?.PKCountryId);
  }

  loadLookUps() {
    this.getPreferedContacts();
    this.getOccupations();
    this.getSpeciality();
    this.getPrimaryDegree();
  }

  getAllCountries() {
    this.subscribeDisplayCountries = this.configService.getCountriesTypeHeadData().subscribe({
      next: (data) => {
        this.allCountries = data;
        let user = this.options.initialState as any as AppUserInfo;
        let country = this.allCountries.find(c => c.PKCountryId == user.FK_COUNTRY_ID);
        if (country != undefined) {
          this.selectedCountry = country.CountryDisplayName;
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  getPreferedContacts() {
    this.configService.getPreferredContact().subscribe({
      next: (data) => {
        this.preferedContacts = data;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }


  getOccupations() {
    let profiletype_id = "";
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo) {
      profiletype_id = profileinfo.PK_ENV_PROFILE_ID ?? '';
    }
    if (profiletype_id) {
      this.configService.getProfessionByProfile(profiletype_id).subscribe({
        next: (data) => {
          this.occupationList = data;

          if (this.occupationList.length > 0) {
            if (this.userData.FK_PROFESSION_ID) {
              var isProfessionexists = this.occupationList.find(item => item.PK_LOOKUP_ID == this.userData.FK_PROFESSION_ID)
              if (isProfessionexists)
                this.EditProfileForm.controls['FK_PROFESSION_ID'].setValue(this.userData.FK_PROFESSION_ID);
              else
                this.EditProfileForm.controls['FK_PROFESSION_ID'].setValue("");
            }
          }

        },
        error: (err) => {
          console.error(err);
        }
      });
    }
  }

  getSpeciality() {
    this.configService.getLookUps('SPECIALITY').subscribe({
      next: (data) => {
        this.specialityList = data;

        if (this.specialityList.length > 0) {
          if (this.userData.FK_SPECAILITY_ID) {
            var isSpecialtyexists = this.specialityList.find(item => item.PK_LOOKUP_ID == this.userData.FK_SPECAILITY_ID)
            if (isSpecialtyexists)
              this.EditProfileForm.controls['FK_SPECAILITY_ID'].setValue(this.userData.FK_SPECAILITY_ID);
            else
              this.EditProfileForm.controls['FK_SPECAILITY_ID'].setValue("");
          }
        }


      },
      error: (err) => {
        console.error(err);
      }
    });

  }

  AgeValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value != "Invalid Date") {
      let timeDiff = Date.now() - control.value.getTime();
      let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
      if (age < 16) {
        return { 'age': true };
      }
    }
    return null;
  }

  Submit() {
    this.cleanForm();
    this.emailIdAlreadyExists = false;
    this.duplicateUserId = false;
    this.duplicateLicense = false;
    this.registeredNotVerified = false;
    this.registeredDeactivated = false;
    if (this.validateForm()) {
      let user: User = <User>{
        PK_USER_ID: this.userData.PK_UserId,
        REGISTERED_EMAIL_ID: this.userData.EmailID,
        FIRST_NAME: this.EditProfileForm.controls["FIRST_NAME"].value,
        LAST_NAME: this.EditProfileForm.controls["LAST_NAME"].value,
        DATE_OF_BIRTH: this.EditProfileForm.controls["DATE_OF_BIRTH"].value,
        FK_COUNTRY_ID: this.EditProfileForm.controls["FK_COUNTRY_ID"].value,
        ZIP_CODE: this.EditProfileForm.controls["ZIP_CODE"].value,
        STATE_PROVINCE: this.EditProfileForm.controls["STATE_PROVINCE"].value,
        CITY: this.EditProfileForm.controls["CITY"].value,
        ADDRESS: this.EditProfileForm.controls["ADDRESS"].value,

        FK_PREFERRED_CONTACT_ID: this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].value,
        PREFERED_EMAIL: this.EditProfileForm.controls["PREFERED_EMAIL"].value,
        PREFERED_PHONE: this.EditProfileForm.controls["PREFERED_PHONE"].value,
        PREFERED_PHONE_EXT: this.EditProfileForm.controls["PREFERED_PHONE_EXT"].value,
        PHONE: this.EditProfileForm.controls["PHONE"].value,
        PHONE_EXT: this.EditProfileForm.controls["PHONE_EXT"].value,
        HAS_RECEIVE_EMAILS: this.EditProfileForm.controls["HAS_RECEIVE_EMAILS"].value ?? false,
        HAS_ALLOW_CALLS: this.EditProfileForm.controls["HAS_ALLOW_CALLS"].value ?? false,

        FK_PROFESSION_ID: this.EditProfileForm.controls["FK_PROFESSION_ID"].value,
        FK_SPECAILITY_ID: this.EditProfileForm.controls["FK_SPECAILITY_ID"].value,
        LICENCE_NUMBER: this.EditProfileForm.controls["LICENCE_NUMBER"].value,
        PRIMARY_PRACTICE: this.EditProfileForm.controls["PRIMARY_PRACTICE"].value,
        FK_PRIMARY_DEGREE_ID: this.EditProfileForm.controls["FK_PRIMARY_DEGREE_ID"].value
      };
      this.authService.editProfile(user).subscribe({
        next: (result) => {
          if (result.Errors != null && result.Errors.length <= 0) {
            this.alertfyService.successBaner("Your details are saved");
            let appUserInfo: AppUserInfo = this.utilityService.getAppUserInfoObject(result);
            if (isPlatformBrowser(this.platformId)) {
              if (appUserInfo) {
                localStorage.removeItem('cpappuserinfo');
                localStorage.setItem('cpappuserinfo', JSON.stringify(appUserInfo));
              }
            }

            this.authService.hcpDetails.next(appUserInfo);
            this.authService.UserFullName.next(appUserInfo.UserFullName ?? "");
            console.log(appUserInfo.PRIMARY_DEGREE_NAME ?? '');

            let userProfile: PortalProfiles = {
              ACCESS_TYPE_CODE: result.ACCESS_TYPE_CODE,
              PK_ENV_PROFILE_ID: result.FK_ENV_PROF_ID,
              PROF_TYPE_CODE: result.PROF_TYPE_CODE,
              NAME: result.ENV_PROFILE,
              IS_LOGIN_OPTIONAL: result.IS_LOGIN_OPTIONAL,
            };
            this.homeService.setselectedProfile(userProfile);
            if (isPlatformBrowser(this.platformId)) {
              if (userProfile) {
                localStorage.setItem('profInfo', JSON.stringify(userProfile));
              }
            }

            this.onSuccess.emit(true);
            this.close();
          }
          else {
            if (result.Errors != null && result.Errors.length > 0) {
              result.Errors.forEach(error => {

              });
            }
          }
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
  }

  formControlValid(controlName: string): boolean {
    let isValid = true;
    if ((this.EditProfileForm.controls[controlName].value != null
      && this.EditProfileForm.controls[controlName].value.trim() == "")
      || (!this.EditProfileForm.controls[controlName].value)) {
      isValid = false;
    }
    return isValid;
  }

  validateForm() {
    let IsValid = true;
    this.invalidPreferredEmail = false;
    if (!this.formControlValid("FIRST_NAME")) {
      IsValid = false;
    }
    if (!this.formControlValid("LAST_NAME")) {
      IsValid = false;
    }
    if ((this.EditProfileForm.controls["DATE_OF_BIRTH"].value == null ||
      this.EditProfileForm.controls["DATE_OF_BIRTH"].value == "") ||
      (!this.EditProfileForm.controls["DATE_OF_BIRTH"].valid)) {
      IsValid = false;
    }

    if ((this.EditProfileForm.controls["FK_PRIMARY_DEGREE_ID"] == null ||
      this.EditProfileForm.controls["FK_PRIMARY_DEGREE_ID"].value == "") ||
      (!this.EditProfileForm.controls["FK_PRIMARY_DEGREE_ID"].valid)) {
      IsValid = false;
    }

    if ((this.EditProfileForm.controls["FK_COUNTRY_ID"] == null ||
      this.EditProfileForm.controls["FK_COUNTRY_ID"].value == "") ||
      (!this.EditProfileForm.controls["FK_COUNTRY_ID"].valid)) {
      IsValid = false;
    }


    if (this.EditProfileForm.controls["PREFERED_EMAIL"].value && !this.utilityService.validateEmail(this.EditProfileForm.controls["PREFERED_EMAIL"].value)) {
      this.invalidPreferredEmail = true;
      IsValid = false;
    }
    if (this.EditProfileForm.controls["PREFERED_EMAIL"].value && this.EditProfileForm.controls["PREFERED_EMAIL"].errors?.email) {
      this.invalidPreferredEmail = true;
      IsValid = false;
    }

    if ((this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"] == null ||
      this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].value == "") ||
      (!this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].valid)) {
      IsValid = false;
    }
    else {
      let selectedValue = this.preferedContacts.find(x => x.Id == this.EditProfileForm.controls["FK_PREFERRED_CONTACT_ID"].value);
      if (selectedValue != undefined &&
        selectedValue.ContactCode == "EMAIL") {
        if (!this.EditProfileForm.controls["PREFERED_EMAIL"].value) {
          IsValid = false;
        }
        else if (this.EditProfileForm.controls["PREFERED_EMAIL"].value
          && !this.utilityService.validateEmail(this.EditProfileForm.controls["PREFERED_EMAIL"].value)) {
          this.invalidPreferredEmail = true;
          IsValid = false;
        }
      }
      else if (selectedValue != undefined &&
        selectedValue.ContactCode == "PHONE") {
        if (!this.EditProfileForm.controls["PREFERED_PHONE"].value) {
          IsValid = false;
        }
        if (this.EditProfileForm.controls["PREFERED_EMAIL"].value
          && !this.utilityService.validateEmail(this.EditProfileForm.controls["PREFERED_EMAIL"].value)) {
          this.invalidPreferredEmail = true;
          IsValid = false;
        }
      }
    }

    if ((this.EditProfileForm.controls["FK_PROFESSION_ID"] == null ||
      this.EditProfileForm.controls["FK_PROFESSION_ID"].value == "") ||
      (!this.EditProfileForm.controls["FK_PROFESSION_ID"].valid)) {
      IsValid = false;
    }
    if ((this.EditProfileForm.controls["FK_SPECAILITY_ID"] == null ||
      this.EditProfileForm.controls["FK_SPECAILITY_ID"].value == "") ||
      (!this.EditProfileForm.controls["FK_SPECAILITY_ID"].valid)) {
      IsValid = false;
    }

    return IsValid;
  }

  public cleanForm() {
    let count = 0;
    if (this.EditProfileForm != null && this.EditProfileForm != undefined) {
      Object.keys(this.EditProfileForm.controls).forEach(key => {

        let value: any = this.EditProfileForm.controls[key].value;
        if (this.EditProfileForm.controls[key].value != null && typeof (this.EditProfileForm.controls[key].value) === "string"
          && this.EditProfileForm.controls[key].value !== "") {
          value = this.EditProfileForm.controls[key].value.trim();
        }
        else if (this.EditProfileForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.EditProfileForm.controls[key].setValue(value);
        }

      });
    }
  }

  close() {
    this.modalRef?.hide();
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.EditProfileForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    if (controlName != "ZIP_CODE") {
      control.setValue($event.value);
    }
    else {
      control.setValue($event.value, { emitEvent: false });
    }
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      if (controlName != "ZIP_CODE") {
        control.setValue($event.value);
      }
      else {
        control.setValue($event.value, { emitEvent: false });
      }
      return false;
    }

    return true;
  }

  ValidateEmail() {
    let IsValid = true;
    this.invalidPreferredEmail = false;
    if (this.EditProfileForm.controls["PREFERED_EMAIL"].value && !this.utilityService.validateEmail(this.EditProfileForm.controls["PREFERED_EMAIL"].value)) {
      this.invalidPreferredEmail = true;
      IsValid = false;
    }
    if (this.EditProfileForm.controls["PREFERED_EMAIL"].value && this.EditProfileForm.controls["PREFERED_EMAIL"].errors?.email) {
      this.invalidPreferredEmail = true;
      IsValid = false;
    }
  }

  MinMaxDateValidation(event: any, fcn: string, MinMaxFlag?: string, MinMaxDateVal?: Date) {
    this.utilityService.ValidateDate(event.target.value, fcn, MinMaxFlag, MinMaxDateVal, this.EditProfileForm);
  }

  getPrimaryDegree() {
    this.configService.getLookUps('PRIMARY_DEGREE').subscribe({
      next: (data) => {
        this.primaryDegree = data;
        if (this.primaryDegree.length > 0) {
          if (this.userData.FK_PRIMARY_DEGREE_ID) {
            var isPdexists = this.primaryDegree.find(item => item.PK_LOOKUP_ID == this.userData.FK_PRIMARY_DEGREE_ID)
            if (isPdexists)
              this.EditProfileForm.controls['FK_PRIMARY_DEGREE_ID'].setValue(this.userData.FK_PRIMARY_DEGREE_ID);
            else
              this.EditProfileForm.controls['FK_PRIMARY_DEGREE_ID'].setValue("");
          }
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }



}
