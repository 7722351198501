import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, Subject, Subscription } from 'rxjs';
import { ZipcodeSelectionComponent } from 'src/app/components/Common/zipcode-selection/zipcode-selection.component';
import { FileStoreType } from 'src/app/_enums/constant-types.enum';
import { CountryAutoComplete,  } from 'src/app/_models/countries';
import { CP_VW_LOOKUPS } from 'src/app/_models/cpvw-lookups';
import { FileStoreParams } from 'src/app/_models/file';
import { Occupation, PreferredContact } from 'src/app/_models/msl';
import { ProductsAutoComplete } from 'src/app/_models/products';
import { ApplicableRuleRequest, AttachmentSizeRequest, RequestModel } from 'src/app/_models/request-model';
import { ZipCode } from 'src/app/_models/zipcode';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { FileService } from 'src/app/_services/file.service';
import { OurProductsService } from 'src/app/_services/our-products.service';
import { SubmitRequestService } from 'src/app/_services/submit-request.service';
import { SystemConfigService } from 'src/app/_services/system-config.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { AppConstants } from 'src/shared/AppConstants';
import { PortalProfiles } from '../../../_models/portals';

@Component({
  selector: 'app-view-edit-mi-request',
  templateUrl: './view-edit-mi-request.component.html',
  styleUrls: ['./view-edit-mi-request.component.css']
})
export class ViewEditMiRequestComponent implements OnInit {

  Data: any;
  RequestData: RequestModel;
  IsView: boolean = false;
  @BlockUI()
  blockUI!: NgBlockUI;

  bsConfig: any;
  code: any = null;
  resultCode: any = null;
  ProductsList: any = [];
  ContactsList: PreferredContact[] = [];
  GenderList: any = [];
  CurrentContact = "";
  PreferedContactName = "";
  maxDate: Date = new Date();
  invalidPreferredEmail = false;
  invalidDOB = false;

  SubmitRequestForm: FormGroup;
  allCountries: CountryAutoComplete[] = [];
  //allprimarydegrees :primaryDegreeAutoComplete[]=[];
  preferedContacts: PreferredContact[] = [];
  //occupationList: Occupation[] = [];
  occupationList: CP_VW_LOOKUPS[] = [];
  specialityList: CP_VW_LOOKUPS[] = [];
  primaryDegreeList: CP_VW_LOOKUPS[] = [];
  customPickList: CP_VW_LOOKUPS[] = [];

  selectedCountry: string | '' = '';
  selectedProduct: string | '' = '';
  allProducts: ProductsAutoComplete[] = [];
  productDataSource$: Observable<ProductsAutoComplete[]>;
  typeaheadNoResults: boolean = false;
  productTypeaheadLoading: boolean;
  isProductInvalid: boolean = false;
  Product_Name: string = "";
  selectedPrimaryDegree: string | '' = '';
  selectedspeciality: string | '' = '';

  HCP_Next = false;
  PC_Next = false;

  allowFileTyles: string = 'doc,docx,ppt,pptx,xlsx,xls,pdf,msg,eml,png,jpeg,jpg,txt,htm,html,wav,mp3';
  allowFileExtensions: string = '';
  fileExtensions: string[] = [];
  fileToUpload?: File;
  fileStoreParams: FileStoreParams = {};
  isFileUploaded = false;
  filename: string | null = '';
  fileDisplayName: string = '';
  uploadbtnName: string = 'Upload';
  pdfDownloadURL: SafeUrl | null = null;
  public onClose: Subject<boolean>;
  submitted: boolean = false;
  attachmentMaxFileSize?: number = 0;
  IsEnableCustomPicklist: boolean = false;
  isConsentMandatory = false;
  isEmailMandatory: boolean = false;
  isPhoneMandatory: boolean = false;

  private subscribeDisplayCountries: Subscription | null = null;
  private subscribeTPAs: Subscription | null = null;
  private subscribeDisplayProducts: Subscription | null = null;
  private subscribeUploadHandle: Subscription | null = null;

  public OnSubmit: EventEmitter<boolean> = new EventEmitter();

  Has_RTF_Rule?: boolean = false;
  Has_RET_Rule?: boolean = false;
  constructor(private ourProductsService: OurProductsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private alertyfyService: AlertifyService,
    public utilityService: UtilityService,
    private authService: AuthenticationService,
    public activaterouter: ActivatedRoute,
    public sumitReq: SubmitRequestService,
    private configService: SystemConfigService,
    public modalRef: BsModalRef,
    public zipCodeModal: BsModalRef,
    private modalService: BsModalService,
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    public options: ModalOptions) {
    this.attachmentMaxFileSize = AppConstants.ReportAttachmentMaxFileSize;
    //this.getRequestAttachmentSize();
    this.onClose = new Subject();

    this.fileExtensions = this.allowFileTyles.split(',');
    this.allowFileExtensions = '.' + this.fileExtensions.join(', .');
  }

  ngOnInit(): void {
    this.Data = this.options.initialState as any;
    this.RequestData = this.Data.Request as RequestModel;
    this.IsView = this.Data.IsView;

    this.bsConfig = Object.assign({}, {
      containerClass: "theme-blue",
      dateInputFormat: 'DD-MMM-YYYY',
      showWeekNumbers: false,
    });

    this.fileDisplayName = '';
    this.BindScreenData();
  }

  BindScreenData() {
    this.occupationList = [];
    this.GenderList = [];
    this.ContactsList = [];
    this.primaryDegreeList=[];
    this.specialityList =[];
    this.allProducts=[];


    this.BindDataModel();
    this.getPreferedContacts();
    this.getProfession();
    this.getProducts();
    this.getAllCountries(this.RequestData.FK_COUNTRY_ID);
    this.preferredContactValidations();

    this.getSpecialities();
    this.getPrimaryDegree();
    this.getCustomPicklist();

    this.getCustomPicklistStatus();

    this.onChanges();
  }

  BindDataModel() {
    this.SubmitRequestForm = new FormGroup({
      PK_REQUEST_ID: new FormControl({ value: this.RequestData.PK_REQUEST_ID ?? "", disabled: this.IsView }),
      REPORT_REQUEST_TYPE: new FormControl("MI"),
      REQUEST_TYPE: new FormControl("MI"),
      FK_PROFESSION_ID: new FormControl({ value: this.RequestData.FK_PROFESSION_ID ?? "", disabled: this.IsView }, Validators.required),
      PROFESSION_NAME: new FormControl({ value: this.RequestData.PROFESSION_NAME ?? "", disabled: this.IsView }),
      FK_REPORTER_TYPE_ID: new FormControl(""),
      REPORTER_TYPE_NAME: new FormControl({ value: this.RequestData.ReporterTypeName ?? "", disabled: this.IsView }),
      LAST_NAME: new FormControl({ value: this.RequestData.LAST_NAME ?? "", disabled: this.IsView }, Validators.required),
      FIRST_NAME: new FormControl({ value: this.RequestData.FIRST_NAME ?? "", disabled: this.IsView }),
      FK_CONTACT_PREFERENCE_ID: new FormControl({ value: this.RequestData.FK_CONTACT_PREFERENCE_ID ?? "", disabled: this.IsView }, Validators.required),
      PREFERRED_CONTACT_NAME: new FormControl({ value: this.RequestData.PREFERRED_CONTACT_NAME ?? "", disabled: this.IsView }),
      EMAIL_ID: new FormControl({ value: this.RequestData.EMAIL_ID ?? "", disabled: this.IsView }, [Validators.email]),
      PHONE: new FormControl({ value: this.RequestData.PHONE ?? "", disabled: this.IsView }),
      PHONE_EXT: new FormControl({ value: this.RequestData.PHONE_EXT ?? "", disabled: this.IsView }),
      POSTAL_CODE: new FormControl({ value: this.RequestData.POSTAL_CODE ?? "", disabled: this.IsView }, Validators.required),
      FK_COUNTRY_ID: new FormControl({ value: this.RequestData.FK_COUNTRY_ID ?? "", disabled: this.IsView }, Validators.required),
      COUNTRY_NAME: new FormControl({ value: this.RequestData.COUNTRY_NAME ?? "", disabled: this.IsView }),
      CITY: new FormControl({ value: this.RequestData.CITY ?? "", disabled: this.IsView }),
      STATE: new FormControl({ value: this.RequestData.STATE ?? "", disabled: this.IsView }),
      PRODUCT_APPROVAL_ID: new FormControl({ value: this.RequestData.PRODUCT_APPROVAL_ID ?? "", disabled: this.IsView }, Validators.required),
      PRODUCT_NAME: new FormControl({ value: this.RequestData.PRODUCT_NAME ?? "", disabled: this.IsView }),
      DESCRIPTION: new FormControl({ value: this.RequestData.DESCRIPTION ?? "", disabled: this.IsView }, Validators.required),
      FILE_NAME: new FormControl({ value: this.RequestData.FILE_NAME ?? "", disabled: this.IsView }),
      FK_FILE_STORE_ID: new FormControl({ value: this.RequestData.FK_FILE_STORE_ID ?? "", disabled: this.IsView }),
      STATUS: new FormControl({ value: this.RequestData.STATUS ?? "", disabled: this.IsView }),
      IS_CALLBACK_ON_PRIORITY: new FormControl({ value: this.RequestData.IS_CALLBACK_ON_PRIORITY ?? "", disabled: this.IsView }),
      IS_LOGGED_IN_USER: new FormControl({ value: this.RequestData.IS_LOGGED_IN_USER ?? "", disabled: this.IsView }),
      CREATED_BY_USER_ID: new FormControl(""),
      FK_SPECIALTY_ID: new FormControl({ value: this.RequestData.FK_SPECIALTY_ID ?? "", disabled: this.IsView }),
      SPECIALTY_NAME: new FormControl({ value: this.RequestData.SPECIALTY_NAME ?? "", disabled: this.IsView }),
      FK_PRIMARY_DEGREE_ID: new FormControl({ value: this.RequestData.FK_PRIMARY_DEGREE_ID ?? "", disabled: this.IsView }),
      PRIMARY_DEGREE_NAME: new FormControl({ value: this.RequestData.PRIMARY_DEGREE_NAME ?? "", disabled: this.IsView }),
      FK_CUSTOM_PICKLIST_ID: new FormControl({ value: !this.RequestData.FK_CUSTOM_PICKLIST_ID ? "" : this.RequestData.FK_CUSTOM_PICKLIST_ID, disabled: this.IsView }),
      CUSTOM_PICKLIST_NAME: new FormControl({ value: this.RequestData.CUSTOM_PICKLIST_NAME ?? "", disabled: this.IsView }),
      CONSENT_TYPE: new FormControl({ value: this.RequestData.CONSENT_TYPE ?? "", disabled: this.IsView }),
      DPPR_RULE_ID: new FormControl({ value: this.RequestData.DPPR_RULE_ID ?? "", disabled: this.IsView }),
      FK_RTF_RULE_ID: new FormControl({ value: this.RequestData.FK_RTF_RULE_ID ?? "", disabled: this.IsView }),
      RTF_CONSENT_TYPE: new FormControl({ value: this.RequestData.RTF_CONSENT_TYPE ?? "", disabled: this.IsView }),
    });

    if (this.RequestData.FILE_NAME && this.RequestData.FILE_EXT) {
      this.fileDisplayName = this.RequestData.FILE_NAME + this.RequestData.FILE_EXT;
      this.fileStoreParams.PK_FileStoreId = this.RequestData.FK_FILE_STORE_ID;
      this.fileStoreParams.FileStoreType = FileStoreType.FILE;
    }

    if (this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value && this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value) {
      this.consentValidations();
    }

  }

  get form() {
    return this.SubmitRequestForm.controls;
  }

  Ondatechange(value: Date, fcn: string) {
    this.utilityService.resetCalendar(value, fcn, this.SubmitRequestForm)
  }

  CP_Change(productID: any) {
    let _currentCP = this.ContactsList.filter(function (a: any) {
      return (a.Id == productID)
    })[0];
    if (_currentCP != null && _currentCP != undefined) {
      this.CurrentContact = _currentCP.ContactCode ?? '';
      this.PreferedContactName = _currentCP.ContactName ?? '';
    }
    else {
      this.CurrentContact = '';
      this.PreferedContactName = '';
    }

  }

  getProfession() {
    let profiletype_id = "";
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo) {
      profiletype_id = profileinfo.PK_ENV_PROFILE_ID ?? '';
    }
    if (profiletype_id) {
      let profId = this.RequestData.STATUS == "DRAFT" ? 0 : parseInt(this.RequestData.FK_PROFESSION_ID);
      this.configService.getProfessionByProfile(profiletype_id, profId).subscribe({
        next: data => {
          this.occupationList = data;
        },
        error: err => {
          console.error(err);
        }
      });
    }
  }

  getPreferedContacts() {
    let contactId: number = this.RequestData.STATUS == "DRAFT" ? 0 : this.RequestData.FK_CONTACT_PREFERENCE_ID;
    this.configService.getPreferredContact(contactId).subscribe({
      next: (res) => {
        this.ContactsList = res;
        this.preferedContacts = res;
        this.preferredContactValidations();
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  getAllCountries(countryId: number | null | undefined) {
    if (countryId == null || countryId == undefined) countryId = 0;
    countryId = this.RequestData.STATUS =="DRAFT"?0 : this.RequestData.FK_COUNTRY_ID
    this.subscribeDisplayCountries = this.configService.getCountriesTypeHeadData(countryId).subscribe({
      next: (res) => {
        this.allCountries = res;
        if (countryId != null) {
          this.selectedCountry = this.allCountries.find(x => x.PKCountryId == countryId)?.CountryDisplayName ?? '';
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  getProducts() {
    let productId: string = this.RequestData.STATUS == "DRAFT" ? "" : this.RequestData.PRODUCT_APPROVAL_ID;
    this.subscribeDisplayProducts = this.ourProductsService.getProductsTypeHeadData(productId).subscribe({
      next: (res) => {
        this.allProducts = res;
        this.selectedProduct = this.allProducts.find(x=>x.PK_PRODUCT_MAPPING_ID == productId)?.PRODUCT_DISPLAY_NAME ?? '';
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  formControlValid(controlName: string): boolean {
    let isValid = true;
    if ((this.SubmitRequestForm.controls[controlName].value != null
      && this.SubmitRequestForm.controls[controlName].value.trim() == "")
      || (!this.SubmitRequestForm.controls[controlName].value)) {
      isValid = false;
    }
    return isValid;
  }

  get isStep1Valid() {
    var pno = new RegExp("^[^0-9()-]*$");
    let isValid = true;
    this.invalidPreferredEmail = false;

    if (this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value == null
      || this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value == "") {
      isValid = false;
    }
    if (!this.formControlValid("LAST_NAME")) {
      isValid = false;
    }
    if (this.SubmitRequestForm.controls["FK_COUNTRY_ID"] == null
      || this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value == ""
      || this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value == undefined) {
      isValid = false;
    }

    if (this.SubmitRequestForm.controls["EMAIL_ID"].value != null &&
      this.SubmitRequestForm.controls["EMAIL_ID"].value != "" &&
      !this.utilityService.validateEmail(this.SubmitRequestForm.controls["EMAIL_ID"].value)) {
      this.invalidPreferredEmail = true;
      isValid = false;
    }

    if (!this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].valid) {
      isValid = false;
    }
    else {
      let selectedValue = this.getPreferredContact(this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].value);
      if (selectedValue != undefined && selectedValue.ContactCode == "EMAIL") {
        if (!this.SubmitRequestForm.controls["EMAIL_ID"].value) {
          isValid = false;
        }

        if (this.SubmitRequestForm.get('EMAIL_ID')?.errors?.email) {
          isValid = false;
        }
      }
      else if (selectedValue != undefined &&
        selectedValue.ContactCode == "PHONE") {
        if (!this.SubmitRequestForm.controls["PHONE"].value) {
          isValid = false;
        }
        if (this.SubmitRequestForm.controls["PHONE"].value && pno.test(this.SubmitRequestForm.controls["PHONE"].value)) {
          isValid = false;
        }
      }
    }
    return isValid;
  }

  get isStep2Valid() {
    let isValid = true;
    if (this.SubmitRequestForm.controls["PRODUCT_APPROVAL_ID"] == null
      || this.SubmitRequestForm.controls["PRODUCT_APPROVAL_ID"].value == ""
      || this.SubmitRequestForm.controls["PRODUCT_APPROVAL_ID"].value == undefined) {
      isValid = false;
    }
    if (!this.formControlValid("DESCRIPTION")) {
      isValid = false;
    }
    if (this.SubmitRequestForm.controls["CONSENT_TYPE"].errors) {
      isValid = false;
    }

    return isValid;
  }

  get isDraftValid() {
    let isValid = true;

    if (
      (this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value == null || this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value == "")
      && (this.SubmitRequestForm.controls["FK_PRIMARY_DEGREE_ID"].value == null || this.SubmitRequestForm.controls["FK_PRIMARY_DEGREE_ID"].value == "")
      && (this.SubmitRequestForm.controls["FK_SPECIALTY_ID"].value == null || this.SubmitRequestForm.controls["FK_SPECIALTY_ID"].value == "")
      && (!this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value
      )

      && !this.formControlValid("FIRST_NAME")
      && !this.formControlValid("LAST_NAME")

      && (this.SubmitRequestForm.controls["POSTAL_CODE"].value == "" || this.SubmitRequestForm.controls["POSTAL_CODE"].value == null)

      && !this.formControlValid("STATE")
      && !this.formControlValid("CITY")

      && !(this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].valid)
      && (!this.SubmitRequestForm.controls["PHONE"].value ||
        !this.SubmitRequestForm.controls["PHONE"].valid)

      && !this.formControlValid("EMAIL_ID")
      && !this.formControlValid("PHONE_EXT")
      && !this.formControlValid("FK_FILE_STORE_ID")

      && !this.SubmitRequestForm.controls["PRODUCT_APPROVAL_ID"].valid

      && !this.formControlValid("DESCRIPTION")
    ) {
      isValid = false;
    }

    return isValid;
  }

  get isSubmitValid() {
    let isValid = true;
    if (!(this.isStep1Valid && this.isStep2Valid)) {
      isValid = false;
    }

    return isValid;
  }

  HCPValidations(IsValid: boolean, errrs: any): boolean {
    this.invalidPreferredEmail = false;
    let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value);
    if (country != undefined) {
      this.selectedCountry = country.CountryDisplayName;
    }

    if (!this.SubmitRequestForm.controls["FK_PROFESSION_ID"].valid) {
      errrs.push("Please select Profession.");
      IsValid = false;
    }
    if (!this.formControlValid("LAST_NAME")) {
      errrs.push("Please enter Last Name.");
      IsValid = false;
    }
    if (!this.SubmitRequestForm.controls["FK_COUNTRY_ID"].valid) {
      errrs.push("Please select Country.");
      IsValid = false;
    }

    if (!this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].valid) {
      errrs.push("Please select Prefered Contact Method.");
      IsValid = false;
    }
    else {
      let selectedValue = this.getPreferredContact(this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].value);
      if (selectedValue != undefined &&
        selectedValue.ContactCode == "EMAIL") {
        if (this.SubmitRequestForm.controls["EMAIL_ID"].value == "") {
          errrs.push("Please enter Email.");
          IsValid = false;
        }
        else if (!this.utilityService.validateEmail(this.SubmitRequestForm.controls["EMAIL_ID"].value)) {
          errrs.push("Please enter valid Email.");
          this.invalidPreferredEmail = true;
          IsValid = false;
        }
      }
      else if (selectedValue != undefined &&
        selectedValue.ContactCode == "PHONE") {
        if (this.SubmitRequestForm.controls["PHONE"].value == "" ||
          !this.SubmitRequestForm.controls["PHONE"].valid) {
          errrs.push("Please enter Phone.");
          IsValid = false;
        }
      }
    }

    if (!IsValid) {
      let errMessage = '';
      for (var i = 0; i < errrs.length; i++) {
        errMessage += errrs[i] + ' </br>';
      }

    }

    return IsValid;
  }

  PCValidations(IsValid: boolean, errrs: any): boolean {

    if (!this.SubmitRequestForm.controls["PRODUCT_APPROVAL_ID"].valid) {
      errrs.push("Please select Product.");
      IsValid = false;
    }
    if (!this.formControlValid("DESCRIPTION")) {
      errrs.push("Please enter Description.");
      IsValid = false;
    }

    if (this.SubmitRequestForm.controls["CONSENT_TYPE"].errors) {
      errrs.push("Please select Consent Type.");
      IsValid = false;
    }

    if (!IsValid) {
      let errMessage = '';
      for (var i = 0; i < errrs.length; i++) {
        errMessage += errrs[i] + ' </br>';
      }
    }

    return IsValid;
  }

  validations(next: string): boolean {
    let IsValid = true;
    let errrs: any = [];

    IsValid = this.HCPValidations(IsValid, errrs);
    IsValid = this.PCValidations(IsValid, errrs);

    return IsValid;
  }

  public cleanForm() {
    let count = 0;
    if (this.SubmitRequestForm != null && this.SubmitRequestForm != undefined) {
      Object.keys(this.SubmitRequestForm.controls).forEach(key => {

        let value: any = this.SubmitRequestForm.controls[key].value;
        if (this.SubmitRequestForm.controls[key].value != null && typeof (this.SubmitRequestForm.controls[key].value) === "string"
          && this.SubmitRequestForm.controls[key].value !== "") {
          value = this.SubmitRequestForm.controls[key].value.trim();
        }
        else if (this.SubmitRequestForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE"
          && key != "FK_COUNTRY_ID" && key != "PRODUCT_APPROVAL_ID") {
          this.SubmitRequestForm.controls[key].setValue(value);
        }

      });
    }
  }

  submitValidations(): boolean {
    let IsValid = true;
    let errrs: any = [];

    IsValid = this.HCPValidations(IsValid, errrs);
    IsValid = this.PCValidations(IsValid, errrs);

    if (!IsValid) {
      this.alertyfyService.validationBaner(errrs);
    }

    return IsValid;
  }

  draftValidations(): boolean {
    let IsValid = true;
    let errrs: any = [];
    this.invalidPreferredEmail = false;

    let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value);
    if (country != undefined) {
      this.selectedCountry = country.CountryDisplayName;
    }

    if (this.SubmitRequestForm.controls["EMAIL_ID"].value != null &&
      this.SubmitRequestForm.controls["EMAIL_ID"].value != "" &&
      !this.utilityService.validateEmail(this.SubmitRequestForm.controls["EMAIL_ID"].value)) {
      errrs.push("Please enter valid Email.");
      this.invalidPreferredEmail = true;
      IsValid = false;
    }

    if (this.SubmitRequestForm.controls["EMAIL_ID"].value &&
      this.SubmitRequestForm.controls["EMAIL_ID"].errors?.email) {
      errrs.push("Please enter valid Email.");
      this.invalidPreferredEmail = true;
      IsValid = false;
    }

    if (this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].valid) {
      let selectedValue = this.getPreferredContact(this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].value);
      if (selectedValue != undefined &&
        selectedValue.ContactCode == "EMAIL") {
        if (this.SubmitRequestForm.controls["EMAIL_ID"].value != null &&
          this.SubmitRequestForm.controls["EMAIL_ID"].value != "" &&
          !this.utilityService.validateEmail(this.SubmitRequestForm.controls["EMAIL_ID"].value)) {
          errrs.push("Please enter valid Email.");
          this.invalidPreferredEmail = true;
          IsValid = false;
        }
      }
    }
    if (!IsValid) {
      let errMessage = '';
      for (var i = 0; i < errrs.length; i++) {
        errMessage += errrs[i] + ' </br>';
      }
    }

    return IsValid;
  }

  onChanges() {
    const contactPreferenceControl = <FormControl>this.SubmitRequestForm.get('FK_CONTACT_PREFERENCE_ID');
    const zipCodeControl = <FormControl>this.SubmitRequestForm.get('POSTAL_CODE');
    const professionControl = <FormControl>this.SubmitRequestForm.get('FK_PROFESSION_ID');
    const countryControl = <FormControl>this.SubmitRequestForm.get('FK_COUNTRY_ID');
    

    setTimeout(() => {
      if (contactPreferenceControl.value) {
        this.preferredContactValidations();
      }
    }, 100);

    contactPreferenceControl.valueChanges
      .subscribe(value => {
        this.preferredContactValidations();
      });

    zipCodeControl.valueChanges
      .subscribe(value => {
        let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value);
        if (country?.CountryCode == 'US') {
          if (value.length > 4) {
            this.subscribeDisplayCountries = this.configService.getZipCodes(value).subscribe({
              next: (zipCodes) => {
                if (zipCodes.length == 1) {
                  this.setCountryWithZipCode(zipCodes[0]);
                }
                else if (zipCodes.length > 1) {
                  this.SubmitRequestForm.controls["CITY"].setValue("");
                  this.SubmitRequestForm.controls["STATE"].setValue("");
                  this.openMultipleZipCodePopup(zipCodes);
                }
              },
              error: (err) => {
                console.error(err);
              }
            });
          }
        }
      });

    professionControl.valueChanges.subscribe(value => {
      this.consentValidations();
    });

    countryControl.valueChanges.subscribe(value => {
      this.consentValidations();
    });

  }

  preferredContactValidations() {
    const preferredEmailControl = <FormControl>this.SubmitRequestForm.get('EMAIL_ID');
    const preferredPhoneControl = <FormControl>this.SubmitRequestForm.get('PHONE');
    this.invalidPreferredEmail = false;
    let selectedValue = this.preferedContacts.find(x => x.Id == this.SubmitRequestForm.controls["FK_CONTACT_PREFERENCE_ID"].value);
    if (selectedValue != undefined && selectedValue.ContactCode == "EMAIL") {
      this.isPhoneMandatory = false;
      this.isEmailMandatory = true;
      this.SubmitRequestForm.get('EMAIL_ID')?.setValidators([Validators.required, Validators.email]);
      //this.SubmitRequestForm.get('PHONE')?.clearValidators();
      this.SubmitRequestForm.get('PHONE')?.setValidators([Validators.pattern(/[^0-9()-]*/g)]);
    }
    else if (selectedValue != undefined && selectedValue.ContactCode == "PHONE") {
      this.isPhoneMandatory = true;
      this.isEmailMandatory = false;
      this.SubmitRequestForm.get('PHONE')?.setValidators([Validators.required, Validators.pattern(/[^0-9()-]*/g)]);
      //this.SubmitRequestForm.get('EMAIL_ID')?.clearValidators();
      this.SubmitRequestForm.get('EMAIL_ID')?.setValidators([Validators.email]);
    }
    else {
      this.isPhoneMandatory = false;
      this.isEmailMandatory = false;
      this.SubmitRequestForm.get('PHONE')?.clearValidators();
      this.SubmitRequestForm.get('EMAIL_ID')?.clearValidators();
    }
    preferredEmailControl.updateValueAndValidity();
    preferredPhoneControl.updateValueAndValidity();
  }

  openMultipleZipCodePopup(zipCodes: ZipCode[]) {
    const config: ModalOptions = {
      initialState: <any>zipCodes,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false,
    };

    this.zipCodeModal = this.modalService.show(ZipcodeSelectionComponent, config);
    this.zipCodeModal.content.closeBtnName = 'Close';
    this.zipCodeModal.content.event.subscribe((res: ZipCode) => {
      this.setCountryWithZipCode(res);
    });
  }

  setCountryWithZipCode(zipcode: ZipCode) {
    this.SubmitRequestForm.controls["CITY"].setValue(zipcode.CITY);
    this.SubmitRequestForm.controls["STATE"].setValue(zipcode.CITYSTATE);
    let country = this.allCountries.find(c => c.CountryCode == zipcode.COUNTRYCODE);
    this.selectedCountry = country?.CountryDisplayName ?? '';
    this.SubmitRequestForm.controls["FK_COUNTRY_ID"].setValue(country?.PKCountryId);
  }


  handleFileInput($event: any) {
    if ($event && $event.target.files) {

      let files = $event.target.files;
      if (files && files.length > 0) {
        this.fileToUpload = files.item(0);

        if (this.validateDocFile() && this.fileToUpload) {
          this.blockUI.start();



          this.subscribeUploadHandle = this.fileService.uploadFile(this.fileToUpload, this.fileStoreParams.PK_FileStoreId).subscribe({
            next: (result) => {
              this.fileStoreParams.PK_FileStoreId = result.PK_TEMP_FILE_STORE_ID;
              this.fileStoreParams.FileStoreType = FileStoreType.TEMP;
              if (result.FILE_NAME) {
                this.fileDisplayName = result.FILE_NAME + result.FILE_EXT;
              }
              this.SubmitRequestForm.controls["FK_FILE_STORE_ID"].setValue(result.PK_TEMP_FILE_STORE_ID);
              this.uploadbtnName = 'Replace';
              //this.SubmitRequestForm.controls["FILE_NAME"].setValue(this.filename);

              this.isFileUploaded = true;
              this.blockUI.stop();
            }, error: (err) => {
              //console.error(error.console);
              //this.alertyfyService.setToastMessage(error, MessageType.Error);
              this.alertyfyService.errorBaner(err);
              this.blockUI.stop();
            },
            complete: () => {
              this.blockUI.stop();
            }
          });
        }
      }
    }
  }

  private validateDocFile() {
    let isValidFile = true;
    let errrs: any = [];
    let fileSize = this.attachmentMaxFileSize ?? 0; //10MB
    let fileType = this.fileToUpload?.name.slice((this.fileToUpload?.name.lastIndexOf(".") - 1 >>> 0) + 2); //this.fileToUpload?.name.substring(this.fileToUpload.name.lastIndexOf('.'), this.fileToUpload.name.length);     
    if (fileType && !(this.fileExtensions.includes(fileType?.toLowerCase()))) {
      isValidFile = false;
      errrs.push('Invalid file, allowed extensions are : ' + this.allowFileExtensions);
    }
    if (this.fileToUpload != null && this.fileToUpload.size > (fileSize * 1048576)) {
      isValidFile = false;
      errrs.push('Document size cannot be more than ' + fileSize + 'MB');
    }
    if (this.fileToUpload != null && this.fileToUpload.name != null && this.fileToUpload.name.length > 250) {
      isValidFile = false;
      errrs.push('File name cannot be more than 250 characters');
    }

    if (!isValidFile) {
      this.alertyfyService.validationBaner(errrs);
    }
    return isValidFile;
  }

  downloadDocument(fileStoreId: string | undefined) {
    if (fileStoreId) {
      this.pdfDownloadURL = this.utilityService.downloadURL(fileStoreId, this.fileStoreParams.FileStoreType ?? FileStoreType.TEMP);
    }
  }

  deleteAttachment() {
    if (this.fileStoreParams && this.fileStoreParams.PK_FileStoreId !== "") {
      this.alertyfyService.ConfirmationBanner("Are you sure you want to delete the attachment?", this.deleteAttachmentCallback, "Confirm");
    }
  }

  deleteAttachmentCallback = (result: any) => {
    if (result) {
      this.filename = null;
      this.fileDisplayName = '';
      this.SubmitRequestForm.controls["FILE_NAME"].setValue(null);
      this.SubmitRequestForm.controls["FK_FILE_STORE_ID"].setValue(null);
      this.uploadbtnName = 'Upload';
      this.fileStoreParams = {};
    }
  }

  saveAsDraftRequest() {
    //this.submitted = true;
    this.cleanForm();
    if (this.draftValidations()) {
      this.blockUI.start();

      let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value);

      var formData = this.SubmitRequestForm.value;
      formData["PROFESSION_NAME"] = this.getProfessionName(this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value);
      formData["PREFERRED_CONTACT_NAME"] = this.PreferedContactName;
      formData["COUNTRY_NAME"] = this.selectedCountry;
      formData["COUNTRY_CODE"] = country?.CountryCode ?? '';
      formData["PRODUCT_NAME"] = this.selectedProduct;
      formData["STATUS"] = "DRAFT";

      formData["DPPR_RULE_ID"] = this.SubmitRequestForm.controls["DPPR_RULE_ID"].value;
      formData["CONSENT_TYPE"] = this.SubmitRequestForm.controls['CONSENT_TYPE']?.value;

      let profileinfo: PortalProfiles = {};
      profileinfo = this.utilityService.getSelectedProfile();
      if (profileinfo.PK_ENV_PROFILE_ID) {
        formData["FK_REPORTER_TYPE_ID"] = profileinfo.PK_ENV_PROFILE_ID;
      }

      this.sumitReq.saveRequest(formData).subscribe({
        next: (res) => {
          this.alertyfyService.successBaner("Your report " + res.ResponseID + " is saved successfully.");
          this.OnSubmit.emit(true);
          this.blockUI.stop();
          this.close();
        },
        error: (err) => {
          console.error(err);
          this.blockUI.stop();
        }
      });
    }
  }

  submitRequest() {
    this.submitted = true;
    this.cleanForm();
    if (this.submitValidations()) {
      this.cleanForm();
      if (this.draftValidations()) {
        this.blockUI.start();

        let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRequestForm.controls["FK_COUNTRY_ID"].value);
      //  let primaryDegree = this.primaryDegreeList.find(P => P.NAME == this.SubmitRequestForm.controls["FK_PRIMARY_DEGREE_ID"].value);
      //  let speciality = this.specialityList.find(s => s.NAME == this.SubmitRequestForm.controls["FK_SPECIALTY_ID"].value);


        var formData = this.SubmitRequestForm.value;
        formData["PROFESSION_NAME"] = this.getProfessionName(this.SubmitRequestForm.controls["FK_PROFESSION_ID"].value);
        formData["PREFERRED_CONTACT_NAME"] = this.PreferedContactName;
        formData["COUNTRY_NAME"] = this.selectedCountry;
        formData["COUNTRY_CODE"] = country?.CountryCode ?? '';
        formData["PRODUCT_NAME"] = this.selectedProduct;
        formData["STATUS"] = "SUBMITTED";

        formData["DPPR_RULE_ID"] = this.SubmitRequestForm.controls["DPPR_RULE_ID"].value;
        formData["CONSENT_TYPE"] = this.SubmitRequestForm.controls['CONSENT_TYPE']?.value;

       // formData["PRIMARY_DEGREE_NAME"] = this.selectedPrimaryDegree;
       // formData["SPECIALITY_NAME"] = this.selectedspeciality;

        let profileinfo: PortalProfiles = {};
        profileinfo = this.utilityService.getSelectedProfile();
        if (profileinfo.PK_ENV_PROFILE_ID) {
          formData["FK_REPORTER_TYPE_ID"] = profileinfo.PK_ENV_PROFILE_ID;
        }

        this.sumitReq.saveRequest(formData).subscribe({
          next: (res) => {
            this.alertyfyService.successBaner("Thank you for submitting the request. Please find the related Reference ID - " + res.ResponseID + " which can be used for future purpose.");
            //this.BindScreenData();
            this.OnSubmit.emit(true);
            this.blockUI.stop();
            this.close();
          },
          error: (err) => {
            console.error(err);
            this.blockUI.stop();
          }
        });
      }
    }
  }

  cancel() {
    if (this.fileStoreParams && this.fileStoreParams.PK_FileStoreId !== "") {
      this.alertyfyService.ConfirmationBanner("Do you want to abort the report submission?", this.cancelCallback, "Confirm");
    }
  }

  cancelCallback = (result: any) => {
    if (result) {
      this.close();
    }
  }

  getCountryName(id: any): string {
    let cname: string = '';
    let _currentCN = this.allCountries.filter(function (a: any) {
      return (a.PK_COUNTRY_ID == id)
    })[0];
    if (_currentCN != null && _currentCN != undefined) {
      cname = _currentCN.CountryDisplayName;
    }
    else {
      cname = '';
    }

    return cname;
  }

  getPreferredContact(id: any): any {
    let pc: any = undefined;
    let _currentPC = this.ContactsList.filter(function (a: any) {
      return (a.Id == id)
    })[0];
    if (_currentPC != null && _currentPC != undefined) {
      pc = _currentPC;
    }

    return pc;
  }

  getProfessionName(id: any): string {
    let name: string = '';
    let _currentPC = this.occupationList.filter(function (a: any) {
      return (a.Id == id)
    })[0];
    if (_currentPC != null && _currentPC != undefined) {
      name = _currentPC.NAME ?? '';
    }

    return name;
  }

  getPreferredContactName(id: any): string {
    let name: string = '';
    let _currentPC = this.ContactsList.filter(function (a: any) {
      return (a.Id == id)
    })[0];
    if (_currentPC != null && _currentPC != undefined) {
      name = _currentPC.ContactName ?? '';
    }

    return name;
  }

  getProductName(id: any): string {
    let name: string = '';
    let _currentPR = this.ProductsList.filter(function (a: any) {
      return (a.MiqProductApprovalId == id)
    })[0];
    if (_currentPR != null && _currentPR != undefined) {
      name = _currentPR.ProductDisplayName;
    }
    else {
      name = '';
    }

    return name;
  }

  getConfirmation() {
    if (this.SubmitRequestForm.touched) {
      this.alertyfyService.ConfirmationBanner("Are you sure you want to abort the Request?", this.ConfimationCalBack, "Confirm");
    }
  }

  ConfimationCalBack = (result: any) => {
    if (result) {
      this.route.navigate(['/home']);
    }
  }

  close() {
    this.modalRef.hide();
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.SubmitRequestForm.controls[controlName];
    if (controlName != "DESCRIPTION") {
      $event.value = this.utilityService.replaceEmojis($event.value);
      if (controlName != "POSTAL_CODE") {
        control.setValue($event.value);
      }
      else {
        control.setValue($event.value, { emitEvent: false });
      }
    }
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      if (controlName != "POSTAL_CODE") {
        control.setValue($event.value);
      }
      else {
        control.setValue($event.value, { emitEvent: false });
      }
      return false;
    }

    return true;
  }

  getSpecialities() {
    let profId: number = this.RequestData.STATUS == "DRAFT" ? 0 : this.RequestData.FK_SPECIALTY_ID;
   // let profId = this.RequestData.FK_SPECIALTY_ID ?? 0;
    this.configService.getLookUps('SPECIALITY', profId).subscribe({
      next: (res) => {
        this.specialityList = res;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  getPrimaryDegree() {
    let degreeId: number = this.RequestData.STATUS == "DRAFT" ? 0 : this.RequestData.FK_PRIMARY_DEGREE_ID;
    //let degreeId = this.RequestData.FK_PRIMARY_DEGREE_ID ?? 0;
    this.configService.getLookUps('PRIMARY_DEGREE',degreeId).subscribe({
      next: (res) => {
        this.primaryDegreeList = res;
        
        
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  getCustomPicklist() {
    this.configService.getLookUps('CUSTOM').subscribe({
      next: (res) => {
        this.customPickList = res;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  getCustomPicklistStatus() {
    this.sumitReq.getCustomPicklistStatus().subscribe({
      next: (res) => {
        this.IsEnableCustomPicklist = res;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  getRequestAttachmentSize() {
    this.attachmentMaxFileSize = 0;
    let request: AttachmentSizeRequest = {
      RequestType: "MI",
    };
    this.sumitReq.getRequestAttachmentSize(request).subscribe({
      next: (res) => {
        if (res && res.Status) {
          this.attachmentMaxFileSize = res.AttachmentSize;
        }
        else {
          this.alertyfyService.errorBaner(res.ErrorMessage ?? '');
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }


  consentValidations() {
    const consentTypeControl = <FormControl>this.SubmitRequestForm.get('CONSENT_TYPE');
    this.isConsentMandatory = false;
    this.Has_RTF_Rule = false;
    this.Has_RET_Rule = false;
    if (this.SubmitRequestForm.controls['FK_PROFESSION_ID'].value && this.SubmitRequestForm.controls['FK_COUNTRY_ID'].value) {
      let dppr_request: ApplicableRuleRequest = {
        CountryId: this.SubmitRequestForm.controls['FK_COUNTRY_ID'].value,
        ProfessionId: this.SubmitRequestForm.controls['FK_PROFESSION_ID'].value,
      };
      let profileinfo: PortalProfiles = {};
      profileinfo = this.utilityService.getSelectedProfile();
      dppr_request.ProfileId = profileinfo.PK_ENV_PROFILE_ID;
      this.sumitReq.getApplicableRule(dppr_request).subscribe({
        next: data => {
          if (data && data.Status) {
            if (data.retentionRule && data.retentionRule.Status) {
              this.Has_RET_Rule = data.retentionRule.HasRule;
              this.SubmitRequestForm.controls['DPPR_RULE_ID'].setValue(data.retentionRule.RuleId);
              if (data.retentionRule.IsConsentRequired) {
                this.isConsentMandatory = true;
                this.SubmitRequestForm.get('CONSENT_TYPE')?.setValidators([Validators.required]);
              }
              else {
                this.isConsentMandatory = false;
                this.SubmitRequestForm.get('CONSENT_TYPE')?.clearValidators();
              }
            }
            else {
              this.SubmitRequestForm.controls['DPPR_RULE_ID'].setValue("");
              this.isConsentMandatory = false;
              this.SubmitRequestForm.get('CONSENT_TYPE')?.clearValidators();
            }
            if (data.rightToForget && data.rightToForget.Status) {
              this.Has_RTF_Rule = data.rightToForget.HasRule;
              this.SubmitRequestForm.controls['FK_RTF_RULE_ID'].setValue(data.rightToForget.RuleId);
            }
            else {
              this.SubmitRequestForm.controls['FK_RTF_RULE_ID'].setValue("");
            }
          }
          else {
            this.SubmitRequestForm.controls['DPPR_RULE_ID'].setValue("");
            this.SubmitRequestForm.controls['FK_RTF_RULE_ID'].setValue("");
            this.SubmitRequestForm.get('CONSENT_TYPE')?.clearValidators();
            if (data.ErrorMessage) {
              this.alertyfyService.errorBaner(data.ErrorMessage);
            }
            else {
              this.alertyfyService.errorBaner("Oops! Please try after sometime.");
            }
          }
          consentTypeControl.updateValueAndValidity();
        },
        error: err => {
          this.SubmitRequestForm.controls['DPPR_RULE_ID'].setValue("");
          this.SubmitRequestForm.controls['FK_RTF_RULE_ID'].setValue("");
          this.SubmitRequestForm.get('CONSENT_TYPE')?.clearValidators();
          console.error(err);
        }
      });
    }
  }


}
