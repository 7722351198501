import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProductsAutoComplete } from '../_models/products';
import { UtilityService } from './utility.service';
@Injectable({
  providedIn: 'root'
})
export class ProductControlService {

  constructor(public utilityService: UtilityService) { }

  searchProducts(products: ProductsAutoComplete[], searchKey: string): Observable<ProductsAutoComplete[]> {
    //searchKey = this.utilityService.replaceEmojis(searchKey);
    if (!searchKey || searchKey.length == 0) {
      return of(products);
    }
    else if (searchKey.length < 3) {
      let prods: ProductsAutoComplete[] = [];
      prods.push(
        {
          PK_PRODUCT_MAPPING_ID: '',
          PRODUCT_DISPLAY_NAME: 'Please enter at least 3 characters to perform search',
          MIQ_PRODUCT_APPROVAL_ID: '',
          IS_INVALID: true
        });

      return of(prods);
    }
    else {

      let filteredProducts = products != null ? products.filter(p => p.PRODUCT_DISPLAY_NAME.toLowerCase().indexOf((searchKey.toLowerCase())) > -1) : null;
      if (filteredProducts && filteredProducts.length > 0) {
        return of(filteredProducts);
      }
      else {
        let prods: ProductsAutoComplete[] = [];
        prods.push(
          {
            PK_PRODUCT_MAPPING_ID: '',
            PRODUCT_DISPLAY_NAME: "No Results for '" + searchKey + "'",
            MIQ_PRODUCT_APPROVAL_ID: '',
            IS_INVALID: true
          });

        return of(prods);
      }
    }
  }

}
