import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/shared/AppConstants';
import { MyProductTypes, MyRequest, MyRequestSearch, MyRequestTypes, RequestAckModel, RequestModel } from '../_models/request-model';
import { UtilityService } from './utility.service';
import * as moment from 'moment';
import { APIResponse } from '../_models/apiResponse';
import { REQUEST_SOURCE } from '../_enums/constant-types.enum';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  currentdate: Date = new Date();
  constructor(private http: HttpClient, private utility: UtilityService) { }

  saveRequest(requestModel: RequestModel): Observable<APIResponse> {
    requestModel.EnvironmentID = AppConstants.EnvironmentId;
    if (!requestModel["FK_REPORTER_TYPE_ID"]) {
      requestModel["FK_REPORTER_TYPE_ID"] = AppConstants.ReporterTypeId;
    }
    requestModel.ReporterTypeName = AppConstants.ReporterTypeName ?? '';
    return this.http.post<APIResponse>(AppConstants.PortalApiBaseUrl + '/api/Request/SaveRequest', requestModel);
  }

  GetMyRequests(criteria: MyRequestSearch): Observable<Array<MyRequest>> {
    criteria.PortalID = this.utility.getPortalID();
    criteria.EnvironmentID = AppConstants.EnvironmentId;
    criteria.UserID = this.utility.getAppUserInfo().PK_UserId;
    criteria.UITIMEZONE = moment(this.currentdate).format('Z');
    return this.http.post<Array<MyRequest>>(AppConstants.PortalApiBaseUrl + '/api/Request/GetMyRequests', criteria);
  }

  GetMyRequestTypes(): Observable<Array<MyRequestTypes>> {
    const criteria = {
      UserID: this.utility.getAppUserInfo().PK_UserId,
      PortalID: this.utility.getPortalID(),
      EnvironmentID: AppConstants.EnvironmentId,
    };
    return this.http.post<Array<MyRequestTypes>>(AppConstants.PortalApiBaseUrl + '/api/Request/GetRequestTypes', criteria);
  }

  GetMyProductTypes(): Observable<Array<MyProductTypes>> {
    const criteria = {
      UserID: this.utility.getAppUserInfo().PK_UserId,
      PortalID: this.utility.getPortalID(),
      EnvironmentID: AppConstants.EnvironmentId,
    };
    return this.http.post<Array<MyProductTypes>>(AppConstants.PortalApiBaseUrl + '/api/Request/GetProductTypes', criteria);
  }

  GetRequest(requestID: string): Observable<RequestModel> {
    const criteria = {
      UserID: this.utility.getAppUserInfo().PK_UserId,
      PortalID: this.utility.getPortalID(),
      EnvironmentID: AppConstants.EnvironmentId,
      PK_REQUEST_ID: requestID,
      REQUEST_SOURCE: REQUEST_SOURCE.PORTALS,
    }
    return this.http.post<RequestModel>(AppConstants.PortalApiBaseUrl + '/api/Request/GetRequest', criteria);
  }

  GetRequestAckDetails(requestID: string): Observable<RequestAckModel> {
    const criteria = {
      UserID: this.utility.getAppUserInfo().PK_UserId,
      PortalID: this.utility.getPortalID(),
      EnvironmentID: AppConstants.EnvironmentId,
      PK_REQUEST_ID: requestID
    }
    return this.http.post<RequestAckModel>(AppConstants.PortalApiBaseUrl + '/api/Request/GetRequestAckDetails', criteria);
  }

  deleteRequest(requestID: string): Observable<APIResponse> {
    const criteria = {
      UserID: this.utility.getAppUserInfo().PK_UserId,
      PortalID: this.utility.getPortalID(),
      EnvironmentID: AppConstants.EnvironmentId,
      PK_REQUEST_ID: requestID
    }
    return this.http.post<APIResponse>(AppConstants.PortalApiBaseUrl + '/api/Request/DeleteRequest', criteria);
  }
}


