import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../../shared/AppConstants';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';
import { UtilityService } from '../_services/utility.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  platformId: any;
  constructor(public authService: AuthenticationService,
    private alertifyService: AlertifyService,
    private router: Router,
    private utilityService: UtilityService, @Inject(PLATFORM_ID) platformId: any) { this.platformId = platformId; }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    // loder need to implement
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let cookie;
    if (isPlatformBrowser(this.platformId)) {
      cookie = localStorage.getItem('cookie');
      const accessToken = localStorage.getItem('access_token');
      const isApiUrl = request.url.startsWith(AppConstants.PortalApiBaseUrl);
      request = request.clone({
        withCredentials: true
      });
      if (accessToken && isApiUrl) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
          }
        });
      }

      request = request.clone({
        setHeaders: {
          PortalCookie: `${cookie}`
        }
      });
    }



    //return next.handle(request);
    this.requests.push(request);
    return new Observable((observer => {
      const subscription = next.handle(request)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(request);
              observer.next(event);
            }
          },
          err => {
            switch (err && err.status) {
              case 401:
                console.log('401 : unauthorized');
                this.callLogout()
                break;
              case 403:
                this.callLogout();
                break;
              case 500:
                //this.autheservice.checkSession();
                break;
              case 803:
                this.callLogout();
                this.alertifyService.errorBaner("Session timed out, please login.");
                break;
              case 804:
                this.callLogout();
                this.alertifyService.errorBaner("Session timed out, please login.");
                break;
              default:
                break;
            }
            observer.error(err && err.message);
            this.removeRequest(request);
          },
          () => {
            this.removeRequest(request);
            observer.complete();
          });
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    }));
  }

  callLogout() {
    let IsSessionTimeOut = false;
    this.authService.logoutResponse(IsSessionTimeOut).subscribe((response) => {
      console.log('logout-res: ', response);
      if (isPlatformBrowser(this.platformId)) {
        this.authService.clearLocalStorage();
      }
      this.authService.loginStatus.next(false);
      this.utilityService.isProfileSelection = true;
      this.utilityService.actionOnSelfQualification(false);
      this.authService.stopRefreshTokenTimer();
      this.router.navigate(['/']);
    });
  }

}
