import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Route, Router, NavigationEnd } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ACCESSTYPES, AccountLoginErrors, RegistrationErrors } from 'src/app/_enums/constant-types.enum';
import { VerificationPopupInput } from 'src/app/_models/register-response';
import { UserLoginResult, UserParams } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { HomeService } from 'src/app/_services/home.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { EditProfileComponent } from '../../user-profile/user-profile/edit-profile/edit-profile.component';
import { PortalProfiles, SearchComponents } from '../../_models/portals';
import { NotifyService } from '../../_services/notify.service';
import { AccountVerificationComponent } from '../account-verification/account-verification.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { RegisterComponent } from '../register/register.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI | undefined;
  public onClose: Subject<boolean>;
  SubmitLoginForm: FormGroup;
  submitted = false;
  invalidCredentials = false;
  userNotVerified = false;
  userDeactivated = false;
  passwordExpired = false;
  noAccountFound = false;
  deactivatedDate?: Date;
  userLoginResult: UserLoginResult = {};
  userAuthenticationType: string | null;
  isEnableCls: boolean = true;
  selectedList: any;
  disableClose: boolean = false;
  data: any;
  public onLogin: EventEmitter<UserLoginResult> = new EventEmitter();
  IsforcetochangePWD: boolean = false;
  platformId: any;
  userDetailsJson: any;

  profilename?: string;
  constructor(private authService: AuthenticationService,
    public utilityService: UtilityService,
    public homeService: HomeService,
    private alertifyService: AlertifyService,
    private bsModalRef: BsModalRef,
    private forgetPWD_bsModalRef: BsModalRef,
    private register_bsModalRef: BsModalRef,
    private verify_bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private notifyService: NotifyService,
    private router: Router,
    public options: ModalOptions, @Inject(PLATFORM_ID) platformId: any) {
    this.platformId = platformId;
    this.onClose = new Subject();
  }

  ngOnInit(): void {
    this.data = this.options.initialState as any;
    if (isPlatformBrowser(this.platformId)) {
      this.userAuthenticationType = localStorage.getItem('UserAuthenticationType');
    }

    //this.isEnableCls = this.isEnableClose();
    this.setFormGroup();

    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo && profileinfo.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED && !profileinfo.IS_LOGIN_OPTIONAL) {
      this.isEnableCls = false;
      //this.close('Mandate');
    }

    //this.homeService.selectedProfileObserver.subscribe((response) => {
    //  this.selectedList = response;
    //  if (this.selectedList && this.selectedList.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED && !this.selectedList.IS_LOGIN_OPTIONAL) {
    //    this.isEnableCls = false;
    //    this.close();
    //  }
    //});

    this.homeService.selectedProfileObserver.subscribe((response) => {
      if (response) {
        this.profilename = response.NAME;
      }
    });

  }

  get form() {
    return this.SubmitLoginForm.controls;
  }

  get enableLogin() {
    let isValid = true;
    if ((this.SubmitLoginForm.controls["USER_ID"].value != null
      && this.SubmitLoginForm.controls["USER_ID"].value.trim() == "")
      || (!this.SubmitLoginForm.controls["USER_ID"].value)) {
      isValid = false;
    }
    if ((this.SubmitLoginForm.controls["PASSWORD"].value != null
      && this.SubmitLoginForm.controls["PASSWORD"].value.trim() == "")
      || (!this.SubmitLoginForm.controls["PASSWORD"].value)) {
      isValid = false;
    }
    return isValid;
  }

  setFormGroup() {
    this.SubmitLoginForm = new FormGroup({
      USER_ID: new FormControl("", Validators.required),
      PASSWORD: new FormControl("", Validators.required),
    });
  }

  login() {
    this.cleanForm();
    this.invalidCredentials = false;
    this.userNotVerified = false;
    this.userDeactivated = false;
    this.passwordExpired = false;
    this.noAccountFound = false;
    this.IsforcetochangePWD = false;
    this.submitted = true;
    this.userLoginResult = {};
    let IsValid = true;
    let errrs: any = [];
    if (!this.SubmitLoginForm.controls["USER_ID"].valid) {
      errrs.push("Please enter User ID/ Email ID");
      IsValid = false;
    }
    if (!this.SubmitLoginForm.controls["PASSWORD"].valid) {
      errrs.push("Please enter Password.");
      IsValid = false;
    }

    if (!IsValid) {
      let errMessage = '';
      for (var i = 0; i < errrs.length; i++) {
        errMessage += errrs[i] + ' </br>';
      }
    }
    else {
      let profileinfo: PortalProfiles = {};
      let profile_id = "";
      profileinfo = this.utilityService.getSelectedProfile();
      if (profileinfo.PK_ENV_PROFILE_ID) {
        profile_id = profileinfo.PK_ENV_PROFILE_ID;
      }
      this.authService.login(this.form.USER_ID.value, this.form.PASSWORD.value, profile_id ?? '')
        .subscribe({
          next: (data) => {
            this.userLoginResult = data;
            if (data.Errors && data.Errors.length > 0) {
              data.Errors.forEach(error => {
                switch (error) {
                  case RegistrationErrors.InvalidCredentials:
                    this.invalidCredentials = true;
                    this.clearFormData();
                    break;
                  case RegistrationErrors.RegisteredNotVerified:
                    this.userNotVerified = true;
                    break;
                  case RegistrationErrors.RegisteredDeactivated:
                    this.deactivatedDate = data.DeactivatedDate;
                    this.userDeactivated = true;
                    break;
                  case RegistrationErrors.PasswordExpired:
                    this.passwordExpired = true;
                    break;
                  case AccountLoginErrors.ProfileNotFound:
                    var errors: any = [];
                    errors.push("User environment profile not found. please contact administrator.");
                    this.alertifyService.errorBaner(errors);
                    break;
                  case AccountLoginErrors.UserNotAllowedToProfile:
                    var errors: any = [];
                    errors.push("User not allowed to this profile. Please login with appropriate profile");
                    this.alertifyService.errorBaner(errors);
                    break;
                  case RegistrationErrors.NoAccountFound:
                    var errors: any = [];
                    errors.push("No account exists with this email ID. Please enter valid registered email id or click on Register to continue Login");
                    this.alertifyService.errorBaner(errors);
                    this.noAccountFound = true;
                    break;
                  case RegistrationErrors.PWD_POLICIES_NOT_CONFIGURED:
                    var errors: any = [];
                    errors.push("Password policies not configured. Please contact your administrator.");
                    this.alertifyService.errorBaner(errors);
                    this.noAccountFound = true;
                    break;
                  case RegistrationErrors.IsForcedPWDChange:
                    this.IsforcetochangePWD = true;
                    break;
                  case RegistrationErrors.MultiBrowserLogin:
                    let params: UserParams = {
                      USER_ID: data.UserID,
                      EnvironmentID: data.FK_EnvironmentId,
                    }
                    this.alertifyService.ConfirmationBanner("You are already logged in from another browser. Do you want to proceed with the new session?", this.sessionOverrideOkClick, "Proceed", this.authService.logout);
                    break;
                }
              });
            }
            else {
              this.onLogin.emit(data);
              let userProfile: PortalProfiles = {
                ACCESS_TYPE_CODE: data.ACCESS_TYPE_CODE,
                PK_ENV_PROFILE_ID: data.FK_ENV_PROF_ID,
                PROF_TYPE_CODE: data.PROF_TYPE_CODE,
                NAME: data.ENV_PROFILE,
                IS_LOGIN_OPTIONAL: data.IS_LOGIN_OPTIONAL,
              };
              this.homeService.setselectedProfile(userProfile);
              if (isPlatformBrowser(this.platformId)) {
                if (userProfile) {
                  localStorage.setItem('profInfo', JSON.stringify(userProfile));
                }

              }

              this.close();
              this.checkPwdExpiry(data.PASSWORD_CREATED_DATE);
              if (data.PROFESSION_UN_MAPPED || !data.IS_FORCE_COUNTRY_SELECTION) {
                this.editProfile(data);
              }
            }
            if (this.userNotVerified || this.userDeactivated) {
              this.openVerificationPopup(data);
            }
            if (this.IsforcetochangePWD) {
              this.openResetPopup(data);
            }
          },
          error: (err) => {
            console.error(err);
            var errors: any = [];
            errors.push("Oops! Please try after sometime.");
            this.alertifyService.errorBaner(errors);
          }
        });
    }
  }

  sessionOverrideOkClick = () => {
    this.authService.LogoutUserInOtherBrowser().subscribe(res => {
      if (res == 200) {
        this.userDetailsJson = localStorage.getItem('cpappuserinfo');
        let userDetails = JSON.parse(this.userDetailsJson);
        this.authService.setLocalStorage(userDetails);
        this.utilityService.setEnv();

        this.onLogin.emit(userDetails);
        let userProfile: PortalProfiles = {
          ACCESS_TYPE_CODE: userDetails.ACCESS_TYPE_CODE,
          PK_ENV_PROFILE_ID: userDetails.FK_ENV_PROF_ID,
          PROF_TYPE_CODE: userDetails.PROF_TYPE_CODE,
          NAME: userDetails.ENV_PROFILE,
          IS_LOGIN_OPTIONAL: userDetails.IS_LOGIN_OPTIONAL,
        };
        this.homeService.setselectedProfile(userProfile);
        if (isPlatformBrowser(this.platformId)) {
          if (userProfile) {
            localStorage.setItem('profInfo', JSON.stringify(userProfile));
          }

        }

        this.close();
        this.checkPwdExpiry(userDetails.PASSWORD_CREATED_DATE);
        if (userDetails.PROFESSION_UN_MAPPED) {
          this.editProfile(userDetails);
        }

        if (this.userNotVerified || this.userDeactivated) {
          this.openVerificationPopup(userDetails);
        }
        if (this.IsforcetochangePWD) {
          this.openResetPopup(userDetails);
        }

        // this.continueLogin(userDetails);
      }
      else {
        this.authService.logoutResponse;
        this.authService.loginStatus.next(false);
      }
    });
  }

  editProfile(userdata: any) {
    const config: ModalOptions = {
      initialState: <any>userdata,
      backdrop: 'static',
      class: 'modal-xl modal-dialog-centered',
      keyboard: false,
    };

    this.bsModalRef = this.modalService.show(EditProfileComponent, config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onSuccess.subscribe((result: boolean) => {
      if (result) {
        //window.location.reload();
      }
    });
  }


  checkPwdExpiry(password_date?: Date) {
    this.blockUI?.stop();
    this.authService.getPasswordPolicy().subscribe({
      next: (data) => {
        if (password_date && data.PASSWORD_EXPIRY_DAYS) {
          let password_expiry_date: Date = new Date(password_date);
          password_expiry_date.setDate(password_expiry_date.getDate() + data.PASSWORD_EXPIRY_DAYS);
          let expDays = this.countDays(password_expiry_date);
          if (expDays) {
            if (expDays.toString().includes('-')) {
              expDays = parseInt(expDays.toString().replace('-', ''));
            }
            //if (next.PWD_EXPIRY_WARN_DAYS && (next.PASSWORD_EXPIRY_DAYS - expDays) < next.PWD_EXPIRY_WARN_DAYS) {
            if (data.PWD_EXPIRY_WARN_DAYS && expDays <= data.PWD_EXPIRY_WARN_DAYS) {
              let msg = `Your password is going to expire in ${expDays} Days. Please change your password.`;
              this.notifyService.showInfo(msg, 'Password Expiry');
            }
          }
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  countDays(lastModifiedDate: Date) {
    let Difference_In_Days: number = (new Date().getTime() - new Date(lastModifiedDate).getTime()) / (1000 * 3600 * 24);
    return Math.floor(Difference_In_Days);
  }

  openResetPopup(next: UserLoginResult) {
    this.close();
    let changepwdInput = <any>{
      EMAIL_ID: next.EmailID,
      PK_USER_ID: next.PK_UserId,
    }
    const config: ModalOptions = {
      initialState: <any>changepwdInput,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(ResetPasswordComponent, config);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onLogin.subscribe((result: UserLoginResult) => {
    });
  }

  getUserProfileFeatures(searchComponents: SearchComponents) {
    this.homeService.getUserProfileFeatures(searchComponents).subscribe({
      next: (data) => {
        if (data) {
          this.setFeaturesList(data)
        } else {
          console.log("No User Profile Data")
        }
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  setFeaturesList(profileFeature: any) {
    let navigateURL: string = '';
    let r: Array<Route> = new Array<Route>();
    let isHomeExist = profileFeature.findIndex((profile: any) => profile.Code == 'SR_MI' || profile.Code == 'RQ_AE');
    if (isHomeExist != -1) {
      //r.push({
      //  path: 'home',
      //  component: HomeComponent,
      //})
      if (navigateURL == '') navigateURL = '/home';
    }

    if (profileFeature.length > 0) {
      for (let i = 0; i < profileFeature.length; i++) {
        if (profileFeature[i].Code == 'RQ_MI' && profileFeature[i].IS_ACTIVE) {
          if (navigateURL == '') navigateURL = '/request-mi';
        }
        else if (profileFeature[i].Code == 'F_MSL' && profileFeature[i].IS_ACTIVE) {
          if (navigateURL == '') navigateURL = '/find-msl';
        }
        else if (profileFeature[i].Code == 'SR_MI' && profileFeature[i].IS_ACTIVE) {
          if (navigateURL == '') navigateURL = '/find-medical-info';
        }
        else if (profileFeature[i].Code == 'NS_AN' && profileFeature[i].IS_ACTIVE) {
          if (navigateURL == '') navigateURL = '/news-announcements';
        }
        else if (profileFeature[i].Code == 'RQ_AE' && profileFeature[i].IS_ACTIVE) {
          if (navigateURL == '') navigateURL = '/report-ae';
        }
        else if (profileFeature[i].Code == 'RQ_PC' && profileFeature[i].IS_ACTIVE) {
          if (navigateURL == '') navigateURL = '/report-pc';
        }
        else if (profileFeature[i].Code == 'EV_CF' && profileFeature[i].IS_ACTIVE) {
          if (navigateURL == '') navigateURL = '/events-conferences';
        }
        else if (profileFeature[i].Code == 'OR_PD' && profileFeature[i].IS_ACTIVE) {
          if (navigateURL == '') navigateURL = '/our-products';
        }
        else if (profileFeature[i].Code == 'RESO' && profileFeature[i].IS_ACTIVE) {
          if (navigateURL == '') navigateURL = '/resources';
        }
      }
    }
    this.homeService.setUserProfileFeaturesList(profileFeature);
    this.utilityService.actionOnSelfQualification(true);

    if (this.data && this.data.navigationpath == "MSL") {
      navigateURL = '/find-msl';
    }
    else if (this.data && this.data.navigationpath == "SMI") {
      navigateURL = '/find-medical-info';
    }
    else if (this.data && this.data.navigationpath == "RMI") {
      navigateURL = '/request-mi';
    }
    else if (this.data && this.data.navigationpath == "AE") {
      navigateURL = '/report-ae';
    }
    else if (this.data && this.data.navigationpath == "PC") {
      navigateURL = '/report-pc';
    }
    this.router.navigate([navigateURL]);
  }

  openRegisterForm() {
    this.close();
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo && profileinfo.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED && !this.authService.isUserLoggedIn()) {
      this._openRegisterForm();
      return;
    }
    //this.close('Register');
    this._openRegisterForm();

  }

  _openRegisterForm(): void {
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-xl modal-dialog-centered',
      keyboard: false,
    };
    this.register_bsModalRef = this.modalService.show(RegisterComponent, initialState);
    this.register_bsModalRef.content.closeBtnName = 'Close';
  }

  openForgotPassword() {
    this.close('ForgetPWD');
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.forgetPWD_bsModalRef = this.modalService.show(ForgotPasswordComponent, initialState);
    this.forgetPWD_bsModalRef.content.closeBtnName = 'Close';
  }

  openVerificationPopup(loginResult: UserLoginResult) {
    this.close();
    let verificationInput = <VerificationPopupInput>{
      EmailID: loginResult.EmailID,
      RegisteredAlreadyVerified: false,
      RegisteredNotVerified: loginResult.RegisteredNotVerified,
      RegisteredDeactivated: loginResult.RegisteredDeactivated,
      DeactivatedDate: loginResult.DeactivatedDate,
    }
    const config: ModalOptions = {
      initialState: <any>verificationInput,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.verify_bsModalRef = this.modalService.show(AccountVerificationComponent, config);
    this.verify_bsModalRef.content.closeBtnName = 'Close';
  }

  clearFormData() {
    this.submitted = false;
    this.SubmitLoginForm.controls["USER_ID"].setValue("");
    this.SubmitLoginForm.controls["PASSWORD"].setValue("");
  }

  public cleanForm() {
    let count = 0;
    if (this.SubmitLoginForm != null && this.SubmitLoginForm != undefined) {
      Object.keys(this.SubmitLoginForm.controls).forEach(key => {

        let value: any = this.SubmitLoginForm.controls[key].value;
        if (this.SubmitLoginForm.controls[key].value != null && typeof (this.SubmitLoginForm.controls[key].value) === "string"
          && this.SubmitLoginForm.controls[key].value !== "") {
          value = this.SubmitLoginForm.controls[key].value.trim();
        }
        else if (this.SubmitLoginForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        value = this.utilityService.replaceEmojis(value);

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE") {
          this.SubmitLoginForm.controls[key].setValue(value);
        }

      });
    }
  }

  close(action: string = '') {
    // this.authService.portal.PortalAccessType = "SQ_RL";
    // if((this.authService.portal.PortalAccessType == "RL" || (this.authService.portal.PortalAccessType == "SQ_RL" && this.userAuthenticationType == "SQ_RL"))
    //    && (action == 'Close') && !this.authService.isUserLoggedIn()) {
    //   this.alertifyService.errorBaner('Please Login to portals')
    // } else
    if (action == 'Mandate' && !this.authService.isUserLoggedIn()) return;
    this.onClose.next(false);
    this.bsModalRef.hide();

    //if ((action == 'ForgetPWD' || action == 'Register') && !this.authService.isUserLoggedIn()) {
    //  this.onClose.next(false);
    //} else {
    //  this.onClose.next(false);
    //  this.bsModalRef.hide();
    //}
  }

  isEnableClose(): boolean {
    //if (this.authService.portal.PortalAccessType == "RL" || (this.authService.portal.PortalAccessType == "SQ_RL" && this.userAuthenticationType == "SQ_RL") && !this.authService.isUserLoggedIn()) {
    //  return false;
    //}
    //return true;
    let isClose: boolean = !this.authService.isUserLoggedIn() ? false : true;
    return isClose;
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.SubmitLoginForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    control.setValue($event.value);
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }

    return true;
  }


  OnNotProfileClick() {
    this.utilityService.isProfileSelection = true;
    this.utilityService.actionOnSelfQualification(false);
    localStorage.removeItem('profInfo');
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
