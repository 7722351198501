<div class="container-fluid">
    <div class="verification-content">
     <div class="row">
         <div class="col-md-12" *ngIf = "isActivated">
             <a routerLink="/" class="text-center d-block pt-2"><img src="./assets/images/logo.png" alt=""> </a>
             <p class="fs-14 text-center pt-3"> <i class="fas fa-check-circle"></i>You are successfully verified. Please <a routerLink="/" class="btn btn-primary px-3">click here</a> to redirect to {{utilityService.getPortalName().toLowerCase()}} med information portal page</p>
             <div class="text-center pt-4"></div>
         </div>
         <div class="col-md-12" *ngIf = "alreadyVerified">
            <a routerLink="/" class="text-center d-block pt-2"><img src="./assets/images/logo.png" alt=""> </a>
            <p class="fs-14 text-center pt-3"> <i class="fas fa-check-circle"></i>Account has already been activated. Please <a routerLink="/" class="btn btn-primary px-3">click here</a> to redirect to {{utilityService.getPortalName().toLowerCase()}} med information portal page</p>
            <div class="text-center pt-4"></div>
        </div>
         <div class="col-md-12" *ngIf = "codeExpired">
            <a routerLink="/" class="text-center d-block pt-2"><img src="./assets/images/logo.png" alt=""> </a>
            <p class="fs-14 text-center pt-3"> <i class="far fa-times-circle"></i>Your verification link has expired. Please click Resend Verification Link to activate.</p>
            <div class="text-center pt-4">
              <button type="button" class="btn btn-primary m-auto" [disabled]="!enableLink" (click)="resendLink()">Resend Verification Link</button>                
            </div>
            <div class="text-center pt-4">
                <span class="float-right clearfix fs-12" *ngIf="this.showTimer">Resend link button will enable in {{("0"+minutesToDday).slice(-2)}} : {{("0"+secondsToDday).slice(-2)}} </span> 
            </div>
        </div>
        <div class="col-md-12" *ngIf = "inValidLink">
            <a routerLink="/" class="text-center d-block pt-2"><img src="./assets/images/logo.png" alt=""> </a>
            <p class="fs-14 text-center pt-3"><i class="far fa-times-circle"></i> Invalid verification link. Please use a valid verification link</p>
        </div>
     </div>
   </div>
</div>