import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../../shared/AppConstants';
import { APIResponse } from '../_models/apiResponse';
import { EventConfSubsModel, EventsConferenceData, EventsConferencesModel, EventsConfParams, EventsConfsResponse, UserProfileEnv } from '../_models/eventsConference';
import { PortalProfiles } from '../_models/portals';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class EventsConferencesService {

  constructor(private http: HttpClient, private utilityService: UtilityService) { }

  uploadBannerFiles(formData: FormData): Observable<EventsConferenceData> {
    return this.http.post<EventsConferenceData>(AppConstants.PortalApiBaseUrl + '/api/File/EventsBannerBulkUpload', formData);
  }

  uploadAttachments(formData: FormData) {
    return this.http.post<EventsConferenceData>(AppConstants.PortalApiBaseUrl + '/api/File/EventsAttachmentsBulkUpload', formData);
  }

  createUpdateEventsConferences(eventsConfParams: EventsConfParams): Observable<APIResponse> {
    eventsConfParams.PortalCode = AppConstants.EnvironmentId;
    eventsConfParams.FK_EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<APIResponse>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/CreateUpdateEventsConferences', eventsConfParams);
  }

  // getEventsAndConfs(): Observable<ConferenceModel[]> {
  //   return this.http.get<ConferenceModel[]>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/GetEventsConfs');
  // }

  getEventsAndConfs(userID: string | undefined, profileId: string | undefined): Observable<EventsConferencesModel> {
    let profile_id: string = '';
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo.PK_ENV_PROFILE_ID) {
      profile_id = profileinfo.PK_ENV_PROFILE_ID;
    }
    if (!profile_id) {
      profile_id = localStorage.getItem('RT_ID') ?? '';
    }
    const obj: UserProfileEnv = {
      userID: userID,
      EnvironmentId: AppConstants.EnvironmentId,
      profileId: profile_id

    }
    return this.http.post<EventsConferencesModel>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/GetEventsConfs/', obj);
  }

  getEventsAndConfSubscriptionStatus(userID: string | undefined): Observable<EventsConferencesModel> {
    let EnvironmentId = AppConstants.EnvironmentId;
    let user_id;
    if (!userID) {
      user_id = null;
    }
    else {
      user_id = userID;
    }
    return this.http.get<EventsConferencesModel>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/GetEventsAndConferenceSubscriptionStatus/' + user_id + "/" + EnvironmentId);
  }

  postEventConfSubs(SubscribeForm: EventConfSubsModel): Observable<EventsConfsResponse> {
    SubscribeForm.FK_EnvironmentId = AppConstants.EnvironmentId;
    let profileid: string = '';
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo.PK_ENV_PROFILE_ID) {
      profileid = profileinfo.PK_ENV_PROFILE_ID;
    }

    if (!profileid) {
      profileid = localStorage.getItem('RT_ID') ?? '';
    }
    SubscribeForm.PK_ENV_PROFILE_ID = profileid;
    return this.http.post<EventsConfsResponse>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/AddSubscribers', SubscribeForm);
  }

  postEventConfEnrolls(enrollForm: EventConfSubsModel): Observable<EventsConfsResponse> {
    enrollForm.FK_EnvironmentId = AppConstants.EnvironmentId;
    enrollForm.PK_UserID = AppConstants.PkUserId;
    let profileid: string = '';
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo.PK_ENV_PROFILE_ID) {
      profileid = profileinfo.PK_ENV_PROFILE_ID;
    }

    if (!profileid) {
      profileid = localStorage.getItem('RT_ID') ?? '';
    }
    enrollForm.PK_ENV_PROFILE_ID = profileid;
    return this.http.post<EventsConfsResponse>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/Enroll', enrollForm);
  }

  unsubscribe(sid: string, eid: string): Observable<EventsConfsResponse> {
    const unsubscribeParams: any = {
      EmailID: eid,
      FK_EnvironmentId: AppConstants.EnvironmentId,
      PK_EVENT_SUBSCRIBER_ID: sid
    };
    return this.http.post<EventsConfsResponse>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/Unsubscribe/', unsubscribeParams);
  }

  searchEventsConfs(searchConfsForm: any, userID: string | undefined): Observable<any> {
    searchConfsForm.FK_EnvironmentId = AppConstants.EnvironmentId;
    searchConfsForm.PK_UserID = userID;
    return this.http.post<EventsConferencesModel>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/SearchEventsConfs', searchConfsForm);
  }

  // getHybridProductMappings(): Observable<ConferenceModel[]> {
  //   return this.http.get<ConferenceModel[]>(AppConstants.PortalApiBaseUrl + '/api/EventsConferences/GetEventsConfs');
  // }

}
