import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AppConstants } from '../../shared/AppConstants';
import { COUNTRIES, CountryAutoComplete } from '../_models/countries';
import { CP_VW_LOOKUPS, SearchLookUp, TabTitlesList } from '../_models/cpvw-lookups';
import { EnvironmentSetupResponse } from '../_models/environmentSetupResponse';
import { FileStoreParams } from '../_models/file';
import { Occupation, PreferredContact } from '../_models/msl';
import { ZipCode } from '../_models/zipcode';

@Injectable({
  providedIn: 'root'
})
export class SystemConfigService {

  constructor(private http: HttpClient) { }

  getAllCountries(countryId: number = 0): Observable<COUNTRIES[]> {
    return this.http.get<COUNTRIES[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetAllCountries/' + countryId);
  }

  getCountriesTypeHeadData(countryId: number = 0): Observable<CountryAutoComplete[]> {

    let countriesData: CountryAutoComplete[] = [];
    return this.getAllCountries(countryId).pipe(
      map(x => {
        x.forEach(c => {
          countriesData.push({
            PKCountryId: c.PK_COUNTRY_ID,
            CountryDisplayName: c.NAME ?? '',
            CountryCode: c.CODE,
            IsInValid: false
          });
        });
        return countriesData;
      }),
      shareReplay(1)
    );

  }

  getLookUps(code: string, id: number = 0): Observable<any[]> {
    let search: SearchLookUp = {
      lookupCode: code,
      EnvironmentID: AppConstants.EnvironmentId,
      lookupId: id,
    };
    return this.http.post<any[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetLookUps', search);
  }

  createEnvironmentSetup(parms: FileStoreParams): Observable<EnvironmentSetupResponse> {
    parms.PortalCode = AppConstants.EnvironmentId;
    parms.FK_EnvironmentId = AppConstants.EnvironmentId;
    return this.http.post<EnvironmentSetupResponse>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig', parms);
  }

  getAllZipCodes(): Observable<ZipCode[]> {
    return this.http.get<ZipCode[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetAllZipCodes/');
  }

  getZipCodes(code: string): Observable<ZipCode[]> {
    return this.http.get<ZipCode[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetZipCodes/' + code);
  }

  

  getOccupations(): Observable<Occupation[]> {
    return this.http.get<Occupation[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetOccupations/' + AppConstants.EnvironmentId);
  }
  getPreferredContact(contactId: number = 0): Observable<PreferredContact[]> {
    return this.http.get<PreferredContact[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetPreferedContacts/' + AppConstants.EnvironmentId + '/' + contactId);
  }

  getTabTitles(): Observable<Array<TabTitlesList>> {
    let code = AppConstants.EnvironmentId;
    return this.http.get<Array<TabTitlesList>>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetTabTitles/' + code);
  }

  getProfessionByProfile(profile_id: string, profId: number = 0): Observable<any[]> {
    let search: SearchLookUp = {
      lookupCode: profile_id,
      EnvironmentID: AppConstants.EnvironmentId,
      lookupId: profId,
    };
    return this.http.post<any[]>(AppConstants.PortalApiBaseUrl + '/api/SystemConfig/GetProfessionByProfile', search);
  }

}
