import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { PasswordValidator } from 'src/app/_helpers/custom-password-validators';
import { ComparePassword } from 'src/app/_helpers/customvalidators';
import { CountryAutoComplete } from 'src/app/_models/countries';
import { CP_VW_LOOKUPS } from 'src/app/_models/cpvw-lookups';
import { Occupation, PreferredContact } from 'src/app/_models/msl';
import { PASSWORD_POLICY } from 'src/app/_models/password-policy';
import { User, UserLoginResult, UserProfiles } from 'src/app/_models/user';
import { ZipCode } from 'src/app/_models/zipcode';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SystemConfigService } from 'src/app/_services/system-config.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { DateValidator } from '../../directives/date-validate.directive';
import { ZipcodeSelectionComponent } from '../Common/zipcode-selection/zipcode-selection.component';
import { LoginComponent } from '../login/login.component';
import { PasswordPolicyComponent } from '../password-policy/password-policy.component';
import { SubmitReviewComponent } from './submit-review/submit-review.component';
import { CalendarModule } from 'primeng/calendar';
import * as moment from 'moment';
import { HomeService } from 'src/app/_services/home.service';
import { PortalProfiles } from '../../_models/portals';
import { ACCESSTYPES } from '../../_enums/constant-types.enum';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  type: string = 'WHO_YOU_ARE';
  config: any = '';
  bsConfig: any;
  code: any = null;
  resultCode: any = null;

  SubmitRegisterForm: FormGroup;
  submitted = false;
  returnUrl: string = '';
  isValidate: boolean = true;
  resClick: boolean = false;
  CurrentContact = "";
  PreferedContactName = "";
  isPreferredEmailSetToRegisterEmail = false;
  isEmailDisabled: boolean = true;
  isPhoneDisabled: boolean = true;

  //allCountries: COUNTRIES[] = [];
  allCountries: CountryAutoComplete[] = [];
  preferedContacts: PreferredContact[] = [];
  ContactsList: PreferredContact[] = [];
  //occupationList: Occupation[] = [];
  occupationList: CP_VW_LOOKUPS[] = [];
  specialityList: CP_VW_LOOKUPS[] = [];
  passwordPolicy: PASSWORD_POLICY = {};
  primaryDegree: CP_VW_LOOKUPS[] = [];
  allZipCodes: ZipCode[] = [];
  userProfiles: Array<UserProfiles>;
  model: User;

  public onClose: Subject<boolean>;
  WHO_YOU_ARE_Next = false;
  COMMUNICATION_Next = false;
  YOUR_PRACTICE_Next = false;
  ACCOUNT_CREATION_Next = false;
  maxDate: Date = new Date();
  invalidPreferredEmail = false;
  invalidRegisteredEmail = false;
  invalidDOB = false;
  private subscribeDisplayCountries: Subscription | null = null;
  selectedCountry: string | '' = '';
  minDate: Date = new Date();
  pattern: string;
  isLoginOnly: boolean = false;
  userAuthenticationType: string | null;

  isPhoneMandatory: boolean = false;
  isEmailMandatory: boolean = false;
  isEnableCls: boolean = false;
  platformId: any;
  constructor(private configService: SystemConfigService,
    private authService: AuthenticationService,
    public utilityService: UtilityService,
    private alertify: AlertifyService,
    private homeService: HomeService,
    private formBuilder: FormBuilder,
    public modalRef: BsModalRef,
    public passwordPolicyModal: BsModalRef,
    public zipCodeModal: BsModalRef,
    private modalService: BsModalService, @Inject(PLATFORM_ID) platformId: any) {
    this.platformId = platformId;
    this.onClose = new Subject();
    this.minDate.setFullYear(this.minDate.getFullYear() - 16);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.userAuthenticationType = localStorage.getItem('UserAuthenticationType');
    }
   
    if ((this.authService.portal.PortalAccessType == "RL") || (this.authService.portal.PortalAccessType == "SQ_RL" && this.userAuthenticationType == "SQ_RL")) {
      this.isLoginOnly = true;
    }
    this.setFormGroup();
    this.getAllCountries();
    this.loadLookUps();
    this.getPasswordPolicy();



  }

  formControlValid(controlName: string): boolean {
    let isValid = true;
    if ((this.SubmitRegisterForm.controls[controlName].value != null
      && this.SubmitRegisterForm.controls[controlName].value.trim() == "")
      || (!this.SubmitRegisterForm.controls[controlName].value)) {
      isValid = false;
    }
    return isValid;
  }

  get isStep1Valid() {
    let isValid = true;
    if (!this.formControlValid("FIRST_NAME")) {
      isValid = false;
    }
    if (!this.formControlValid("LAST_NAME")) {
      isValid = false;
    }
    if (this.SubmitRegisterForm.controls["DATE_OF_BIRTH"].value == null
      || this.SubmitRegisterForm.controls["DATE_OF_BIRTH"].value == "") {
      isValid = false;
    }
    if (this.SubmitRegisterForm.controls["DATE_OF_BIRTH"].errors?.dateValidator) {
      isValid = false;
    }
    if (this.SubmitRegisterForm.controls["FK_COUNTRY_ID"] == null
      || this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].value == ""
      || this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].value == undefined) {
      isValid = false;
    }
    if ((this.SubmitRegisterForm.controls["FK_PRIMARY_DEGREE_ID"] == null ||
      this.SubmitRegisterForm.controls["FK_PRIMARY_DEGREE_ID"].value == "") ||
      (!this.SubmitRegisterForm.controls["FK_PRIMARY_DEGREE_ID"].valid)) {
      isValid = false;
    }
    return isValid;
  }

  get isStep2Valid() {
    var pno = new RegExp("^[^0-9()-]*$");
    this.invalidPreferredEmail = false;
    let isValid = true;
    if (!this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"].valid) {
      isValid = false;
    }
    else {
      let selectedValue = this.preferedContacts.find(x => x.Id == this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"].value);
      if (this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value && !this.utilityService.validateEmail(this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value)) {
        this.invalidPreferredEmail = true;
        isValid = false;
      }
      if (this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value && this.SubmitRegisterForm.controls["PREFERED_EMAIL"].errors?.email) {
        this.invalidPreferredEmail = true;
        isValid = false;
      }

      if (selectedValue != undefined && selectedValue.ContactCode == "EMAIL") {
        if (!this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value) {
          //this.SubmitRegisterForm.controls["PREFERED_EMAIL"].setValue("");
          isValid = false;
        }
      }
      else if (selectedValue != undefined &&
        selectedValue.ContactCode == "PHONE") {
        if (!this.SubmitRegisterForm.controls["PREFERED_PHONE"].value) {
          isValid = false;
        }
        if (this.SubmitRegisterForm.controls["PREFERED_PHONE"].value && pno.test(this.SubmitRegisterForm.controls["PREFERED_PHONE"].value)) {
          isValid = false;
        }
      }
    }
    return isValid;
  }

  get isStep3Valid() {
    let isValid = true;
    if (this.isLoginOnly && !this.formControlValid("FK_REPORTINGTYPE_ID")) {
      isValid = false;
    }
    if (!this.formControlValid("FK_PROFESSION_ID")) {
      isValid = false;
    }
    if (!this.formControlValid("FK_SPECAILITY_ID")) {
      isValid = false;
    }
    if (!this.formControlValid("LICENCE_NUMBER")) {
      isValid = false;
    }
    return isValid;
  }

  get isStep4Valid() {
    let isValid = true;
    this.invalidRegisteredEmail = false;
    if (this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value == "") {
      isValid = false;
    }
    if ((!this.utilityService.validateEmail(this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value)) || (this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value && this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].errors?.email)) {
      this.invalidRegisteredEmail = true;
      isValid = false;
    }
    if (this.SubmitRegisterForm.controls["USER_ID"].value == "") {
      isValid = false;
    }
    if (this.SubmitRegisterForm.controls["PASSWORD"].value == "") {
      isValid = false;
    }
    if (!this.SubmitRegisterForm.controls["USER_ID"].valid) {
      isValid = false;
    }
    if (!this.SubmitRegisterForm.controls["PASSWORD"].valid) {
      isValid = false;
    }
    if (this.SubmitRegisterForm.controls["CONFIRM_PASSWORD"].value == "") {
      isValid = false;
    }
    if (this.SubmitRegisterForm.controls["CONFIRM_PASSWORD"].valid) {
      if (this.SubmitRegisterForm.controls["PASSWORD"].valid && (this.SubmitRegisterForm.controls["PASSWORD"].value != this.SubmitRegisterForm.controls["CONFIRM_PASSWORD"].value)) {
        isValid = false;
      }
    }
    else {
      isValid = false;
    }
    return isValid;
  }

  get form() {
    return this.SubmitRegisterForm.controls;
  }

  //get minDate() {
  //  let date: Date = new Date();
  //  date.setFullYear(date.getFullYear() - 16);
  //  return date;
  //}

  setFormGroup() {
    this.bsConfig = Object.assign({}, {
      containerClass: "theme-blue",
      dateInputFormat: 'DD-MMM-YYYY',
      showWeekNumbers: false,
      maxDate: this.minDate,
    });
    this.config = {
      type: 1,
      length: 6,
      cssClass: 'custom',
      back: {
        stroke: "#2F9688",
        solid: "#f2efd2"
      },
      font: {
        color: "#000000",
        size: "35px"
      }
    };

    this.SubmitRegisterForm = this.formBuilder.group({
      FIRST_NAME: new FormControl("", Validators.required),
      LAST_NAME: new FormControl("", Validators.required),
      DATE_OF_BIRTH: new FormControl(null, [Validators.required, this.AgeValidator, DateValidator.dateValidator]),
      ZIP_CODE: new FormControl("", Validators.required),
      FK_COUNTRY_ID: new FormControl("", Validators.required),
      CITY: new FormControl(""),
      STATE_PROVINCE: new FormControl(""),
      ADDRESS: new FormControl(""),
      PHONE: new FormControl("", [Validators.pattern(/[^0-9()-]*/g)]),
      PHONE_EXT: new FormControl("", [Validators.pattern(/[^0-9]*/g)]),
      FK_PREFERRED_CONTACT_ID: new FormControl("", Validators.required),
      PREFERED_EMAIL: new FormControl("", Validators.email),
      PREFERED_PHONE: new FormControl("", Validators.pattern(/[^0-9()-]*/g)),
      PREFERED_PHONE_EXT: new FormControl("", Validators.pattern(/[^0-9]*/g)),
      HAS_RECEIVE_EMAILS: new FormControl({ value: false, disabled: true }),
      HAS_ALLOW_CALLS: new FormControl({ value: false, disabled: true }),
      FK_REPORTINGTYPE_ID: new FormControl("", Validators.required),
      FK_PROFESSION_ID: new FormControl("", Validators.required),
      FK_SPECAILITY_ID: new FormControl("", Validators.required),
      LICENCE_NUMBER: new FormControl("", Validators.required),
      PRIMARY_PRACTICE: new FormControl(""),
      REGISTERED_EMAIL_ID: new FormControl("", [Validators.required, Validators.email]),
      USER_ID: new FormControl("", Validators.required),
      PASSWORD: new FormControl(""),
      CONFIRM_PASSWORD: new FormControl("", [Validators.required]),
      FK_PRIMARY_DEGREE_ID: new FormControl("", Validators.required),
    },
      {
        // Used custom form validator name
        validator: ComparePassword("PASSWORD", "CONFIRM_PASSWORD")
      });

    this.onChanges();
  }

  onChanges() {
    const contactPreferenceControl = <FormControl>this.SubmitRegisterForm.get('FK_PREFERRED_CONTACT_ID');
    const preferredEmailControl = <FormControl>this.SubmitRegisterForm.get('PREFERED_EMAIL');
    const preferredPhoneControl = <FormControl>this.SubmitRegisterForm.get('PREFERED_PHONE');
    const otherPhoneControl = <FormControl>this.SubmitRegisterForm.get('PHONE');
    const zipCodeControl = <FormControl>this.SubmitRegisterForm.get('ZIP_CODE');
    preferredEmailControl.valueChanges
      .subscribe(value => {
        if (!value) {
          this.SubmitRegisterForm.controls["HAS_RECEIVE_EMAILS"]?.disable();
          this.SubmitRegisterForm.controls["HAS_RECEIVE_EMAILS"].setValue(false);
        }
        else {
          this.SubmitRegisterForm.controls["HAS_RECEIVE_EMAILS"]?.enable();
        }
      });
    preferredPhoneControl.valueChanges
      .subscribe(value => {
        if ((!value)
          && (!otherPhoneControl.value)) {
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"]?.disable();
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"].setValue(false);
        }
        else {
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"]?.enable();
        }
      });
    otherPhoneControl.valueChanges
      .subscribe(value => {
        if ((!value)
          && (preferredPhoneControl.value == undefined || preferredPhoneControl.value == null || preferredPhoneControl.value == "")) {
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"]?.disable();
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"].setValue(false);
        }
        else {
          this.SubmitRegisterForm.controls["HAS_ALLOW_CALLS"]?.enable();
        }
      });

    contactPreferenceControl.valueChanges
      .subscribe(value => {
        this.invalidPreferredEmail = false;
        let selectedValue = this.preferedContacts.find(x => x.Id == this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"].value);
        if (selectedValue != undefined && selectedValue.ContactCode == "EMAIL") {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = true;
          this.SubmitRegisterForm.get('PREFERED_EMAIL')?.setValidators([Validators.required, Validators.email]);
          this.SubmitRegisterForm.get('PREFERED_PHONE')?.setValidators([Validators.pattern(/[^0-9()-]*/g)]);
        }
        else if (selectedValue != undefined && selectedValue.ContactCode == "PHONE") {
          this.isPhoneMandatory = true;
          this.isEmailMandatory = false;
          this.SubmitRegisterForm.get('PREFERED_PHONE')?.setValidators([Validators.required, Validators.pattern(/[^0-9()-]*/g)]);
          this.SubmitRegisterForm.get('PREFERED_EMAIL')?.setValidators([Validators.email]);
        }
        else {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = false;
        }
        preferredEmailControl.updateValueAndValidity();
        preferredPhoneControl.updateValueAndValidity();
      });

    zipCodeControl.valueChanges
      .subscribe(value => {
        let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].value);
        if (country?.CountryCode == 'US') {
          if (value.length > 4) {
            this.subscribeDisplayCountries = this.configService.getZipCodes(value).subscribe({
              next:(zipCodes) => {
              if (zipCodes.length == 1) {
                this.setCountryWithZipCode(zipCodes[0]);
              }
              else if (zipCodes.length > 1) {
                this.SubmitRegisterForm.controls["CITY"].setValue("");
                this.SubmitRegisterForm.controls["STATE_PROVINCE"].setValue("");
                this.openMultipleZipCodePopup(zipCodes);
              }
            },
              error:(err) => {
                console.error(err);
              }});
          }
        }
      });
  }

  openMultipleZipCodePopup(zipCodes: ZipCode[]) {
    const config: ModalOptions = {
      initialState: <any>zipCodes,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false,
    };

    this.zipCodeModal = this.modalService.show(ZipcodeSelectionComponent, config);
    this.zipCodeModal.content.closeBtnName = 'Close';
    this.zipCodeModal.content.event.subscribe((res: ZipCode) => {
      this.setCountryWithZipCode(res);
    });
  }

  setCountryWithZipCode(zipcode: ZipCode) {
    this.SubmitRegisterForm.controls["CITY"].setValue(zipcode.CITY);
    this.SubmitRegisterForm.controls["STATE_PROVINCE"].setValue(zipcode.CITYSTATE);
    let country = this.allCountries.find(c => c.CountryCode == zipcode.COUNTRYCODE);
    this.selectedCountry = country?.CountryDisplayName ?? "";
    this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].setValue(country?.PKCountryId);
  }

  loadLookUps() {
    this.getPreferedContacts();
    //this.getUserProfiles();
    this.getOccupations();
    this.getSpeciality();
    this.getPrimaryDegree();
  }

  getAllCountries() {
    this.subscribeDisplayCountries = this.configService.getCountriesTypeHeadData().subscribe({
      next:(data) => {
      this.allCountries = data;
    },
      error:(err) => {
        console.error(err);
      }});
  }

  getPasswordPolicy() {
    this.authService.getPasswordPolicy().subscribe({
      next:(data) => {
      this.passwordPolicy = data;
      this.pattern = this.utilityService.getPasswordPolicyPattern(this.passwordPolicy);

      PasswordValidator.minUpperCase = this.passwordPolicy.MIN_UPPERCASE_CHARS ?? 0;
      PasswordValidator.minLowerCase = this.passwordPolicy.MIN_LOWER_CHARS ?? 0;
      PasswordValidator.minSpecialChars = this.passwordPolicy.MIN_SPECAIL_CHARS ?? 0;
      PasswordValidator.minNumbers = this.passwordPolicy.MIN_NUMERIC_CHARS ?? 0;

      const passwordValidations = [
        Validators.required,
        Validators.pattern(this.pattern),
        Validators.minLength(this.passwordPolicy.MIN_PASSWORD_LENGTH ?? 0),
        PasswordValidator.number,
        PasswordValidator.special,
        PasswordValidator.lower,
        PasswordValidator.upper,];

      this.SubmitRegisterForm.controls['PASSWORD'].setValidators(passwordValidations);
    },
      error:(err) => {
        console.error(err);
      }});
  }

  getPreferedContacts() {
    this.configService.getPreferredContact().subscribe({
      next :(data)=> {
      this.ContactsList = data;
      this.preferedContacts = data;
    },
      error:(err) => {
        console.error(err);
      }});
  }

  getPreferredContact(id: any): any {
    let pc: any = undefined;
    let _currentPC = this.ContactsList.filter(function (a: any) {
      return (a.Id == id)
    })[0];
    if (_currentPC != null && _currentPC != undefined) {
      pc = _currentPC;
    }

    return pc;
  }

  getOccupations() {
    let profiletype_id = "";
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo) {
      profiletype_id = profileinfo.PK_ENV_PROFILE_ID ?? '';
    }
    if (profiletype_id) {
      this.configService.getProfessionByProfile(profiletype_id).subscribe({
        next:(data) => {
        this.occupationList = data;
      },
        error:(err) => {
          console.error(err);
        }});
    }
  }

  getSpeciality() {
    this.configService.getLookUps('SPECIALITY').subscribe({
      next:(data) => {
      this.specialityList = data;
    },
      error:(err) => {
        console.error(err);
      }});
  }

  CP_Change(productID: any) {
    let _currentCP = this.preferedContacts.filter(function (a: any) {
      return (a.PK_LOOKUP_ID == productID)
    })[0];
    if (_currentCP != null && _currentCP != undefined) {
      this.CurrentContact = _currentCP.ContactCode ?? '';
      this.PreferedContactName = _currentCP.ContactName ?? '';
    }
    else {
      this.CurrentContact = '';
      this.PreferedContactName = '';
    }
  }

  handleInput($event: any, controlName: string): boolean {
    const control = this.SubmitRegisterForm.controls[controlName];
    $event.value = this.utilityService.replaceEmojis($event.value);
    if (controlName != "ZIP_CODE") {
      control.setValue($event.value);
    }
    else {
      control.setValue($event.value, { emitEvent: false });
    }
    if ($event && $event.value != "") {
      $event.value = $event.value.trim();
      if (controlName != "ZIP_CODE") {
        control.setValue($event.value);
      }
      else {
        control.setValue($event.value, { emitEvent: false });
      }
      return false;
    }

    return true;
  }

  validations(next: string): boolean {
    let IsValid = true;
    let errrs: any = [];
    this.cleanForm();

    if (this.type == 'WHO_YOU_ARE') {
      let country = this.allCountries.find(c => c.PKCountryId == this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].value);
      if (country != undefined) {
        this.selectedCountry = country.CountryDisplayName;
      }
      this.WHO_YOU_ARE_Next = true;
      if (!this.SubmitRegisterForm.controls["FIRST_NAME"].valid) {
        errrs.push("Please enter First Name.");
        IsValid = false;
      }
      if (!this.SubmitRegisterForm.controls["LAST_NAME"].valid) {
        errrs.push("Please enter Last Name.");
        IsValid = false;
      }
      if (!this.SubmitRegisterForm.controls["DATE_OF_BIRTH"].valid) {
        errrs.push("Please enter Date of Birth.");
        IsValid = false;
      }
      if (!this.SubmitRegisterForm.controls["FK_COUNTRY_ID"].valid) {
        errrs.push("Please select Country.");
        IsValid = false;
      }
    }
    else if (this.type == 'COMMUNICATION') {
      this.COMMUNICATION_Next = true;
      this.invalidPreferredEmail = false;

      if (!this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"].valid) {
        errrs.push("Please select Prefered Contact Method.");
        IsValid = false;
      }
      else {
        let selectedValue = this.preferedContacts.find(x => x.Id == this.SubmitRegisterForm.controls["FK_PREFERRED_CONTACT_ID"].value);
        if (selectedValue != undefined &&
          selectedValue.ContactCode == "EMAIL") {
          if (!this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value) {
            errrs.push("Please enter Email.");
            IsValid = false;
          }
          else if (!this.utilityService.validateEmail(this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value)) {
            errrs.push("Please enter valid Email.");
            this.invalidPreferredEmail = true;
            IsValid = false;
          }
        }
        else if (selectedValue != undefined &&
          selectedValue.ContactCode == "PHONE") {
          if (!this.SubmitRegisterForm.controls["PREFERED_PHONE"].value ||
            !this.SubmitRegisterForm.controls["PREFERED_PHONE"].valid) {
            errrs.push("Please enter Phone.");
            IsValid = false;
          }
          if (this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value &&
            !this.utilityService.validateEmail(this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value)) {
            errrs.push("Please enter valid Email.");
            this.invalidPreferredEmail = true;
            IsValid = false;
          }
        }
      }
    }
    else if (this.type == 'YOUR_PRACTICE') {
      this.YOUR_PRACTICE_Next = true;
      if (this.isLoginOnly && !this.SubmitRegisterForm.controls["FK_REPORTINGTYPE_ID"].valid) {
        errrs.push("Please select User Profile.");
        IsValid = false;
      }
      if (!this.SubmitRegisterForm.controls["FK_PROFESSION_ID"].valid) {
        errrs.push("Please select Profession.");
        IsValid = false;
      }
      if (!this.SubmitRegisterForm.controls["FK_SPECAILITY_ID"].valid) {
        errrs.push("Please select Speciality.");
        IsValid = false;
      }
      if (!this.SubmitRegisterForm.controls["LICENCE_NUMBER"].valid) {
        errrs.push("Please enter Licence Number.");
        IsValid = false;
      }
      if (IsValid) {
        if (!this.isPreferredEmailSetToRegisterEmail) {
          if (this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value != "" &&
            (this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value == null
              || this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value == "")) {
            this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].setValue(this.SubmitRegisterForm.controls["PREFERED_EMAIL"].value);
            this.isPreferredEmailSetToRegisterEmail = true;
          }
        }
      }
    }
    else if (this.type == 'ACCOUNT_CREATION') {
      this.ACCOUNT_CREATION_Next = true;
      this.invalidRegisteredEmail = false;
      if (this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value == "") {
        errrs.push("Please enter Email.");
        IsValid = false;
      }
      else if (!this.utilityService.validateEmail(this.SubmitRegisterForm.controls["REGISTERED_EMAIL_ID"].value)) {
        errrs.push("Please enter valid Email.");
        this.invalidRegisteredEmail = true;
        IsValid = false;
      }

      if (!this.SubmitRegisterForm.controls["USER_ID"].valid) {
        errrs.push("Please enter User ID.");
        IsValid = false;
      }
      if (!this.SubmitRegisterForm.controls["PASSWORD"].valid) {
        errrs.push("Please enter Password.");
        IsValid = false;
      }
      if (this.SubmitRegisterForm.controls["CONFIRM_PASSWORD"].valid) {
        if (this.SubmitRegisterForm.controls["PASSWORD"].valid && (this.SubmitRegisterForm.controls["PASSWORD"].value != this.SubmitRegisterForm.controls["CONFIRM_PASSWORD"].value)) {
          errrs.push("Password and Confirm Password does not match.");
          IsValid = false;
        }
      }
      else {
        errrs.push("Please enter Confirm Password.");
        IsValid = false;
      }
    }

    if (!IsValid) {
      //this.alertify.validationBaner(errrs);
      let errMessage = '';
      for (var i = 0; i < errrs.length; i++) {
        errMessage += errrs[i] + ' </br>';
      }

      //this.alertify.alert(errMessage, () => { });
    }

    if (IsValid) {
      if (next == '') {
        this.openSubmitReviewForm();
      }
      else {
        this.type = next;
      }
    }

    return IsValid;
  }

  submitRequest() {
    this.submitted = true;
    //// stop here if form is invalid
    //if (this.registerForm.invalid) {
    //  return;
    //}

    this.resClick = true;
    if (this.validations('ACCOUNT_CREATION')) {
      this.authService.userRegister(this.model).subscribe({
        next:(data) => {

      },
        error:(err) => {
          console.error(err);
        }});
    }
    else {
      this.resClick = false;
      this.alertify.alert("Validation Errors", () => { });
    }
  }

  ngOnChanges() {
    if (this.config) {
      if (!this.config.font || !this.config.font.size) {
        this.config["font"]["size"] = "40px";
      }
      if (!this.config.font || !this.config.font.family) {
        this.config["font"]["family"] = "Arial";
      }
      if (!this.config.strokeColor) {
        this.config["strokeColor"] = "#f20c6c";
      }
      if (!this.config.length) {
        this.config["length"] = 6;
      }
      if (!this.config.cssClass) {
        this.config["cssClass"] = '';
      }

      if (!this.config.type) {
        this.config["type"] = 1;
      }

      if (!this.config.back || !this.config.back.stroke) {
        this.config["back"]["stroke"] = "";
      }
      if (!this.config.back || !this.config.back.solid) {
        this.config["back"]["solid"] = "#f2efd2";
      }
    }
  }

  openLoginForm() {
    if ((this.authService.portal.PortalAccessType == "RL" || (this.authService.portal.PortalAccessType == "SQ_RL" && this.userAuthenticationType == "SQ_RL"))
      && !this.authService.isUserLoggedIn()) {
      this.close();
    } else {
      this.close();
      const initialState: ModalOptions = {
        backdrop: 'static',
        class: 'modal-dialog-centered',
        keyboard: false
      };
      this.modalRef = this.modalService.show(LoginComponent, initialState);
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.content.onLogin.subscribe((result: UserLoginResult) => {
        if (result != null) {
          this.authService.loginStatus.next(true);
          this.authService.UserFullName.next(result.UserFullName ?? "");
          this.authService.hcpDetails.next(this.utilityService.getAppUserInfoObject(result));
        }
      });
    }
  }

  openPasswordPolicy() {
    const config: ModalOptions = {
      initialState: <any>this.passwordPolicy,
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.passwordPolicyModal = this.modalService.show(PasswordPolicyComponent, config);
    this.passwordPolicyModal.content.closeBtnName = 'Close';
  }

  openSubmitReviewForm() {
    this.close();
    const config: ModalOptions = {
      initialState: this.SubmitRegisterForm.value,
      backdrop: 'static',
      class: 'modal-xl modal-dialog-centered',
      keyboard: false,
    };

    this.modalRef = this.modalService.show(SubmitReviewComponent, config);
    this.modalRef.content.closeBtnName = 'Close';
  }

  close(isClose: boolean = false) {
    this.modalRef.hide();
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo && profileinfo.ACCESS_TYPE_CODE == ACCESSTYPES.AUTHENTICATED && !profileinfo.IS_LOGIN_OPTIONAL && isClose) {
      this.loginPopup();
    }
  }

  loginPopup() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      class: 'modal-dialog-centered',
      keyboard: false
    };
    this.modalRef = this.modalService.show(LoginComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.onLogin.subscribe((result: UserLoginResult) => {
      if (result != null) {
        this.authService.loginStatus.next(true);
        this.authService.UserFullName.next(result.UserFullName ?? "");
        this.authService.hcpDetails.next(this.utilityService.getAppUserInfoObject(result));
      }
    });
  }

  AgeValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value != "Invalid Date") {
      let timeDiff = Date.now() - control.value.getTime();
      let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
      console.log(age);
      if (age < 16) {
        return { 'age': true };
      }
    }
    return null;
  }

  alphaNumberOnly(e: any) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }

    e.preventDefault();
    return false;
  }

  onPaste(e: any) {
    e.preventDefault();
    return false;
  }

  public cleanForm() {
    let count = 0;
    if (this.SubmitRegisterForm != null && this.SubmitRegisterForm != undefined) {
      Object.keys(this.SubmitRegisterForm.controls).forEach(key => {

        //console.log(this.SubmitRequestForm.controls[key].value + ": count:" + count++);
        //console.log(typeof (this.SubmitRequestForm.controls[key].value) + ": count:" + count++);

        let value: any = this.SubmitRegisterForm.controls[key].value;
        if (this.SubmitRegisterForm.controls[key].value != null && typeof (this.SubmitRegisterForm.controls[key].value) === "string"
          && this.SubmitRegisterForm.controls[key].value !== "") {
          value = this.SubmitRegisterForm.controls[key].value.trim();
        }
        else if (this.SubmitRegisterForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE" && key != "FK_REPORTINGTYPE_ID"
          && key != "FK_PROFESSION_ID" && key != "FK_SPECAILITY_ID"
          && key != "HAS_ALLOW_CALLS" && key != "HAS_RECEIVE_EMAILS") {
          this.SubmitRegisterForm.controls[key].setValue(value);
        }

      });
    }
  }

  MinMaxDateValidation(event: any, fcn: string, MinMaxFlag?: string, MinMaxDateVal?: Date) {
    this.utilityService.ValidateDate(event.target.value, fcn, MinMaxFlag, MinMaxDateVal, this.SubmitRegisterForm);
  }

  getPrimaryDegree() {
    this.configService.getLookUps('PRIMARY_DEGREE').subscribe({
      next:(data) => {
      this.primaryDegree = data;
    },
      error:(err) => {
        console.error(err);
      }});
  }


}
