<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title w-100 d-block" id="exampleModalLabel">Forgot Password 
        <a (click)="close(true)" class="fs-12 text-underline float-right pt-1 text-white" data-dismiss="modal"><i class="fas fa-times"></i></a>
      </h5>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
          <form [formGroup]="forgotPasswordForm">
            <div class="row" *ngIf = "userDoesnotExists">
                <div class="col-md-12">
                  <span class="fs-14 text-center pb-3">No User found with email. Please click here to <a href="javascript:void(0)"
                  (click)="openRegisterForm()">Register</a></span></div>
            </div>
            <div class="row" *ngIf = "!userDoesnotExists">
                <div class="col-md-12">
                  <p class="fs-14">No Worries! Enter your registered email ID linked to account. We will send you Reset Password code to email.</p>
                </div>
                <div class="col-md-12">
                  <div class="form-group my-2">
                    <label class="fieldLabel mandatory">Registered Email ID</label>
                    <input type="text" class="form-control"  formControlName="EmailID" 
                    (blur)="handleInput($event.target,'EmailID');" autocomplete="off" maxlength="100"
                    [ngClass]="{ 'is-invalid': (form.EmailID.dirty || form.EmailID.touched || submitted) && (form.EmailID.errors || invalidEmail) }">
                    <div *ngIf="(form.EmailID.dirty || form.EmailID.touched || submitted) && (form.EmailID.invalid || invalidEmail)" class="invalid-form-control text-danger fs-12">
                      <div *ngIf="form.EmailID.errors?.['required']">
                        Email ID is required.
                      </div>      
                      <div *ngIf="!form.EmailID.errors?.['required'] && (form.EmailID.errors?.['email'] || invalidEmail)">
                        Please enter valid Email.
                      </div>                
                    </div>
                    <!-- <div *ngIf="submitted && noAccountFound" class="invalid-form-control text-danger fs-12">
                      No account exists with this email ID. Please enter valid registered email id or click on Register to continue Login
                    </div> -->
                    <div *ngIf = "submitted && userDeactivated">
                      You have requested for deactivation of your account on {{this.deactivatedDate | date:'dd-MMM-YYYY'}} please click on <a href="javascript:void(0)"
                        (click)="openVerificationPopup(userResult)">Verify</a> to reactivate your account for Login
                    </div>
                </div>
                </div>
                <div class="col-md-12 py-2">
                  <button type="button" (click)="requestPassword()" 
                  [disabled]="!this.enableContinue" class="btn btn-primary btn-block fs-14">Continue</button>
                </div>

            </div>
          </form>
        </div>
    </div>
  </div>