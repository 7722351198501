import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../../shared/AppConstants';
import { NewsAnnouncementsData, NewsAnnouncementsSubscribe } from '../_models/newsAnnouncements';
import { PortalProfiles } from '../_models/portals';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class NewsAnnouncementsService {

  constructor(private http: HttpClient, private utilityService: UtilityService) { }

  getNewsAndAnnouncements(PkUserId: string, ProfileId: string): Observable<NewsAnnouncementsData> {
    const userParams: any = {
      FK_EnvironmentId: AppConstants.EnvironmentId,
      PK_UserID: PkUserId,
      ProfileId: ProfileId
    };
    return this.http.post<NewsAnnouncementsData>(AppConstants.PortalApiBaseUrl + '/api/NewsAnnouncements/GetNewsAnnouncements', userParams);
  }

  subscribeNewsAnnnouncements(subscribe: NewsAnnouncementsSubscribe): Observable<NewsAnnouncementsData> {
    subscribe.FK_EnvironmentId = AppConstants.EnvironmentId;
    let profileid: string = '';
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo.PK_ENV_PROFILE_ID) {
      profileid = profileinfo.PK_ENV_PROFILE_ID;
    }

    if (!profileid) {
      profileid = localStorage.getItem('RT_ID') ?? '';
    }
    subscribe.PK_ENV_PROFILE_ID = profileid;
    return this.http.post<NewsAnnouncementsData>(AppConstants.PortalApiBaseUrl + '/api/NewsAnnouncements/Subscribe', subscribe);
  }

  unsubscribe(sid: string, eid: string): Observable<NewsAnnouncementsData> {
    const unsubscribeParams: any = {
      EmailID: eid,
      FK_EnvironmentId: AppConstants.EnvironmentId,
      PK_NEWS_SUBSCRIBER_ID: sid
    };
    return this.http.post<NewsAnnouncementsData>(AppConstants.PortalApiBaseUrl + '/api/NewsAnnouncements/Unsubscribe/', unsubscribeParams);
  }
}
