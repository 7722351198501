import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User, UserParams } from 'src/app/_models/user';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.css']
})
export class AccountActivationComponent implements OnInit {

  isActivated: boolean = false;
  isResendActivation: boolean = false;
  isAlreadyActivated: boolean = false;
  userName: string = '';
  userParams: UserParams = {};
  userModel: User;


  constructor(private route: ActivatedRoute, private authService: AuthenticationService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(
      params => {
        // Check whether any parameters provided
        if (params.hasOwnProperty('uid') && params.hasOwnProperty('code')) {
          this.userParams.USER_ID = params['uid'];
          this.userParams.ACTIVATION_CODE = params['code'];
          this.getUserAccount();
        }
      });
  }

  getUserAccount() {
    this.authService.getUserAccount(this.userParams).subscribe({
      next:(result) => {

      if (result) {
        // if (next.IS_ACTIVATED) {
        //   this.isAlreadyActivated = true;
        // }
        // else {
          this.isAlreadyActivated = false;
          this.userModel = result;
          //this.userParams.PK_USER_ID = next.PK_USER_ID;
          this.userParams.USER_ID = result.USER_ID;
          //this.userParams.ACTIVATION_CODE = next.ACTIVATION_CODE;
          this.accountActivation();
        //}
      }
      else {
        this.isActivated = false;
      }     
    },
      error:(err) => {
        console.error(err);
      }});
  }

  accountActivation() {
    // this.authService.accountActivation(this.userParams).subscribe(next => {
    //   this.isActivated = next;
    // },
    //   error => {
    //     console.error(error);
    //   });
  }

  resendActivation() {
    this.authService.resendActivation(this.userParams).subscribe({
      next:(result) => {
      this.isResendActivation = result;
    },
      error:(err) => {
        console.error(err);
      }});
  }

}
