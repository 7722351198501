import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccountVerifyComponent } from './externalpage-components/account-verify/account-verify.component';
import { AccountActivationComponent } from './components/account-activation/account-activation.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { SupportLoginComponent } from './components/support-login/support-login.component';
import { AccountResetpasswordComponent } from './externalpage-components/account-resetpassword/account-resetpassword.component';
import { EventUnsubscribeComponent } from './externalpage-components/event-unsubscribe/event-unsubscribe.component';
import { NewsUnsubscribeComponent } from './externalpage-components/news-unsubscribe/news-unsubscribe.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { ProfileFeatureGuard } from './_helpers/profile.guard';
import { HomeComponent } from './components/home/home.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { SeoTagsResolver } from './_resolver/seotags.resolver.service';
import { AboutPageComponent } from './about-page/about-page.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'HOME'
    }
  },
  {
    path: '',
    redirectTo: 'home',
    /*resolve: { seoTags: SeoTagsResolver },*/
    pathMatch: 'full',

  },
  {
    path: 'sitemap',
    component: SitemapComponent,
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'ST_MP'
    }
  },
  {
    path: 'slogin',
    component: SupportLoginComponent,
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'SA_LGN'
    }
  },
  {
    path: 'account-activate',
    component: AccountActivationComponent
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./super-admin/super-admin.module').then(m => m.SuperAdminModule)
  },
  {
    path: 'account-verify',
    component: AccountVerifyComponent
  },
  {
    path: 'account-resetpassword',
    component: AccountResetpasswordComponent
  },
  {
    path: 'event-unsubscribe',
    component: EventUnsubscribeComponent
  },
  {
    path: 'news-unsubscribe',
    component: NewsUnsubscribeComponent
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./user-profile/user-profile.module').then(r => r.UserProfileModule)
  },
  {
    path: 'my-requests',
    loadChildren: () => import('./user-request/user-request.module').then(r => r.UserRequestModule)
  },
  {
    path: 'request-mi',
    loadChildren: () => import('./request-mi/request-mi.module').then(r => r.RequestMIModule),
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'RQ_MI'
    }
  },
  {
    path: 'about-page',
    //resolve: { seoTags: SeoTagsResolver },
    component: AboutPageComponent,
  },
  {
    path: 'find-msl',
    loadChildren: () => import('./msl/msl.module').then(m => m.MslModule),
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'F_MSL'
    }
  },
  {
    path: 'find-medical-info',
    loadChildren: () => import('./find-medical/find-medical.module').then(m => m.FindMedicalModule),
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'SR_MI'
    }
  },
  {
    path: 'news-announcements',
    loadChildren: () => import('./news-announcements/news-announcements.module').then(n => n.NewsAnnouncementsModule),
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'NS_AN'
    }
  },
  {
    path: 'report-ae',
    loadChildren: () => import('./report-ae/report-ae.module').then(r => r.ReportAEModule),
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'RQ_AE'
    }
  },
  {
    path: 'report-pc',
    loadChildren: () => import('./report-pc/report-pc.module').then(r => r.ReportPCModule),
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'RQ_PC'
    }
  },
  {
    path: 'events-conferences',
    loadChildren: () => import('./events-conferences/events-conferences.module').then(e => e.EventsConferencesModule),
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'EV_CF'
    }
  },
  {
    path: 'our-products',
    loadChildren: () => import('./our-products/our-products.module').then(o => o.OurProductsModule),
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'OR_PD'
    }
  },
  {
    path: 'file-preview',
    loadChildren: () => import('./file-preview/file-preview.module').then(o => o.FilePreviewModule),
  },
  {
    path: 'resources',
    component: ResourcesComponent,
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'RESO'
    }
  },
  {
    path: 'error-page',
    component: ErrorPageComponent,
    canActivate: [ProfileFeatureGuard],
    data: {
      featurecode: 'Error'
    }
  },
  {
    path: '**',
    redirectTo: 'error-page',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
