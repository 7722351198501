import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CP_VW_LOOKUPS } from 'src/app/_models/cpvw-lookups';
import { ApplicableRuleRequest, RequestModel } from 'src/app/_models/request-model';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { RequestService } from 'src/app/_services/request.service';
import { SystemConfigService } from 'src/app/_services/system-config.service';
import { UtilityService } from 'src/app/_services/utility.service';
import { CalendarModule } from 'primeng/calendar';
import { PreferredContact } from '../../../_models/msl';
import { PortalProfiles } from '../../../_models/portals';
import { Subscription } from 'rxjs';
import { CountryAutoComplete } from '../../../_models/countries';
import { SubmitRequestService } from '../../../_services/submit-request.service';
@Component({
  selector: 'app-view-edit-meeting-request',
  templateUrl: './view-edit-meeting-request.component.html',
  styleUrls: ['./view-edit-meeting-request.component.css']
})
export class ViewEditMeetingRequestComponent implements OnInit {
  MSLRequestForm: FormGroup;
  Data: any;
  RequestData: RequestModel;
  IsView: boolean = false;
  bsConfig: any;
  submitted: boolean = false;
  preferedContacts: PreferredContact[] = [];
  //preferedContacts: CP_VW_LOOKUPS[] = [];
  specialityList: CP_VW_LOOKUPS[] = [];
  primaryDegreeList: CP_VW_LOOKUPS[] = [];
  professionList: CP_VW_LOOKUPS[] = [];
  invalidPreferredEmail = false;
  minDate: Date = new Date();
  isConsentMandatory?: boolean = false;

  public OnSubmit: EventEmitter<boolean> = new EventEmitter();
  private subscribeDisplayCountries: Subscription | null = null;
  allCountries: CountryAutoComplete[] = [];
  selectedCountry: string | '' = '';

  isEmailMandatory: boolean = false;
  isPhoneMandatory: boolean = false;
  Has_RTF_Rule?: boolean = true;
  Has_RET_Rule?: boolean = false;

  constructor(private configService: SystemConfigService,
    public alertfyService: AlertifyService,
    private _requestService: RequestService,
    public utilityService: UtilityService,
    private authService: AuthenticationService,
    public modalRef: BsModalRef,
    public options: ModalOptions,
    public sumitReq: SubmitRequestService) {
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  ngOnInit(): void {
    this.Data = this.options.initialState as any;
    this.RequestData = this.Data.Request as RequestModel;
    this.IsView = this.Data.IsView;

    this.getProfession();
    this.getSpecialities();
    this.getPrimaryDegree();

    this.bsConfig = Object.assign({}, {
      containerClass: "theme-blue",
      dateInputFormat: 'DD-MMM-YYYY',
      showWeekNumbers: false,
      minDate: this.minDate
    });

    this.MSLRequestForm = new FormGroup({
      TYPE: new FormControl(this.RequestData.REPORT_REQUEST_TYPE ?? ""),
      PRODUCTNAME: new FormControl(""),
      ZIPCODE: new FormControl(this.RequestData.POSTAL_CODE ?? ""),
      MSLID: new FormControl(this.RequestData.MSL_ID ?? ""),
      FIRST_NAME: new FormControl({ value: this.RequestData.FIRST_NAME ?? "", disabled: this.IsView }, Validators.required),
      LAST_NAME: new FormControl({ value: this.RequestData.LAST_NAME ?? "", disabled: this.IsView }, Validators.required),
      FK_PREFERRED_CONTACT_ID: new FormControl({ value: this.RequestData.FK_CONTACT_PREFERENCE_ID ?? "", disabled: this.IsView }, Validators.required),
      EMAIL_ID: new FormControl({ value: this.RequestData.EMAIL_ID ?? "", disabled: this.IsView }, [Validators.required, Validators.email]),
      PHONE: new FormControl({ value: this.RequestData.PHONE ?? "", disabled: this.IsView }),
      PHONE_EXT: new FormControl({ value: this.RequestData.PHONE_EXT ?? "", disabled: this.IsView }),
      MSL_SCHEDULED_DATE: new FormControl({ value: this.RequestData.MSL_SCHEDULED_DATE ? new Date(this.RequestData.MSL_SCHEDULED_DATE) : "", disabled: this.IsView }, Validators.required),
      MSL_SCHEDULED_TIME: new FormControl({ value: this.RequestData.MSL_SCHEDULED_TIME ?? "", disabled: this.IsView }, Validators.required),
      MSL_SCHEDULED_TIME_ZONE: new FormControl({ value: this.RequestData.MSL_SCHEDULED_TIME_ZONE ?? "", disabled: this.IsView }, Validators.required),
      FK_PROFESSION_ID: new FormControl({ value: this.RequestData.FK_PROFESSION_ID ?? "", disabled: this.IsView }, [Validators.required]),
      PROFESSION_NAME: new FormControl(""),
      FK_SPECIALTY_ID: new FormControl({ value: this.RequestData.FK_SPECIALTY_ID ?? "", disabled: this.IsView }),
      SPECIALTY_NAME: new FormControl(""),
      FK_PRIMARY_DEGREE_ID: new FormControl({ value: this.RequestData.FK_PRIMARY_DEGREE_ID ?? "", disabled: this.IsView }),
      PRIMARY_DEGREE_NAME: new FormControl(""),
      CONSENT_TYPE: new FormControl({ value: !this.RequestData.CONSENT_TYPE ? "Accept" : this.RequestData.CONSENT_TYPE, disabled: this.IsView }),
      DPPR_RULE_ID: new FormControl({ value: !this.RequestData.DPPR_RULE_ID ? "" : this.RequestData.DPPR_RULE_ID, disabled: this.IsView }),
      FK_COUNTRY_ID: new FormControl({ value: !this.RequestData.FK_COUNTRY_ID ? "" : this.RequestData.FK_COUNTRY_ID, disabled: this.IsView }),
      COUNTRY_NAME: new FormControl({ value: !this.RequestData.COUNTRY_NAME ? "" : this.RequestData.COUNTRY_NAME, disabled: this.IsView }),
      FK_RTF_RULE_ID: new FormControl({ value: this.RequestData.FK_RTF_RULE_ID ?? "", disabled: this.IsView }),
      RTF_CONSENT_TYPE: new FormControl({ value: this.RequestData.RTF_CONSENT_TYPE ?? "", disabled: this.IsView }),
    });
    this.getPreferedContacts();
    this.getAllCountries(this.RequestData.FK_COUNTRY_ID);
    this.onChanges();
    if (this.MSLRequestForm.controls["FK_COUNTRY_ID"].value && this.MSLRequestForm.controls["FK_PROFESSION_ID"].value) {
      this.consentValidations();
    }
  }

  get form() {
    return this.MSLRequestForm.controls;
  }

  //get minDate() {
  //  let date: Date = new Date();
  //  date.setDate(date.getDate() + 1);
  //  return date;
  //}

  formControlValid(controlName: string): boolean {
    let isValid = true;
    if ((this.MSLRequestForm.controls[controlName].value != null
      && this.MSLRequestForm.controls[controlName].value.trim() == "")
      || (!this.MSLRequestForm.controls[controlName].value)) {
      isValid = false;
    }
    return isValid;
  }

  get enableSaveDraft() {
    let isValid = true;
    this.invalidPreferredEmail = false;
    if (this.MSLRequestForm.controls["FIRST_NAME"].value
      || this.MSLRequestForm.controls["LAST_NAME"].value
      || this.MSLRequestForm.controls["PHONE"].value
      || this.MSLRequestForm.controls["PHONE_EXT"].value
      || this.MSLRequestForm.controls["EMAIL_ID"].value
      || this.MSLRequestForm.controls["FK_PREFERRED_CONTACT_ID"].value
      || this.MSLRequestForm.controls["MSL_SCHEDULED_DATE"].value
      || this.MSLRequestForm.controls["MSL_SCHEDULED_TIME"].value
      || this.MSLRequestForm.controls["MSL_SCHEDULED_TIME_ZONE"].value
      || this.MSLRequestForm.controls["FK_PROFESSION_ID"].value
      || this.MSLRequestForm.controls["FK_SPECIALTY_ID"].value
      || this.MSLRequestForm.controls["FK_PRIMARY_DEGREE_ID"].value
      || this.MSLRequestForm.controls["FK_COUNTRY_ID"].value) {
      isValid = false;
    }
    else if (this.MSLRequestForm.controls["EMAIL_ID"].value && !this.utilityService.validateEmail(this.MSLRequestForm.controls["EMAIL_ID"].value)) {
      isValid = false;
      this.invalidPreferredEmail = true;
    }
    else if (this.MSLRequestForm.controls["EMAIL_ID"].value && this.MSLRequestForm.controls["EMAIL_ID"].errors?.email) {
      isValid = false;
      this.invalidPreferredEmail = true;
    }
    return isValid;
  }

  get enableSubmit() {
    this.invalidPreferredEmail = false;
    let isValid = true;

    let selectedValue = this.preferedContacts.find(x => x.Id == this.MSLRequestForm.controls["FK_PREFERRED_CONTACT_ID"].value);
    if (!this.MSLRequestForm.controls["FK_PROFESSION_ID"].value) {
      isValid = false;
    }
    if (!this.formControlValid("FIRST_NAME")) {
      isValid = false;
    } else if (!this.formControlValid("LAST_NAME")) {
      isValid = false;
    } else if (this.MSLRequestForm.controls["EMAIL_ID"].value &&
      !this.utilityService.validateEmail(this.MSLRequestForm.controls["EMAIL_ID"].value)) {
      this.invalidPreferredEmail = true;
      isValid = false;
    } else if (this.MSLRequestForm.controls["EMAIL_ID"].value &&
      this.MSLRequestForm.controls["EMAIL_ID"].errors?.email) {
      this.invalidPreferredEmail = true;
      isValid = false;
    } else if (!this.MSLRequestForm.controls["MSL_SCHEDULED_DATE"].value) {
      isValid = false;
    } else if (!this.MSLRequestForm.controls["MSL_SCHEDULED_TIME"].value) {
      isValid = false;
    } else if (!this.MSLRequestForm.controls["MSL_SCHEDULED_TIME_ZONE"].value) {
      isValid = false;
    } else if (selectedValue == undefined) {
      isValid = false;
    } else {
      if (selectedValue.ContactCode == "EMAIL" && (this.MSLRequestForm.controls["EMAIL_ID"].value == "" || this.MSLRequestForm.get('EMAIL_ID')?.errors?.email)) {
        isValid = false;
      }
      else if (selectedValue.ContactCode == "PHONE" && !this.MSLRequestForm.controls["PHONE"].value) {
        isValid = false;
      }

      if (this.MSLRequestForm.controls["FK_COUNTRY_ID"].value == null
        || this.MSLRequestForm.controls["FK_COUNTRY_ID"].value == "") {
        isValid = false;
      }
      if (this.MSLRequestForm.controls["CONSENT_TYPE"].errors) {
        isValid = false;
      }
    }
    return isValid;
  }

  onChanges() {
    const contactPreferenceControl = <FormControl>this.MSLRequestForm.get('FK_PREFERRED_CONTACT_ID');
    const preferredEmailControl = <FormControl>this.MSLRequestForm.get('EMAIL_ID');
    const preferredPhoneControl = <FormControl>this.MSLRequestForm.get('PHONE');
    const professionControl = <FormControl>this.MSLRequestForm.get('FK_PROFESSION_ID');
    const countryControl = <FormControl>this.MSLRequestForm.get('FK_COUNTRY_ID');

    setTimeout(() => {
      if (contactPreferenceControl.value) {
        this.invalidPreferredEmail = false;
        let selectedValue = this.preferedContacts.find(x => x.Id == this.MSLRequestForm.controls["FK_PREFERRED_CONTACT_ID"].value);
        if (selectedValue != undefined && selectedValue.ContactCode == "EMAIL") {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = true;
          this.MSLRequestForm.get('EMAIL_ID')?.setValidators([Validators.required, Validators.email]);
          //this.MSLRequestForm.get('PHONE')?.clearValidators();
          this.MSLRequestForm.get('PHONE')?.setValidators([Validators.pattern(/[^0-9()-]*/g)]);
        }
        else if (selectedValue != undefined && selectedValue.ContactCode == "PHONE") {
          this.isPhoneMandatory = true;
          this.isEmailMandatory = false;
          this.MSLRequestForm.get('PHONE')?.setValidators([Validators.required, Validators.pattern(/[^0-9()-]*/g)]);
          //this.MSLRequestForm.get('EMAIL_ID')?.clearValidators();
          this.MSLRequestForm.get('EMAIL_ID')?.setValidators([Validators.email]);
        }
        else {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = false;
        }
      }
    }, 100);

    contactPreferenceControl.valueChanges
      .subscribe(value => {
        this.invalidPreferredEmail = false;
        let selectedValue = this.preferedContacts.find(x => x.Id == this.MSLRequestForm.controls["FK_PREFERRED_CONTACT_ID"].value);
        if (selectedValue != undefined && selectedValue.ContactCode == "EMAIL") {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = true;
          this.MSLRequestForm.get('EMAIL_ID')?.setValidators([Validators.required, Validators.email]);
          //this.MSLRequestForm.get('PHONE')?.clearValidators();
          this.MSLRequestForm.get('PHONE')?.setValidators([Validators.pattern(/[^0-9()-]*/g)]);
        }
        else if (selectedValue != undefined && selectedValue.ContactCode == "PHONE") {
          this.isPhoneMandatory = true;
          this.isEmailMandatory = false;
          this.MSLRequestForm.get('PHONE')?.setValidators([Validators.required, Validators.pattern(/[^0-9()-]*/g)]);
          //this.MSLRequestForm.get('EMAIL_ID')?.clearValidators();
          this.MSLRequestForm.get('EMAIL_ID')?.setValidators([Validators.email]);
        }
        else {
          this.isPhoneMandatory = false;
          this.isEmailMandatory = false;
        }
        preferredEmailControl.updateValueAndValidity();
        preferredPhoneControl.updateValueAndValidity();
      });

    professionControl.valueChanges.subscribe(value => {
      this.consentValidations();
    });

    countryControl.valueChanges.subscribe(value => {
      this.consentValidations();
    });

  }

  getPreferedContacts() {
    this.configService.getPreferredContact().subscribe({
      next: data => {
        this.preferedContacts = data;
      },
      error: err => {
        console.error(err);
      }
    });
  }

  validateForm(): boolean {
    this.MSLRequestForm.markAsTouched();
    let hasValid = true;
    if (!this.MSLRequestForm.valid) {
      var errrs: any = [];
      if (!this.MSLRequestForm.controls["FK_PROFESSION_ID"].value) {
        errrs.push("Please select Profession.");
      }
      if (!this.MSLRequestForm.controls["FIRST_NAME"].valid) {
        errrs.push("Please enter First Name.");
      }
      if (!this.MSLRequestForm.controls["LAST_NAME"].valid) {
        errrs.push("Please enter Last Name.");
      }

      if (this.MSLRequestForm.controls["EMAIL_ID"].value != null &&
        this.MSLRequestForm.controls["EMAIL_ID"].value != "" &&
        !this.utilityService.validateEmail(this.MSLRequestForm.controls["EMAIL_ID"].value)) {
        errrs.push("Please enter valid Email.");
        this.invalidPreferredEmail = true;
        hasValid = false;
      }

      if (this.MSLRequestForm.controls["EMAIL_ID"].value &&
        this.MSLRequestForm.controls["EMAIL_ID"].errors?.email) {
        errrs.push("Please enter valid Email.");
        this.invalidPreferredEmail = true;
        hasValid = false;
      }

      if (this.MSLRequestForm.controls["FK_PREFERRED_CONTACT_ID"].valid) {
        let selectedValue = this.preferedContacts.find(x => x.Id == this.MSLRequestForm.controls["FK_PREFERRED_CONTACT_ID"].value);
        if (selectedValue != undefined &&
          selectedValue.ContactCode == "EMAIL") {
          if (this.MSLRequestForm.controls["EMAIL_ID"].value == "") {
            errrs.push("Please enter Email.");
            hasValid = false;
          }
          else if (!this.utilityService.validateEmail(this.MSLRequestForm.controls["EMAIL_ID"].value)) {
            errrs.push("Please enter valid Email.");
            this.invalidPreferredEmail = true;
            hasValid = false;
          }
        }
        else if (selectedValue != undefined &&
          selectedValue.ContactCode == "PHONE") {
          if (this.MSLRequestForm.controls["PHONE"].value == "" ||
            !this.MSLRequestForm.controls["PHONE"].valid) {
            errrs.push("Please enter Phone.");
            hasValid = false;
          }
        }
      }
      if (!this.MSLRequestForm.controls["MSL_SCHEDULED_DATE"].valid) {
        errrs.push("Please enter Date.");
        hasValid = false;
      }

      if (!this.MSLRequestForm.controls["FK_COUNTRY_ID"].valid) {
        errrs.push("Please select country.");
      }
      if (this.MSLRequestForm.controls["CONSENT_TYPE"].errors) {
        errrs.push("Please select CONSENT TYPE.");
      }

    }
    return hasValid;
  }

  draftValidations(): boolean {
    this.invalidPreferredEmail = false;
    this.MSLRequestForm.markAsTouched();
    let hasValid = true;
    if (!this.MSLRequestForm.valid) {
      var errrs: any = [];
      if (this.MSLRequestForm.controls["FIRST_NAME"].value && !this.MSLRequestForm.controls["FIRST_NAME"].valid) {
        errrs.push("Please enter First Name.");
        hasValid = false;
      }
      if (this.MSLRequestForm.controls["LAST_NAME"].value && !this.MSLRequestForm.controls["LAST_NAME"].valid) {
        errrs.push("Please enter Last Name.");
        hasValid = false;
      }

      if (this.MSLRequestForm.controls['EMAIL_ID'].value &&
        !this.utilityService.validateEmail(this.MSLRequestForm.controls["EMAIL_ID"].value)) {
        errrs.push("Please enter valid Email.");
        this.invalidPreferredEmail = true;
        hasValid = false;
      }

      if (this.MSLRequestForm.controls["EMAIL_ID"].value &&
        this.MSLRequestForm.controls["EMAIL_ID"].errors?.email) {
        errrs.push("Please enter valid Email.");
        this.invalidPreferredEmail = true;
        hasValid = false;
      }
      if (this.MSLRequestForm.controls["MSL_SCHEDULED_DATE"].value && !this.MSLRequestForm.controls["MSL_SCHEDULED_DATE"].valid) {
        errrs.push("Please enter Date.");
        hasValid = false;
      }
    }
    return hasValid;
  }


  SendRequest(requestStatus: string) {
    this.cleanForm();
    if (requestStatus == "SUBMITTED")
      this.submitted = true;
    if ((requestStatus == "SUBMITTED" && this.validateForm())
      || requestStatus == "DRAFT" && this.draftValidations()) {
      let typeid = 2;
      this.MSLRequestForm.controls["TYPE"].setValue(typeid);
      this.MSLRequestForm.controls["PRODUCTNAME"].setValue(this.RequestData.PRODUCT_NAME);
      this.MSLRequestForm.controls["ZIPCODE"].setValue(this.RequestData.POSTAL_CODE);
      this.MSLRequestForm.controls["MSLID"].setValue(this.RequestData.MSL_ID);
      var formData = this.MSLRequestForm.value;

      let request: RequestModel = <RequestModel>
        {
          PK_REQUEST_ID: this.RequestData.PK_REQUEST_ID,
          FK_USER_ID: this.RequestData.FK_USER_ID,
          USER_ID: this.RequestData.USER_ID,
          REPORT_REQUEST_TYPE: "MSL_MEETING",
          FK_REPORT_REQUEST_TYPE: "MSL_MEETING",
          PRODUCT_APPROVAL_ID: this.RequestData.PRODUCT_APPROVAL_ID,
          REQUEST_TYPE: "MI",
          STATUS: requestStatus,
          FIRST_NAME: this.MSLRequestForm.controls["FIRST_NAME"].value,
          LAST_NAME: this.MSLRequestForm.controls["LAST_NAME"].value,
          PHONE: this.MSLRequestForm.controls["PHONE"].value,
          PHONE_EXT: this.MSLRequestForm.controls["PHONE_EXT"].value,
          MSL_ID: this.RequestData.MSL_ID,
          MSL_NAME: this.RequestData.MSL_NAME,
          MSL_FIRST_NAME: this.RequestData.MSL_FIRST_NAME,
          MSL_LAST_NAME: this.RequestData.MSL_LAST_NAME,
          MSL_PHONE: this.RequestData.MSL_PHONE,
          MSL_EMAIL_ID: this.RequestData.MSL_EMAIL_ID,
          MSL_ZIPCODE: this.RequestData.MSL_ZIPCODE,
          MSL_SCHEDULED_DATE: this.MSLRequestForm.controls["MSL_SCHEDULED_DATE"].value,
          MSL_SCHEDULED_TIME: this.MSLRequestForm.controls["MSL_SCHEDULED_TIME"].value,
          MSL_SCHEDULED_TIME_ZONE: this.MSLRequestForm.controls["MSL_SCHEDULED_TIME_ZONE"].value,
          FK_PROFESSION_ID: this.MSLRequestForm.controls["FK_PROFESSION_ID"].value,
          FK_SPECIALTY_ID: this.MSLRequestForm.controls["FK_SPECIALTY_ID"].value,
          FK_PRIMARY_DEGREE_ID: this.MSLRequestForm.controls["FK_PRIMARY_DEGREE_ID"].value,
          DPPR_RULE_ID: this.MSLRequestForm.controls["DPPR_RULE_ID"].value,
          FK_COUNTRY_ID: this.MSLRequestForm.controls["FK_COUNTRY_ID"].value,
          CONSENT_TYPE: this.MSLRequestForm.controls["CONSENT_TYPE"].value,
        }
      request.CONSENT_TYPE = this.MSLRequestForm.controls["CONSENT_TYPE"].value;

      if (this.MSLRequestForm.controls["FK_PREFERRED_CONTACT_ID"].value)
        request.FK_CONTACT_PREFERENCE_ID = this.MSLRequestForm.controls["FK_PREFERRED_CONTACT_ID"].value;
      request.EMAIL_ID = this.MSLRequestForm.controls["EMAIL_ID"].value;

      let profileinfo: PortalProfiles = {};
      profileinfo = this.utilityService.getSelectedProfile();
      if (profileinfo.PK_ENV_PROFILE_ID) {
        formData["FK_REPORTER_TYPE_ID"] = profileinfo.PK_ENV_PROFILE_ID;
      }

      this._requestService.saveRequest(request)
        .subscribe({
          next: res => {
            if (res) {
              if (res.Status) {
                if (requestStatus == "DRAFT") {
                  this.alertfyService.successBaner("Your request " + res.ResponseID + " is saved successfully.");
                  this.OnSubmit.emit(true);
                }
                else if (requestStatus == "SUBMITTED") {
                  this.alertfyService.successBaner("Your request " + res.ResponseID + " is submitted successfully.");
                  this.OnSubmit.emit(true);
                }
                this.close();
              }
              else {
                this.alertfyService.errorBaner(res.ErrorMessage ?? "");
              }
            }
            else {
              this.alertfyService.errorBaner('Unknown Error..!');
            }
          },
          error: err => {
            this.alertfyService.error(err);
          }
        });
    }
  }

  public cleanForm() {
    let count = 0;
    if (this.MSLRequestForm != null && this.MSLRequestForm != undefined) {
      Object.keys(this.MSLRequestForm.controls).forEach(key => {

        let value: any = this.MSLRequestForm.controls[key].value;
        if (this.MSLRequestForm.controls[key].value != null && typeof (this.MSLRequestForm.controls[key].value) === "string"
          && this.MSLRequestForm.controls[key].value !== "") {
          value = this.MSLRequestForm.controls[key].value.trim();
        }
        else if (this.MSLRequestForm.controls[key].value == "") {
          value = value != "" ? value : null;
        }

        if (key !== "FILE_NAME" && key != "POSTAL_CODE" && key != "ZIP_CODE" && key != "MSL_SCHEDULED_TIME" && key != "MSL_SCHEDULED_TIME_ZONE" && key != "FK_PREFERRED_CONTACT_ID") {
          this.MSLRequestForm.controls[key].setValue(value);
        }

      });
    }
  }

  close() {
    this.modalRef.hide();
  }

  cancel() {
    this.alertfyService.ConfirmationBanner("Do you want to Abort the Request ?", this.cancelCallback, "Confirm");
  }

  cancelCallback = (result: any) => {
    if (result) {
      this.close()
    }
  }

  handleInput($event: any, controlName: string): boolean {
    if (controlName != "DESCRIPTION") {
      $event.value = this.utilityService.replaceEmojis($event.value);
    }
    if ($event && $event.value != "") {
      const control = this.MSLRequestForm.controls[controlName];
      $event.value = $event.value.trim();
      control.setValue($event.value);
      return false;
    }

    return true;
  }

  MinMaxDateValidation(event: any, fcn: string, MinMaxFlag?: string, MinMaxDateVal?: Date) {
    this.utilityService.ValidateDate(event.target.value, fcn, MinMaxFlag, MinMaxDateVal, this.MSLRequestForm);
  }

  getProfession() {
    let profiletype_id = "";
    let profileinfo: PortalProfiles = {};
    profileinfo = this.utilityService.getSelectedProfile();
    if (profileinfo) {
      profiletype_id = profileinfo.PK_ENV_PROFILE_ID ?? '';
    }
    if (profiletype_id) {
      let profId = this.RequestData.STATUS == "DRAFT" ? 0 : parseInt(this.RequestData.FK_PROFESSION_ID);
      this.configService.getProfessionByProfile(profiletype_id, profId).subscribe({
        next: (data) => {
          this.professionList = data;
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
  }

  getSpecialities() {
    let profId: number = this.RequestData.STATUS == "DRAFT" ? 0 : this.RequestData.FK_SPECIALTY_ID;
    this.configService.getLookUps('SPECIALITY',profId).subscribe({
      next: (data) => {
        this.specialityList = data;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  getPrimaryDegree() {
    let degreeId: number = this.RequestData.STATUS == "DRAFT" ? 0 : this.RequestData.FK_PRIMARY_DEGREE_ID;
    this.configService.getLookUps('PRIMARY_DEGREE',degreeId).subscribe({
      next: (data) => {
        this.primaryDegreeList = data;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  getAllCountries(countryId: number | null | undefined) {
    countryId=this.RequestData.STATUS=='DRAFT'?0:this.RequestData.FK_COUNTRY_ID;
    this.subscribeDisplayCountries = this.configService.getCountriesTypeHeadData(countryId).subscribe({
      next: (res) => {
        this.allCountries = res;
        if (countryId != null) {
          let country = this.allCountries.find(x => x.PKCountryId == countryId);
          if (country) {
            this.selectedCountry = country?.CountryDisplayName ?? '';
            this.MSLRequestForm.controls["FK_COUNTRY_ID"].setValue(country?.PKCountryId);
          }
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }


  //consentValidations() {
  //  const consentTypeControl = <FormControl>this.MSLRequestForm.get('CONSENT_TYPE');
  //  this.isConsentMandatory = false;
  //  //this.MSLRequestForm.controls['DPPR_RULE_ID'].setValue("");
  //  //this.MSLRequestForm.get('CONSENT_TYPE')?.setValue("");
  //  if (this.MSLRequestForm.controls['FK_PROFESSION_ID'].value && this.MSLRequestForm.controls['FK_COUNTRY_ID'].value) {
  //    let dppr_request: ApplicableRuleRequest = {
  //      CountryId: this.MSLRequestForm.controls['FK_COUNTRY_ID'].value,
  //      ProfessionId: this.MSLRequestForm.controls['FK_PROFESSION_ID'].value,
  //    };
  //    let profileinfo: PortalProfiles = {};
  //    profileinfo = this.utilityService.getSelectedProfile();
  //    dppr_request.ProfileId = profileinfo.PK_ENV_PROFILE_ID;
  //    this.sumitReq.getApplicableRule(dppr_request).subscribe({
  //      next: data => {
  //        if (data && data.retentionRule && data.Status) {
  //          this.MSLRequestForm.controls['DPPR_RULE_ID'].setValue(data.retentionRule.RuleId);
  //          if (data.retentionRule.IsConsentRequired) {
  //            this.isConsentMandatory = true;
  //            this.MSLRequestForm.get('CONSENT_TYPE')?.setValidators([Validators.required]);
  //          }
  //          else {
  //            this.isConsentMandatory = false;
  //            this.MSLRequestForm.get('CONSENT_TYPE')?.clearValidators();
  //          }
  //        }
  //        else {
  //          this.MSLRequestForm.controls['DPPR_RULE_ID'].setValue("");
  //          if (data.ErrorMessage) {
  //            this.alertfyService.errorBaner(data.ErrorMessage);
  //          }
  //          else {
  //            this.alertfyService.errorBaner("Oops! Please try after sometime.");
  //          }
  //        }
  //        consentTypeControl.updateValueAndValidity();
  //      },
  //      error: err => {
  //        this.MSLRequestForm.controls['DPPR_RULE_ID'].setValue("");
  //        console.error(err);
  //      }
  //    });
  //  }
  //}


  consentValidations() {
    const consentTypeControl = <FormControl>this.MSLRequestForm.get('CONSENT_TYPE');
    this.isConsentMandatory = false;
    this.Has_RTF_Rule = false;
    this.Has_RET_Rule = false;
    if (this.MSLRequestForm.controls['FK_PROFESSION_ID'].value && this.MSLRequestForm.controls['FK_COUNTRY_ID'].value) {
      let dppr_request: ApplicableRuleRequest = {
        CountryId: this.MSLRequestForm.controls['FK_COUNTRY_ID'].value,
        ProfessionId: this.MSLRequestForm.controls['FK_PROFESSION_ID'].value,
      };
      let profileinfo: PortalProfiles = {};
      profileinfo = this.utilityService.getSelectedProfile();
      dppr_request.ProfileId = profileinfo.PK_ENV_PROFILE_ID;
      this.sumitReq.getApplicableRule(dppr_request).subscribe({
        next: data => {
          if (data && data.Status) {
            if (data.retentionRule && data.retentionRule.Status) {
              this.Has_RET_Rule = data.retentionRule.HasRule;
              this.MSLRequestForm.controls['DPPR_RULE_ID'].setValue(data.retentionRule.RuleId);
              if (data.retentionRule.IsConsentRequired) {
                this.isConsentMandatory = true;
                this.MSLRequestForm.get('CONSENT_TYPE')?.setValidators([Validators.required]);
              }
              else {
                this.isConsentMandatory = false;
                this.MSLRequestForm.get('CONSENT_TYPE')?.clearValidators();
              }
            }
            else {
              this.MSLRequestForm.controls['DPPR_RULE_ID'].setValue("");
              this.isConsentMandatory = false;
              this.MSLRequestForm.get('CONSENT_TYPE')?.clearValidators();
            }
            if (data.rightToForget && data.rightToForget.Status) {
              this.Has_RTF_Rule = data.rightToForget.HasRule;
              this.MSLRequestForm.controls['FK_RTF_RULE_ID'].setValue(data.rightToForget.RuleId);
            }
            else {
              this.MSLRequestForm.controls['FK_RTF_RULE_ID'].setValue("");
            }
          }
          else {
            this.MSLRequestForm.controls['DPPR_RULE_ID'].setValue("");
            this.MSLRequestForm.controls['FK_RTF_RULE_ID'].setValue("");
            this.MSLRequestForm.get('CONSENT_TYPE')?.clearValidators();
            if (data.ErrorMessage) {
              this.alertfyService.errorBaner(data.ErrorMessage);
            }
            else {
              this.alertfyService.errorBaner("Oops! Please try after sometime.");
            }
          }
          consentTypeControl.updateValueAndValidity();
        },
        error: err => {
          this.MSLRequestForm.controls['DPPR_RULE_ID'].setValue("");
          this.MSLRequestForm.controls['FK_RTF_RULE_ID'].setValue("");
          this.MSLRequestForm.get('CONSENT_TYPE')?.clearValidators();
          console.error(err);
        }
      });
    }
  }
}
